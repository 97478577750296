exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".widget-builder__widgetBuilderFrame-6E5{display:flex;border-radius:20px;border:2px dashed var(--colorsGeneralBlue);background:var(--colorsGeneralWhite);padding:32px;gap:32px;font-size:var(--fontsSizesBodyNormal);transform:translate(0)}.widget-builder__widgetPreviewWrapper-SLw{display:flex;flex-direction:column;flex:1;gap:12px;width:calc(100% - 300px)}.widget-builder__widgetPreview-2BG{flex:1;background:var(--colorsBackgroundLightGray);padding:32px;border-radius:20px;display:flex;align-items:center;justify-content:center;position:relative}.widget-builder__emptyWidgetPreview-JJM{font-size:var(--fontsSizesTitleH4);color:var(--colorsGeneralBlack);font-weight:var(--fontsWeightBold)}.widget-builder__widgetProperties-3Yb{color:var(--colorsTextGray);width:300px;display:flex;flex-direction:column;gap:16px;z-index:4}.widget-builder__widgetProperties-3Yb .widget-builder__row-1Dc{display:flex;flex-direction:column;gap:8px;position:relative}.widget-builder__chartIcon-3ia{background:var(--colorsGeneralBlack);-webkit-mask:url(/assets/linesChart.svg) center center no-repeat;mask:url(/assets/linesChart.svg) center center no-repeat;height:18px;width:18px}.widget-builder__filtersPopup-16o{position:fixed;left:240px;top:-20px}.widget-builder__timeFrameAsText-3_R{font-size:var(--fontsSizesBodyNormal);color:var(--colorsTextGray);position:absolute;bottom:32px;left:32px}.widget-builder__widgetPreviewButton-5yF{display:flex;align-items:center;gap:12px;margin-left:auto}.widget-builder__toggle-3Wb{display:flex}.widget-builder__selectColumnsButton-2c2{width:unset}.widget-builder__selectColumnsButtonContainer-w1U{flex:1}.widget-builder__widgetBuilderFrame-6E5 .widget-builder__popupOptionsWrapper-3jK{max-height:160px}", ""]);

// exports
exports.locals = {
	"widgetBuilderFrame": "widget-builder__widgetBuilderFrame-6E5",
	"widgetBuilderFrame": "widget-builder__widgetBuilderFrame-6E5",
	"widgetPreviewWrapper": "widget-builder__widgetPreviewWrapper-SLw",
	"widgetPreviewWrapper": "widget-builder__widgetPreviewWrapper-SLw",
	"widgetPreview": "widget-builder__widgetPreview-2BG",
	"widgetPreview": "widget-builder__widgetPreview-2BG",
	"emptyWidgetPreview": "widget-builder__emptyWidgetPreview-JJM",
	"emptyWidgetPreview": "widget-builder__emptyWidgetPreview-JJM",
	"widgetProperties": "widget-builder__widgetProperties-3Yb",
	"widgetProperties": "widget-builder__widgetProperties-3Yb",
	"row": "widget-builder__row-1Dc",
	"row": "widget-builder__row-1Dc",
	"chartIcon": "widget-builder__chartIcon-3ia",
	"chartIcon": "widget-builder__chartIcon-3ia",
	"filtersPopup": "widget-builder__filtersPopup-16o",
	"filtersPopup": "widget-builder__filtersPopup-16o",
	"timeFrameAsText": "widget-builder__timeFrameAsText-3_R",
	"timeFrameAsText": "widget-builder__timeFrameAsText-3_R",
	"widgetPreviewButton": "widget-builder__widgetPreviewButton-5yF",
	"widgetPreviewButton": "widget-builder__widgetPreviewButton-5yF",
	"toggle": "widget-builder__toggle-3Wb",
	"toggle": "widget-builder__toggle-3Wb",
	"selectColumnsButton": "widget-builder__selectColumnsButton-2c2",
	"selectColumnsButton": "widget-builder__selectColumnsButton-2c2",
	"selectColumnsButtonContainer": "widget-builder__selectColumnsButtonContainer-w1U",
	"selectColumnsButtonContainer": "widget-builder__selectColumnsButtonContainer-w1U",
	"popupOptionsWrapper": "widget-builder__popupOptionsWrapper-3jK",
	"popupOptionsWrapper": "widget-builder__popupOptionsWrapper-3jK"
};