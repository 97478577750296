import React from 'react';
import moment from 'moment';
import history from 'history';
import HTML5Backend from 'react-dnd-html5-backend';
import IdleTimer from 'react-idle-timer';
import { withRouter } from 'react-router';
import { DragDropContext } from 'react-dnd';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { get, isEmpty, mapValues } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import userStore from 'stores/userStore';
import attributionStore from 'stores/attributionStore';
import servicesStore from 'stores/servicesStore';
import reportsStore from 'stores/reports/reportsStore';
import filterStore from 'stores/filterStore';
import interactionsStore from 'stores/interactionsStore';

import config from 'components/utils/Configuration';
import { extendedData, calculatedDataExtender } from 'dataExtenders/calculatedDataExtender';
import serverCommunication from 'data/serverCommunication';
import Component from 'components/Component';
import UnsavedPopup from 'components/UnsavedPopup';
import PlanLoading from 'components/pages/plan/PlanLoading';
import Loader from 'components/controls/Loader';
import LDIdentify from 'components/LDIdentify';
import SideBarNavigationMenu from 'components/navigationMenu/SideBarNavigationMenu';
import HeaderBar from 'components/navigationMenu/HeaderBar';
import NewMemberRoleDataPopup from 'components/actionRequiredPopups/NewMemberRoleDataPopup';
import KeepAliveModule from 'modules/keepAlive';

import { initialize as initializeIndicators, newIndicatorMapping, NEW_FUNNEL_PREFIX } from 'components/utils/indicators';
import { initialize as initializeChannels } from 'components/utils/channels';
import { localStorageKeys } from 'enums';
import { masterRegion } from 'components/enums';
import { TIMEFRAME_VALUES } from 'components/utils/timeframe';
import { getUnmappedKey, getUnmappedKeyName, QUERY_LIMIT } from 'components/utils/settings';
import { isPopupMode } from 'modules/popup-mode';
import { getSelectedStagesDefault } from 'components/pages/analyze/optimalJourneyTab/logic/optimalJourneysTab';

import style from 'styles/app.css';
import '@infinigrow/libs/dist/common/theme/theme.module.css';

const isScheduleTaskRunning = false;

class AppComponent extends Component {
  style = style;

  constructor(props) {
    super(props);
    this.routerWillLeave = this.routerWillLeave.bind(this);
    this.handleCallback = this.handleCallback.bind(this);

    this.idleTimeout = 1000 * 60 * 30;
    this.idleTimerRef = React.createRef();

    this.APIMethods = {
      getUserMonthPlan: this.getUserMonthPlan.bind(this),
      updateUserMonthPlan: this.updateUserMonthPlan.bind(this),
      updateUserAccount: this.updateUserAccount.bind(this),
      createUserMonthPlan: this.createUserMonthPlan.bind(this),
      createUserAccount: this.createUserAccount.bind(this),
      updateState: this.updateState.bind(this),
      setDataAsState: this.setDataAsState.bind(this),
      setNewGlobalState: this.setNewGlobalState.bind(this),
      unsaved: false,
      getUserAccount: this.getUserAccount.bind(this),
      sendSnippetEmail: this.sendSnippetEmail.bind(this),
      addUnknownChannel: this.addUnknownChannel.bind(this),
      getScriptValidator: this.getScriptValidator.bind(this),
      runScheduleTask: this.runScheduleTask.bind(this),
      updateTimerTime: this.updateTimerTime.bind(this),
      doneRunningScheduleTask: this.doneRunningScheduleTask.bind(this),
      getUnmappedData: this.getUnmappedData.bind(this),
      updateCustomModules: this.updateCustomModules,
    };

    this.state = {
      extendedUserData: null,
      loaded: false,
      regionLoad: false,
      isScheduleTaskRunning,
      setGroupByMapping: this.setGroupByMapping,
      journeyFilter: null,
      roleDataLoaded: true,
    };
  }

  // Asynchronous version of `setRouteLeaveHook`.
  // Instead of synchronously returning a result, the hook is expected to
  // return a promise.
  setAsyncRouteLeaveHook(router, hook) {
    let withinHook = false;
    let finalResult;
    let finalResultSet = false;
    router.listenBefore((nextLocation) => {
      withinHook = true;
      if (!finalResultSet) {
        hook(nextLocation).then((result) => {
          this.handleCallback(result);
          finalResult = result;
          finalResultSet = true;
          if (!withinHook && nextLocation) {
            // Re-schedule the navigation
            router.push(nextLocation);
          }
        });
      }
      const result = finalResultSet ? finalResult : false;
      withinHook = false;
      finalResult = undefined;
      finalResultSet = false;
      return result;
    });
  }

  routerWillLeave() {
    return new Promise((resolve) => {
      if (!this.state.unsaved) {
        // No unsaved changes -- leave
        resolve(true);
      } else {
        // Unsaved changes -- ask for confirmation
        /**
         vex.dialog.confirm({
          message: 'There are unsaved changes. Leave anyway?' + nextLocation,
          callback: result => resolve(result)
        })
         * */
        this.setState({ showUnsavedPopup: true, callback: resolve });
      }
    });
  }

  handleCallback(userAnswer) {
    if (this.state.showUnsavedPopup) {
      if (userAnswer && this.state.unsaved) {
        this.getUserMonthPlan(localStorage.getItem('region'));
      }
      this.setState({ showUnsavedPopup: false });
    }
  }

  componentDidMount() {
    this.setAsyncRouteLeaveHook(this.props.router, this.routerWillLeave);
    this.getUserAccount()
      .then(() => {
        Promise.all([
          this.getRegions(),
          this.getIndicatorsMetadata(),
          this.getGlobalChannelsSchema(),
          this.getCurrencies(),
          this.getUserMonthPlan(localStorage.getItem('region')),
          reportsStore.getReportsWithWidgetsMetadataRequest(),
          KeepAliveModule.start({ UID: this.state.UID }),
        ])
          .then(() => {
            const tasks = [
              this.updateAllRegionsDataIfNeeded,
            ];
            if (!isPopupMode()) {
              tasks.push(userStore.getUserMetrics());
            }
            return Promise.all(tasks);
          })
          .then(() => {
            attributionStore.setMetricOptions();
            interactionsStore.createInteractionTracker();

            const { extendedUserData } = this.state;
            if (extendedUserData && !isEmpty(extendedUserData)) {
              filterStore.getUserFiltersData();
              this.initAnalyze();
            }
            this.setState({ loaded: true });
            userStore.getCommonSegments();
            return this.getCrmLastUpdateTimes();
          });
      })
      .catch((error) => {
        servicesStore.logger.error('failed to load the app', {
          error,
          UID: this.state.UID,
        });
      });

    document.title = 'InfiniGrow';
  }

  initAnalyze = () => {
    const {
      isLoaded,
      setTimeFrame,
      setConversionIndicator,
      conversionIndicator: persistedIndicator,
      optimalJourneyStages,
      metricsOptions,
    } = attributionStore;
    if (!isLoaded) {
      const customDates = get(this.state.userAccount, 'customDates');
      if (!persistedIndicator) {
        setConversionIndicator(newIndicatorMapping()[`${NEW_FUNNEL_PREFIX}1`]);
      }
      if (customDates) {
        const { startDate, endDate } = customDates;
        setTimeFrame(TIMEFRAME_VALUES.CUSTOM, { startDate, endDate });
      }
      if (!optimalJourneyStages) {
        attributionStore.setOptimalJourneyStages({ stages: getSelectedStagesDefault({ stages: metricsOptions }), isInitialState: true });
      }
    }
  };

  updateAllRegionsDataIfNeeded = async () => {
    const callback = () => (userStore.userRegionsNicknames?.length ? Promise.resolve() : this.getRegions());
    await callback();
    if (userStore.userRegionsNicknames.includes(masterRegion)) {
      const masterRegionValue = userStore.userBusinessUnitsWithIds?.find((userBusinessUnit) => userBusinessUnit.name === masterRegion).region;
      return this.getMasterUserMonthPlans(masterRegionValue);
    } else {
      return Promise.resolve();
    }
  };

  updateState(newState, callback, calculateData) {
    if (newState.userChannelsSchema) {
      initializeChannels(this.state.channelsSchema, newState.userChannelsSchema);
    }
    this.setState((prevState) => ({
      ...newState,
      extendedUserData: {
        ...prevState.extendedUserData,
        ...newState,
      },
    }), callback);

    if (calculateData) {
      this.setExtendedUserData({
        ...this.state,
        ...newState,
      });
    }

    this.setState({
      unsaved: newState.unsaved === undefined ? true : newState.unsaved,
    });
  }

  setExtendedUserData(data) {
    const extendedUserData = this.getExtendedState(data);
    return this.setAsyncState({ extendedUserData });
  }

  updateUserMonthPlan(body, region, dontSetState, isV2) {
    const route = isV2 ? 'v2/usermonthplan' : 'usermonthplan';
    this.setState({ unsaved: false });
    return new Promise((resolve, reject) => {
      serverCommunication.serverRequest('PUT', route, JSON.stringify(body), region)
        .then((response) => {
          if (response.ok) {
            if (isV2) {
              resolve();
            } else {
              response.json()
                .then((data) => {
                  if (!dontSetState) {
                    initializeIndicators(this.state.indicatorsSchema, data.userIndicatorsSchema);
                    this.setDataAsState(data).then((res) => this.setExtendedUserData(res));
                  }
                  resolve(data);
                });
            }
          } else if (response.status === 401) {
            history.push('/');
            reject();
          }
        })
        .catch((error) => {
          servicesStore.logger.error('failed to update user month plan', {
            error,
            UID: this.state.UID,
            region,
          });
          reject();
        });
    });
  }

  getMasterUserMonthPlans(region) {
    return serverCommunication.serverRequest('GET', 'masterUsermonthplan', null, region)
      .then((response) => {
        if (response.ok) {
          return response.json()
            .then((data) => {
              if (data) {
                const extendedRegionData = mapValues(data, (regionData) => {
                  const { userMonthPlanDataFromState } = extendedData(regionData);
                  return userMonthPlanDataFromState;
                });
                userStore.setUserFunnels(extendedRegionData.master);
                attributionStore.setMetricOptions();
                return this.updateState({ allRegionsData: data, extendedRegionData, unsaved: false });
              }

              return null;
            });
        } else if (response.status === 401) {
          history.push('/');
        }

        return null;
      })
      .catch((error) => {
        servicesStore.logger.error('failed to get master user month plan', {
          error,
          UID: this.state.UID,
          region,
        });
      });
  }

  setAsyncState = (newState, resolveValue) => new Promise((resolve) => { this.setState(newState, () => resolve(resolveValue)); });

  getUserMonthPlanWithOptions(region, endpoint) {
    this.setState({ unsaved: false });
    return serverCommunication.serverRequest('GET', endpoint, null, region)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 401) {
          history.push('/');
          throw new Error();
        }

        return null;
      })
      .then((data) => {
        if (data) {
          initializeIndicators(this.state.indicatorsSchema, data.userIndicatorsSchema);
          return this.setDataAsState(data);
        }

        return null;
      })
      .then((stateExtended) => (stateExtended ? this.setExtendedUserData(stateExtended) : null))
      .catch((error) => {
        servicesStore.logger.error('failed to get user month plan with options', {
          error,
          UID: this.state.UID,
          region,
        });
        throw new Error();
      });
  }

  getUserMonthPlan(region) {
    return this.getUserMonthPlanWithOptions(region, 'usermonthplan');
  }

  getUserAccount() {
    return new Promise((resolve, reject) => {
      serverCommunication.serverRequest('GET', 'useraccount')
        .then((response) => {
          if (response.ok) {
            response.json()
              .then((data) => {
                if (data) {
                  userStore.setApiMethods(this.APIMethods);
                  userStore.setUserAccount(data);
                  userStore.setUserChannelsSchema(data.userChannelsSchema);
                  initializeChannels(this.state.channelsSchema, data.userChannelsSchema);
                  this.setAsyncState({
                    userAccount: data,
                    userCompany: data.companyName,
                    companyWebsite: data.companyWebsite,
                    logoURL: data.companyWebsite ? `https://logo.clearbit.com/${data.companyWebsite}` : '',
                    teamMembers: data.teamMembers,
                    userChannelsSchema: data.userChannelsSchema,
                    campaignKeyToNameMapping: data.campaignKeyToNameMapping,
                    isSSO: data.SSO && data.SSO.active,
                  })
                    .then(() => resolve(data));
                } else {
                  resolve(null);
                }
              });
          } else if (response.status === 401) {
            history.push('/');
            reject();
          }
        })
        .catch((error) => {
          servicesStore.logger.error('failed to get user account', {
            error,
            UID: this.state.UID,
          });
          reject();
        });
    });
  }

  updateUserAccount(body) {
    this.setState({ unsaved: false });
    return new Promise((resolve, reject) => {
      serverCommunication.serverRequest('PUT', 'useraccount', JSON.stringify(body))
        .then((response) => {
          if (response.ok) {
            response.json()
              .then((data) => {
                initializeChannels(this.state.channelsSchema, data.userChannelsSchema);
                userStore.setUserChannelsSchema(data.userChannelsSchema);
                this.updateState({
                  userAccount: data,
                  userCompany: data.companyName,
                  logoURL: data.companyWebsite ? `https://logo.clearbit.com/${data.companyWebsite}` : '',
                  teamMembers: data.teamMembers,
                  userChannelsSchema: data.userChannelsSchema,
                  campaignKeyToNameMapping: data.campaignKeyToNameMapping || {},
                  unsaved: false,
                });
                resolve();
              });
          } else if (response.status === 401) {
            history.push('/');
            reject();
          }
        })
        .catch((error) => {
          servicesStore.logger.error('failed to update user account', {
            error,
            UID: this.state.UID,
          });
          reject();
        });
    });
  }

  updateCustomModules = (body) => serverCommunication.serverRequest('PUT', 'useraccount', JSON.stringify(body))
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error();
      }
    })
    .catch((error) => {
      servicesStore.logger.error('failed to update user account custom models', {
        error,
        UID: this.state.UID,
      });
    });

  createUserAccount(body) {
    return new Promise((resolve, reject) => {
      serverCommunication.serverRequest('POST', 'useraccount', JSON.stringify(body))
        .then((response) => {
          if (response.ok) {
            response.json()
              .then((data) => {
                userStore.setUserAccount(data);

                this.updateState({
                  userAccount: data,
                  userCompany: data.companyName,
                  logoURL: data.companyWebsite ? `https://logo.clearbit.com/${data.companyWebsite}` : '',
                  teamMembers: data.teamMembers,
                  unsaved: false,
                });
                resolve();
              });
          } else if (response.status === 401) {
            history.push('/');
            reject();
          }
        })
        .catch((error) => {
          servicesStore.logger.error('failed to create user account', {
            error,
            UID: this.state.UID,
          });
          reject();
        });
    });
  }

  createUserMonthPlan(body) {
    return serverCommunication.serverRequest('POST', 'usermonthplan', JSON.stringify(body))
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 401) {
          history.push('/');
          throw new Error();
        }
        return null;
      })
      .then((data) => {
        if (data) {
          // after receiving new user month plan, we need to update state with new data and then get all user regions.
          this.setDataAsState(data).then((res) => this.setExtendedUserData(res));
          return data;
        } else {
          throw new Error();
        }
      })
      .then(async (data) => {
        await this.getRegions();
        return data;
      });
  }

  getGlobalChannelsSchema() {
    return new Promise((resolve, reject) => {
      serverCommunication.serverRequest('GET', 'global/channelsSchema', false, false, true)
        .then((response) => {
          if (response.ok) {
            response.json()
              .then((channelsSchema) => {
                if (channelsSchema) {
                  this.setState({
                    channelsSchema,
                  });
                  initializeChannels(channelsSchema, this.state.userChannelsSchema);
                  resolve();
                }
              });
          } else if (response.status === 401) {
            history.push('/');
            reject();
          }
        })
        .catch((error) => {
          servicesStore.logger.error('failed to get channels metadata', {
            error,
            UID: this.state.UID,
          });
          reject();
        });
    });
  }

  getIndicatorsMetadata() {
    return new Promise((resolve, reject) => {
      serverCommunication.serverRequest('GET', 'metadata/indicators', false, false, true)
        .then((response) => {
          if (response.ok) {
            response.json()
              .then((data) => {
                if (data) {
                  this.setState({
                    indicatorsSchema: data,
                  });
                  initializeIndicators(data, this.state.userIndicatorsSchema);
                  resolve();
                }
              });
          } else if (response.status === 401) {
            history.push('/');
            reject();
          }
        })
        .catch((error) => {
          servicesStore.logger.error('failed to get indicators metadata', {
            error,
            UID: this.state.UID,
          });
          reject();
        });
    });
  }

  getRegions() {
    return new Promise((resolve, reject) => {
      serverCommunication.serverRequest('GET', 'regions')
        .then((response) => {
          if (response.ok) {
            response.json()
              .then((data) => {
                if (data) {
                  const regionNames = data.map((region) => region.name);
                  const regionValues = data.map((region) => region.region);
                  userStore.setUserRegionsNicknames({ userRegions: regionNames });
                  userStore.setUserBusinessUnitsWithIds({ businessUnit: data });
                  this.setState({
                    regions: regionValues,
                  }, () => {
                    servicesStore.logger.info('regions state', {
                      regions: this.state.regions,
                    });
                    return resolve();
                  });
                }
              });
          } else if (response.status === 401) {
            history.push('/');
            reject();
          }
        })
        .catch((error) => {
          servicesStore.logger.error('failed to get all user regions', {
            error,
            UID: this.state.UID,
          });
          reject();
        });
    });
  }

  getCurrencies() {
    return new Promise((resolve, reject) => {
      serverCommunication.serverRequest('GET', 'getCurrencies', false, false, true)
        .then((response) => {
          if (response.ok) {
            response.json()
              .then((data) => {
                if (data) {
                  this.setState({
                    currencies: data,
                  });
                  userStore.setCurrencies(data);
                }
                resolve();
              });
          } else if (response.status === 401) {
            history.push('/');
            reject();
          }
        })
        .catch((error) => {
          servicesStore.logger.error('failed to get currencies', {
            error,
            UID: this.state.UID,
          });
          reject();
        });
    });
  }

  async setDataAsState(data, cb) {
    const { userMonthPlanDataFromState } = extendedData(data);
    await this.updateAllRegionsDataIfNeeded();
    userStore.setUserMonthPlan(userMonthPlanDataFromState);
    if (!userStore.userRegionsNicknames.includes(masterRegion)) {
      userStore.setUserFunnels(userMonthPlanDataFromState);
      attributionStore.setMetricOptions();
    }
    const newState = {
      ...this.state,
      dataUpdated: !cb,
      userProfile: data.userProfile,
      ...userMonthPlanDataFromState,
    };
    return this.setAsyncState(newState, newState);
  }

  setNewGlobalState(data, cb) {
    return this.setState(data, cb);
  }

  sendSnippetEmail(senderEmail, UID, to) {
    serverCommunication.serverRequest(
      'POST',
      'snippetEmail',
      JSON.stringify({
        email: to,
        UID,
        sender: senderEmail,
      }),
      false,
      false,
      true
    );
  }

  addUnknownChannel(channelKey, nickname = channelKey, category = 'Other') {
    const userChannelsSchema = { ...this.state.userChannelsSchema };
    userChannelsSchema[channelKey] = {
      nickname,
      category,
      isUnknownChannel: true,
    };
    this.updateState({ userChannelsSchema }, () => {
      this.updateUserAccount({
        userChannelsSchema: this.state.userChannelsSchema,
      });
    });
  }

  getUnmappedData({
    unmappedType, endpoint = 'getUnmappedData', showMore = false, searchValue, shouldIncludePredictedChannel = false,
  }) {
    const unmappedKey = getUnmappedKeyName(unmappedType);
    const unmappedData = this.state[unmappedKey] || [];
    const { paginationCounterUnmappedPage } = this.state;
    let queryOffset = paginationCounterUnmappedPage[getUnmappedKey(unmappedType)];
    return new Promise((resolve, reject) => {
      if (!showMore && queryOffset) {
        // new rule was saved, pull the data from scratch
        queryOffset = 0;
      }
      serverCommunication.serverRequest('POST', endpoint, JSON.stringify({
        queryType: unmappedType,
        queryLimit: QUERY_LIMIT,
        queryOffset,
        searchValue,
        shouldIncludePredictedChannel,
      }), localStorage.getItem('region'))
        .then((response) => {
          if (response.ok) {
            response.json()
              .then((unmappedResults) => {
                paginationCounterUnmappedPage[getUnmappedKey(unmappedType)] = queryOffset + 1;
                this.updateState({
                  unsaved: false,
                  [unmappedKey]: queryOffset ? unmappedData.concat(unmappedResults) : unmappedResults,
                  paginationCounterUnmappedPage,
                });
                resolve(!unmappedResults.length);
              });
          }
        })
        .catch((error) => {
          servicesStore.logger.error('failed to get unmapped data', {
            error,
            UID: this.state.UID,
          });
          reject();
        });
    });
  }

  getScriptValidator() {
    return new Promise((resolve, reject) => {
      serverCommunication.serverRequest('GET', 'getScriptValidator')
        .then((response) => {
          if (response.ok) {
            response.json()
              .then((date) => {
                let isImplementedCorrectly = false;
                const last24hours = moment().utc().subtract(1, 'day');
                if (date !== null && moment(date).isSameOrAfter(last24hours)) {
                  isImplementedCorrectly = true;
                }
                resolve(isImplementedCorrectly);
              });
          }
        })
        .catch((error) => {
          servicesStore.logger.error('failed to get script validator', {
            error,
            UID: this.state.UID,
          });
          reject();
        });
    });
  }

  runScheduleTask() {
    this.updateState({ isScheduleTaskRunning: true, unsaved: false });
    return new Promise((resolve, reject) => {
      serverCommunication.serverRequest('POST', 'runUpdateData', null, localStorage.getItem('region'))
        .then((response) => {
          if (response.ok) {
            this.doneRunningScheduleTask();
          }
        })
        .catch((error) => {
          servicesStore.logger.error('failed to run schedule task', {
            error,
            UID: this.state.UID,
          });
          reject();
        });
    });
  }

  updateTimerTime = (newTime) => {
    this.updateState({ timerMinutes: newTime, unsaved: false });
  };

  doneRunningScheduleTask = () => {
    this.getUserMonthPlan(localStorage.getItem('region'));
    this.updateState({ isScheduleTaskRunning: false, unsaved: false });
  };

  getExtendedState(state) {
    return calculatedDataExtender(state);
  }

  async getCrmLastUpdateTimes() {
    try {
      const response = await serverCommunication.serverRequest('GET', 'lastRefreshTime');
      if (response.ok) {
        const crmLastUpdateTimes = await response.json();
        userStore.setUserCrmLastUpdateTimes(crmLastUpdateTimes);
      }
    } catch (exception) {
      servicesStore.logger.error('failed to get crm last refresh time', {
        exception,
        UID: this.state.UID,
      });
      throw exception;
    }
  }

  async updateRoleData({ roleData, currentMember }) {
    this.setState({ roleDataLoaded: false });
    const updatedMember = { ...currentMember, roleData };

    try {
      const response = await serverCommunication.serverRequest('PUT', 'members', JSON.stringify({
        memberData: updatedMember,
        isNewMember: false,
      }));

      if (response.ok) {
        const { teamMembers: updatedTeamMembers } = await response.json();
        userStore.updateUserAccountByKey({ key: 'teamMembers', value: updatedTeamMembers });
        this.setState((prevState) => ({
          roleDataLoaded: true,
          userAccount: {
            ...prevState.userAccount,
            teamMembers: updatedTeamMembers,
          },
        }));
      }
    } catch (error) {
      this.setState(() => ({
        roleDataLoaded: true,
        roleDataError: true,
      }));

      servicesStore.logger.error('failed to update user role data', {
        error,
        UID: this.state.UID,
        currentMember,
      });
    }
  }

  createNewSession() {
    localStorage.setItem(localStorageKeys.sessionData, JSON.stringify({ sessionId: uuidv4(), sessionStartTime: new Date().getTime() }));
  }

  recreateSession() {
    this.createNewSession();
    KeepAliveModule.start({ UID: this.state.UID });
  }

  endSession() {
    localStorage.removeItem(localStorageKeys.sessionData);
    KeepAliveModule.stop({ UID: this.state.UID });
  }

  render() {
    const { childRoutes } = this.props.route;

    let extendedUserData;
    if (this.state.extendedUserData) {
      extendedUserData = this.state.extendedUserData;
    } else {
      extendedUserData = this.state.dataUpdated ? this.getExtendedState(this.state) : this.state;
    }

    extendedUserData = {
      ...extendedUserData,
      ...this.APIMethods,
    };

    const childrenWithProps = React.Children.map(
      this.props.children,
      (child) => React.cloneElement(child, extendedUserData)
    );

    const currentMember = userStore.userAccount?.teamMembers?.find((member) => member.userId === servicesStore.authService.getProfileSync().user_id);
    const isInNewAccountMode = isPopupMode();
    const shouldShowRoleDataPopup = this.state.loaded && currentMember && isEmpty(currentMember.roleData) && !isInNewAccountMode;

    const sessionData = localStorage.getItem(localStorageKeys.sessionData);
    if (!sessionData) {
      this.createNewSession();
    }

    return (
      <div>
        <IdleTimer
          ref={this.idleTimerRef}
          timeout={this.idleTimeout}
          onIdle={() => {
            this.endSession();
          }}
          onActive={() => {
            this.recreateSession();
          }}
          debounce={500}
          crossTab
        >
          <SideBarNavigationMenu
            updateParentState={(newState, callback) => this.updateState(newState, callback, true)}
            globalRoutes={childRoutes}
          />
          <HeaderBar
            updateParentState={(newState, callback) => this.updateState(newState, callback, true)}
            createUserMonthPlan={(body) => this.createUserMonthPlan(body)}
            getUserMonthPlan={(region) => this.getUserMonthPlan(region)}
            updateUserAccount={(body) => this.updateUserAccount(body)}
            updateUserMonthPlan={(body, region, dontSetState, isV2) => this.updateUserMonthPlan(body, region, dontSetState, isV2)}
          />
          <UnsavedPopup hidden={!this.state.showUnsavedPopup} callback={this.state.callback} />
          <PlanLoading
            showPopup={this.state.isPlannerLoading}
            close={() => this.setState({ isPlannerLoading: false })}
          />
          {this.state.loaded ? (
            <div
              className={this.classes.wrap}
              data-loading={this.state.isPlannerLoading ? true : null}
            >
              {childrenWithProps}
              {this.state.regionLoad && (
              <div className={this.classes.loader}>
                <Loader newStyle />
              </div>
              )}
            </div>
          ) : (
            <Loader newStyle />
          )}
          {this.state.userAccount && <LDIdentify userAccount={this.state.userAccount} />}
          {shouldShowRoleDataPopup
            ? (
              <NewMemberRoleDataPopup
                isLoaded={this.state.roleDataLoaded}
                updateRoleData={({ roleData }) => this.updateRoleData({ roleData, currentMember })}
                error={this.state.roleDataError}
              />
            )
            : null}
        </IdleTimer>
      </div>
    );
  }
}

export default withLDProvider({
  clientSideID: config.clientSideID,
  context: {
    kind: 'user',
    key: '000000000',
    name: 'Default User',
    email: 'default@infinigrow.com',
  },
})(withRouter(DragDropContext(HTML5Backend)(AppComponent)));
