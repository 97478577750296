exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".StepsProgressBar-module__wrapper___GF2w8{margin:0 auto;display:flex;justify-content:space-between;font-size:var(--fontsSizesBodyNormal);width:100%}.StepsProgressBar-module__step___2-zbb{position:relative;display:flex;flex-direction:column;align-items:center;flex:1;text-align:center;padding:0 12px}.StepsProgressBar-module__step___2-zbb.StepsProgressBar-module__currentStep___3JygY{font-weight:var(--fontsWeightBold)}.StepsProgressBar-module__step___2-zbb.StepsProgressBar-module__activeStep___OmBfi .StepsProgressBar-module__circle___24X_v{background:var(--colorsGeneralBlue)}.StepsProgressBar-module__inBetweenLine___KzfqK:after{position:absolute;content:\"\";border-bottom:1px solid var(--colorsGeneralBlue);width:100%;top:7px;left:50%;z-index:1}.StepsProgressBar-module__circle___24X_v{position:relative;z-index:2;display:flex;justify-content:center;align-items:center;width:13px;height:13px;border-radius:50%;background:var(--colorsGeneralWhite);border:1px solid var(--colorsGeneralBlue);margin-bottom:6px}", ""]);

// exports
exports.locals = {
	"wrapper": "StepsProgressBar-module__wrapper___GF2w8",
	"step": "StepsProgressBar-module__step___2-zbb",
	"currentStep": "StepsProgressBar-module__currentStep___3JygY",
	"activeStep": "StepsProgressBar-module__activeStep___OmBfi",
	"circle": "StepsProgressBar-module__circle___24X_v",
	"inBetweenLine": "StepsProgressBar-module__inBetweenLine___KzfqK"
};