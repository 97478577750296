import { workflowKeys, workflowsTitles } from 'components/pages/users/logic/enums';

export function getWorkflowSectionByType({
  type, email, values, companyName, titleClassName, bodyClassName, highlightClassName,
}) {
  const title = workflowsTitles[type];
  const notAnonymous = email !== 'Anonymous';
  let body;

  switch (type) {
    case workflowKeys.leadToAccount:
      body = (
        <div>
          {notAnonymous
            ? (
              <span className={highlightClassName}>
                {email}
              </span>
            )
            : 'This lead'}
          {' '}
          is not associated with
          {' '}
          <span className={highlightClassName}>
            {companyName}
          </span>
          in your CRM - InfiniGrow matched it to the account.
        </div>
      );
      break;
    case workflowKeys.dedup:
      body = (
        <div>
          Your CRM contains duplicate records of
          {' '}
          {notAnonymous
            ? (
              <span className={highlightClassName}>
                {email}
              </span>
            )
            : 'this contact'}
          {' '}
          InfiniGrow’s de-duplication merged them into a single contact.
        </div>
      );
      break;
    case workflowKeys.deAnonymization:
      body = (
        <div>
          InfiniGrow recognized that someone from
          {' '}
          <span className={highlightClassName}>
            {companyName}
          </span>
          {' '}
          interacted with your account via IP de-anonymization.
        </div>
      );
      break;
    case workflowKeys.timestampAdjustment: {
      const value = values?.[0];
      body = (
        <div>
          This touchpoint’s timing is set to be pushed back by
          {' '}
          <span className={highlightClassName}>
            {value}
          </span>
        </div>
      );
      break;
    }
    case workflowKeys.crmMultiTouch:
      body = (
        <div>
          This touchpoint was extracted using InfiniGrow’s field history tracking –
          Your CRM will only show it via the field’s history.
        </div>
      );
      break;
    case workflowKeys.onlineSession:
      body = (
        <div>
          This touchpoint was logged by InfiniGrow and was not identified by your CRM.
        </div>
      );
      break;
    case workflowKeys.oppEnrichment: {
      const hasMultiDeals = values?.length > 1;
      body = (
        <>
          <div>
            {notAnonymous
              ? (
                <span className={highlightClassName}>
                  {email}
                </span>
              )
              : 'This contact'}
            {' '}
            is not associated with
            {' '}
            {hasMultiDeals ? (
              <>
                the following deals in your CRM:
                <ul>
                  {values.map((value) => (
                    <li key={value}>
                      <span className={highlightClassName}>
                        {value}
                      </span>
                    </li>
                  ))}
                </ul>
              </>
            )
              : (
                <>
                  <span className={highlightClassName}>
                    {values?.[0]}
                  </span>
                  {' '}
                  in your CRM -
                </>
              )}
            InfiniGrow matched it to the
            {hasMultiDeals ? ' deals' : ' deal'}
            .
          </div>
        </>
      );
      break;
    }
    default:
      break;
  }
  return (title && body
    ? (
      <>
        <div className={titleClassName}>
          {title}
        </div>
        <div className={bodyClassName}>
          {body}
        </div>
      </>
    )
    : null
  );
}

export function getFilteredWorkflows({ workflows = {}, shouldShowOppEnrichmentWorkflow }) {
  const filteredWorkflows = {};
  for (const [workflowType, workflowData] of Object.entries(workflows)) {
    const shouldHideOppEnrichmentWorkflow = workflowType === workflowKeys.oppEnrichment && !shouldShowOppEnrichmentWorkflow;
    const shouldSkipWorkflow = !workflowData.isActive || shouldHideOppEnrichmentWorkflow;

    if (shouldSkipWorkflow) {
      continue;
    }

    filteredWorkflows[workflowType] = workflowData;
  }

  return filteredWorkflows;
}
