import get from 'lodash/get';
import intersection from 'lodash/intersection';
import isFunction from 'lodash/isFunction';
import PropTypes from 'prop-types';

import userStore from 'stores/userStore';

import { getChannelNickname } from 'components/utils/filters/channels';
import { FUNNEL1 } from 'components/utils/indicators';
import { isContentTypeField } from 'components/utils/filters/content';
import { getCurrencySymbol } from 'components/utils/budget';
import {
  VARIANTS,
  EQUALS,
  NOT_EQUALS,
  filterKinds,
  isCostCampaignsFilter,
  isCostCustomFieldsFilter,
  areFiltersEqual,
  getSelectedFunnelFromActiveFilter,
} from 'components/utils/filters/logic';
import { getAiFilterLabel } from 'components/utils/filters/aiFilterLabel';

export const LOWER = 'LOWER';
export const HIGHER = 'HIGHER';
export const CONTAINS = 'CONTAINS';
export const NOT_CONTAINS = 'NOT_CONTAINS';
export const CAMPAIGN = 'CAMPAIGN';
export const CAMPAIGN_TAGS = 'CAMPAIGN_TAGS';

export const POSITION_TYPES = {
  anywhere: 'ANYWHERE',
  first: 'FIRST',
  last: 'LAST',
};

export const POSITION_OPTIONS = ['Include any of', 'Include only'];

export const COMPARISON_OPERATORS = {
  [EQUALS]: 'Is equal to',
  [LOWER]: 'Is lower than',
  [HIGHER]: 'Is higher than',
};

export const COMPARISON_OPERATORS_COSTS = {
  [EQUALS]: 'Equals',
  [NOT_EQUALS]: 'Doesn\'t equal',
  [CONTAINS]: 'Contains',
  [NOT_CONTAINS]: 'Doesn\'t contain',
};

export const COMPARISON_OPERATORS_CONTAINS = {
  [EQUALS]: 'Is equal to',
  [CONTAINS]: 'Contains',
  [LOWER]: 'Is lower than',
  [HIGHER]: 'Is higher than',
};

export const COMPARISON_TOGGLE = {
  [EQUALS]: 'Is equal to',
  [CONTAINS]: 'Contains',
};

const CONTENT = 0;
const CONTENT_URL = 1;
const CONTENT_TYPE = 2;
const CHANNEL = 0;
export const CATEGORY = 1;

export const CONTENT_VIEW_LOOKUP = {
  [CONTENT]: 'title',
  [CONTENT_URL]: 'url',
  [CONTENT_TYPE]: 'channel',
};

export const CHANNEL_VIEW_LOOKUP = {
  [CHANNEL]: 'channel',
  [CATEGORY]: 'category',
};

const ConfigKindPropType = PropTypes.oneOf(Object.values(filterKinds)).isRequired;

export const ConfigPropType = PropTypes.shape({
  kind: ConfigKindPropType,
  options: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  getOptions: PropTypes.func,
  fieldKey: PropTypes.any,
});

export const FilterPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  config: PropTypes.shape({ kind: ConfigKindPropType }),
  data: PropTypes.any.isRequired,
});

export const UI_FILTER_VARIANTS = [VARIANTS.INCLUDE_ANY_OF_EXACT, VARIANTS.DONT_SHOW_ANY_OF];

const provideArray = (items) => (Array.isArray(items) ? items : [items]);

export const isUiFilter = (data = {}) => UI_FILTER_VARIANTS.includes(data.variant);

const noIntersection = (items, selectedItems) => intersection(provideArray(items.filter((item) => typeof item === 'string').map((item) => item?.toLowerCase())), selectedItems.filter((item) => typeof item === 'string').map((item) => item?.toLowerCase())).length === 0;

const someIntersection = (items, selectedItems) => intersection(provideArray(items.filter((item) => typeof item === 'string').map((item) => item?.toLowerCase())), selectedItems.filter((item) => typeof item === 'string').map((item) => item?.toLowerCase())).length > 0;

export const VARIANTS_CONFIGS = {
  [VARIANTS.INCLUDE_ANY_OF]: {
    // label and value for Select option (see example in ChannelsFilter)
    label: 'Include any of',
    value: VARIANTS.INCLUDE_ANY_OF,
    // prefix for filter's label
    prefix: () => 'Include any of',
    tooltip: 'Include all journeys (and all additional data points from those journeys) that meet at least one of the selection criteria. Selecting this rule will show data from any journey that fits the filtered criteria, but will not hide any additional data that these journeys have.',
  },
  [VARIANTS.INCLUDE_ALL]: {
    label: 'Include all',
    value: VARIANTS.INCLUDE_ALL,
    prefix: () => 'Include all',
    tooltip: 'Include all journeys (and all additional data points from those journeys) that meet the selection criteria. Selecting this rule will show data from any journey that fits the filtered criteria, but will not hide any additional data that these journeys have.',
  },
  [VARIANTS.DONT_INCLUDE]: {
    label: 'Don\'t include',
    value: VARIANTS.DONT_INCLUDE,
    prefix: () => 'Don\'t Include',
    tooltip: 'Do not include all journeys (and all additional data points from those journeys) that meet least one of the selection criteria. This will filter the view to show only journeys that meet your criteria and their associated data.',
  },
  [VARIANTS.BECAME_ONE_OF]: {
    label: 'Became one of',
    value: VARIANTS.BECAME_ONE_OF,
    prefix: (count) => (count <= 1 ? 'Became' : 'Became one of'),
    tooltip: 'Include only journeys with at least one of the selected stage transitions in the chosen timeframe.',
  },
  [VARIANTS.INCLUDE_ONLY]: {
    label: 'Include only',
    value: VARIANTS.INCLUDE_ONLY,
    prefix: () => 'Include only',
    tooltip: 'Include only the journeys that meet the selected criteria. Selecting this rule will show data only from journeys that fit the filtered criteria, but will not hide any additional data that these journeys have.',
  },
  [VARIANTS.NEVER_BEEN_A]: {
    label: 'Never been a',
    value: VARIANTS.NEVER_BEEN_A,
    prefix: () => 'Never been a',
    tooltip: 'Include only journeys that never had a transition to any of the selected stage transitions in the chosen timeframe.',
  },
  [VARIANTS.EVER_BEEN_A]: {
    label: 'Ever been a',
    value: VARIANTS.EVER_BEEN_A,
    prefix: () => 'Ever been a',
    tooltip: 'Include journeys that had at least one of the selected stage transitions regardless of time frame.',
  },
  [VARIANTS.DONT_INCLUDE_ONLY]: {
    label: 'Don\'t include only',
    value: VARIANTS.DONT_INCLUDE_ONLY,
    prefix: () => 'Don\'t include only',
    tooltip: 'Don\'t include the journeys that contain only the exact specified criteria. This will filter the view to consider only the journeys that fit the criteria and will ignore any additional associated data.',
  },
  [VARIANTS.RECENT_TRANSITION_IS]: {
    label: 'Recent Transition is',
    value: VARIANTS.RECENT_TRANSITION_IS,
    prefix: () => 'Recent Transition is',
    tooltip: 'Only include journeys if their current funnel stage is one of the selected values. This will exclude journeys if their current stage is more or less advanced than the selected stage.',
  },
  [VARIANTS.INCLUDE_ANY_OF_EXACT]: {
    label: 'Show only',
    value: VARIANTS.INCLUDE_ANY_OF_EXACT,
    prefix: () => 'Show only',
    _assert: someIntersection,
    tooltip: 'As opposed to the Show Any Of filter, this rule will not exclude journeys from showing up in the Analyze view. Instead, it\'ll focus the view to show all journeys, but only selected activities (and hide any additional information). This won\'t have any effect on the Journeys tab, but will affect Channels and Campaigns.',
  },
  [VARIANTS.DONT_SHOW_ANY_OF]: {
    label: 'Hide',
    value: VARIANTS.DONT_SHOW_ANY_OF,
    prefix: () => 'Hide',
    _assert: noIntersection,
    tooltip: 'Hide data based on the selected criteria. This rule will not exclude journeys from showing up in the Analyze view. Instead, it\'ll focus the view to show all journeys, but only selected activities (and hide any additional information). This won\'t have any effect on the Journeys tab, but will affect Channels and Campaigns.',
  },
  [VARIANTS.INCLUDE_ENTITIES]: {
    label: 'Include (Object)',
    value: VARIANTS.INCLUDE_ENTITIES,
    prefix: () => 'Include (Object)',
    tooltip: '',
  },
  [VARIANTS.EXCLUDE_ENTITIES]: {
    label: 'Exclude (Object)',
    value: VARIANTS.EXCLUDE_ENTITIES,
    prefix: () => 'Exclude (Object)',
    tooltip: '',
  },
  [VARIANTS.ACCOUNT_STAGE_IS]: {
    label: 'Account stage is',
    value: VARIANTS.ACCOUNT_STAGE_IS,
    prefix: () => 'Account stage is',
    tooltip: 'Include all journeys (and all additional data points from those journeys) that meet at least one of the selection criteria. Selecting this rule will show data from any journey that fits the filtered criteria, but will not hide any additional data that these journeys have.',
  },
};

// configuration of options for INCLUDE filter type
export const includeVariants = [
  VARIANTS_CONFIGS[VARIANTS.INCLUDE_ANY_OF],
  VARIANTS_CONFIGS[VARIANTS.INCLUDE_ANY_OF_EXACT],
  VARIANTS_CONFIGS[VARIANTS.INCLUDE_ALL],
  VARIANTS_CONFIGS[VARIANTS.DONT_INCLUDE],
  VARIANTS_CONFIGS[VARIANTS.INCLUDE_ONLY],
  VARIANTS_CONFIGS[VARIANTS.DONT_SHOW_ANY_OF],
];

export const includeVariantsForCosts = [
  VARIANTS_CONFIGS[VARIANTS.INCLUDE_ANY_OF],
  VARIANTS_CONFIGS[VARIANTS.DONT_INCLUDE],
];

export const includeVariantsMarketingVsSales = [
  VARIANTS_CONFIGS[VARIANTS.INCLUDE_ANY_OF],
  VARIANTS_CONFIGS[VARIANTS.INCLUDE_ANY_OF_EXACT],
  VARIANTS_CONFIGS[VARIANTS.INCLUDE_ALL],
  VARIANTS_CONFIGS[VARIANTS.DONT_INCLUDE],
  VARIANTS_CONFIGS[VARIANTS.INCLUDE_ONLY],
  VARIANTS_CONFIGS[VARIANTS.DONT_SHOW_ANY_OF],
];

export const funnelStagesVariants = [
  VARIANTS_CONFIGS[VARIANTS.BECAME_ONE_OF],
  VARIANTS_CONFIGS[VARIANTS.ACCOUNT_STAGE_IS],
  VARIANTS_CONFIGS[VARIANTS.RECENT_TRANSITION_IS],
  VARIANTS_CONFIGS[VARIANTS.EVER_BEEN_A],
  VARIANTS_CONFIGS[VARIANTS.NEVER_BEEN_A],
];

export const funnelStagesKeyIndexes = {
  [VARIANTS.ACCOUNT_STAGE_IS]: 0,
  [VARIANTS.BECAME_ONE_OF]: 1,
  [VARIANTS.RECENT_TRANSITION_IS]: 2,
  [VARIANTS.EVER_BEEN_A]: 3,
  [VARIANTS.NEVER_BEEN_A]: 4,
};

export const leadStatusVariants = [
  VARIANTS_CONFIGS[VARIANTS.EVER_BEEN_A],
  VARIANTS_CONFIGS[VARIANTS.NEVER_BEEN_A],
  VARIANTS_CONFIGS[VARIANTS.INCLUDE_ONLY],
  VARIANTS_CONFIGS[VARIANTS.DONT_INCLUDE_ONLY],
];

export const customCRMVariants = [
  ...includeVariants,
  VARIANTS_CONFIGS[VARIANTS.DONT_INCLUDE_ONLY],
  VARIANTS_CONFIGS[VARIANTS.INCLUDE_ENTITIES],
  VARIANTS_CONFIGS[VARIANTS.EXCLUDE_ENTITIES],
];

export const CRMSourcesVariants = [
  ...includeVariants,
  VARIANTS_CONFIGS[VARIANTS.DONT_INCLUDE_ONLY],
];

export const getLabelPrefix = ({ filterKind, data }) => {
  switch (filterKind) {
    case filterKinds.CHANNELS:
    case filterKinds.CHANNELS_BEFORE_STAGES:
    case filterKinds.CAMPAIGN_BEFORE_STAGES:
    case filterKinds.CAMPAIGNS:
    case filterKinds.CAMPAIGN_TAGS:
    case filterKinds.CONTENT:
    case filterKinds.FORMS:
    case filterKinds.CUSTOM_FIELDS:
    case filterKinds.FUNNEL_STAGES:
    case filterKinds.MARKETING_VS_SALES:
    case filterKinds.PRODUCT:
    case filterKinds.REGION:
    case filterKinds.LEAD_STATUS:
    case filterKinds.CRMSource: {
      const variantPrefix = VARIANTS_CONFIGS[data.variant].prefix;

      return `${variantPrefix(data?.selectedOptions?.length)} `;
    }
    default:
      return '';
  }
};

// get filter function by it's type
const getUiFilterFn = ({ data, filterKind, isSegmentAnalysisTable }) => {
  const { selectedOptions = [], fieldIndex } = data;
  const variantAssert = VARIANTS_CONFIGS[data.variant]._assert;
  const isContains = data.comparisonOperator === CONTAINS;

  if (filterKind === filterKinds.CONTENT && !isSegmentAnalysisTable) {
    const dataField = CONTENT_VIEW_LOOKUP[fieldIndex];
    return (item, getDataFromRecord) => {
      const contentObj = getDataFromRecord ? getDataFromRecord(item, filterKind, fieldIndex) : item;
      let itemData = (typeof contentObj === 'object' && !Array.isArray(contentObj) && contentObj !== null) ? contentObj[dataField] : contentObj;

      itemData = Array.isArray(itemData) ? itemData : [itemData];
      if (isContains) {
        const itemDataStr = itemData.toString();
        const isFilterItemExists = selectedOptions.some((option) => itemDataStr.toLowerCase().includes(option.toLowerCase()));
        if (VARIANTS.DONT_SHOW_ANY_OF === data.variant) {
          return !isFilterItemExists;
        }
        return isFilterItemExists;
      }
      return itemData ? variantAssert(itemData, selectedOptions) : false;
    };
  }

  if (filterKind === filterKinds.CHANNELS && !isSegmentAnalysisTable) {
    const dataField = CHANNEL_VIEW_LOOKUP[fieldIndex];
    return (item, getDataFromRecord) => {
      const channelObj = getDataFromRecord ? getDataFromRecord(item, filterKind, fieldIndex) : item;
      let itemData = (typeof channelObj === 'object' && !Array.isArray(channelObj) && channelObj !== null) ? channelObj[dataField] : channelObj;

      itemData = Array.isArray(itemData) ? itemData : [itemData];
      if (isContains) {
        const itemDataStr = itemData.toString();
        const isFilterItemExists = selectedOptions.some((option) => itemDataStr.toLowerCase().includes(option.toLowerCase()));
        if (VARIANTS.DONT_SHOW_ANY_OF === data.variant) {
          return !isFilterItemExists;
        }
        return isFilterItemExists;
      }
      return itemData ? variantAssert(itemData, selectedOptions) : false;
    };
  }

  if (filterKind === filterKinds.CAMPAIGNS && !isSegmentAnalysisTable) {
    const dataField = 'campaign';
    return (item, getDataFromRecord) => {
      const channelObj = getDataFromRecord ? getDataFromRecord(item, filterKind, fieldIndex) : item;
      let itemData = (typeof channelObj === 'object' && !Array.isArray(channelObj) && channelObj !== null) ? channelObj[dataField] : channelObj;

      itemData = Array.isArray(itemData) ? itemData : [itemData];
      if (isContains) {
        const itemDataStr = itemData.toString();
        const isFilterItemExists = selectedOptions.some((option) => itemDataStr.toLowerCase().includes(option.toLowerCase()));
        if (VARIANTS.DONT_SHOW_ANY_OF === data.variant) {
          return !isFilterItemExists;
        }
        return isFilterItemExists;
      }
      return itemData ? variantAssert(itemData, selectedOptions) : false;
    };
  }

  return (item, getDataFromRecord) => {
    let itemData = getDataFromRecord(item, filterKind, fieldIndex);
    itemData = (typeof itemData === 'object' && !Array.isArray(itemData) && itemData !== null) ? Object.values(itemData) : itemData;
    itemData = Array.isArray(itemData) ? itemData : [itemData];
    if (isContains) {
      const itemDataStr = itemData.toString();
      const isFilterItemExists = selectedOptions.some((option) => itemDataStr.toLowerCase().includes(option.toLowerCase()));
      if (VARIANTS.DONT_SHOW_ANY_OF === data.variant) {
        return !isFilterItemExists;
      }
      return isFilterItemExists;
    }
    return itemData ? variantAssert(itemData, selectedOptions) : false;
  };
};

export const getFilterLabel = ({
  data, filterKind, filterFieldNames, filterFieldKey,
}) => {
  const prefix = getLabelPrefix({ filterKind, data });

  const { selectedOptions = [], selectedFunnel } = data;
  const selectedOptionsCount = selectedOptions?.length > 0 ? selectedOptions?.length : '';

  switch (filterKind) {
    case filterKinds.CHANNELS_BEFOREgetAiFilterLabel_STAGES: {
      const optionsLabel = selectedOptions.length === 1
        ? getChannelNickname(selectedOptions[0])
        : `${selectedOptionsCount} channels`;
      return `${optionsLabel} before ${userStore.getMetricNickname({ metric: selectedFunnel, isSingular: true })}`;
    }
    case filterKinds.CAMPAIGN_BEFORE_STAGES: {
      const optionsLabel = selectedOptions.length === 1
        ? selectedOptions[0]
        : `${selectedOptionsCount} campaigns`;
      return `${optionsLabel} before ${userStore.getMetricNickname({ metric: selectedFunnel, isSingular: true })}`;
    }
    case filterKinds.CONTENT_BEFORE_STAGES: {
      const { fieldIndex } = data;
      const label = fieldIndex === 2 ? getChannelNickname(selectedOptions[0]) : selectedOptions[0];
      const optionsLabel = selectedOptions.length === 1
        ? label
        : `${selectedOptionsCount} content`;
      return `${optionsLabel} before ${userStore.getMetricNickname({ metric: selectedFunnel, isSingular: true })}`;
    }
    case filterKinds.CHANNELS: {
      const { fieldIndex } = data;
      if (selectedOptions.length === 1) {
        return `${prefix}${getChannelNickname(selectedOptions[0])}`;
      }
      return `${prefix}${selectedOptionsCount} ${filterFieldNames[fieldIndex]}`;
    }
    case filterKinds.CAMPAIGNS: {
      if (selectedOptions.length === 1) {
        return `${prefix}${selectedOptions[0]}`;
      }

      return `${prefix}${selectedOptionsCount} campaigns`;
    }
    case filterKinds.CAMPAIGN_TAGS: {
      if (selectedOptions.length === 1) {
        return `${prefix}${selectedOptions[0]}`;
      }
      return `${prefix}${selectedOptionsCount} campaign tags`;
    }
    case filterKinds.CAMPAIGN_COST_CUSTOM_FIELDS: {
      const { platform } = data;
      return `Campaigns in ${platform}`;
    }
    case filterKinds.CAMPAIGN_COST: {
      const { comparisonOperator, manualValue } = data;
      if (Array.isArray(manualValue)) {
        return `Cost is between ${manualValue[0]} & ${manualValue[1]}`;
      }
      return `Cost ${COMPARISON_OPERATORS[comparisonOperator]} ${manualValue}`;
    }
    case filterKinds.VELOCITY: {
      const { comparisonOperator, velocityValue } = data;
      const [velocity] = selectedOptions;
      return `${userStore.getMetricNickname({ metric: velocity, isSingular: true })} ${COMPARISON_OPERATORS[comparisonOperator]} ${velocityValue} day${velocityValue === 1 ? '' : 's'}`;
    }
    case filterKinds.REVENUE: {
      const { comparisonOperator, revenue } = data;
      const formatMoney = (amount) => `${getCurrencySymbol()}${amount}`;
      if (Array.isArray(revenue)) {
        if (revenue[1] === -1) { return `Revenue ${COMPARISON_OPERATORS[HIGHER]} ${formatMoney(revenue[0])}`; }
        return `Revenue is between ${formatMoney(revenue[0])} & ${formatMoney(revenue[1])}`;
      }
      return `Revenue ${COMPARISON_OPERATORS[comparisonOperator]} ${formatMoney(revenue)}`;
    }
    case filterKinds.FUNNEL_STAGES: {
      if (selectedOptions.length === 1) {
        return `${prefix}${userStore.getMetricNickname({ metric: selectedOptions[0], isSingular: true })}${selectedFunnel ? ` before ${userStore.getMetricNickname({ metric: selectedFunnel, isSingular: true })}` : ''}`;
      }

      return `${prefix}${selectedOptionsCount} stages${selectedFunnel ? ` before ${userStore.getMetricNickname({ metric: selectedFunnel, isSingular: true })}` : ''}`;
    }

    case filterKinds.CUSTOM_FIELDS: {
      const {
        comparisonOperator, manualValue, fieldIndex, fieldId,
      } = data;

      let customFieldsLabel = '';
      if (fieldId) {
        const customFieldsIdToLabelMap = userStore.userAccount?.customFieldsIdToLabelMap;
        customFieldsLabel = customFieldsIdToLabelMap[fieldId];
      }
      if (selectedOptions.length === 0) {
        if (comparisonOperator && fieldIndex && manualValue) {
          return `${filterFieldNames[fieldIndex]} ${COMPARISON_OPERATORS[comparisonOperator]} ${manualValue} ${customFieldsLabel}`;
        }
        if (fieldId) {
          return `${prefix}${customFieldsLabel}`;
        }

        return `${prefix}CRM Fields ${customFieldsLabel}`;
      }

      if (selectedOptions.length === 1) {
        return `${prefix}${selectedOptions[0]} ${customFieldsLabel}`;
      }
      return `${prefix}${selectedOptionsCount} ${filterFieldNames[data.fieldIndex] || ''} ${customFieldsLabel}`;
    }
    case filterKinds.CONTENT: {
      const { fieldIndex } = data;
      const isContentType = filterFieldKey && isContentTypeField(filterFieldKey[fieldIndex]);

      if (selectedOptions.length === 1) {
        const selectedOption = selectedOptions[0];

        if (isContentType) {
          return `${prefix}${getChannelNickname(selectedOption)} content`;
        }

        return `${prefix}${selectedOption}`;
      }

      return `${prefix}${selectedOptionsCount} ${filterFieldNames[data.fieldIndex] || ''}`;
    }
    case filterKinds.FORMS: {
      if (selectedOptions.length === 1) {
        return `${prefix}${selectedOptions[0]}`;
      }
      return `${prefix}${selectedOptionsCount} forms`;
    }
    case filterKinds.CRMSource: {
      if (selectedOptions.length === 1) {
        return `${prefix}${selectedOptions[0]}`;
      }
      return `${prefix}${selectedOptionsCount} CRM sources`;
    }
    case filterKinds.MARKETING_VS_SALES: {
      if (selectedOptions.length === 0) {
        return `${prefix}Department`;
      }
      return `${prefix}${data.selectedOptions?.join(', ')}`;
    }
    case filterKinds.PRODUCT: {
      return `${prefix}${data.selectedOptions?.join(', ')}`;
    }
    case filterKinds.REGION: {
      return `${prefix}${data.selectedOptions?.join(', ')}`;
    }
    case filterKinds.LEAD_STATUS: {
      return `${prefix}${data.selectedOptions?.join(', ')}`;
    }
    default:
      return '';
  }
};

const FILTER_ID_KEYS = [
  'variant',
  'selectedFunnel',
  ['timeFrame', (timeFrame) => `${timeFrame.startTS}-${timeFrame.endTS}`],
  'comparisonOperator',
  'velocityValue',
  ['revenue', (revenue) => (Array.isArray(revenue) ? `between-${revenue.join(',')}` : revenue)],
];

// generate filter uniq id based on it's kind, type and selected data
export const getFilterId = ({ data, filterKind, filterFieldKey }) => {
  const idParts = [filterKind];

  if (Array.isArray(filterFieldKey)) {
    idParts.push(filterFieldKey[data.fieldIndex]);
  }

  FILTER_ID_KEYS.forEach((key) => {
    if (Array.isArray(key)) {
      const [_key, makeIdPart] = key;
      if (_key in data) {
        idParts.push(makeIdPart(data[_key]));
      }
    } else if (key in data) {
      idParts.push(data[key]);
    }
  });

  const isCostCustomFields = isCostCustomFieldsFilter(filterKind);
  const isCostCampaigns = isCostCampaignsFilter(filterKind);

  if (isCostCustomFields) {
    const filterFlatRulesItems = [];
    for (const dataItem of data.rules.flat()) {
      const ruleItems = dataItem.selectedOptions.map((item) => `-${item}`);
      filterFlatRulesItems.push(ruleItems);
    }
    const filterFlatRulesLabels = filterFlatRulesItems.join('');
    idParts.push(`${data.platform}-${filterFlatRulesLabels}`);
  } else if (isCostCampaigns) {
    const flatData = Object.values(data).join('-');
    idParts.push(flatData);
  } else {
    idParts.push(...data.selectedOptions.slice().sort());
  }

  if (data.fieldId) {
    idParts.push(data.fieldId);
  }
  if (data.relatedEntities) {
    idParts.push('relatedEntities');
  }

  if (data.selectedFunnels) {
    idParts.push(data.selectedFunnels.join('-'));
  }

  return idParts.join('-');
};

export const getFilter = ({
  config, data, isDisabled, filterTagColorTheme,
}) => {
  const filterKind = config.kind;
  const filterLabel = getFilterLabel({
    data, filterKind, filterFieldNames: config.fieldNames, filterFieldKey: config.fieldKey,
  });
  const aiLabel = getAiFilterLabel({ kind: filterKind, prefixLabel: filterLabel, selectedOptions: data.selectedOptions });

  const parsedFilter = {
    id: getFilterId({ data, filterKind: config.kind, filterFieldKey: config.fieldKey }),
    label: filterLabel,
    isUiOnly: data.variant
      ? isFunction(get(VARIANTS_CONFIGS[data.variant], '_assert'))
      : false,
    config,
    data,
    aiLabel,
  };

  if (isDisabled) {
    parsedFilter.isDisabled = isDisabled;
  }
  if (filterTagColorTheme) {
    parsedFilter.filterTagColorTheme = filterTagColorTheme;
  }

  return parsedFilter;
};

export const makeFilter = ({
  kind, data, isDisabled, filterTagColorTheme,
}, filterConfigs) => {
  const config = filterConfigs.find((c) => c.kind === kind);
  if (!config) {
    return null;
  }
  return getFilter({
    config, data, isDisabled, filterTagColorTheme,
  });
};

export const makeFilters = (filtersData, filterConfigs) => filtersData.map((data) => (data.id ? data : makeFilter(data, filterConfigs))).filter(Boolean);

export const makeRawFilters = ({ filters = [] }) => filters.map((filter) => ({ data: filter.data, kind: filter.config?.kind || filter.kind, isUiOnly: filter.isUiOnly }));

export function getRawFilter({ config: { kind }, data }) {
  return ({ kind, data });
}

export const getInitState = (selectedFilter, filterKind) => {
  const isFunnelStageF = filterKind === filterKinds.FUNNEL_STAGES;
  const selectedFunnels = getSelectedFunnelFromActiveFilter({ activeFilter: selectedFilter });

  return {
    selectedFilter,
    isFunnelMode: get(selectedFilter, 'data.selectedFunnel'),
    selectedFunnel: FUNNEL1,
    variant: isFunnelStageF ? 3 : 0,
    fieldIndex: isFunnelStageF ? 1 : 0,
    ...(selectedFilter ? selectedFilter.data : {}),
    selectedFunnels,
    isCustomDate: !!(selectedFilter && selectedFilter.data.timeFrame),
    relatedEntities: get(selectedFilter, 'data.relatedEntities'),
    touchpointPosition: get(selectedFilter, 'data.position', POSITION_TYPES.anywhere),
    isPositionVariantDisable: false,
    isShowBeforeFunnelConversion: get(selectedFilter, 'kind') !== filterKinds.CAMPAIGN_TAGS,
  };
};

export const beforeStageMap = {
  [filterKinds.FUNNEL_STAGES]: () => filterKinds.FUNNEL_STAGES,
  [filterKinds.CHANNELS]: () => filterKinds.CHANNELS_BEFORE_STAGES,
  [filterKinds.CAMPAIGNS]: () => filterKinds.CAMPAIGN_BEFORE_STAGES,
  [filterKinds.CONTENT]: () => filterKinds.CONTENT_BEFORE_STAGES,
};

// eslint-disable-next-line default-param-last
export const filterGivenRecords = (users = [], filters, getData, isSegmentAnalysisTable) => {
  let retval = users;
  if (filters && filters.length > 0 && retval.length > 0) {
    retval = retval.filter((item) => filters.every((filter) => {
      const {
        data,
        kind,
        config,
      } = filter;
      const assertFn = getUiFilterFn({
        data, filterKind: kind || config.kind, isSegmentAnalysisTable,
      });
      return assertFn(item, getData);
    }));
  }
  return retval;
};

export function isFilterInList({ filterItem = {}, filtersList = [] }) {
  const rawFilterItem = makeRawFilters({ filters: [filterItem] });
  const rawFilterList = makeRawFilters({ filters: filtersList });
  for (const filterListItem of rawFilterList) {
    if (areFiltersEqual({ firstFiltersArray: rawFilterItem, secondFiltersArray: [filterListItem] })) {
      return true;
    }
  }
  return false;
}
