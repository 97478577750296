import { useState } from 'react';
import { TextField, Button, Spinner } from '@infinigrow/libs';

import useStyles from 'hooks/useStyles';

import Dropdown from 'components/controls/Dropdown';
import Page from 'components/Page';

import {
  objectiveDropDownOptions,
  requiredFieldsInRoleData,
  roleDataKeys,
  roleDataPopupTexts,
} from 'components/actionRequiredPopups/enums';

import style from 'styles/actionRequiredPopups/NewMemberRoleDataPopup.css';

const styles = style.locals || {};

export default function NewMemberRoleDataPopup({ isLoaded, updateRoleData, error }) {
  useStyles([style]);

  const [roleData, setRoleData] = useState({
    [roleDataKeys.jobTitle]: null,
    [roleDataKeys.team]: null,
    [roleDataKeys.objective]: null,
  });

  function handleChange({ value, textFieldKey }) {
    setRoleData((prevState) => ({ ...prevState, [textFieldKey]: value }));
  }

  function shouldDisableSubmit() {
    const emptyFields = Object.entries(roleData).filter(([fieldKey, fieldValue]) => requiredFieldsInRoleData.includes(fieldKey) && !fieldValue);
    return emptyFields.length > 0;
  }

  return (
    <Page popup width="600px" data-testid="role-data-popup" className={styles.roleDataPopup}>
      <div className={styles.wrapper}>
        <div className={styles.warningMessage}>
          {roleDataPopupTexts.warningMessage}
        </div>
        <div className={styles.content}>
          <div className={styles.title}>
            {roleDataPopupTexts.title}
          </div>
          <div className={styles.subTitle}>
            InfiniGrow will soon introduce a new
            {' '}
            <b>personalized experience</b>
            {' '}
            to help you reach
            {' '}
            <b>insights</b>
            {' '}
            faster.
            <br />
            <br />
            To do so, please fill in the details below:
          </div>
          <TextField
            required
            label={roleDataPopupTexts.jobTitleTextFieldLabel}
            placeholder={roleDataPopupTexts.jobTitleTextFieldPlaceHolder}
            labelClassName={styles.textFieldLabel}
            className={styles.textField}
            onChange={(e) => handleChange({ value: e.target.value, textFieldKey: roleDataKeys.jobTitle })}
          />
          <div className={styles.textField}>
            <div className={styles.textFieldLabel}>
              {roleDataPopupTexts.objectiveDropDownLabel}
              <span className={styles.required}>*</span>
            </div>
            <Dropdown
              options={objectiveDropDownOptions}
              selectedKey={roleData[roleDataKeys.objective]}
              onChange={({ value }) => setRoleData((prevState) => ({ ...prevState, [roleDataKeys.objective]: value }))}
            />
          </div>
          <TextField
            required
            label={roleDataPopupTexts.teamTextFieldLabel}
            placeholder={roleDataPopupTexts.teamFieldPlaceHolder}
            labelClassName={styles.textFieldLabel}
            className={styles.textField}
            onChange={(e) => handleChange({ value: e.target.value, textFieldKey: roleDataKeys.team })}
          />
          <TextField
            label={(
              <>
                {roleDataPopupTexts.additionalCommentsTextFieldLabel}
                {': '}
                <i>(optional)</i>
              </>
              )}
            labelClassName={styles.textFieldLabel}
            className={styles.textField}
            onChange={(e) => handleChange({ value: e.target.value, textFieldKey: roleDataKeys.additionalComments })}
            dataTestId="additional-comments-text-field"
          />
          <div className={styles.footer}>
            {error
              ? <div className={styles.error}>{roleDataPopupTexts.errorMessage}</div>
              : null}
            {isLoaded
              ? (
                <Button
                  onClick={() => updateRoleData({ roleData })}
                  disabled={shouldDisableSubmit()}
                >
                  Submit
                </Button>
              )
              : <Spinner />}
          </div>
        </div>
      </div>
    </Page>
  );
}
