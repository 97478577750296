import classnames from 'classnames';
import { isNil } from 'lodash';

import useStyles from 'hooks/useStyles';

import Skeleton from 'components/common/Skeleton';
import Tooltip from 'components/controls/Tooltip';

import { metricsFormatter } from 'components/widgets/optimalJourney/logic/optimalJourney';
import { stageVelocity } from 'components/widgets/optimalJourney/enums';

import style from 'styles/analyze/journey-canvas.css';

const styles = style.locals || {};

export default function VelocityPill({
  isLoaded,
  data = {},
  currentStage,
  nextStage,
  lastStage,
}) {
  useStyles([style]);

  const velocityValue = metricsFormatter({ metricKey: stageVelocity, value: data.value });
  const velocityGrowthValue = metricsFormatter({ growth: data.growth });

  const velocityTooltipText = (
    <div>
      <div>
        {'Velocity from '}
        <b>{currentStage.label}</b>
        {' to '}
        <b>{nextStage.label}</b>
        {': '}
        <b>{velocityValue}</b>
      </div>
      <ul>
        <li>
          {lastStage.label}
          {' journeys convert from '}
          {` ${currentStage.label} to ${nextStage.label} `}
          {velocityGrowthValue === 0 ? null : (
            <>
              {velocityGrowthValue > 0 ? (
                <>
                  <span className={styles.velocityRed}>
                    {Math.abs(velocityGrowthValue)}
                    %
                  </span>
                  <b> slower</b>
                </>
              ) : (
                <>
                  <span className={styles.velocityGreen}>
                    {Math.abs(velocityGrowthValue)}
                    %
                  </span>
                  <b> faster</b>
                </>
              )}
              {' than the previous period.'}
            </>
          )}
        </li>
      </ul>
    </div>
  );

  return (
    <div className={styles.velocityWrapper}>
      <div className={styles.arrow} />
      {!isLoaded && isNil(data.value) ? (
        <Skeleton height={20} width={60} />
      ) : (
        <Tooltip
          id={`velocity-${currentStage.value}-${nextStage.value}-${velocityValue}`}
          content={velocityTooltipText}
          TooltipProps={{
            className: styles.clickableTooltip,
          }}
          hidden={!data.value}
        >
          {data.value ? (
            <div className={styles.velocityLabel} data-testid="velocity-label">
              {velocityValue}
            </div>
          ) : ''}
        </Tooltip>
      )}
      <div className={classnames(styles.arrow, styles.flip)} />
    </div>
  );
}
