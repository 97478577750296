exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".login__page-OcM{display:flex;flex-direction:column;align-items:center}.login__titles-3RE{color:var(--colorsGeneralBlack);margin-bottom:25px}.login__check-boxes-1HK{align-self:center}.login__title-2rz{font-size:36px;font-weight:600}.login__sub-title-4fA{font-size:22px;font-weight:var(--fontsWeightNormal)}.login__login-wrapper-36P{width:400px;height:fit-content;border-radius:8px;box-shadow:0 2px 8px 0 rgba(227,230,244,.5);border:1px solid #e3e6f4;background-color:var(--colorsGeneralWhite);padding:0 75px 3px;box-sizing:border-box;display:flex;flex-direction:column;text-align:center;margin-bottom:15px}.login__checkbox-wrapper-1jx{display:flex;margin-top:35px}.login__logo-3Kd{background:url(/assets/linkedin-logo-igfinal.gif) 50% no-repeat;background-size:contain;background-color:#273142;width:150px;height:150px;margin:40px 0}.login__checkbox-label-2lf{font-size:var(--fontsSizesBodyLarge);font-weight:var(--fontsWeightNormal);text-align:left}.login__input-13W{height:38px;border-radius:8px;border:3px solid #e3e6f4;font-size:var(--fontsSizesBodyNormal);padding-left:15px}.login__input-13W,.login__input-label-3fJ{font-weight:var(--fontsWeightNormal);color:var(--colorsTextDarkGray)}.login__input-label-3fJ{font-size:18px;margin-bottom:14px}.login__input-field-37N{margin-top:30px;display:grid;text-align:left}.login__bottom-2Id{color:var(--colorsTextDarkGray);cursor:pointer;font-size:var(--fontsSizesBodyLarge)}.login__spinner-3Pq{display:flex;justify-content:center}.login__error-3TR{margin-top:4px;font-size:var(--fontsSizesBodySmall);font-weight:var(--fontsWeightMedium);line-height:2.08;color:#ee2032;white-space:pre;text-align:left;align-self:center}[data-icon=\"checkbox:checked-blue\"]{background-image:url(\"/assets/checked-blue.svg\")}", ""]);

// exports
exports.locals = {
	"page": "login__page-OcM",
	"page": "login__page-OcM",
	"titles": "login__titles-3RE",
	"titles": "login__titles-3RE",
	"check-boxes": "login__check-boxes-1HK",
	"checkBoxes": "login__check-boxes-1HK",
	"title": "login__title-2rz login__titles-3RE",
	"title": "login__title-2rz login__titles-3RE",
	"sub-title": "login__sub-title-4fA login__titles-3RE",
	"subTitle": "login__sub-title-4fA login__titles-3RE",
	"login-wrapper": "login__login-wrapper-36P",
	"loginWrapper": "login__login-wrapper-36P",
	"checkbox-wrapper": "login__checkbox-wrapper-1jx",
	"checkboxWrapper": "login__checkbox-wrapper-1jx",
	"logo": "login__logo-3Kd",
	"logo": "login__logo-3Kd",
	"checkbox-label": "login__checkbox-label-2lf",
	"checkboxLabel": "login__checkbox-label-2lf",
	"input": "login__input-13W",
	"input": "login__input-13W",
	"input-label": "login__input-label-3fJ",
	"inputLabel": "login__input-label-3fJ",
	"input-field": "login__input-field-37N",
	"inputField": "login__input-field-37N",
	"bottom": "login__bottom-2Id",
	"bottom": "login__bottom-2Id",
	"spinner": "login__spinner-3Pq",
	"spinner": "login__spinner-3Pq",
	"error": "login__error-3TR",
	"error": "login__error-3TR"
};