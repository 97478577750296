import { isNil } from 'lodash';
import classnames from 'classnames';

import useStyles from 'hooks/useStyles';

import userStore from 'stores/userStore';
import Tooltip from 'components/controls/Tooltip';
import Skeleton from 'components/common/Skeleton';
import InfoMarker from 'components/pages/InfoMarker';

import { segmentsFormatter } from 'components/widgets/optimalJourney/logic/optimalJourney';
import { firstStageKey } from 'components/widgets/optimalJourney/journeyCanvas/enums';

import style from 'styles/analyze/journey-canvas.css';

const styles = style.locals || {};

export default function SegmentPill({
  isLoaded,
  color,
  data = {},
  segment = {},
  currentStage,
  destinationStage,
}) {
  useStyles([style]);

  if (!isLoaded && isNil(data.segmentValue)) {
    return (
      <div className={styles.pillSkeleton}>
        <Skeleton height={60} width="100%" borderRadius={8} />
      </div>
    );
  }

  if (isLoaded && !data.segmentValue) {
    const segmentNATooltip = `There isn’t enough data to show the best converting ${segment.label} before the ${currentStage?.label} stage`;
    const firstCRMSegmentNATooltip = 'CRM Segments are not available for unidentified visitors. As a result, this metric cannot be calculated.';

    const isCustomFieldSegment = !!userStore.userAccount?.customFieldsIdToLabelMap?.[segment.value];
    const shouldShowFirstCRMSegmentNATooltip = isCustomFieldSegment && currentStage.value === firstStageKey;

    return (
      <div data-testid="segment-pill-empty">
        <div className={classnames(styles.pillContainer, styles.pillEmptyValue)} style={{ backgroundColor: color }}>
          N/A
          <InfoMarker
            tooltipText={shouldShowFirstCRMSegmentNATooltip ? firstCRMSegmentNATooltip : segmentNATooltip}
            iconClassName={styles.iconTooltipColor}
            withPortal
          />
        </div>
      </div>
    );
  }

  const segmentLabel = segmentsFormatter.label({ segmentValue: data.segmentValue });
  const value = segmentsFormatter.value({ value: data.value });
  const shareOfTotal = segmentsFormatter.value({ value: data.shareOfTotal });

  const segmentTooltipText = (
    <div>
      <div>
        {segment.label}
        {': '}
        <strong>{segmentLabel}</strong>
        <br />
        {'before the '}
        <b>{currentStage?.label}</b>
        {' stage'}
      </div>
      <ul>
        <li>
          {'Best converting '}
          {segment.label}
          {': '}
          <b>
            {value}
            % conversion rate
          </b>
          {' to '}
          {destinationStage?.label}
        </li>
        <li>
          <b>
            {shareOfTotal}
            %
          </b>
          {' of your '}
          {currentStage?.label}
          {' were touched by '}
          {segmentLabel}
        </li>
      </ul>
    </div>
  );

  return (
    <Tooltip
      id={`pill-tooltip-segment-${segmentLabel}-before-${currentStage.value}`}
      content={segmentTooltipText}
      TooltipProps={{
        className: styles.clickableTooltip,
      }}
      place="top"
    >
      <div className={styles.pillContainer} style={{ backgroundColor: color }}>
        <div className={styles.segmentLabel} data-testid="segment-label">
          {segmentLabel}
        </div>
        <div className={styles.segmentValue} data-testid="segment-value">
          {value}
          {`% CR to ${destinationStage?.label}`}
        </div>
      </div>
    </Tooltip>
  );
}
