exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".textfield__box-W5s{height:auto;align-items:center}.textfield__box-W5s[data-with-error]{width:100%}.textfield__input-2uC{border:none;box-shadow:none;background:#fff;padding:0 14px;font-size:var(--fontsSizesBodyNormal);line-height:16px;color:var(--colorsTextGray);border:solid 1px var(--colorsInputGrayLine);border-radius:4px;height:40px;width:100%;box-sizing:border-box}.textfield__input-2uC:disabled{background:var(--colorsBackgroundLightGray)}.textfield__validation-error-wrapper-1Fq{margin-left:15px;width:fit-content;height:fit-content}.textfield__validation-error-wrapper-1Fq[data-disabled]{visibility:hidden}.textfield__inputError-2Yo{border:var(--colorsGeneralRed) 1px solid;box-shadow:none}.textfield__inputErrorMessage-Pmz{color:var(--colorsGeneralRed);font-size:var(--fontsSizesBodySmall);padding-top:6px}.textfield__sign-jZp{position:absolute;right:8px;top:10px}.textfield__inputWrapper-1rW{position:relative}[data-icon=\"objective:target\"]{background-image:url(\"/assets/target-for-kpi.svg\")}", ""]);

// exports
exports.locals = {
	"box": "textfield__box-W5s",
	"box": "textfield__box-W5s",
	"input": "textfield__input-2uC",
	"input": "textfield__input-2uC",
	"validation-error-wrapper": "textfield__validation-error-wrapper-1Fq",
	"validationErrorWrapper": "textfield__validation-error-wrapper-1Fq",
	"inputError": "textfield__inputError-2Yo",
	"inputError": "textfield__inputError-2Yo",
	"inputErrorMessage": "textfield__inputErrorMessage-Pmz",
	"inputErrorMessage": "textfield__inputErrorMessage-Pmz",
	"sign": "textfield__sign-jZp",
	"sign": "textfield__sign-jZp",
	"inputWrapper": "textfield__inputWrapper-1rW",
	"inputWrapper": "textfield__inputWrapper-1rW"
};