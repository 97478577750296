exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".manage-columns-button__manage-columns-button-2W3{z-index:5;display:flex;align-items:center;padding:0 12px;font-size:var(--fontsSizesBodyNormal);font-weight:var(--fontsWeightNormal);color:var(--colorsTextGray);border:1px solid var(--colorsInputGrayLine);border-radius:4px;background-color:var(--colorsGeneralWhite);cursor:pointer;gap:6px;height:40px;white-space:nowrap;box-sizing:border-box}.manage-columns-button__manage-columns-button-2W3 b{font-weight:var(--fontsWeightBold)}.manage-columns-button__manage-columns-button-2W3:focus{outline:none}.manage-columns-button__dropdownIndicator-2Zk{border-left:4px solid transparent;border-right:4px solid transparent;border-top:5px solid var(--colorsTextGray);margin:0 0 0 12px}.manage-columns-button__relative-RJ_{position:relative;top:0;left:0}.manage-columns-button__disabled-2Vk{cursor:default;border-radius:4px;border:1px solid var(--colorsInputGrayLine);background:#fbfbfd;color:var(--colorsTextGray)}.manage-columns-button__manage-columns-button-2W3[data-disabled]{cursor:default}.manage-columns-button__manage-columns-button-2W3.manage-columns-button__active-svc:after{content:\"\";display:block;width:0;height:0;border-left:4px solid transparent;border-right:4px solid transparent;border-bottom:5px solid var(--colorsTextGray);border-top:none;margin:0 0 0 12px}.manage-columns-button__manage-columns-button-2W3:not(.manage-columns-button__disabled-2Vk):not([data-disabled]):hover{border-color:#b2bbd5}.manage-columns-button__dropdownAsLink-3SL{height:auto;padding:0;border:none;font-weight:var(--fontsWeightNormal);gap:0;background:no-repeat}.manage-columns-button__dropdownAsLink-3SL b{text-decoration:underline;font-weight:var(--fontsWeightNormal)}.manage-columns-button__dropdownAsLink-3SL b:hover{text-decoration:none}.manage-columns-button__dropdownAsLink-3SL:not(.manage-columns-button__disabled-2Vk):after{border:none;margin:0;content:none}", ""]);

// exports
exports.locals = {
	"manage-columns-button": "manage-columns-button__manage-columns-button-2W3",
	"manageColumnsButton": "manage-columns-button__manage-columns-button-2W3",
	"dropdownIndicator": "manage-columns-button__dropdownIndicator-2Zk",
	"dropdownIndicator": "manage-columns-button__dropdownIndicator-2Zk",
	"relative": "manage-columns-button__relative-RJ_",
	"relative": "manage-columns-button__relative-RJ_",
	"disabled": "manage-columns-button__disabled-2Vk",
	"disabled": "manage-columns-button__disabled-2Vk",
	"active": "manage-columns-button__active-svc",
	"active": "manage-columns-button__active-svc",
	"dropdownAsLink": "manage-columns-button__dropdownAsLink-3SL",
	"dropdownAsLink": "manage-columns-button__dropdownAsLink-3SL"
};