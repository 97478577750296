exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".errorIndication__wrapper-2YP{display:inline-flex;padding:20px;flex-direction:column;align-items:center;gap:20px;border-radius:10px;background:var(--colorsGeneralWhite);box-shadow:0 4px 19px 0 rgba(36,42,63,.08);max-width:300px}.errorIndication__icon-1wa{width:124px;height:100px;flex-shrink:0}.errorIndication__iconWrapper-ssP{display:flex;justify-content:center;width:80%;height:128px;flex-shrink:0;border-radius:10px;background:#f7f8ff}.errorIndication__text-1F2{color:#000;line-height:28px;text-align:center}.errorIndication__title-1lM{font-size:18px;font-weight:700}.errorIndication__subTitle-bMW{font-size:var(--fontsSizesBodyLarge);font-weight:var(--fontsWeightNormal)}[data-icon=\"errorIndication:error\"]{background-image:url(\"/assets/serverError.svg\")}", ""]);

// exports
exports.locals = {
	"wrapper": "errorIndication__wrapper-2YP",
	"wrapper": "errorIndication__wrapper-2YP",
	"icon": "errorIndication__icon-1wa",
	"icon": "errorIndication__icon-1wa",
	"iconWrapper": "errorIndication__iconWrapper-ssP",
	"iconWrapper": "errorIndication__iconWrapper-ssP",
	"text": "errorIndication__text-1F2",
	"text": "errorIndication__text-1F2",
	"title": "errorIndication__title-1lM",
	"title": "errorIndication__title-1lM",
	"subTitle": "errorIndication__subTitle-bMW",
	"subTitle": "errorIndication__subTitle-bMW"
};