exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".footerItem__wrapper-1Lf{display:flex;flex-direction:column;font-size:var(--fontsSizesBodyNormal);font-weight:var(--fontsWeightNormal);gap:4px}.footerItem__wrapper-1Lf:last-child{flex:1 1 100%}.footerItem__title--t8{color:#a3a9c1}.footerItem__body-3Nf{display:flex;flex-direction:row;align-items:center;gap:4px}.footerItem__textWithoutColor-2rX{color:#182033}", ""]);

// exports
exports.locals = {
	"wrapper": "footerItem__wrapper-1Lf",
	"wrapper": "footerItem__wrapper-1Lf",
	"title": "footerItem__title--t8",
	"title": "footerItem__title--t8",
	"body": "footerItem__body-3Nf",
	"body": "footerItem__body-3Nf",
	"textWithoutColor": "footerItem__textWithoutColor-2rX",
	"textWithoutColor": "footerItem__textWithoutColor-2rX"
};