import { useEffect, useState } from 'react';
import { isNil } from 'lodash';

import useStyles from 'hooks/useStyles';

import servicesStore from 'stores/servicesStore';
import { Events } from 'trackers/analytics/enums';

import Dropdown from 'components/controls/Dropdown';
import ComparisonValue from 'components/common/ComparisonValue';
import MetaDataBox from 'components/common/MetaDataBox';
import Skeleton from 'components/common/Skeleton';

import { getGrowthValue, getIsGrowthDesired } from 'components/pages/analyze/utils/comparisonUtils';
import { formatIndicatorDisplay } from 'components/utils/indicators';
import { directions } from 'components/common/enums';
import { formatNumberWithDecimalPoint } from 'components/utils/logic/budget';
import { extractFieldsFromData } from 'components/widgets/funnelAnalysis/logic/funnelStage';
import { metricTypeToLabelMapping } from 'components/common/logic/enums';
import { getSelectedMetricState, isComparisonSupported } from 'components/widgets/segmentsDrilldown/logic/stageOverview';

import style from 'styles/segmentsDrilldown/segmentsDrilldown.css';

const styles = style.locals || {};

export default function StageOverview({
  data,
  fullTimeFrame,
  selectedStage,
  isLoaded,
  supportedMetrics = [],
  drilldownMetricsOptions,
  drilldownMetricsData,
}) {
  useStyles([style]);

  const { currentStep, currentStepData } = extractFieldsFromData({
    currentStage: selectedStage, data, fullTimeFrame,
  });

  const [stagesMetrics, setStagesMetrics] = useState({});

  useEffect(() => {
    if (!drilldownMetricsData) {
      return;
    }

    let metric = Object.values(drilldownMetricsOptions)[0];
    if (stagesMetrics.selectedMetric) {
      metric = Object.values(drilldownMetricsOptions).find((drilldownMetric) => drilldownMetric.value === stagesMetrics.selectedMetric);
    }

    setStagesMetrics(getSelectedMetricState({
      metric,
      drilldownMetricsData,
    }));
  }, [drilldownMetricsData]);

  const currentStepDataToShow = [];
  for (const [metric, stepData] of Object.entries(currentStepData)) {
    if (supportedMetrics.includes(metric)) {
      currentStepDataToShow.push(stepData);
    }
  }

  function onChangeDrilldownMetric({ metric }) {
    const state = getSelectedMetricState({ metric, drilldownMetricsData });
    setStagesMetrics(state);

    const metricFunnels = metric.value.matchAll(/(?<fromStage>funnel\d+)To(?<toStage>funnel\d+)/gi).next().value.groups;
    let maxFunnelNumber = 1;
    for (const drilldownMetric of Object.keys(drilldownMetricsData)) {
      const drilldownMetricFunnels = drilldownMetric.matchAll(/funnel(\d+)ToFunnel(?<toStageNumber>\d+)/gi).next().value.groups;
      const toStageNumber = parseInt(drilldownMetricFunnels.toStageNumber, 10);
      if (maxFunnelNumber < toStageNumber) {
        maxFunnelNumber = toStageNumber;
      }
    }

    servicesStore.eventTracker.track({
      eventName: Events.funnelDrilldown.selectFunnelStagesConversion,
      properties: {
        fromStage: metricFunnels.fromStage,
        toStage: metricFunnels.toStage,
        maxStage: `funnel${maxFunnelNumber}`,
      },
    });
  }

  function renderOption({
    metricData, optionClassName, isDataLoaded, isSkeletonLightTheme = false,
  }) {
    const indicator = metricData?.metric || selectedStage;
    const value = indicator.includes(metricTypeToLabelMapping.velocity)
      ? `${metricData.value || 0} D`
      : formatIndicatorDisplay(indicator, metricData.value || 0, true, true, true, 2);

    return (
      <div className={optionClassName}>
        <div className={styles.title}>
          {!isDataLoaded && isNil(metricData.name)
            ? <Skeleton height={23} width="100%" isLightTheme={isSkeletonLightTheme} />
            : metricData.name}
        </div>
        <div className={styles.value}>
          {!isDataLoaded && isNil(metricData.value)
            ? <Skeleton height={23} width="100%" isLightTheme={isSkeletonLightTheme} />
            : value || 0}
        </div>
        {!isDataLoaded && isNil(metricData?.growth)
          ? <Skeleton height={23} width="100%" isLightTheme={isSkeletonLightTheme} />
          : isComparisonSupported({ metricLabel: metricData.name })
            ? (
              <ComparisonValue
                value={formatNumberWithDecimalPoint(getGrowthValue({
                  metricName: metricData.metric,
                  growthPerMetric: { [metricData.metric]: metricData?.growth },
                }), 1)}
                isGrowthDesired={getIsGrowthDesired({ metricName: metricData.metric })}
                className={styles.growth}
              />
            )
            : null}
      </div>
    );
  }

  return (
    <div className={styles.stageOverview}>
      {renderOption({ metricData: currentStep, optionClassName: styles.dataRow, isDataLoaded: isLoaded })}
      <MetaDataBox
        options={Object.values(currentStepDataToShow)}
        renderChild={(option) => renderOption({
          metricData: option, optionClassName: styles.metricDataBoxOption, isDataLoaded: isLoaded, isSkeletonLightTheme: true,
        })}
        direction={directions.vertical}
      />
      <Dropdown
        selectedKey={stagesMetrics.selectedMetric}
        options={drilldownMetricsOptions}
        onChange={(metric) => onChangeDrilldownMetric({ metric })}
        isSearchable
      />
      <MetaDataBox
        options={stagesMetrics.selectedMetricData}
        renderChild={(option) => renderOption({
          metricData: option, optionClassName: styles.metricDataBoxOption, isDataLoaded: isLoaded, isSkeletonLightTheme: true,
        })}
        direction={directions.vertical}
      />
    </div>
  );
}
