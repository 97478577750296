exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".chartSkeleton__chartWrapper-2Yq{padding-top:32px;display:flex}.chartSkeleton__dashedLine-22a{stroke-dasharray:3,3;stroke-width:1;stroke:#ebeceb}.chartSkeleton__leftTicks-297{width:80px;flex-direction:column;align-items:center;padding-left:20px;padding-bottom:40px}.chartSkeleton__bottomTicks-1Fe,.chartSkeleton__leftTicks-297{display:flex;justify-content:space-around}.chartSkeleton__chartcontainer-OuI{flex-grow:1;display:flex;flex-direction:column;gap:24px}.chartSkeleton__bars-2oy{display:flex;justify-content:space-around;align-items:flex-end;background-image:linear-gradient(90deg,var(--colorsGeneralWhite) 5px,transparent 0),linear-gradient(#ebeceb 1px,transparent 0);background-size:8px 113px}", ""]);

// exports
exports.locals = {
	"chartWrapper": "chartSkeleton__chartWrapper-2Yq",
	"chartWrapper": "chartSkeleton__chartWrapper-2Yq",
	"dashedLine": "chartSkeleton__dashedLine-22a",
	"dashedLine": "chartSkeleton__dashedLine-22a",
	"leftTicks": "chartSkeleton__leftTicks-297",
	"leftTicks": "chartSkeleton__leftTicks-297",
	"bottomTicks": "chartSkeleton__bottomTicks-1Fe",
	"bottomTicks": "chartSkeleton__bottomTicks-1Fe",
	"chartcontainer": "chartSkeleton__chartcontainer-OuI",
	"chartcontainer": "chartSkeleton__chartcontainer-OuI",
	"bars": "chartSkeleton__bars-2oy",
	"bars": "chartSkeleton__bars-2oy"
};