import React, { useRef, useState } from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { toast } from 'react-toastify';
import { Button } from '@infinigrow/libs';

import useStyles from 'hooks/useStyles';

import MultiCheckSelect from 'components/controls/MultiCheckSelect';
import Page from 'components/Page';
import Spinner from 'components/pages/journeys/Spinner';
import Textfield from 'components/controls/Textfield';

import { Events } from 'trackers/analytics/enums';
import { reportTemplateTypes } from 'components/pages/reports/enums';

import servicesStore from 'stores/servicesStore';

import reportsStyle from 'styles/reports/reports.css';

const styles = reportsStyle.locals;

const reportActions = {
  add: 'addToReport',
  createNew: 'createNewReport',
  widgetName: 'nameYourWidget',
};

function AddToReportPopup({
  onClosePopup,
  widgetsOfSelectedReport,
  isInReports,
  reportsList,
  createNewReportRequest,
  timeFrame,
  formattedTimeParams,
  isCompareToPreviousEnabled,
  filters,
  autoFilter,
  attributionModel,
  attributionCredit,
  widgetHeaderConfig,
  addWidgetToReportRequest,
}) {
  const [currentStep, setCurrentStep] = useState('addToReport');
  const [selectedReports, setSelectedReports] = useState([]);
  const [inputErrors, setInputErrors] = useState({ reportName: false, widgetName: false });
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);
  const [isAddingWidgetToReportLoading, setIsAddingWidgetToReportLoading] = useState(false);
  const [reportSearchTerm, setReportSearchTerm] = useState('');
  const reportNameRef = useRef();
  const widgetNameRef = useRef();

  useStyles([reportsStyle]);

  const toastSuccessMessage = (message = 'Update successfully!') => {
    toast.success(message, {
      position: 'bottom-center',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: 'light',
      toastId: message,
      style: { width: '320px', fontSize: '14px' },
    });
  };

  const closePopupHandler = () => {
    backAndResetAllSetting();
    onClosePopup();
  };

  const backAndResetAllSetting = () => {
    setCurrentStep(reportActions.add);
    setInputErrors({ reportName: false, widgetName: false });
  };

  const addWidgetToGivenReport = async (report) => {
    const reportId = report.value;
    const title = _.get(widgetNameRef, ['current', 'refs', 'input', 'value'], '');
    const widgetConfig = _.cloneDeep(widgetHeaderConfig);
    delete widgetConfig.title;
    delete widgetConfig.type;
    delete widgetConfig.reportId;
    delete widgetConfig.description;
    delete widgetConfig.isInReports;
    delete widgetConfig.isShowTimeframeSelect;
    delete widgetConfig.isReadOnly;
    delete widgetConfig.isShowAttributionModelOptions;
    delete widgetConfig.isShowFilterOptions;
    if (widgetConfig.goalsAnalysisParams?.timeframe) {
      widgetConfig.goalsAnalysisParams.timeframe = {
        value: widgetConfig.goalsAnalysisParams.timeframe.value,
      };
    }
    const filtersConfigs = filters.map((filter) => ({ data: filter.data, kind: filter.config.kind, isUiOnly: filter.isUiOnly }));

    await addWidgetToReportRequest({
      reportId,
      title,
      type: widgetHeaderConfig.type,
      timeFrame: {
        ...timeFrame,
        ...formattedTimeParams,
      },
      shouldUsePredefinedFilters: autoFilter,
      isCompareToPreviousEnabled,
      filters: filtersConfigs,
      attributionModel,
      attributionCredit,
      widgetConfig,
    });
  };

  const trackAddToReport = ({ isNewReport }) => {
    servicesStore.eventTracker.track({
      eventName: Events.addToReport,
      properties: {
        widgetType: widgetHeaderConfig.type,
        isNewReport,
      },
    });
  };

  const onCreateNewReportAndAddWidget = async () => {
    setIsAddingWidgetToReportLoading(true);
    const reportName = _.get(reportNameRef, ['current', 'refs', 'input', 'value'], '');
    const newReport = await createNewReportRequest({ name: reportName, sourceType: reportTemplateTypes.scratch });
    await addWidgetToGivenReport(newReport);
    setIsAddingWidgetToReportLoading(false);

    trackAddToReport({ isNewReport: true });
    closePopupHandler();
    toastSuccessMessage('Report created successfully!');
  };

  const onCopyWidgetToReportOrNextStep = () => {
    if (isInReports) {
      // TODO: COPY WIDGET TO REPORT
      closePopupHandler();
      toastSuccessMessage(`Widget copied to ${selectedReports.length === 1 ? 'report' : 'reports'} successfully!`);
    } else {
      setCurrentStep(reportActions.widgetName);
    }
  };

  const onAddWidgetToReport = async () => {
    setIsAddingWidgetToReportLoading(true);
    for (const report of selectedReports) {
      await addWidgetToGivenReport(report);
    }
    setIsAddingWidgetToReportLoading(false);

    trackAddToReport({ isNewReport: false });
    closePopupHandler();
    toastSuccessMessage('Widget saved successfully!');
  };

  const onSelectReportsHandler = (item) => {
    setSelectedReports(item);
  };

  const checkForDuplicateInputInOptions = (inputValue, options) => options.some((item) => {
    if (item.label) {
      return item.label.toLowerCase() === inputValue.target.value.toLowerCase();
    }
    return item.toLowerCase() === inputValue.target.value.toLowerCase();
  });

  const checkForDuplicateValues = (inputValue, type, options) => {
    const isDuplicateFound = checkForDuplicateInputInOptions(inputValue, options);
    let isDisableSubmitButton = isDuplicateFound || inputValue === '';
    if (currentStep === reportActions.createNew && !isInReports) {
      const hasValueForWidgetName = !!_.get(widgetNameRef, ['current', 'refs', 'input', 'value']);
      const hasValueForReportName = !!_.get(reportNameRef, ['current', 'refs', 'input', 'value']);
      isDisableSubmitButton = isDuplicateFound || !hasValueForWidgetName || !hasValueForReportName;
    }
    setIsSubmitButtonDisabled(isDisableSubmitButton);
    setInputErrors({ ...inputErrors, [type]: isDuplicateFound });
  };

  const widgetsList = widgetsOfSelectedReport.map((item) => item.title);

  return (
    <Page wrapBoxStyle={styles.popupBox} contentClassName={styles.reportPopup} popup width="350px">
      <div className={styles.closePopupIcon} onClick={closePopupHandler} />

      {currentStep === reportActions.add
        && (
          <>
            <div className={styles.popupTitle}>
              <b>
                {`${isInReports ? 'Copy' : 'Add'} to report`}
              </b>
              {reportsList.length === 0
                && (
                  <div className={styles.popupSubTitle}>
                    There are no reports yet. Create your first report
                  </div>
                )}
            </div>

            {reportsList.length > 0
              && (
                <div className={styles.popupFields}>
                  <MultiCheckSelect
                    selected={selectedReports}
                    options={reportsList}
                    placeholder="Search..."
                    onSearchTermChange={setReportSearchTerm}
                    onChange={onSelectReportsHandler}
                  />
                </div>
              )}

            <div className={styles.popupCenterButtons}>
              {reportsList.length > 0
                && (
                  <>
                    <Button
                      type="primaryBlue"
                      disabled={!selectedReports.length}
                      onClick={onCopyWidgetToReportOrNextStep}
                    >
                      {`${isInReports ? 'Copy' : 'Add'} to selection`}
                    </Button>
                    <div className={styles.addOrTextOnBorder}><span>OR</span></div>
                  </>
                )}
              <Button
                type="secondaryBlue"
                disabled={selectedReports.length}
                onClick={() => setCurrentStep(reportActions.createNew)}
                onMouseDown={(e) => e.preventDefault()}
              >
                Create new report
              </Button>
            </div>
          </>
        )}

      {currentStep === reportActions.createNew
        && (
          <>
            <div className={styles.popupTitle}>
              <b>
                Create new report
              </b>
            </div>
            <div className={styles.popupFields}>
              <Textfield
                ref={reportNameRef}
                isShowError={inputErrors.reportName}
                inputErrorMessage="This name is already in use, please select another name"
                placeHolder="Report name"
                defaultValue={reportSearchTerm}
                onChange={(value) => checkForDuplicateValues(value, 'reportName', reportsList)}
              />
              {!isInReports
                && (
                  <Textfield
                    ref={widgetNameRef}
                    isShowError={inputErrors.widgetName}
                    inputErrorMessage="This name is already in use, please select another name"
                    placeHolder="Widget name"
                    onChange={(value) => checkForDuplicateValues(value, 'widgetName', widgetsList)}
                  />
                )}
            </div>
            <div className={styles.popupButtons}>
              <Button
                type="secondaryBlue"
                onClick={() => backAndResetAllSetting()}
              >
                Back
              </Button>
              {isAddingWidgetToReportLoading && <Spinner />}
              <Button
                type="primaryBlue"
                disabled={isSubmitButtonDisabled}
                onClick={() => onCreateNewReportAndAddWidget()}
              >
                Create report
              </Button>
            </div>
          </>
        )}

      {currentStep === reportActions.widgetName
        && (
          <>
            <div className={styles.popupTitle}>
              <b>
                Name your widget
              </b>
            </div>
            <div className={styles.popupFields}>
              <Textfield
                ref={widgetNameRef}
                isShowError={inputErrors.widgetName}
                inputErrorMessage="This name is already in use, please select another name"
                placeHolder="Widget name"
                onChange={(value) => checkForDuplicateValues(value, 'widgetName', widgetsList)}
              />
            </div>
            <div className={styles.popupButtons}>
              <Button
                type="secondaryBlue"
                onClick={() => backAndResetAllSetting()}
              >
                Back
              </Button>
              {isAddingWidgetToReportLoading && <Spinner />}
              <Button
                type="primaryBlue"
                disabled={isSubmitButtonDisabled}
                onClick={() => onAddWidgetToReport()}
              >
                Save
              </Button>
            </div>
          </>
        )}
    </Page>
  );
}

export default inject(
  ({
    attributionStore: {
      timeFrame,
      formattedTimeParams,
      previousTimeframe: isCompareToPreviousEnabled,
      attributionModel,
      autoFilter,
      filters,
      zeroWeightsNotInTimeFrame: attributionCredit,
    },
    reportsStore: {
      reportsList,
      widgetsOfSelectedReport,
      addWidgetToReportRequest,
      createNewReportRequest,
    },
  }) => ({
    timeFrame,
    formattedTimeParams,
    isCompareToPreviousEnabled,
    filters,
    attributionModel,
    autoFilter,
    attributionCredit,
    reportsList,
    addWidgetToReportRequest,
    widgetsOfSelectedReport,
    createNewReportRequest,
  }),
  observer
)(AddToReportPopup);
