exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".indicationTag__wrapper-280{display:flex;padding:4px 8px;align-items:center;gap:4px;border-radius:4px;white-space:nowrap;text-align:center;overflow:hidden}.indicationTag__text-1CM{font-size:var(--fontsSizesBodySmall);font-weight:var(--fontsWeightNormal)}.indicationTag__icon-2lk{width:12px;height:12px}.indicationTag__green-1Fa{color:#00c875;background:#d9f7ea}.indicationTag__red-2yR{color:var(--colorsGeneralRed);background:#fbe1e5}.indicationTag__gray-2Iz{color:var(--colorsTextGray);background:#e3e9fb}.indicationTag__orange-1k_{color:#ff932f;background:#ffe9d5}[data-icon=\"indicationTag:trendUp\"]{background-image:url(\"/assets/trend-up.svg\")}[data-icon=\"indicationTag:trendDown\"]{background-image:url(\"/assets/trend-down.svg\")}[data-icon=\"indicationTag:performanceUp\"]{background-image:url(\"/assets/performance-up.svg\")}[data-icon=\"indicationTag:performanceDown\"]{background-image:url(\"/assets/performance-down.svg\")}[data-icon=\"indicationTag:unfilter\"]{background-image:url(\"/assets/unfilter.svg\")}[data-icon=\"indicationTag:timeframeNotSupported\"]{background-image:url(\"/assets/timeframeNotSupported.svg\")}", ""]);

// exports
exports.locals = {
	"wrapper": "indicationTag__wrapper-280",
	"wrapper": "indicationTag__wrapper-280",
	"text": "indicationTag__text-1CM",
	"text": "indicationTag__text-1CM",
	"icon": "indicationTag__icon-2lk",
	"icon": "indicationTag__icon-2lk",
	"green": "indicationTag__green-1Fa",
	"green": "indicationTag__green-1Fa",
	"red": "indicationTag__red-2yR",
	"red": "indicationTag__red-2yR",
	"gray": "indicationTag__gray-2Iz",
	"gray": "indicationTag__gray-2Iz",
	"orange": "indicationTag__orange-1k_",
	"orange": "indicationTag__orange-1k_"
};