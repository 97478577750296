exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".planned-vs-actual-off-track__item-title-3Hs{display:flex;font-size:var(--fontsSizesBodyLarge);font-weight:var(--fontsWeightMedium);color:#182033}.planned-vs-actual-off-track__item-title--bold-2T0{font-weight:var(--fontsWeightBold)}.planned-vs-actual-off-track__chart-module-2Fd{display:flex;flex-direction:column;justify-content:flex-start;width:100%;max-width:380px;height:320px;padding:0 20px}.planned-vs-actual-off-track__chart-module-title-8hX{display:flex;flex-direction:row;justify-content:center;padding-bottom:10px;font-size:11px;font-weight:var(--fontsWeightBold);color:var(--colorsTextGray);text-transform:uppercase}.planned-vs-actual-off-track__chart-module-title--margin-e_8{margin-top:10px;margin-bottom:10px}.planned-vs-actual-off-track__chart-row-MmA{height:40px;border-radius:4px;display:flex;align-items:center}.planned-vs-actual-off-track__chart-row--small-NNQ{height:30px}.planned-vs-actual-off-track__chart-row-wrapper-1hm{display:flex;justify-content:space-between;align-items:center;margin-bottom:13px;position:relative}.planned-vs-actual-off-track__chart-row-wrapper--small-21K{margin-bottom:9px}.planned-vs-actual-off-track__chart-row-title-1Df{position:absolute;left:0;top:13px;padding-left:20px;font-size:var(--fontsSizesBodyNormal);font-weight:var(--fontsWeightMedium);color:var(--colorsButtonPrimaryBlueHover);text-transform:capitalize;width:245px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.planned-vs-actual-off-track__chart-row-title--small-1Ip{top:8px;font-size:13px;width:200px}.planned-vs-actual-off-track__metrics-1Kw{position:absolute;top:12px;right:0;font-size:var(--fontsSizesBodySmall);font-weight:var(--fontsWeightMedium);color:var(--colorsGeneralBlack)}.planned-vs-actual-off-track__metrics--division-3jU{color:var(--colorsTextGray)}.planned-vs-actual-off-track__container--collapsed-f1s{height:72px!important}[data-icon=\"plan:monthNavigation\"]{background-image:url(\"/assets/plan_icons/shape.svg\")}", ""]);

// exports
exports.locals = {
	"item-title": "planned-vs-actual-off-track__item-title-3Hs",
	"itemTitle": "planned-vs-actual-off-track__item-title-3Hs",
	"item-title--bold": "planned-vs-actual-off-track__item-title--bold-2T0",
	"itemTitleBold": "planned-vs-actual-off-track__item-title--bold-2T0",
	"chart-module": "planned-vs-actual-off-track__chart-module-2Fd",
	"chartModule": "planned-vs-actual-off-track__chart-module-2Fd",
	"chart-module-title": "planned-vs-actual-off-track__chart-module-title-8hX",
	"chartModuleTitle": "planned-vs-actual-off-track__chart-module-title-8hX",
	"chart-module-title--margin": "planned-vs-actual-off-track__chart-module-title--margin-e_8",
	"chartModuleTitleMargin": "planned-vs-actual-off-track__chart-module-title--margin-e_8",
	"chart-row": "planned-vs-actual-off-track__chart-row-MmA",
	"chartRow": "planned-vs-actual-off-track__chart-row-MmA",
	"chart-row--small": "planned-vs-actual-off-track__chart-row--small-NNQ",
	"chartRowSmall": "planned-vs-actual-off-track__chart-row--small-NNQ",
	"chart-row-wrapper": "planned-vs-actual-off-track__chart-row-wrapper-1hm",
	"chartRowWrapper": "planned-vs-actual-off-track__chart-row-wrapper-1hm",
	"chart-row-wrapper--small": "planned-vs-actual-off-track__chart-row-wrapper--small-21K",
	"chartRowWrapperSmall": "planned-vs-actual-off-track__chart-row-wrapper--small-21K",
	"chart-row-title": "planned-vs-actual-off-track__chart-row-title-1Df",
	"chartRowTitle": "planned-vs-actual-off-track__chart-row-title-1Df",
	"chart-row-title--small": "planned-vs-actual-off-track__chart-row-title--small-1Ip",
	"chartRowTitleSmall": "planned-vs-actual-off-track__chart-row-title--small-1Ip",
	"metrics": "planned-vs-actual-off-track__metrics-1Kw",
	"metrics": "planned-vs-actual-off-track__metrics-1Kw",
	"metrics--division": "planned-vs-actual-off-track__metrics--division-3jU",
	"metricsDivision": "planned-vs-actual-off-track__metrics--division-3jU",
	"container--collapsed": "planned-vs-actual-off-track__container--collapsed-f1s",
	"containerCollapsed": "planned-vs-actual-off-track__container--collapsed-f1s"
};