exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".loading-3-dots__spinner-1hc{margin:0;width:60px;text-align:center}.loading-3-dots__spinner-1hc>div{width:6px;height:6px;background-color:var(--colorsTextIgGray);border-radius:100%;display:inline-block;animation:loading-3-dots__sk-bouncedelay-1Pu 1.4s infinite ease-in-out both;opacity:.5;margin:1px}.loading-3-dots__spinner-1hc .loading-3-dots__bounce1-3Mn{animation-delay:-.32s}.loading-3-dots__spinner-1hc .loading-3-dots__bounce2-2ww{animation-delay:-.16s}@keyframes loading-3-dots__sk-bouncedelay-1Pu{0%,80%,to{transform:scale(.6)}40%{transform:scale(1)}}", ""]);

// exports
exports.locals = {
	"spinner": "loading-3-dots__spinner-1hc",
	"spinner": "loading-3-dots__spinner-1hc",
	"sk-bouncedelay": "loading-3-dots__sk-bouncedelay-1Pu",
	"skBouncedelay": "loading-3-dots__sk-bouncedelay-1Pu",
	"bounce1": "loading-3-dots__bounce1-3Mn",
	"bounce1": "loading-3-dots__bounce1-3Mn",
	"bounce2": "loading-3-dots__bounce2-2ww",
	"bounce2": "loading-3-dots__bounce2-2ww"
};