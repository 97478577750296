import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { inject, observer } from 'mobx-react';
import { Button } from '@infinigrow/libs';

import Page from 'components/Page';
import useOnClickOutside from 'components/pages/users/useOnClickOutside';
import Dropdown from 'components/controls/Dropdown';
import Spinner from 'components/pages/journeys/Spinner';
import FilterTag from 'components/pages/users/Filters/FilterPanel/FilterTag';
import MetricStatsCard from 'components/common/MetricStatsCard';
import SegmentTag from 'components/common/SegmentTag';
import InspectAttributionTable
  from 'components/pages/analyze/inspectData/InspectAttributionTable';

import { getAttributionModelsWithFlags } from 'attribution/models';
import { removeQueryParams } from 'components/utils/UrlParamsProvider';
import { getFilterLabel } from 'components/utils/filters';
import { formatIndicatorDisplay } from 'components/utils/indicators';
import {
  createSheetInspectDataTable,
} from 'components/utils/excelExport';
import { getNickname } from 'components/utils/channels';
import { widgetTypes } from 'components/pages/analyze/enums';
import exportData from 'modules/exportData';
import { parseInspectTableDataForExport } from 'components/pages/analyze/inspectData/logic/Inspect';

import style from 'styles/analyze/inspectData/inspect.css';
import filterStyle from 'styles/users/filters.css';

const styles = style.locals || {};
const filterStyles = filterStyle.locals || {};

function RenderSegmentsTags({ metricData }) {
  const firstSegment = metricData.firstSegmentValue;
  const secondSegment = metricData.secondSegmentValue;
  return (
    <div className={styles.segmentTags}>
      {firstSegment && (
        <SegmentTag
          segment={firstSegment}
          type={metricData.firstSegmentType}
        />
      )}
      {secondSegment && (
        <SegmentTag
          segment={secondSegment}
          type={metricData.secondSegmentType}
        />
      )}
    </div>
  );
}

function Inspect({
  setIsShowInspectPopup,
  flags,
  attributionModel,
  timeFrame,
  filters,
  inspectMetricData,
  kpiFocus,
  getMetricNickname,
  filtersData,
  setDrillDownPopupEntityId,
  metricType,
}) {
  useEffect(() => {
    style.use();
    filterStyle.use();
    return () => {
      if (!style.unuse || !filterStyle.unuse) {
        return;
      }
      style.unuse();
      filterStyle.unuse();
    };
  }, []);

  const {
    firstSegmentType: firstSegment,
    secondSegmentType: secondSegment,
    firstSegmentValue,
    secondSegmentValue,
  } = inspectMetricData;

  const [isShowFiltersTags, setIsShowFiltersTags] = useState(false);
  const [isExportLoading, setIsExportLoading] = useState(false);

  async function exportInspectData() {
    setIsExportLoading(true);

    const segmentLabel = getNickname(firstSegmentValue)
      // Excel doesn't allow "\/:()" symbols in the sheet name
      .replaceAll(/[\\/:()]/g, ' ')
      // Max length of a sheet name in Excel is 31
      .slice(0, 31);

    await exportData.exportAnalyzeWidgetData({
      widget: widgetTypes.funnelTransitionsBySegments,
      config: {
        funnelTransitionsBySegmentsParams: {
          firstSegment,
          firstSegmentValue,
          secondSegment,
          secondSegmentValue,
          offset: 0,
          limit: 10000,
          searchValue: '',
          selectedFunnel: kpiFocus,
        },
        metricType,
        filters,
        timeFrame,
        attributionModel,
        conversionIndicator: kpiFocus,
      },
      parse: ({ data }) => {
        const tableData = data?.results || [];
        const firstColumnName = data?.type;

        return parseInspectTableDataForExport({
          tableData,
          firstColumnName,
          kpiFocus,
        });
      },
      createSheet: ({ XLSX, tableData, fileName }) => {
        createSheetInspectDataTable({
          XLSX,
          tableData: {
            label: segmentLabel,
            data: tableData,
          },
          fileName,
        });
      },
      fileName: `InfiniGrow Export - Attribution Data : ${segmentLabel} - ${timeFrameLabel}`,
    });

    setIsExportLoading(false);
  }

  function getTimeFrameLabel() {
    const dateFormatForLabel = 'MMM DD, YY';
    return `${moment(timeFrame.startDate).format(dateFormatForLabel)} - ${moment(timeFrame.endDate).format(dateFormatForLabel)}`;
  }

  const attributionModelsItems = getAttributionModelsWithFlags(flags);
  const timeFrameLabel = getTimeFrameLabel();

  const refInspectPopup = useRef();
  useOnClickOutside(refInspectPopup, () => {
    setIsShowInspectPopup(false);
    removeQueryParams({ queryParamKey: 'inspect' });
  });

  const totalsByMetric = [];
  for (const [segmentTotalKey, segmentTotalValue] of Object.entries(inspectMetricData.totals)) {
    const isMetricContainsPipelineOrRevenue = ['pipeline', 'revenue'].some((option) => segmentTotalKey.toLowerCase().includes(option));
    const metric = isMetricContainsPipelineOrRevenue ? segmentTotalKey : `${segmentTotalKey}${kpiFocus}`;
    totalsByMetric.push({
      label: getMetricNickname({ metric, isSingular: true }),
      value: segmentTotalValue,
      key: segmentTotalKey,
    });
  }

  return (
    <Page
      popup
      width="900px"
      otherProps={{
        id: 'inspect-popup',
      }}
      contentClassName={styles.content}
    >
      <div className={styles.inspectPopupWrapper} ref={refInspectPopup}>
        <div className={styles.mainTopPopup}>
          <div
            className={styles.close}
            onClick={() => {
              setIsShowInspectPopup(false);
              removeQueryParams({ queryParamKey: 'inspect' });
            }}
          />
          <div
            className={styles.title}
          >
            {`Inspect ${getMetricNickname({ metric: kpiFocus })}`}
          </div>
          <div className={styles.topMenu}>
            <Dropdown
              options={[{ label: timeFrameLabel, value: timeFrameLabel }]}
              selectedKey={timeFrameLabel}
              dropdownLabel="Date range"
              disabled
            />
            <Dropdown
              options={attributionModelsItems}
              selectedKey={attributionModel.value}
              dropdownLabel="Attribution model"
              disabled
            />
            <Button
              type="primaryBlue"
              disabled={isExportLoading}
              onClick={() => exportInspectData()}
            >
              Export data
            </Button>
            {isExportLoading && <Spinner />}
          </div>
          {filters.length > 0 && (
            <div className={styles.filterTagsWrapper}>
              <div
                className={filterStyles.filterTagsCollapse}
                onClick={() => setIsShowFiltersTags((prev) => !prev)}
              >
                {isShowFiltersTags ? 'Hide ' : 'Show '}
                {`filters (${filters.length})`}
                <span
                  className={isShowFiltersTags ? filterStyles.arrowUpBlue : filterStyles.arrowDownBlue}
                />
              </div>
              {isShowFiltersTags && (
                <div className={styles.filterTags}>
                  {filters.map((filter) => {
                    let filterLabel = filter.label;
                    if (!filterLabel) {
                      const config = filtersData.find((c) => c.kind === filter.config.kind);
                      filterLabel = getFilterLabel({
                        data: filter.data,
                        filterKind: filter.config.kind,
                        filterFieldNames: config.fieldNames,
                        filterFieldKey: config.fieldKey,
                      });
                    }
                    return (
                      <FilterTag
                        key={`filter-tag-${filter.value}`}
                        label={filterLabel}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </div>
        <div className={styles.mainContentPopup}>
          <div className={styles.mainContentMetrics}>
            {totalsByMetric.map((metric) => (
              <MetricStatsCard
                metricValue={formatIndicatorDisplay(metric.key, metric.value, false, true, false, 2)}
                metricLabel={metric.label}
                className={styles.metricStatsCard}
              >
                <RenderSegmentsTags metricData={inspectMetricData} />
              </MetricStatsCard>
            ))}
          </div>
          <InspectAttributionTable
            firstSegment={firstSegment}
            firstSegmentValue={firstSegmentValue}
            secondSegment={secondSegment}
            secondSegmentValue={secondSegmentValue}
            kpiFocus={kpiFocus}
            totalsByMetric={totalsByMetric}
            filters={filters}
            timeFrame={timeFrame}
            attributionModel={attributionModel}
            setDrillDownPopupEntityId={setDrillDownPopupEntityId}
            metricType={metricType}
          />
        </div>
      </div>
    </Page>
  );
}

export default inject(({
  filterStore: {
    filtersData,
  },
  userStore: {
    getMetricNickname,
  },
}) => ({
  getMetricNickname,
  filtersData,
}), observer)(withLDConsumer()(Inspect));
