import React, { useState } from 'react';
import classnames from 'classnames';

import useStyles from 'hooks/useStyles';

import userStore from 'stores/userStore';
import InfoMarker from 'components/pages/InfoMarker';
import ComparisonValue from 'components/common/ComparisonValue';
import Skeleton from 'components/common/Skeleton';
import Tooltip from 'components/controls/Tooltip';
import MultiLevelMenuPopup from 'components/common/MultiLevelMenuPopup';
import servicesStore from 'stores/servicesStore';

import { Events } from 'trackers/analytics/enums';
import { getIsGrowthDesired, getGrowthValue } from 'components/pages/analyze/utils/comparisonUtils';
import { formatBudget, roiFormatter } from 'components/utils/budget';
import { getPacingCalculate, calculateLogarithmicPercent } from 'components/utils/logic/utils';
import { getStageCRTooltipText } from 'components/widgets/funnelAnalysis/logic/funnelStage';
import { skeletonSmallNumberSizes } from 'components/common/enums';
import { formatIndicatorDisplay } from 'components/utils/indicators';
import { formatNumberWithDecimalPoint, formatToFloatWithoutZeros } from 'components/utils/logic/budget';
import { supportedMetricsKeys } from 'components/widgets/funnelAnalysis/enums';
import { metricTypeToLabelMapping } from 'components/common/logic/enums';

import style from 'styles/funnelAnalysis/funnelAnalysis.css';

const styles = style.locals || {};

function StageBar({
  volume,
  costPer,
  velocity,
  isLoaded,
  fullTimeFrame,
  currentStage,
  tooltipContent,
  nextStageVolume,
  nextStage,
  conversionRate,
  stagesConfigs,
  maxStageValue,
  supportedMetrics,
  skeletonWidth,
  stageWarning,
  averageDealSize,
  ROI,
  getStageMenuParams,
  isLogarithmicCalculate = true,
}) {
  useStyles([style]);

  const [showNavigationMenuPopup, setShowNavigationMenuPopup] = useState(false);
  const [popupPosition, setPopupPosition] = useState({ left: 0, top: 0 });

  function handleWrapperClick({ event }) {
    setShowNavigationMenuPopup(true);

    const marginErrorConst = 10;
    const wrapperRect = event.currentTarget.getBoundingClientRect();
    const left = event.clientX - wrapperRect.left + marginErrorConst;
    const top = event.clientY - wrapperRect.top - marginErrorConst;
    setPopupPosition({ left, top });

    servicesStore.eventTracker.track({
      eventName: Events.funnelAnalysis.clickedOnFunnelStage,
      properties: {
        funnelStage: currentStage,
      },
    });
  }

  const currentStageName = userStore.getMetricNickname({ metric: currentStage });

  function FunnelContent() {
    return (
      <>
        {currentStageName}
        {tooltipContent ? (
          <InfoMarker
            content={tooltipContent}
            iconClassName={styles.infoMarker}
            place="right"
            TooltipProps={{
              clickable: true,
              delayHide: 200,
              className: styles.funnelStageTooltipWrapper,
            }}
            withPortal
          />
        ) : null}
        {stageWarning?.isShow ? (
          <Tooltip id={`stage-warning-${currentStage}`} tip={stageWarning.message} place="right">
            <div
              data-testid={`stage-warning-${currentStage}`}
              className={styles.warning}
            />
          </Tooltip>
        ) : null}
      </>
    );
  }

  const pacing = volume?.value != null ? getPacingCalculate({ timeframe: fullTimeFrame, actual: volume.value }) : null;
  const isPastTimeframe = fullTimeFrame.endDate <= new Date();

  const stageCRTooltipText = volume?.value != null ? getStageCRTooltipText({
    stagesConfigs, currentStage, nextStage, volume, nextStageVolume, conversionRate,
  }) : '';

  let funnelStageWidth = skeletonWidth;
  if (volume != null || isLoaded) {
    if (isLogarithmicCalculate) {
      funnelStageWidth = calculateLogarithmicPercent({ currentValue: volume?.value || 0, maxValue: maxStageValue });
    } else {
      funnelStageWidth = ((volume?.value || 0) / maxStageValue) * 100;
    }
  }

  return (
    <div
      className={styles.stageGroupWrapper}
      onClick={(event) => handleWrapperClick({ event })}
      data-testid={`funnel-stage-${currentStage}`}
    >
      <div className={styles.stageWrapper}>
        <div className={styles.stageProgressWrapper}>
          {volume == null && !isLoaded ? (
            <Skeleton height={48} width={`${funnelStageWidth}%`} borderRadius={8} />
          ) : (
            <>
              <div className={styles.stageProgress} style={{ width: `${funnelStageWidth}%` }}>
                <div>
                  <FunnelContent />
                </div>
              </div>
              <div className={styles.stageProgressBottom}>
                <FunnelContent />
              </div>
            </>
          )}
        </div>

        <div className={styles.stageMetrics}>
          <div className={classnames(styles.volumeMetric, styles.metricItem)}>
            <div className={styles.metricTitle}>
              <div>Volume</div>
            </div>
            {volume == null && !isLoaded ? (
              <Skeleton {...skeletonSmallNumberSizes} isLightTheme />
            ) : (
              <>{formatIndicatorDisplay(currentStage, volume?.value || 0, true)}</>
            )}
          </div>

          {supportedMetrics.includes(supportedMetricsKeys.comparison) ? (
            <div className={styles.metricItem}>
              <div className={styles.metricTitle}>
                <div>Vs. previous</div>
              </div>
              {volume?.growth == null && !isLoaded ? (
                <Skeleton {...skeletonSmallNumberSizes} isLightTheme />
              ) : (
                <ComparisonValue
                  value={formatNumberWithDecimalPoint(getGrowthValue({
                    metricName: currentStage,
                    growthPerMetric: { [currentStage]: volume?.growth || 0 },
                  }), 0)}
                  isGrowthDesired={getIsGrowthDesired({ metricName: currentStage })}
                  className={styles.growthMetric}
                />
              )}
            </div>
          ) : null}

          {supportedMetrics.includes(supportedMetricsKeys.pacing) && !isPastTimeframe ? (
            <div className={styles.metricItem}>
              <div className={styles.metricTitle}>
                <div>Pacing for</div>
              </div>
              <div className={styles.flex}>
                {pacing == null && !isLoaded ? (
                  <Skeleton {...skeletonSmallNumberSizes} isLightTheme />
                ) : (
                  <>
                    <div className={styles.pacingIcon} />
                    {formatIndicatorDisplay(currentStage, pacing || 0, true)}
                  </>
                )}
              </div>
            </div>
          ) : null}

          {supportedMetrics.includes(supportedMetricsKeys.costPer) ? (
            <div className={styles.metricItem}>
              <div className={styles.metricTitle}>
                <div>Cost per</div>
              </div>
              {costPer == null && !isLoaded ? (
                <Skeleton {...skeletonSmallNumberSizes} isLightTheme />
              ) : (
                <>{formatBudget(costPer) || 0}</>
              )}
            </div>
          ) : null}

          {supportedMetrics.includes(supportedMetricsKeys.avgDealSize) ? (
            <div className={styles.metricItem}>
              <div className={styles.metricTitle}>
                <div>{metricTypeToLabelMapping.avgDealSize}</div>
              </div>
              <div className={styles.flex}>
                {pacing == null && !isLoaded ? (
                  <Skeleton {...skeletonSmallNumberSizes} isLightTheme />
                ) : (
                  <>
                    <div className={styles.dealSizeIcon} />
                    {formatIndicatorDisplay(currentStage, averageDealSize || 0, true)}
                  </>
                )}
              </div>
            </div>
          ) : null}

          {supportedMetrics.includes(supportedMetricsKeys.ROI) ? (
            <div className={styles.metricItem}>
              <div className={styles.metricTitle}>
                <div>ROI</div>
              </div>
              <div className={styles.flex}>
                {pacing == null && !isLoaded ? (
                  <Skeleton {...skeletonSmallNumberSizes} isLightTheme />
                ) : (
                  <>
                    {roiFormatter(ROI || 0)}
                  </>
                )}
              </div>
            </div>
          ) : null}
        </div>
      </div>

      {nextStage ? (
        <div className={styles.optimizationMetrics}>
          <div className={styles.optimizationMetricsName}>
            <div>{`${currentStageName} > ${userStore.getMetricNickname({ metric: nextStage })}`}</div>
          </div>

          {supportedMetrics.includes(supportedMetricsKeys.conversionRate) ? (
            <div className={styles.metricTag}>
              <div className={classnames(styles.flex, styles.showOnHover)}>
                {`${metricTypeToLabelMapping.conversionRate} `}
                <InfoMarker
                  tooltipText={stageCRTooltipText}
                />
              </div>
              <div className={styles.metricTagValue}>
                {conversionRate == null && !isLoaded ? (
                  <Skeleton width={24} height={16} isLightTheme />
                ) : (
                  <>
                    {formatToFloatWithoutZeros({ number: conversionRate?.value, numberOfDigitsAfterPoint: 2 })}
                    %
                  </>
                )}
              </div>
              <div className={styles.showOnHover}>
                {conversionRate == null && !isLoaded ? (
                  <Skeleton {...skeletonSmallNumberSizes} isLightTheme />
                ) : (
                  <ComparisonValue
                    value={getGrowthValue({
                      metricName: currentStage,
                      growthPerMetric: { [currentStage]: conversionRate?.growth },
                    })}
                    isGrowthDesired={getIsGrowthDesired({ metricName: currentStage })}
                    className={styles.growthMetric}
                  />
                )}
              </div>
            </div>
          ) : null}

          {supportedMetrics.includes(supportedMetricsKeys.velocity) ? (
            <div className={styles.metricTag}>
              <div className={classnames(styles.flex, styles.showOnHover)}>
                {metricTypeToLabelMapping.velocity}
              </div>
              <div className={styles.metricTagValue}>
                {velocity == null && !isLoaded ? (
                  <Skeleton width={24} height={16} isLightTheme />
                ) : (
                  <>
                    {formatToFloatWithoutZeros({ number: velocity?.value, numberOfDigitsAfterPoint: 2 })}
                    {' D'}
                  </>
                )}
              </div>
              <div className={styles.showOnHover}>
                {velocity == null && !isLoaded ? (
                  <Skeleton {...skeletonSmallNumberSizes} isLightTheme />
                ) : (
                  <ComparisonValue
                    value={getGrowthValue({
                      metricName: currentStage,
                      growthPerMetric: { [currentStage]: velocity?.growth },
                    })}
                    isGrowthDesired={false}
                    className={styles.growthMetric}
                  />
                )}
              </div>
            </div>
          ) : null}
        </div>
      ) : null}

      {showNavigationMenuPopup ? (
        <div className={classnames(styles.multiLevelMenuContainer, 'stageBarPopup')} style={{ ...popupPosition }}>
          <MultiLevelMenuPopup
            menuOptions={getStageMenuParams()}
            onClosePopup={(showNavigationPopup) => setShowNavigationMenuPopup(showNavigationPopup)}
          />
        </div>
      ) : null}
    </div>
  );
}

export default StageBar;
