exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".quickFilters__wrapper-28J{display:flex;flex-direction:column;gap:8px}.quickFilters__content-2U7{display:flex;gap:40px;flex-direction:column}.quickFilters__wrapperWithoutSuggestMore-2Ww{padding-bottom:40px}.quickFilters__aiWrapper-1L3{border-radius:20px;border:1px solid var(--colorsTagAiPurpleText);background:var(--colorsBackgroundPurple);box-shadow:0 4px 12px 0 rgba(230,232,240,.25);padding:16px 16px 24px}.quickFilters__header-1rM{display:flex;align-items:center;justify-content:space-between;height:40px}.quickFilters__title-2dO{color:#192032;font-size:var(--fontsSizesTitleH4);font-weight:var(--fontsWeightBold);line-height:20px}.quickFilters__spaceBetween-1X6{padding-bottom:40px}.quickFilters__emptyStateImage-BvC{background:url(/assets/userActions-icons/emptyStateActions.svg) 0 no-repeat;background-size:cover;width:100%;height:100%}.quickFilters__headerActions-1KM{display:flex;gap:8px}.quickFilters__description-39Z{font-size:var(--fontsSizesBodyLarge)}.quickFilters__footer-UKW{display:flex;padding:24px 0 0;justify-content:center}.quickFilters__suggestMore-3VH{color:var(--colorsGeneralBlue);font-size:var(--fontsSizesTitleH4);font-weight:var(--fontsWeightBold);text-decoration:underline;cursor:pointer}.quickFilters__suggestMore-3VH:hover{text-decoration:none}", ""]);

// exports
exports.locals = {
	"wrapper": "quickFilters__wrapper-28J",
	"wrapper": "quickFilters__wrapper-28J",
	"content": "quickFilters__content-2U7",
	"content": "quickFilters__content-2U7",
	"wrapperWithoutSuggestMore": "quickFilters__wrapperWithoutSuggestMore-2Ww",
	"wrapperWithoutSuggestMore": "quickFilters__wrapperWithoutSuggestMore-2Ww",
	"aiWrapper": "quickFilters__aiWrapper-1L3",
	"aiWrapper": "quickFilters__aiWrapper-1L3",
	"header": "quickFilters__header-1rM",
	"header": "quickFilters__header-1rM",
	"title": "quickFilters__title-2dO",
	"title": "quickFilters__title-2dO",
	"spaceBetween": "quickFilters__spaceBetween-1X6",
	"spaceBetween": "quickFilters__spaceBetween-1X6",
	"emptyStateImage": "quickFilters__emptyStateImage-BvC",
	"emptyStateImage": "quickFilters__emptyStateImage-BvC",
	"headerActions": "quickFilters__headerActions-1KM",
	"headerActions": "quickFilters__headerActions-1KM",
	"description": "quickFilters__description-39Z",
	"description": "quickFilters__description-39Z",
	"footer": "quickFilters__footer-UKW",
	"footer": "quickFilters__footer-UKW",
	"suggestMore": "quickFilters__suggestMore-3VH",
	"suggestMore": "quickFilters__suggestMore-3VH"
};