exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".loading__frame-37S{background:#fff;-webkit-user-select:none;user-select:none;border:1px #f8f8f8;border-radius:5px;-moz-box-sizing:border-box;box-shadow:-2px 1px 12px 1px #e0e0e0;padding:10px;box-sizing:border-box}.loading__top-izd{justify-content:center;display:flex;border-bottom:1px solid #f2f2f2;align-items:center}.loading__top-inner-3mM{display:block}.loading__title-X8k{font-weight:var(--fontsWeightBold);color:#bbc2c5;font-size:var(--fontsSizesTitleH4)}.loading__icon-3aj{background-repeat:no-repeat;background-position:50%;background-size:contain;height:59px}.loading__bottom-3LA{margin-top:18px;display:flex;justify-content:center}.loading__gif-3ZT{background:url(/assets/giphy-downsized.gif) 50% no-repeat;background-size:contain;width:76px;height:76px}", ""]);

// exports
exports.locals = {
	"frame": "loading__frame-37S",
	"frame": "loading__frame-37S",
	"top": "loading__top-izd",
	"top": "loading__top-izd",
	"top-inner": "loading__top-inner-3mM",
	"topInner": "loading__top-inner-3mM",
	"title": "loading__title-X8k",
	"title": "loading__title-X8k",
	"icon": "loading__icon-3aj",
	"icon": "loading__icon-3aj",
	"bottom": "loading__bottom-3LA",
	"bottom": "loading__bottom-3LA",
	"gif": "loading__gif-3ZT",
	"gif": "loading__gif-3ZT"
};