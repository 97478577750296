exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".avatar__picture-16M{width:45px;height:45px;background:none 50% no-repeat;background-size:contain}.avatar__initials-q79{display:inline-flex;text-transform:uppercase;font-size:var(--fontsSizesBodyNormal);border:1px solid #a7a7a7;color:#fff;justify-content:center;box-sizing:border-box;align-items:center}.avatar__shadow-1BK{width:40px;height:40px;opacity:.4;filter:blur(6.3px);position:absolute;left:0;top:6px}.avatar__outer-div-2LJ{position:relative}", ""]);

// exports
exports.locals = {
	"picture": "avatar__picture-16M",
	"picture": "avatar__picture-16M",
	"initials": "avatar__initials-q79 avatar__picture-16M",
	"initials": "avatar__initials-q79 avatar__picture-16M",
	"shadow": "avatar__shadow-1BK",
	"shadow": "avatar__shadow-1BK",
	"outer-div": "avatar__outer-div-2LJ",
	"outerDiv": "avatar__outer-div-2LJ"
};