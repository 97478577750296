import { userMetricTypes, nonCumulativeMetricTypes } from 'components/common/logic/enums';
import { metricsBlacklist } from 'components/pages/settings/goals/logic/enums';

export function getMetricsOptions({ userMetrics }) {
  const funnelStagesMetrics = userMetrics.filter((metric) => metric.metricType === userMetricTypes.funnelStage);
  const velocityMetrics = userMetrics.filter((metric) => metric.metricType === userMetricTypes.velocity);
  const conversionRateMetrics = userMetrics.filter((metric) => metric.metricType === userMetricTypes.conversionRate);
  const revenueMetrics = userMetrics.filter((metric) => metric.metricType === userMetricTypes.revenue);

  const supportedMetrics = [
    ...funnelStagesMetrics,
    ...revenueMetrics,
    ...conversionRateMetrics,
    ...velocityMetrics,
  ].filter((metric) => !metricsBlacklist.includes(metric.metricName));

  return supportedMetrics.map((metric) => ({
    value: metric.metricName,
    label: metric.nickname,
    type: metric.metricType,
    cumulative: !nonCumulativeMetricTypes.includes(metric.metricType),
  }));
}
