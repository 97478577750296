import { action, decorate, observable } from 'mobx';
import { persist } from 'mobx-persist';
import hydrate from 'stores/hydrate';

import interactionsStore from 'stores/interactionsStore';

import { getToggleTabConfigLabel } from 'stores/logic/analyzeStore';
import { Interactions } from 'trackers/interactions/enums';

class AnalyzeStore {
  constructor() {
    this.configPerTab = {
      channels: {},
      content: {},
    };
  }

  updateTabConfig({ tabName, configKey, configValue }) {
    const configPerTab = { ...this.configPerTab };
    if (!configPerTab[tabName]) {
      configPerTab[tabName] = {};
    }
    configPerTab[tabName][configKey] = configValue;

    this.configPerTab = configPerTab;

    const toggleTabConfigLabel = getToggleTabConfigLabel({ configKey, configValue });
    interactionsStore.interactionTracker.trackConfig({
      type: Interactions.global.segmentTabToggleChanged.type,
      name: Interactions.global.segmentTabToggleChanged.name,
      description: toggleTabConfigLabel,
    });
  }
}

decorate(AnalyzeStore, {
  configPerTab: observable.ref,
  updateTabConfig: action.bound,
});

const schema = {
  configPerTab: {
    type: 'object',
  },
};

const store = persist(schema)(new AnalyzeStore());

hydrate('analyzeStore', store);

export default store;
