exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".PopupMenu-module__menuStyle___1aWi0{position:absolute;top:100%;left:0;background-color:var(--colorsGeneralWhite);border-radius:4px;box-shadow:0 4px 16px 0 rgba(24,32,51,.12);z-index:10}.PopupMenu-module__optionStyle___sSsXG{display:flex;align-items:center;padding:0 12px;margin:6px;border-radius:4px;height:40px;white-space:nowrap;font-size:var(--fontsSizesBodyNormal);cursor:pointer;color:var(--colorsTextGray)}.PopupMenu-module__optionStyle___sSsXG:hover{background-color:var(--colorsBackgroundLightGray);border-radius:4px}.PopupMenu-module__menu___Y7mGo{position:relative}", ""]);

// exports
exports.locals = {
	"menuStyle": "PopupMenu-module__menuStyle___1aWi0",
	"optionStyle": "PopupMenu-module__optionStyle___sSsXG",
	"menu": "PopupMenu-module__menu___Y7mGo"
};