import { tabsPaths, tabsIds } from 'enums';

export const firstTierRoutes = [
  {
    tabName: 'Getting Started',
    path: tabsPaths.gettingStarted,
    icon: 'gettingStarted',
    id: tabsIds.gettingStarted,
    flag: 'onboardingGettingStartedPage',
  },
  {
    tabName: 'Home',
    path: tabsPaths.home,
    icon: 'home',
    id: tabsIds.home,
  },
  {
    tabName: 'Analyze',
    icon: 'analyze',
    id: tabsIds.analyze,
  },
  {
    tabName: 'Reports',
    path: tabsPaths.reports,
    icon: 'reports',
    id: tabsIds.reports,
    flag: 'headerReportsTab',
  },
  {
    tabName: 'Insights',
    icon: 'insights',
    id: tabsIds.insights,
    flag: 'headerInsightsTab',
    path: tabsPaths.insights,
  },
  {
    tabName: 'Sales Enablement',
    icon: 'salesEnablement',
    id: tabsIds.salesEnablement,
    flag: 'headerSalesEnablementTab',
  },
  {
    tabName: 'Plan',
    icon: 'plan',
    id: tabsIds.plan,
    flag: 'headerPlanTab',
  },
  {
    tabName: 'Ops',
    icon: 'ops',
    id: tabsIds.ops,
  },
];

export const settingsNavigationMenu = [
  {
    tabName: 'Settings',
    icon: 'settings',
    id: tabsIds.settings,
  },
];

export const headerMenuKinds = {
  questionsResults: 'questionsResults',
  aiAnswers: 'aiAnswers',
  analyze: 'analyze',
  createNewReport: 'createNewReport',
  reports: 'reports',
  dashboard: 'dashboard',
  widgetBuilder: 'widgetBuilder',
  home: 'home',
  optimalJourney: 'optimalJourney',
};

export const lastUpdatedTimesFormatDate = 'DD MMM YYYY, h:mm a';
