import timeFrameModule from 'modules/timeframe';
import analysisStore from 'stores/analysisStore';
import filterStore from 'stores/filterStore';
import userStore from 'stores/userStore';

import { attributionModelsItems } from 'attribution/models';
import { analyzeWidgetsResultKeyNames } from 'stores/logic/enums';
import { makeFilters } from 'components/utils/filters';
import { getTSForTimezone } from 'stores/analyze/timeUtils';

export default async function getWidgetsData({
  widgets,
  configPerWidget = {},
  filters,
  resultKeyName = analyzeWidgetsResultKeyNames.insightsPage,
  isConcatResult,
  timeFrame,
}) {
  const timeFrameParams = timeFrameModule.getTimeframeParams({
    ...timeFrame,
    fiscalYearFirstMonth: userStore.userMonthPlan.fiscalYearFirstMonth,
  });

  const baseConfig = {
    filters: filters ? makeFilters(filters, filterStore.filtersData) : [],
    timeFrame: {
      ...timeFrameParams,
      startDate: new Date(getTSForTimezone(timeFrameParams.startDate)),
      endDate: new Date(getTSForTimezone(timeFrameParams.endDate)),
      value: timeFrame.rollingValue || timeFrame.value,
    },
    attributionModel: attributionModelsItems[0],
    shouldUsePredefinedFilters: true,
  };

  const config = {};
  for (const widget of widgets) {
    if (!config[widget]) {
      config[widget] = [];
    }

    const customConfigPerWidget = configPerWidget[widget];
    if (customConfigPerWidget) {
      for (const customConfig of customConfigPerWidget) {
        config[widget].push({
          ...baseConfig,
          ...customConfig,
        });
      }
      continue;
    }

    config[widget].push(baseConfig);
  }

  await analysisStore.getWidgetsData({
    widgets,
    config,
    resultKeyName,
    isConcatResult,
  });
}
