import { useMemo } from 'react';

import useStyles from 'hooks/useStyles';

import CanvasSection from 'components/widgets/optimalJourney/journeyCanvas/CanvasSection';

import { firstJourneySection } from 'components/widgets/optimalJourney/journeyCanvas/enums';

import style from 'styles/analyze/journey-canvas.css';

const styles = style.locals || {};

export default function JourneyCanvas({
  isLoaded,
  data = {},
  optimalJourneyStages,
  blocksItems,
  colorsBySegment,
  optimalJourneyKpi,
}) {
  useStyles([style]);

  const stagesToRender = useMemo(() => {
    const updatedOptimalJourneyStages = [firstJourneySection, ...optimalJourneyStages];
    return updatedOptimalJourneyStages.slice(0, updatedOptimalJourneyStages.length - 1);
  }, [optimalJourneyStages]);

  return (
    <>
      <div className={styles.container}>
        {stagesToRender.map((stage, index) => {
          const stageKey = `stage-${index}-${stage.value}`;
          const isLastSection = index === stagesToRender.length - 1;
          const lastSectionLabel = isLastSection ? optimalJourneyStages[optimalJourneyStages.length - 1].label : null;

          const dataForStage = data[stage.value];
          return (
            <CanvasSection
              key={stageKey}
              label={stage.label}
              lastSectionLabel={lastSectionLabel}
              blocksItems={blocksItems}
              colorsBySegment={colorsBySegment}
              isLoaded={isLoaded}
              data={dataForStage}
              optimalJourneyKpi={optimalJourneyKpi}
            />
          );
        })}
      </div>
      <div className={styles.bottomBar}>
        <div>Start of Journey</div>
        <div>End of Journey</div>
      </div>
    </>
  );
}
