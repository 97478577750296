import classnames from 'classnames';

import useStyles from 'hooks/useStyles';

import Skeleton from 'components/common/Skeleton';

import { journeyInsightsSkeletons, journeyInsightsTitle } from 'components/widgets/optimalJourney/insights/enums';
import { generalBlocks } from 'components/widgets/optimalJourney/enums';

import style from 'styles/analyze/optimal-journey.css';

const styles = style.locals || {};

export default function JourneyInsights({
  isLoaded,
  insightsItems = [],
  blocksItems,
}) {
  useStyles([style]);

  const skeletonBlocks = [];

  for (const blocksItem of blocksItems) {
    if (generalBlocks.some((block) => block.value === blocksItem.value)) {
      if (!isLoaded.accountPerformance) {
        skeletonBlocks.push(blocksItem.value);
      }
      continue;
    }
    if (!isLoaded[blocksItem.value]) {
      skeletonBlocks.push(blocksItem.value);
    }
  }

  return (
    <div className={styles.journeyInsightsWrapper}>
      <div className={styles.journeyInsightsTitle}>{journeyInsightsTitle}</div>
      {insightsItems.map((item, index) => {
        const blockKey = `insights-${item.type}-${item.value}-${index}`;
        return (
          <div key={blockKey} className={styles.journeyInsightsItem}>
            <div className={styles.lightBulbIcon} data-testid="lightBulbIcon" />
            <div>{item.insight}</div>
          </div>
        );
      })}

      {skeletonBlocks.map((block, index) => {
        const blockKey = `insights-${block.value}-${index}`;
        return (
          <div key={blockKey} className={styles.journeyInsightsItem}>
            <div className={styles.lightBulbIcon} data-testid="lightBulbIcon" />
            <div className={styles.insightsSkeletons}>
              {journeyInsightsSkeletons.map((width) => (
                <Skeleton width={width} height={18} key={`skeleton-${width}-${block}`} />
              ))}
            </div>
          </div>
        );
      })}

      <div className={styles.journeyInsightsEmpty}>
        <div className={classnames(styles.lightBulbIcon, styles.lightBulbIconBig)} />
        Select a journey block
        <br />
        {' to generate more insights.'}
      </div>
    </div>
  );
}
