exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".uplift__wrapper-2yI{display:flex;align-items:center;width:100%;height:calc(100% + 24px);gap:8px;margin-top:-12px}.uplift__upliftBar-Prt{display:flex;align-items:center;flex:1;height:100%;position:relative}.uplift__negativeBarWrapper-2od{flex:0 0 50%;display:flex;justify-content:flex-end}.uplift__positiveBarWrapper-3kp{flex:1}.uplift__negativeBar-3Ap{background-color:var(--colorsGeneralRed);height:12px;border-radius:8px 0 0 8px;margin-top:12px}.uplift__positiveBar-1Vy{background-color:var(--colorsGeneralGreen);height:12px;border-radius:0 8px 8px 0;flex:0 0 50%;margin-top:12px}.uplift__originLine-3N8{width:1px;border-right:dashed 1px var(--colorsTextLightGray100);flex:0 0 1px;height:100%}.uplift__upliftValue-ToY{flex:0 0 68px;font-size:var(--fontsSizesBodyNormal);color:var(--colorsTextGray);margin-top:12px;text-align:right}.uplift__positiveValue-2fD{color:var(--colorsGeneralGreen)}.uplift__negativeValue-3M2{color:var(--colorsGeneralRed)}", ""]);

// exports
exports.locals = {
	"wrapper": "uplift__wrapper-2yI",
	"wrapper": "uplift__wrapper-2yI",
	"upliftBar": "uplift__upliftBar-Prt",
	"upliftBar": "uplift__upliftBar-Prt",
	"negativeBarWrapper": "uplift__negativeBarWrapper-2od",
	"negativeBarWrapper": "uplift__negativeBarWrapper-2od",
	"positiveBarWrapper": "uplift__positiveBarWrapper-3kp",
	"positiveBarWrapper": "uplift__positiveBarWrapper-3kp",
	"negativeBar": "uplift__negativeBar-3Ap",
	"negativeBar": "uplift__negativeBar-3Ap",
	"positiveBar": "uplift__positiveBar-1Vy",
	"positiveBar": "uplift__positiveBar-1Vy",
	"originLine": "uplift__originLine-3N8",
	"originLine": "uplift__originLine-3N8",
	"upliftValue": "uplift__upliftValue-ToY",
	"upliftValue": "uplift__upliftValue-ToY",
	"positiveValue": "uplift__positiveValue-2fD",
	"positiveValue": "uplift__positiveValue-2fD",
	"negativeValue": "uplift__negativeValue-3M2",
	"negativeValue": "uplift__negativeValue-3M2"
};