import { getTSForTimezone } from 'stores/analyze/timeUtils';

import timeFrameModule from 'modules/timeframe';
import userStore from 'stores/userStore';
import base from './base';

function getWidgetConfig({ widgetConfig }) {
  if (!widgetConfig) {
    return widgetConfig;
  }
  const baseConfig = base.getWidgetConfig({ widgetConfig });

  if (baseConfig.whatIfParams?.timeframe?.value) {
    const { startDate, endDate } = timeFrameModule.getTimeframeParams({
      value: baseConfig.whatIfParams.timeframe.value,
      fiscalYearFirstMonth: userStore.userMonthPlan.fiscalYearFirstMonth,
      useEndOfToday: false,
    });

    const startTZ = new Date(getTSForTimezone(startDate.getTime()));
    const endTZ = new Date(getTSForTimezone(endDate.getTime()));

    baseConfig.whatIfParams.timeframe = {
      value: baseConfig.whatIfParams.timeframe.value,
      startDate: startTZ,
      endDate: endTZ,
    };
  }

  return baseConfig;
}

export default {
  ...base,
  getWidgetConfig,
};
