exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".Loader-module__loading___3PmeA{position:fixed;top:35%;left:40%}.Loader-module__popup___XBY6h{width:300px;height:200px;display:flex;justify-content:center;align-items:center}.Loader-module__spinnerText___3bQzl{height:24px;font-size:var(--fontsSizesBodyLarge);font-weight:var(--fontsWeightMedium);color:var(--colorsTextGray)}.Loader-module__loaderPopup___3Xno2{background:var(--colorsGeneralWhite);border-radius:12px;box-shadow:0 4px 12px 0 rgba(0,0,0,.06);flex-direction:column;padding-bottom:16px}.Loader-module__loadingLogo___27dd2{height:80px}", ""]);

// exports
exports.locals = {
	"loading": "Loader-module__loading___3PmeA",
	"popup": "Loader-module__popup___XBY6h",
	"spinnerText": "Loader-module__spinnerText___3bQzl",
	"loaderPopup": "Loader-module__loaderPopup___3Xno2",
	"loadingLogo": "Loader-module__loadingLogo___27dd2"
};