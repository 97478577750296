exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".beta-tag__betaTag-1bT{background:var(--colorsGeneralBlue);border-radius:4px;color:var(--colorsGeneralWhite);font-size:var(--fontsSizesBodySmall);padding:4px 6px;margin-left:8px;height:12px;line-height:12px}", ""]);

// exports
exports.locals = {
	"betaTag": "beta-tag__betaTag-1bT",
	"betaTag": "beta-tag__betaTag-1bT"
};