export const errorIndicationStatuses = {
  error: 'error',
  warning: 'warn',
  inProgress: 'inProgress',
  timeout: 'timeout',
  finished: 'finished',
};

export const errorIndicationTitles = {
  [errorIndicationStatuses.error]: {
    title: 'Widget Loading Issues',
    subTitle: 'Our team is aware and working on a fix! Thank you for your patience.',
  },
  [errorIndicationStatuses.timeout]: {
    title: 'Time for a Quick Refresh',
    subTitle: 'Looks like your data’s playing hard to get. Tap ‘Refresh’ to reel it in!',
  },
};

export const skeletonTableRows = 6;

export const skeletonChartHeightPattern = [0.5, 1, 0.7, 0.3, 0.9, 0.6, 0.4, 0.1, 0.8];

export const skeletonSmallTableCellSizes = {
  width: 140,
  height: 24,
};

export const skeletonSmallTextSizes = {
  width: 100,
  height: 22,
};

export const skeletonBigTextSizes = {
  width: 70,
  height: 50,
};

export const widgetDescriptionMaxLength = 50;

export const skeletonSmallNumberSizes = {
  width: 60,
  height: 24,
};

export const skeletonListRandomWidth = [0.5, 1, 0.7, 0.9];

export const directions = {
  vertical: 'vertical',
  horizontal: 'horizontal',
};

export const menuOpenDirections = {
  ltr: 'ltr',
  rtl: 'rtl',
};

export const indicationStatuses = {
  error: 'error',
  warning: 'warning',
};
