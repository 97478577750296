import React, { useMemo } from 'react';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { AsyncPaginate } from 'react-select-async-paginate';
import classNames from 'classnames';
import { Button } from '@infinigrow/libs';

import useStyles from 'hooks/useStyles';

import InfoMarker from 'components/pages/InfoMarker';
import Tooltip from 'components/controls/Tooltip';
import EllipsisTooltip from 'components/controls/EllipsisTooltip';

import { selectLoadMoreOptions } from 'components/controls/logic/AsyncSelect';

import dropdownStyle from 'styles/controls/dropdown.css';

const styles = dropdownStyle.locals || {};

function Dropdown({
  onChange,
  placeholder,
  options = [],
  noOptionsMessage,
  controlWidth,
  bottomButton,
  withTooltip,
  promptTextCreator,
  searchFunction,
  isAsyncPagination,
  isSearchable = false,
  selectedKey = '',
  findSelectedByKey = 'value',
  dropdownLabel,
  disabled,
  isShowDropdownIndicator = true,
  toolTip,
  className,
  optionRenderer,
  onNewOptionClick,
  allowCreateNewOption,
  isClearable = false,
  isDropdownAsLink,
  optionsTooltipProps = { place: 'right' },
  dataTestId,
  menuListMaxHeight = '300px',
  menuMaxWidth = 'fit-content',
  openMenuFromRightCorner,
  bottomMenuRenderer,
  customSelectOption,
  customControlTextStyles,
  customDropdownIndicatorWrapperStyles,
  customDropdownIndicatorArrowStyles,
  controlTextColor,
  isShowError,
  menuIsOpen,
  classNameOption,
  classNameMenu,
  tagId,
  autoFocus,
  id,
  menuShouldScrollIntoView,
  menuListWidth,
}) {
  useStyles([dropdownStyle]);

  const Control = useMemo(() => function ({ children, ...props }) {
    return (
      <Tooltip id={`tooltip-dropdwon-${toolTip}`} tip={toolTip}>
        <components.Control
          {...props}
          className={classNames(
            styles.controlWrap,
            isDropdownAsLink && styles.dropdownAsLinkControlWrap
          )}
        >
          {dropdownLabel ? (
            <span className={styles.dropdownLabel} style={customControlTextStyles}>
              {dropdownLabel}
            </span>
          ) : null}

          {children}
        </components.Control>
      </Tooltip>
    );
  }, [dropdownLabel, isDropdownAsLink, toolTip]);

  const selectedValue = useMemo(() => {
    for (const option of options) {
      if (option[findSelectedByKey] === selectedKey) {
        return { label: option.label, value: option[findSelectedByKey] };
      }
      if (option.options) {
        for (const childOption of option.options) {
          if (childOption[findSelectedByKey] === selectedKey) {
            return { label: childOption.label, value: childOption[findSelectedByKey] };
          }
        }
      }
    }
    return '';
  }, [selectedKey, options]);

  const SelectOption = useMemo(() => function (prop) {
    return prop.data.label.includes('(Object)') ? null
      : (
        <components.Option
          {...prop}
          className={classNames(
            styles.selectOptionWrap,
            prop.data.disabled && styles.selectOptionDisabled,
            classNameOption
          )}
        >
          {optionRenderer
            ? <>{optionRenderer(prop)}</>
            : (
              <EllipsisTooltip
                tip={prop.data.label}
                text={(
                  <div className={styles.selectOptionWrapper}>
                    {prop.data.label}
                    <div className={styles.selectOptionButtons}>
                      {prop.data.icon && (
                        prop.data.icon
                      )}
                      {prop.data.tooltip && (
                      <InfoMarker
                        tooltipText={prop.data.tooltip}
                        TooltipProps={optionsTooltipProps}
                      />
                      )}
                    </div>
                  </div>
                      )}
                place="bottom"
                TooltipProps={{ wide: true }}
              />
            )}
        </components.Option>
      );
  }, [optionRenderer, optionsTooltipProps, classNameOption]);

  const Menu = useMemo(() => function (props) {
    return (
      <components.Menu {...props} className={classNameMenu}>
        <div className={styles.menuList}>{props.children}</div>
        {bottomButton && <Button className={classNames(styles.menuButton, bottomButton.className)} onClick={bottomButton.action} type="secondaryBlue">{bottomButton.label}</Button>}
        {bottomMenuRenderer}
      </components.Menu>
    );
  }, [bottomButton, bottomMenuRenderer, classNameMenu]);

  const DropdownIndicator = useMemo(() => function (prop) {
    return (
      <components.DropdownIndicator {...prop}>
        <div
          className={classNames(styles.arrowDown)}
          style={customDropdownIndicatorArrowStyles}
        />
      </components.DropdownIndicator>
    );
  }, []);

  const updatedControlTextColor = controlTextColor || (isDropdownAsLink ? '#4D91FC' : '#707EA7');

  const customStyles = {
    menuList: (provided) => ({
      ...provided,
      maxHeight: menuListMaxHeight,
      width: menuListWidth || 'auto',
    }),
    menu: (provided) => ({
      ...provided,
      border: '1px solid #DCE1EF',
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 4px 12px rgba(24, 32, 51, 0.07)',
      fontSize: '14px',
      color: '#707EA7',
      fontWeight: '400',
      minWidth: menuMaxWidth,
      right: openMenuFromRightCorner ? '0' : 'auto',
      zIndex: 4,
    }),
    option: (provided) => ({
      ...provided,
      padding: '6px 12px',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      backgroundColor: 'white',
      color: '#707EA7',
      fontSize: '14px',
      minHeight: '40px',
      '&:hover': {
        backgroundColor: '#F6F7FB',
        borderRadius: '4px',
      },
    }),
    singleValue: () => ({
      color: updatedControlTextColor,
      ...customControlTextStyles,
    }),
    control: (provided, state) => ({
      ...provided,
      width: controlWidth,
      fontSize: '14px',
      boxShadow: 'none',
      borderColor: isShowError ? '#E43A54' : (!menuIsOpen && state.menuIsOpen) ? '#5E8FF4' : '#DCE1EF',
      padding: '6px',
      color: updatedControlTextColor,
      textDecoration: isDropdownAsLink ? 'underline' : 'none',
      cursor: 'pointer',
      backgroundColor: disabled ? '#F6F7FB' : '#FFFFFF',
      fontWeight: dropdownLabel ? '600' : '400',
      height: '40px',
      whiteSpace: 'nowrap',
      '&:hover': {
        borderColor: isShowError ? '#E43A54' : (!menuIsOpen && state.menuIsOpen) ? '#5E8FF4' : '#B2BBD5',
        textDecoration: 'none !important',
      },
      ...customControlTextStyles,
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#707EA7',
      position: 'relative',
      top: 'auto',
      margin: '0',
      transform: 'initial',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
      color: '#707EA7',
      ...customDropdownIndicatorWrapperStyles,
    }),
    clearIndicator: (provided) => ({
      ...provided,
      padding: '0',
    }),
    groupHeading: (provided) => ({
      ...provided,
      color: '#182033',
      textTransform: 'none',
      fontSize: '12px',
      padding: '0 12px',
    }),
  };

  const renderers = {};

  if (withTooltip) {
    renderers.optionRenderer = ({ label }) => (
      <div className={customStyles.option}>
        <Tooltip
          text={label}
          place="bottom"
          TooltipProps={{ wide: true }}
        />
      </div>
    );
  }

  const DropdownSelect = allowCreateNewOption ? CreatableSelect : Select;

  if (isAsyncPagination) {
    return (
      <AsyncPaginate
        key={`paginate-${id}`}
        value={selectedKey === -1 ? selectedKey : selectedValue}
        loadOptions={(searchValue, prevOptions) => selectLoadMoreOptions({ searchValue, prevOptions, searchFunction })}
        onChange={onChange}
        debounceTimeout={1000}
        placeholder={placeholder}
        styles={customStyles}
        className={className}
        isSearchable={isSearchable}
        components={{
          Option: customSelectOption || SelectOption,
          DropdownIndicator: (disabled || !isShowDropdownIndicator) ? () => null : DropdownIndicator,
          Menu,
          IndicatorSeparator: () => null,
          Control,
        }}
        menuIsOpen={menuIsOpen}
        autoFocus={autoFocus}
      />
    );
  }

  return (
    <DropdownSelect
      {...renderers}
      className={classNames(className, tagId)}
      data-testid={dataTestId}
      noOptionsMessage={noOptionsMessage}
      onChange={onChange}
      value={selectedKey === -1 ? selectedKey : selectedValue}
      styles={customStyles}
      options={options}
      components={{
        Option: customSelectOption || SelectOption,
        DropdownIndicator: (disabled || !isShowDropdownIndicator) ? () => null : DropdownIndicator,
        Menu,
        IndicatorSeparator: () => null,
        Control,
      }}
      placeholder={placeholder}
      isSearchable={isSearchable}
      isDisabled={disabled}
      onNewOptionClick={onNewOptionClick}
      promptTextCreator={promptTextCreator}
      isClearable={isClearable}
      isOptionDisabled={(option) => option.disabled}
      toolTip={toolTip}
      isDropdownAsLink={isDropdownAsLink}
      dropdownLabel={dropdownLabel}
      menuIsOpen={menuIsOpen}
      autoFocus={autoFocus}
      menuShouldScrollIntoView={menuShouldScrollIntoView}
    />
  );
}

export default Dropdown;
