import React, { useState } from 'react';
import Page from 'components/Page';
import { Button } from '@infinigrow/libs';

import useStyles from 'hooks/useStyles';

import Dropdown from 'components/controls/Dropdown';
import ComparisonValue from 'components/common/ComparisonValue';
import InfiniteScrollTable from 'components/common/InfiniteScrollTable';
import ChannelList from 'components/common/ChannelList';
import CompanyLogo from 'components/pages/users/CompanyLogo';

import { inspectTableData } from 'components/pages/settings/workflows/demoInspectPopups/mockData';
import {
  inspectTableColumns,
  inspectTableColumnsKeys,
  inspectTableColumnsLabels,
} from 'components/pages/settings/workflows/demoInspectPopups/enums';

import style from 'styles/settings/workflows/compareEnrichedMetrics.css';
import inspectStyle from 'styles/analyze/inspectData/inspect.css';

const styles = style.locals || {};
const inspectStyles = inspectStyle.locals || {};

export default function InspectPercentageOfMetricPopup({
  onClose,
  timeFrameLabel,
  onClick,
}) {
  useStyles([style, inspectStyle]);

  const [tableData, setTableData] = useState(inspectTableData);

  function getTableColumnByKey({ columnKey }) {
    const newColumn = {
      id: columnKey,
      accessor: columnKey,
      header: inspectTableColumnsLabels[columnKey],
      cell: (cellItem) => cellItem,
    };

    if (columnKey === inspectTableColumnsKeys.displayName) {
      const clearbitUrl = 'https://logo.clearbit.com';
      newColumn.cell = (cellItem, props) => {
        const domain = props.original.domain;
        return (
          <div>
            <CompanyLogo
              src={`${clearbitUrl}/${domain}`}
              fallbackSrc={`${clearbitUrl}/${cellItem}`}
              className={styles.companyLogo}
            />
            <div className={styles.companyName} onClick={() => onClick(props.original)}>
              {cellItem}
            </div>
          </div>
        );
      };
    }

    if (columnKey === inspectTableColumnsKeys.uniqChannels) {
      newColumn.cell = (cellItem) => (
        <div className={inspectStyles.channelContainer}>
          <ChannelList
            channels={cellItem.map((channel) => channel)}
            width={32}
          />
        </div>
      );
    }

    return newColumn;
  }

  function onSearchTable({ value }) {
    const newTableData = inspectTableData.filter((tableRow) => {
      const displayName = tableRow.displayName.toLowerCase();
      return displayName.includes(value.toLowerCase());
    });
    setTableData(newTableData);
  }

  const tableColumns = inspectTableColumns.map((columnKey) => getTableColumnByKey({ columnKey }));

  return (
    <Page
      popup
      width="940px"
      otherProps={{
        id: 'inspect-percentage-of-MetricPopup',
      }}
      contentClassName={inspectStyles.content}
    >
      <div className={inspectStyles.inspectPopupWrapper}>
        <div className={inspectStyles.mainTopPopup}>

          <div
            className={inspectStyles.close}
            onClick={() => { onClose(); }}
          />
          <div
            className={inspectStyles.title}
          >
            Inspect Opps
          </div>
          <div className={inspectStyles.topMenu}>
            <Dropdown
              options={[{ label: timeFrameLabel, value: timeFrameLabel }]}
              selectedKey={timeFrameLabel}
              dropdownLabel="Date range"
              disabled
            />
            <Button type="primaryBlue" className={styles.exportButton}>
              Export data
            </Button>
          </div>
        </div>
        <div className={inspectStyles.mainContentPopup}>
          <div className={inspectStyles.mainContentMetrics}>
            <div className={styles.statsCard}>
              <div># of Opps</div>
              <div className={styles.statsCardValue}>100</div>
            </div>
            <div className={styles.statsCard}>
              <div>InfiniGrow % Opps credited to Direct </div>
              <div className={styles.statsCardValue}>12%</div>
              <ComparisonValue
                value={-61}
                isGrowthDesired={false}
              />
            </div>
            <div className={styles.statsCard}>
              <div>Salesforce % Opps credited to Direct</div>
              <div className={styles.statsCardValue}>73%</div>
            </div>
          </div>
          <div>
            <div className={styles.tableTitle}>Opps credited to Direct in Salesforce</div>
            <InfiniteScrollTable
              tableData={tableData}
              tableColumns={tableColumns}
              onSearch={(value) => onSearchTable({ value })}
              isLoaded
            />
          </div>
        </div>
      </div>
    </Page>
  );
}
