import React from 'react';
import { inject, observer } from 'mobx-react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import classNames from 'classnames';

import Component from 'components/Component';
import FeatureFlagsWithBlur from 'components/common/FeatureFlagsWithBlur';
import FunnelView from 'components/pages/analyze/OverviewTab/FunnelView';
import GoalsAnalysis from 'components/pages/insights/overviewTab/goals/GoalsAnalysis';
import HistoricalPerformance from 'components/pages/analyze/OverviewTab/HistoricalPerformance';
import StatsSquares from 'components/pages/analyze/OverviewTab/StatsSquares';
import TrendAnalysis from 'components/pages/analyze/OverviewTab/TrendAnalysis';
import attributionStore from 'stores/attributionStore';
import ImpactBySegment from 'components/pages/analyze/SegmentsTab/ImpactBySegment';
import BreadcrumbsTitle from 'components/common/BreadcrumbsTitle';

import { navigateBetweenAnalyzeTabs } from 'stores/analyze/logic/navigations';
import { FREQUENCY_VALUES } from 'components/utils/frequency';
import { FUNNEL3 } from 'components/utils/indicators';
import { compose } from 'components/utils/utils';
import { getGoalTimeframe } from 'components/utils/goals';
import {
  getWidgetsData, getWidgetsDataV2, getWidgetFullConfig, getWidgetsDataFromStoreV2,
} from 'components/pages/analyze/widgetsRequest';
import { isFiltersChanged, isTimeframeChanged } from 'components/pages/analyze/logic/Tabs';
import { isFlagActive } from 'components/pages/analyze/logic/FeatureFlagsWithBlur';
import { navigateToJourneys } from 'stores/analyze/logic/journeysStore';
import { pickerTimeframeToFrequencyType, activityStatusValues } from 'components/pages/settings/goals/logic/enums';
import { trendAnalysisInitialMetrics } from 'components/pages/analyze/OverviewTab/logic/enums';
import { widgetTypes, flagsNames } from 'components/pages/analyze/enums';
import { getTimeframeOptionsByActivityStatus } from 'components/pages/settings/goals/logic/PropertiesTab';
import { parseHistoricalPerformanceDataToStatsSquares, getSelectedMetricsForStatsSquare } from 'components/pages/analyze/OverviewTab/logic/statsSquares';
import { pageLabels } from 'enums';
import { getFilteredImpactBySegmentRecords } from 'components/pages/analyze/SegmentsTab/logic/segments';

import style from 'styles/analyze/analyze.css';
import { isEqual } from 'lodash';

const enhance = compose(
  inject(({
    analysisStore: {
      dataPerWidget: {
        [widgetTypes.trendBySegments]: trendBySegments,
        [widgetTypes.accountOptimizationMetrics]: accountOptimizationMetrics,
      },
      restIsLoadedWidgetIndication,
    },
    widgetsAnalysisStore: {
      dataPerWidget: {
        [widgetTypes.trendAnalysis]: trendAnalysis,
        [widgetTypes.historicalPerformance]: historicalPerformance,
        [widgetTypes.goalsAnalysis]: goalsAnalysis,
        [widgetTypes.impactBySegment]: impactBySegment,
      },
      configPerWidget: {
        [widgetTypes.trendAnalysis]: trendAnalysisConfig,
        [widgetTypes.historicalPerformance]: historicalPerformanceConfig,
        [widgetTypes.impactBySegment]: impactBySegmentSavedConfig,
      },
      getWidgetRequestId,
      resetWidgetData,
    },
    userStore: {
      userMonthPlan: {
        region,
        fiscalYearFirstMonth,
      },
      funnels,
    },
    attributionStore: {
      autoFilter,
      previousTimeframe: isCompareToPreviousEnabled,
      metricsOptions,
      attributionModel,
      filters,
      timeFrame,
      isAccountMode,
      isCalculateAdvancedMetrics,
    },
  }) => ({
    isCompareToPreviousEnabled,
    metricsOptions,
    historicalPerformance,
    historicalPerformanceConfig,
    attributionModel,
    timeFrame,
    filters,
    trendAnalysis,
    trendAnalysisConfig,
    autoFilter,
    region,
    funnels,
    goalsAnalysis,
    restIsLoadedWidgetIndication,
    fiscalYearFirstMonth,
    isAccountMode,
    getWidgetRequestId,
    resetWidgetData,
    impactBySegment,
    impactBySegmentSavedConfig,
    isCalculateAdvancedMetrics,
    trendBySegments,
    accountOptimizationMetrics,
  })),
  observer
);

class Overview extends Component {
  style = style;

  constructor(props) {
    super(props);
    this.currentTabName = 'overview';
    this.impactByTrendKey = 'impactByTrendKey';
    this.accountOptimizationMetricsKey = 'accountOptimizationMetricsAnalyze';

    const conversionIndicator = props.impactBySegmentSavedConfig?.[this.currentTabName]?.conversionIndicator || FUNNEL3;
    this.state = {
      trendAnalysisMetrics: props.trendAnalysisConfig?.[this.currentTabName]?.metrics || trendAnalysisInitialMetrics,
      trendAnalysisFrequency: props.trendAnalysisConfig?.[this.currentTabName]?.frequency || FREQUENCY_VALUES.MONTH,
      trendAnalysisShouldUseRelativeTimeframe: props.trendAnalysisConfig?.[this.currentTabName]?.shouldUseRelativeTimeframe || false,
      historicalPerformanceFrequency: props.historicalPerformanceConfig?.[this.currentTabName]?.historicalPerformanceFrequency || FREQUENCY_VALUES.MONTH,
      historicalPerformanceSelectedMetrics: [],
      isInitialLoad: null,
      impactByFrequency: props.impactBySegmentSavedConfig?.[this.currentTabName]?.impactBySegmentParams?.segmentFrequency || FREQUENCY_VALUES.MONTH,
      impactByShouldUseRelativeTimeframe: props.impactBySegmentSavedConfig?.[this.currentTabName]?.impactBySegmentParams?.shouldUseRelativeTimeframe || false,
      selectedSegmentImpactBySegment: 'department',
      checkedSegments: [],
      uncheckedSegments: [],
      drilldownMetric: null,
      impactByConversionIndicator: {
        originalFunnel: conversionIndicator,
        value: conversionIndicator,
      },
    };
  }

  componentDidMount() {
    const {
      flags,
      location,
      accountOptimizationMetrics,
    } = this.props;

    this.setState({ isInitialLoad: true });

    const isRenderViaNavigation = location?.state?.isRenderViaNavigation && location?.action === 'PUSH';
    if (isRenderViaNavigation) {
      return;
    }

    if (isFlagActive({ flag: flags.marketingVsSalesWidget })) {
      if (!accountOptimizationMetrics?.[this.accountOptimizationMetricsKey]?.isLoaded) {
        getWidgetsData({
          widgets: [widgetTypes.accountOptimizationMetrics],
          isPreviousTimeFrame: false,
          resultKeyName: this.accountOptimizationMetricsKey,
          configPerWidget: {
            [widgetTypes.accountOptimizationMetrics]: [{
              conversionIndicator: this.state.impactByConversionIndicator.originalFunnel,
            }],
          },
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      filters,
      attributionModel,
      isCompareToPreviousEnabled,
      autoFilter,
      region,
      flags,
      timeFrame,
      fiscalYearFirstMonth,
      isAccountMode,
      isCalculateAdvancedMetrics,
    } = this.props;

    const {
      isInitialLoad,
      selectedSegmentImpactBySegment,
      impactByConversionIndicator,
    } = this.state;

    const didInitialLoadChanged = isInitialLoad !== prevState.isInitialLoad;
    const didAccountModeChanged = isAccountMode !== prevProps.isAccountMode;
    const didFiltersChanged = isFiltersChanged({ filters, prevFilters: prevProps.filters });
    const didTimeFrameChanged = isTimeframeChanged({ timeFrame, prevTimeFrame: prevProps.timeFrame });
    const didAttributionModelChanged = attributionModel !== prevProps.attributionModel;
    const didPreDefinedFiltersChanged = autoFilter !== prevProps.autoFilter;
    const didCompareToPreviousChanged = isCompareToPreviousEnabled !== prevProps.isCompareToPreviousEnabled;
    const didRegionChanged = region !== prevProps.region;
    const didImpactByConversionIndicatorChanged = !isEqual(impactByConversionIndicator, prevState.impactByConversionIndicator);

    if (didInitialLoadChanged || didAccountModeChanged || didFiltersChanged || didTimeFrameChanged || didAttributionModelChanged || didPreDefinedFiltersChanged || didRegionChanged) {
      if (isFlagActive({ flag: flags.historicalPerformanceWidget }) || isFlagActive({ flag: flags.funnelViewWidget }) || isFlagActive({ flag: flags.overviewStatsSquareWidget })) {
        getWidgetsDataV2({
          widget: widgetTypes.historicalPerformance,
          widgetConfig: {
            historicalPerformanceFrequency: this.state.historicalPerformanceFrequency,
          },
          configKeyName: this.currentTabName,
          isPreviousTimeFrame: isCompareToPreviousEnabled,
        });
      }

      if (isFlagActive({ flag: flags.marketingVsSalesWidget })) {
        getWidgetsDataV2({
          widget: widgetTypes.impactBySegment,
          widgetConfig: {
            impactBySegmentParams: {
              segmentFrequency: this.state.impactByFrequency,
              firstSegment: selectedSegmentImpactBySegment,
              fieldsToEnrich: [],
              shouldUseRelativeTimeframe: this.state.impactByShouldUseRelativeTimeframe,
            },
            conversionIndicator: impactByConversionIndicator.originalFunnel,
            kpiFocus: this.state.drilldownMetric || impactByConversionIndicator.value,
          },
          isPreviousTimeFrame: isCompareToPreviousEnabled,
          configKeyName: this.currentTabName,
        });
        if (isCalculateAdvancedMetrics) {
          getWidgetsData({
            widgets: [widgetTypes.trendBySegments],
            configPerWidget: {
              [widgetTypes.trendBySegments]: [{
                trendBySegmentsParams: { firstSegment: selectedSegmentImpactBySegment, secondSegment: null },
                conversionIndicator: impactByConversionIndicator.originalFunnel,
              }],
            },
            isPreviousTimeFrame: false,
            resultKeyName: this.impactByTrendKey,
          });
        }
      }

      if (isFlagActive({ flag: flags.analyzeOverviewGoalsAnalysis })) {
        const goalsSupportedTimeframe = getTimeframeOptionsByActivityStatus({ status: activityStatusValues.ALL }).map((option) => option.value);
        const isGoalsSupportSelectedTimeframe = goalsSupportedTimeframe.includes(timeFrame?.value);
        if (isGoalsSupportSelectedTimeframe) {
          getWidgetsDataV2({
            widget: widgetTypes.goalsAnalysis,
            widgetConfig: {
              goalsAnalysisParams: {
                frequencyType: pickerTimeframeToFrequencyType[timeFrame?.value],
                timeframe: getGoalTimeframe({ timeframe: timeFrame, fiscalYearFirstMonth }),
                supportFilters: true,
                status: activityStatusValues.ALL,
              },
            },
            isPreviousTimeFrame: false,
          });
        }
      }
    }

    if (didInitialLoadChanged || didAccountModeChanged || didFiltersChanged || didTimeFrameChanged || didPreDefinedFiltersChanged) {
      if (isFlagActive({ flag: flags.trendAnalysisWidget })) {
        getWidgetsDataV2({
          widget: widgetTypes.trendAnalysis,
          widgetConfig: {
            trendAnalysisParams: {
              metrics: this.state.trendAnalysisMetrics,
              frequency: this.state.trendAnalysisFrequency,
              shouldUseRelativeTimeframe: this.state.trendAnalysisShouldUseRelativeTimeframe,
            },
          },
          configKeyName: this.currentTabName,
          isPreviousTimeFrame: isCompareToPreviousEnabled,
        });
      }
    }

    if (didCompareToPreviousChanged) {
      if (isFlagActive({ flag: flags.historicalPerformanceWidget }) || isFlagActive({ flag: flags.funnelViewWidget }) || isFlagActive({ flag: flags.overviewStatsSquareWidget })) {
        getWidgetsDataV2({
          widget: widgetTypes.historicalPerformance,
          widgetConfig: {
            historicalPerformanceFrequency: this.state.historicalPerformanceFrequency,
          },
          configKeyName: this.currentTabName,
          isPreviousTimeFrame: isCompareToPreviousEnabled,
        });
      }

      if (isCompareToPreviousEnabled) {
        if (isFlagActive({ flag: flags.marketingVsSalesWidget })) {
          getWidgetsDataV2({
            widget: widgetTypes.impactBySegment,
            widgetConfig: {
              impactBySegmentParams: {
                segmentFrequency: this.state.impactByFrequency,
                firstSegment: selectedSegmentImpactBySegment,
                fieldsToEnrich: [],
                shouldUseRelativeTimeframe: this.state.impactByShouldUseRelativeTimeframe,
              },
              conversionIndicator: impactByConversionIndicator.originalFunnel,
              kpiFocus: this.state.drilldownMetric || impactByConversionIndicator.value,
            },
            isPreviousTimeFrame: isCompareToPreviousEnabled,
            configKeyName: this.currentTabName,
          });
        }

        if (isFlagActive({ flag: flags.trendAnalysisWidget })) {
          getWidgetsDataFromStoreV2({
            widget: widgetTypes.trendAnalysis,
            widgetConfig: {
              trendAnalysisParams: {
                metrics: this.state.trendAnalysisMetrics,
                frequency: this.state.trendAnalysisFrequency,
                shouldUseRelativeTimeframe: this.state.trendAnalysisShouldUseRelativeTimeframe,
              },
            },
            configKeyName: this.currentTabName,
            isPreviousTimeFrame: this.props.isCompareToPreviousEnabled,
          });
        }
      }
    }

    if ((didFiltersChanged || didTimeFrameChanged || didAttributionModelChanged || didImpactByConversionIndicatorChanged || didPreDefinedFiltersChanged)) {
      if (isFlagActive({ flag: flags.channelImpactBySegmentWidget })) {
        getWidgetsData({
          widgets: [widgetTypes.accountOptimizationMetrics],
          isPreviousTimeFrame: false,
          resultKeyName: this.accountOptimizationMetricsKey,
          configPerWidget: {
            [widgetTypes.accountOptimizationMetrics]: [{
              conversionIndicator: impactByConversionIndicator.originalFunnel,
            }],
          },
        });
      }
    }
  }

  updateTrendAnalysisFrequency = ({ newTrendAnalysisFrequency }) => {
    this.setState({ trendAnalysisFrequency: newTrendAnalysisFrequency });
    getWidgetsDataV2({
      widget: widgetTypes.trendAnalysis,
      widgetConfig: {
        trendAnalysisParams: {
          metrics: this.state.trendAnalysisMetrics,
          frequency: newTrendAnalysisFrequency,
          shouldUseRelativeTimeframe: this.state.trendAnalysisShouldUseRelativeTimeframe,
        },
      },
      configKeyName: this.currentTabName,
      isPreviousTimeFrame: this.props.isCompareToPreviousEnabled,
    });
  };

  updateTrendAnalysisShouldUseRelativeTimeframe = () => {
    const { trendAnalysisMetrics, trendAnalysisFrequency, trendAnalysisShouldUseRelativeTimeframe } = this.state;
    const newTrendAnalysisShouldUseRelativeTimeframe = !trendAnalysisShouldUseRelativeTimeframe;
    this.setState({ trendAnalysisShouldUseRelativeTimeframe: newTrendAnalysisShouldUseRelativeTimeframe });
    getWidgetsDataV2({
      widget: widgetTypes.trendAnalysis,
      widgetConfig: {
        trendAnalysisParams: {
          metrics: trendAnalysisMetrics,
          frequency: trendAnalysisFrequency,
          shouldUseRelativeTimeframe: newTrendAnalysisShouldUseRelativeTimeframe,
        },
      },
      configKeyName: this.currentTabName,
      isPreviousTimeFrame: this.props.isCompareToPreviousEnabled,
    });
  };

  updateTrendAnalysisMetrics = ({ newTrendAnalysisMetrics }) => {
    this.setState({ trendAnalysisMetrics: newTrendAnalysisMetrics });
    getWidgetsDataV2({
      widget: widgetTypes.trendAnalysis,
      widgetConfig: {
        trendAnalysisParams: {
          metrics: newTrendAnalysisMetrics,
          frequency: this.state.trendAnalysisFrequency,
          shouldUseRelativeTimeframe: this.state.trendAnalysisShouldUseRelativeTimeframe,
        },
      },
      configKeyName: this.currentTabName,
      isPreviousTimeFrame: this.props.isCompareToPreviousEnabled,
    });
  };

  updateHistoricalPerformanceFrequency = ({ newHistoricalPerformanceFrequency }) => {
    this.setState({ historicalPerformanceFrequency: newHistoricalPerformanceFrequency });
    getWidgetsDataV2({
      widget: widgetTypes.historicalPerformance,
      widgetConfig: {
        historicalPerformanceFrequency: newHistoricalPerformanceFrequency,
      },
      configKeyName: this.currentTabName,
      isPreviousTimeFrame: this.props.isCompareToPreviousEnabled,
    });
  };

  onChangeImpactByConversionIndicator({ conversionIndicator }) {
    this.setState({ impactByConversionIndicator: conversionIndicator });
    getWidgetsDataV2({
      widget: widgetTypes.impactBySegment,
      widgetConfig: {
        impactBySegmentParams: {
          segmentFrequency: this.state.impactByFrequency,
          firstSegment: this.state.selectedSegmentImpactBySegment,
          fieldsToEnrich: [],
          shouldUseRelativeTimeframe: this.state.impactByShouldUseRelativeTimeframe,
        },
        kpiFocus: this.state.drilldownMetric || conversionIndicator.value,
        conversionIndicator: conversionIndicator.originalFunnel,
      },
      configKeyName: this.currentTabName,
      isPreviousTimeFrame: this.props.isCompareToPreviousEnabled,
    });

    getWidgetsData({
      widgets: [widgetTypes.accountOptimizationMetrics],
      isPreviousTimeFrame: false,
      resultKeyName: this.accountOptimizationMetricsKey,
      configPerWidget: {
        [widgetTypes.accountOptimizationMetrics]: [{
          conversionIndicator: conversionIndicator.originalFunnel,
        }],
      },
    });

    if (this.props.isCalculateAdvancedMetrics) {
      getWidgetsData({
        widgets: [widgetTypes.trendBySegments],
        configPerWidget: {
          [widgetTypes.trendBySegments]: [{
            trendBySegmentsParams: { firstSegment: this.state.selectedSegmentImpactBySegment, secondSegment: null },
            conversionIndicator: conversionIndicator.originalFunnel,
          }],
        },
        isPreviousTimeFrame: false,
        resultKeyName: this.impactByTrendKey,
      });
    }
  }

  updateShouldUseRelativeTimeframe() {
    this.setState((prevState) => ({ impactByShouldUseRelativeTimeframe: !prevState.impactByShouldUseRelativeTimeframe }), () => {
      getWidgetsDataV2({
        widget: widgetTypes.impactBySegment,
        widgetConfig: {
          impactBySegmentParams: {
            segmentFrequency: this.state.impactByFrequency,
            firstSegment: this.state.selectedSegmentImpactBySegment,
            fieldsToEnrich: [],
            shouldUseRelativeTimeframe: this.state.impactByShouldUseRelativeTimeframe,
          },
          conversionIndicator: this.state.impactByConversionIndicator.originalFunnel,
          kpiFocus: this.state.drilldownMetric || this.state.impactByConversionIndicator.value,
        },
        configKeyName: this.currentTabName,
        isPreviousTimeFrame: this.props.isCompareToPreviousEnabled,
      });
    });
  }

  updateImpactFrequency({ impactFrequency }) {
    this.setState({ impactByFrequency: impactFrequency });
    getWidgetsDataV2({
      widget: widgetTypes.impactBySegment,
      widgetConfig: {
        impactBySegmentParams: {
          segmentFrequency: impactFrequency,
          firstSegment: this.state.selectedSegmentImpactBySegment,
          fieldsToEnrich: [],
          shouldUseRelativeTimeframe: this.state.impactByShouldUseRelativeTimeframe,
        },
        conversionIndicator: this.state.impactByConversionIndicator.originalFunnel,
        kpiFocus: this.state.drilldownMetric || this.state.impactByConversionIndicator.value,
      },
      configKeyName: this.currentTabName,
      isPreviousTimeFrame: this.props.isCompareToPreviousEnabled,
    });
  }

  updateDrilldownMetric({ metric }) {
    this.setState({ drilldownMetric: metric?.value });
    getWidgetsDataV2({
      widget: widgetTypes.impactBySegment,
      widgetConfig: {
        impactBySegmentParams: {
          segmentFrequency: this.state.impactByFrequency,
          firstSegment: this.state.selectedSegmentImpactBySegment,
          fieldsToEnrich: [],
          shouldUseRelativeTimeframe: this.state.impactByShouldUseRelativeTimeframe,
        },
        conversionIndicator: this.state.impactByConversionIndicator.originalFunnel,
        kpiFocus: metric?.value || this.state.impactByConversionIndicator.value,
      },
      configKeyName: this.currentTabName,
      isPreviousTimeFrame: this.props.isCompareToPreviousEnabled,
    });
  }

  render() {
    const {
      historicalPerformanceSelectedMetrics,
      selectedSegmentImpactBySegment,
      impactByShouldUseRelativeTimeframe,
      impactByFrequency,
      impactByConversionIndicator,
    } = this.state;
    const {
      flags,
      metricsOptions,
      historicalPerformance,
      isCompareToPreviousEnabled,
      trendAnalysis,
      goalsAnalysis,
      timeFrame,
      fiscalYearFirstMonth,
      filters,
      getWidgetRequestId,
      funnels,
      impactBySegment,
      isCalculateAdvancedMetrics,
      trendBySegments,
      accountOptimizationMetrics,
    } = this.props;

    const historicalPerformanceFullWidgetConfig = getWidgetFullConfig({
      widgetConfig: {
        historicalPerformanceFrequency: this.state.historicalPerformanceFrequency,
      },
      isPreviousTimeFrame: isCompareToPreviousEnabled,
    });
    const historicalPerformanceRequestId = getWidgetRequestId({ widget: widgetTypes.historicalPerformance, widgetConfig: historicalPerformanceFullWidgetConfig });

    const trendAnalysisFullWidgetConfig = getWidgetFullConfig({
      widgetConfig: {
        trendAnalysisParams: {
          metrics: this.state.trendAnalysisMetrics,
          frequency: this.state.trendAnalysisFrequency,
          shouldUseRelativeTimeframe: this.state.trendAnalysisShouldUseRelativeTimeframe,
        },
      },
      isPreviousTimeFrame: isCompareToPreviousEnabled,
    });
    const trendAnalysisRequestId = getWidgetRequestId({ widget: widgetTypes.trendAnalysis, widgetConfig: trendAnalysisFullWidgetConfig });

    const goalsAnalysisFullConfig = getWidgetFullConfig({
      widgetConfig: {
        goalsAnalysisParams: {
          frequencyType: pickerTimeframeToFrequencyType[timeFrame?.value],
          timeframe: getGoalTimeframe({ timeframe: timeFrame, fiscalYearFirstMonth: this.props.fiscalYearFirstMonth }),
          supportFilters: true,
          status: activityStatusValues.ALL,
        },
      },
    });
    const goalsAnalysisRequestId = getWidgetRequestId({ widget: widgetTypes.goalsAnalysis, widgetConfig: goalsAnalysisFullConfig });

    const impactBySegmentFullWidgetConfig = getWidgetFullConfig({
      widgetConfig: {
        impactBySegmentParams: {
          segmentFrequency: this.state.impactByFrequency,
          firstSegment: selectedSegmentImpactBySegment,
          fieldsToEnrich: [],
          shouldUseRelativeTimeframe: impactByShouldUseRelativeTimeframe,
        },
        kpiFocus: this.state.drilldownMetric || this.state.impactByConversionIndicator.value,
        conversionIndicator: this.state.impactByConversionIndicator.originalFunnel,
      },
      isPreviousTimeFrame: isCompareToPreviousEnabled,
    });
    const impactBySegmentRequestId = getWidgetRequestId({ widget: widgetTypes.impactBySegment, widgetConfig: impactBySegmentFullWidgetConfig });

    const impactBySegmentData = impactBySegment?.[impactBySegmentRequestId]?.result?.impactData;
    const metadataBySegment = impactBySegment?.[impactBySegmentRequestId]?.result?.metadataBySegment;
    const filteredImpactBySegment = getFilteredImpactBySegmentRecords({
      data: impactBySegmentData, filters, segment: selectedSegmentImpactBySegment, metadataBySegment,
    });

    const historicalPerformanceData = historicalPerformance?.[historicalPerformanceRequestId]?.result;
    const goalsAnalysisData = goalsAnalysis?.[goalsAnalysisRequestId]?.result?.goals ?? [];
    const goalsSupportedTimeframe = getTimeframeOptionsByActivityStatus({ status: activityStatusValues.ALL }).map((option) => option.value);
    const isGoalsSupportSelectedTimeframe = goalsSupportedTimeframe.includes(timeFrame?.value);

    const isHistoricalPerformanceLoaded = historicalPerformance?.[historicalPerformanceRequestId]?.status === 'finished';
    const isHistoricalPerformanceFailedToLoad = historicalPerformance?.[historicalPerformanceRequestId]?.status === 'failed';
    const isTrendAnalysisLoaded = trendAnalysis?.[trendAnalysisRequestId]?.status === 'finished';
    const isTrendAnalysisFailedToLoad = trendAnalysis?.[trendAnalysisRequestId]?.status === 'failed';
    const isGoalsAnalysisFailedToLoad = goalsAnalysis?.[goalsAnalysisRequestId]?.status === 'failed';
    const isGoalAnalysisLoaded = goalsAnalysis?.[goalsAnalysisRequestId]?.status === 'finished' || !isGoalsSupportSelectedTimeframe || isGoalsAnalysisFailedToLoad;
    const isImpactBySegmentLoaded = impactBySegment?.[impactBySegmentRequestId]?.status === 'finished';
    const isImpactBySegmentFailedToLoad = impactBySegment?.[impactBySegmentRequestId]?.status === 'failed';

    const requireKeysForStatsSquare = getSelectedMetricsForStatsSquare({ userFunnels: funnels });

    const isHistoricalPerformanceForStatsSquareLoaded = requireKeysForStatsSquare.every((key) => Object.keys(historicalPerformance?.[historicalPerformanceRequestId]?.result || {}).includes(key)) || isHistoricalPerformanceLoaded;
    let statsSquaresData = {};
    if (isHistoricalPerformanceForStatsSquareLoaded) {
      statsSquaresData = parseHistoricalPerformanceDataToStatsSquares({ historicalPerformance: historicalPerformance?.[historicalPerformanceRequestId]?.result, isCompareToPreviousEnabled });
    }

    const impactByTrendData = trendBySegments?.[this.impactByTrendKey]?.data?.[impactByConversionIndicator.originalFunnel]?.segments;
    const accountOptimizationMetricsData = accountOptimizationMetrics?.[this.accountOptimizationMetricsKey]?.data;

    return (
      <>
        <BreadcrumbsTitle breadcrumbs={[pageLabels.analyze, pageLabels.overview]} />

        <FeatureFlagsWithBlur flag={flags.analyzeOverviewGoalsAnalysis} name={flagsNames.analyzeOverviewGoalsAnalysis}>
          <GoalsAnalysis
            isLoaded={isGoalAnalysisLoaded}
            goals={goalsAnalysisData}
            frequencyType={pickerTimeframeToFrequencyType[timeFrame?.value]}
            timeframe={getGoalTimeframe({ timeframe: timeFrame, fiscalYearFirstMonth })}
            isEnableCreateNewGoal
            isGoalsSupportSelectedTimeframe={isGoalsSupportSelectedTimeframe}
            isHavingApplyingFilters={filters.length > 0}
            isShowAddAlertInPopupNavigationMenu
            isFailedToLoad={isGoalsAnalysisFailedToLoad}
            goalsAnalysisRequestId={goalsAnalysisRequestId}
          />
        </FeatureFlagsWithBlur>
        <FeatureFlagsWithBlur flag={flags.overviewStatsSquareWidget} name={flagsNames.overviewStatsSquareWidget}>
          <StatsSquares
            indicatorsCount={statsSquaresData.indicatorsCount}
            revenueAndPipeline={statsSquaresData.revenueAndPipelineOverview}
            totalCost={statsSquaresData.totalCost}
            growthValues={statsSquaresData.growthValues || {}}
            isCompareToPreviousEnabled={isCompareToPreviousEnabled}
            isLoaded={isHistoricalPerformanceForStatsSquareLoaded}
            isFailedToLoad={isHistoricalPerformanceFailedToLoad}
          />
        </FeatureFlagsWithBlur>

        <div className={classNames(this.classes.splitWidget, this.classes.splitWidgetNoPadding)}>
          <FeatureFlagsWithBlur flag={flags.funnelViewWidget} name={flagsNames.funnelViewWidget}>
            <FunnelView
              navigateToJourneys={() => navigateToJourneys({ indicator: null }, attributionStore.rawFilters)}
              isLoaded={isHistoricalPerformanceLoaded}
              isFailedToLoad={isHistoricalPerformanceFailedToLoad}
              funnels={this.props.funnels}
              data={historicalPerformance?.[historicalPerformanceRequestId]?.result}
              isCompareToPreviousEnabled={isCompareToPreviousEnabled}
            />
          </FeatureFlagsWithBlur>
          <FeatureFlagsWithBlur flag={flags.historicalPerformanceWidget} name={flagsNames.historicalPerformanceWidget}>
            <HistoricalPerformance
              isLoaded={isHistoricalPerformanceLoaded}
              isFailedToLoad={isHistoricalPerformanceFailedToLoad}
              navigateToJourneys={(funnelStage) => navigateToJourneys({ indicator: funnelStage }, attributionStore.rawFilters)}
              historicalPerformance={historicalPerformanceData}
              historicalPerformanceFrequency={this.state.historicalPerformanceFrequency}
              updateHistoricalPerformanceFrequency={(frequencyValue) => this.updateHistoricalPerformanceFrequency({ newHistoricalPerformanceFrequency: frequencyValue })}
              isCompareToPreviousEnabled={isCompareToPreviousEnabled}
              selectedMetrics={historicalPerformanceSelectedMetrics}
              setSelectedMetrics={({ selectedMetrics }) => this.setState({ historicalPerformanceSelectedMetrics: selectedMetrics })}
              title="Historical Performance"
            />
          </FeatureFlagsWithBlur>
        </div>

        <FeatureFlagsWithBlur flag={flags.marketingVsSalesWidget} name={flagsNames.marketingVsSalesWidget}>
          <ImpactBySegment
            title="Impact by Department"
            isLoaded={isImpactBySegmentLoaded}
            isImpactBySegmentFailedToLoad={isImpactBySegmentFailedToLoad}
            isLoadedTrendData={isCalculateAdvancedMetrics ? trendBySegments?.[this.impactByTrendKey]?.isLoaded : false}
            showSegmentDropdown={false}
            showNoValueCheckbox={false}
            currentSegmentLabel="Department"
            segmentData={filteredImpactBySegment ?? []}
            segmentPrevData={[]}
            totalBySegment={impactBySegment?.[impactBySegmentRequestId]?.result?.totalBySegment}
            totalByFrequency={impactBySegment?.[impactBySegmentRequestId]?.result?.totalByFrequency}
            metricType={impactBySegment?.[impactBySegmentRequestId]?.result?.segmentKey ?? 'attributed'}
            relatedEntitiesIndication={impactBySegment?.[impactBySegmentRequestId]?.result?.shouldUseRelatedEntities}
            filters={filters}
            firstSegment={selectedSegmentImpactBySegment}
            conversionIndicator={this.state.impactByConversionIndicator.value}
            currentFrequency={impactByFrequency}
            onChangeFrequency={(frequency) => this.updateImpactFrequency({ impactFrequency: frequency.value })}
            trendData={impactByTrendData}
            kpiFocus={this.state.drilldownMetric || this.state.impactByConversionIndicator.value}
            updateFilters={() => this.updateFilters()}
            dataTotals={accountOptimizationMetricsData ?? {}}
            innerPageNavigation={({ filters: filtersForNavigate, isNewFiltersUIOnly }) => navigateBetweenAnalyzeTabs({
              filters: filtersForNavigate, tabName: 'overview', withAnimation: false, isNewFiltersUIOnly,
            })}
            timeframe={this.props.timeFrame}
            attributionModel={this.props.attributionModel}
            updateDrilldownMetric={({ metric }) => this.updateDrilldownMetric({ metric })}
            growthByFrequency={impactBySegment?.[impactBySegmentRequestId]?.result?.growthByFrequency}
            growthBySegment={impactBySegment?.[impactBySegmentRequestId]?.result?.growthBySegment}
            growthImpactData={impactBySegment?.[impactBySegmentRequestId]?.result?.growthImpactData}
            isCompareToPreviousEnabled={isCompareToPreviousEnabled}
            isCalculateAdvancedMetrics={isCalculateAdvancedMetrics}
            shouldUseRelativeTimeframe={impactByShouldUseRelativeTimeframe}
            updateShouldUseRelativeTimeframe={() => this.updateShouldUseRelativeTimeframe()}
            onChangeCheckedSegments={({ segments }) => this.setState({ checkedSegments: segments })}
            onChangeUncheckedSegments={({ segments }) => this.setState({ uncheckedSegments: segments })}
            checkedSegments={this.state.checkedSegments || []}
            uncheckedSegments={this.state.uncheckedSegments || []}
            isShowKPIFocusDropdown
            metricsOptions={metricsOptions}
            updateCrmParam={(item) => this.onChangeImpactByConversionIndicator({ conversionIndicator: item })}
          />
        </FeatureFlagsWithBlur>

        <FeatureFlagsWithBlur flag={flags.trendAnalysisWidget} name={flagsNames.trendAnalysisWidget}>
          <TrendAnalysis
            isLoaded={isTrendAnalysisLoaded}
            isFailedToLoad={isTrendAnalysisFailedToLoad}
            trendAnalysis={trendAnalysis?.[trendAnalysisRequestId]?.result}
            trendAnalysisFrequency={this.state.trendAnalysisFrequency}
            trendAnalysisMetrics={this.state.trendAnalysisMetrics}
            trendAnalysisShouldUseRelativeTimeframe={this.state.trendAnalysisShouldUseRelativeTimeframe}
            updateTrendAnalysisFrequency={(frequencyValue) => this.updateTrendAnalysisFrequency({ newTrendAnalysisFrequency: frequencyValue })}
            updateTrendAnalysisMetrics={(metrics) => this.updateTrendAnalysisMetrics({ newTrendAnalysisMetrics: metrics })}
            updateTrendAnalysisShouldUseRelativeTimeframe={() => this.updateTrendAnalysisShouldUseRelativeTimeframe()}
            metricsOptions={metricsOptions}
            isCompareToPreviousEnabled={isCompareToPreviousEnabled}
            timeFrame={timeFrame}
          />
        </FeatureFlagsWithBlur>
      </>
    );
  }
}

export default withLDConsumer()(enhance(Overview));
