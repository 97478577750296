import { get } from 'lodash';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import { DEFAULT_TIME_ZONE } from 'components/utils/timeframe';
import { stringifyDateWithTimeZone } from 'components/utils/date';
import userStore from 'stores/userStore';

const offsetToMS = (offset) => offset * 60 * 1000;

export function getTSForTimezone(utcTS = Date.now()) {
  const timezone = get(userStore, 'userMonthPlan.CRMConfig.timezone', DEFAULT_TIME_ZONE);
  const datetz = momentTZ(utcTS).tz(timezone);
  const thistz = momentTZ(utcTS).tz(Intl.DateTimeFormat().resolvedOptions().timeZone);
  //  utc offset is in minutes, so I'm converting it to ms
  return utcTS - offsetToMS(datetz.utcOffset()) + offsetToMS(thistz.utcOffset());
}

export const transformToGivenTZ = (dateStr, givenTZ) => {
  const d = new Date(dateStr).getTime();
  return momentTZ(d).tz(givenTZ).format('YYYY-MM-DD HH:mm:ss');
};

export function disabledDate(current) {
  if (!current) {
    // allow empty select
    return false;
  }

  const tomorrow = moment({
    hour: 0,
    minute: 0,
    seconds: 0,
    milliseconds: 0,
  }).add(1, 'days');

  return current > tomorrow;
}

export const getDateFromUserTZ = (date) => {
  const accountTZ = get(userStore, 'userMonthPlan.CRMConfig.timezone', DEFAULT_TIME_ZONE);
  return transformToGivenTZ(date, accountTZ);
};

export function getStringifyDateWithUserTimeZone(date) {
  const timezone = get(userStore, 'userMonthPlan.CRMConfig.timezone', DEFAULT_TIME_ZONE);
  const stringifyDate = stringifyDateWithTimeZone(timezone);
  const updatedDate = stringifyDate(date, 'YYYY-MM-DD HH:mm:ss');
  return updatedDate;
}

export function convertDays({
  days, appendLabel = true, isShortLabel = false, shouldRound = true,
}) {
  const duration = moment.duration(days, 'days');

  let parsedUnits = duration.asDays();
  let timeUnit = 'Day';
  let pluralTimeUnits = 'Days';
  let shortTimeUnit = 'D';

  if (days >= 30) {
    parsedUnits = duration.asMonths();
    timeUnit = 'Month';
    pluralTimeUnits = 'Months';
    shortTimeUnit = 'M';
  } else if (days >= 7) {
    parsedUnits = duration.asWeeks();
    timeUnit = 'Week';
    pluralTimeUnits = 'Weeks';
    shortTimeUnit = 'W';
  }

  if (shouldRound) {
    parsedUnits = Math.round(parsedUnits);
  }

  if (appendLabel) {
    const fullTimeLabel = parsedUnits === 1 ? timeUnit : pluralTimeUnits;
    const label = isShortLabel ? shortTimeUnit : fullTimeLabel;

    return `${parsedUnits} ${label}`;
  }

  return `${parsedUnits}`;
}
