import { isEmpty } from 'lodash';

import userStore from 'stores/userStore';

import { convertDays } from 'stores/analyze/timeUtils';
import { getSegmentLabel } from 'components/pages/analyze/SegmentsTab/logic/segments';
import { formatToFloatWithoutZeros } from 'components/utils/logic/budget';
import { getNickname } from 'components/utils/channels';
import {
  avgDealSize,
  currentFunnelStage,
  generalBlocks,
  numberOfContacts,
  numberOfTouchpoints,
  metricKeysToLabelMapping,
  salesCycle,
  pipelineAvgDealSize,
  stageVelocity,
} from 'components/widgets/optimalJourney/enums';
import { formatBudget } from 'components/utils/budget';

export function getDefaultAndSelectedBlocksItems({ commonSegments = [], selectedBlocks = [] }) {
  const staticBlocks = new Set();
  staticBlocks.add('channel');

  if (commonSegments.length > 0) {
    let commonSegmentToAdd = commonSegments[0];
    if (commonSegmentToAdd === 'channel' && commonSegments.length > 1) {
      commonSegmentToAdd = commonSegments[1];
    }
    staticBlocks.add(commonSegmentToAdd);
  }

  const staticBlocksArray = Array.from(staticBlocks);
  const updatedSelectedBlocks = [...selectedBlocks];

  const blockItems = [];
  for (const staticBlock of staticBlocksArray) {
    const selectedBlockIndex = updatedSelectedBlocks.findIndex((blockItem) => blockItem === staticBlock);
    const isSelected = selectedBlockIndex !== -1;
    if (isSelected) {
      updatedSelectedBlocks.splice(selectedBlockIndex, 1);
    }

    blockItems.push({
      value: staticBlock,
      label: getSegmentLabel({
        segment: staticBlock,
        customFieldsIdToLabelMap: userStore.userAccount.customFieldsIdToLabelMap,
        customUtmsWhitelist: userStore.userAccount.customUtmsWhitelist,
      }),
      isStatic: true,
      isSelected,
    });
  }

  for (const generalBlock of generalBlocks) {
    const selectedBlockIndex = updatedSelectedBlocks.findIndex((blockItem) => blockItem === generalBlock.value);
    const isSelected = selectedBlockIndex !== -1;
    if (isSelected) {
      updatedSelectedBlocks.splice(selectedBlockIndex, 1);
    }

    blockItems.push({
      ...generalBlock,
      isStatic: true,
      isSelected,
    });
  }

  blockItems.push(...updatedSelectedBlocks.map((blockItem) => ({
    ...blockItem,
    isSelected: true,
  })));

  return blockItems;
}

export function isSegmentBlock({ blockItem }) {
  return ![stageVelocity, numberOfTouchpoints, numberOfContacts].includes(blockItem);
}

export function metricsFormatter({ metricKey, value, growth }) {
  if (value !== undefined) {
    switch (metricKey) {
      case salesCycle:
      case stageVelocity:
        return convertDays({ days: value });
      case avgDealSize:
      case pipelineAvgDealSize:
        return formatBudget(value, true, true, false, false, true);
      case numberOfTouchpoints:
      case numberOfContacts:
        return formatToFloatWithoutZeros({ number: value, numberOfDigitsAfterPoint: 2 });
      default:
        return formatBudget(value, false, true, false, false, true);
    }
  }

  if (growth !== undefined) {
    return parseInt(formatToFloatWithoutZeros({ number: growth, numberOfDigitsAfterPoint: 2 }), 10);
  }

  return '';
}

export const segmentsFormatter = {
  label: ({ segmentValue }) => getNickname(segmentValue),
  value: ({ value }) => formatToFloatWithoutZeros({ number: value, numberOfDigitsAfterPoint: 2 }),
};

export function getOverviewMetricLabel({ metricKey, lastSelectedStage }) {
  if (isEmpty(lastSelectedStage) || !metricKey) {
    return '';
  }

  switch (metricKey) {
    case currentFunnelStage:
      return lastSelectedStage.label;
    case numberOfTouchpoints:
      return `${metricKeysToLabelMapping[numberOfTouchpoints]} before ${lastSelectedStage.label}`;
    case numberOfContacts:
      return `${metricKeysToLabelMapping[numberOfContacts]} per ${lastSelectedStage.label}`;
    case salesCycle:
      return metricKeysToLabelMapping[salesCycle];
    case avgDealSize:
    case pipelineAvgDealSize:
      return metricKeysToLabelMapping[avgDealSize];
    default:
      return '';
  }
}
