import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import { Checkbox } from '@infinigrow/libs';

import userStore from 'stores/userStore';
import attributionStore from 'stores/attributionStore';

import useStyles from 'hooks/useStyles';
import Dropdown from 'components/controls/Dropdown';
import FeatureFlags from 'components/common/FeatureFlags';
import InfoMarker from 'components/pages/InfoMarker';
import Popup from 'components/Popup';
import RollingTimeframeSelect from 'components/controls/RollingTimeframeSelect';
import Tooltip from 'components/controls/Tooltip';
import timeFrameModule from 'modules/timeframe';
import MenuButton from 'components/common/MenuButton';
import useOnClickOutside from 'components/pages/users/useOnClickOutside';

import { getTimeframeOptions, TIMEFRAME_VALUES, rollingTimeframeTypesOptions } from 'components/utils/timeframe';
import { RangeCalendar } from 'components/controls/Calendar';
import { disabledDate } from 'stores/analyze/timeUtils';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import style from 'styles/common/timeframeDropdown.css';

const styles = style.locals || {};

function TimeframeDropdown({
  disabled,
  onChange,
  timeframe = {},
  isShowCompareToPrev,
  flags,
  setPreviousTimeframe,
  isPreviousTimeframeEnable,
  titlePrefix = '',
  customMenuButton: CustomMenuButton,
  customButtonLabel,
  classNamePopup,
  classNameMenuButton,
  isShowPopupTitle = true,
  isOpenDatePickerOnMount = false,
  tagId,
  rightIconRerender,
  classNameMenuTooltip,
}) {
  const [chooseCustomDate, setChooseCustomDate] = useState(false);
  const [chooseRollingDate, setChooseRollingDate] = useState(false);
  const [isOpenFilterDatePicker, setIsOpenFilterDatePicker] = useState(false);
  const [timeframeValue, setTimeframeValue] = useState(timeframe.value);
  const popupRef = useRef();

  useStyles([style]);

  useEffect(() => {
    if (isOpenDatePickerOnMount) {
      setIsOpenFilterDatePicker(isOpenDatePickerOnMount);
    }
  }, [isOpenDatePickerOnMount]);

  useEffect(() => {
    if (timeframe.value !== timeframeValue) {
      setTimeframeValue(timeframe.value);
    }
  }, [timeframe]);

  useOnClickOutside(popupRef, () => {
    setIsOpenFilterDatePicker(false);
  });

  function onClosePopup() {
    setIsOpenFilterDatePicker(false);
    setChooseCustomDate(false);
    setChooseRollingDate(false);
  }

  function handleChangeTimeFrame({ newTimeFrame }) {
    setTimeframeValue(newTimeFrame.value);
    if (newTimeFrame.value === TIMEFRAME_VALUES.CUSTOM) {
      setChooseCustomDate(true);
      setChooseRollingDate(false);
      return;
    }
    if (newTimeFrame.value === TIMEFRAME_VALUES.ROLLING) {
      setChooseCustomDate(false);
      setChooseRollingDate(true);
      return;
    }
    onClosePopup();
    onChange(newTimeFrame);
  }

  function getSelectedTimeFrameTooltip() {
    if (isEmpty(timeframe)) {
      return '';
    }

    const { startDate, endDate } = timeFrameModule.getTimeframeParams({ ...timeframe, fiscalYearFirstMonth: userStore.userMonthPlan.fiscalYearFirstMonth });

    return `${moment(startDate).format('MMM D YYYY')} - ${moment(endDate).format('MMM D YYYY')}`;
  }

  function getOptionsCurrentLabel({ options, current }) {
    if (!current) {
      return 'Select';
    }
    const selectOptionsLabels = [];
    for (const item of options) {
      if (item.options) {
        item.options.map((child) => selectOptionsLabels.push([child.label, child.value]));
      }
      selectOptionsLabels.push([item.label, item.value]);
    }

    const optionDateLabel = selectOptionsLabels.find((item) => item[1] === current) || selectOptionsLabels[0];
    return optionDateLabel[0];
  }

  const currentSelectDateOptions = getTimeframeOptions();

  return (
    <div className={styles.filterDatePickerContainer} ref={popupRef}>
      {CustomMenuButton ? (
        <CustomMenuButton
          onClick={() => !disabled && setIsOpenFilterDatePicker((prev) => !prev)}
          isOpen={isOpenFilterDatePicker}
          tagId={tagId}
        />
      ) : (
        <Tooltip
          className={classNameMenuTooltip}
          id="filterTolltipDate"
          tip={getSelectedTimeFrameTooltip()}
          TooltipProps={{ multiline: true }}
          dataTestId="timeframeDropdownTooltip"
        >
          <MenuButton
            disabled={disabled}
            title={customButtonLabel || `${titlePrefix}${getOptionsCurrentLabel({ options: currentSelectDateOptions, current: timeframeValue })}`}
            onClick={() => !disabled && setIsOpenFilterDatePicker((prev) => !prev)}
            isOpen={isOpenFilterDatePicker}
            className={classNameMenuButton}
            tagId={tagId}
            rightIconRerender={rightIconRerender}
            dataTestId="timeframeDropdown"
          />
        </Tooltip>
      )}

      {isOpenFilterDatePicker ? (
        <div>
          <Popup className={classNames(
            styles.wrapper,
            classNamePopup
          )}
          >
            <div className={styles.rangeCalendarContainter}>
              {isShowPopupTitle ? 'Date range' : null}

              <Dropdown
                selectedKey={timeframeValue}
                options={currentSelectDateOptions}
                onChange={(newTimeFrame) => handleChangeTimeFrame({ newTimeFrame })}
                style={{ width: '100%' }}
              />

              {chooseCustomDate ? (
                <div>
                  <RangeCalendar
                    className={styles.rangeCalendar}
                    onSelect={(newTimeframe) => {
                      newTimeframe.value = TIMEFRAME_VALUES.CUSTOM;
                      onClosePopup();
                      onChange(newTimeframe);
                    }}
                    onClose={() => {
                      onClosePopup();
                      setTimeframeValue(timeframe.value);
                    }}
                    defaultSelectedValue={
                      attributionStore.customDateMode && timeframe.startDate && timeframe.endDate && [
                        moment(new Date(timeframe.startDate)),
                        moment(new Date(timeframe.endDate)),
                      ]
                    }
                    disabledDate={disabledDate}
                  />
                </div>
              ) : null}

              {chooseRollingDate ? (
                <RollingTimeframeSelect
                  timeframeTypeOptions={rollingTimeframeTypesOptions}
                  timeFrame={timeframe}
                  onApply={(newRollingTimeframe) => {
                    onClosePopup();
                    onChange(newRollingTimeframe);
                  }}
                  onCancel={() => {
                    onClosePopup();
                    setTimeframeValue(timeframe.value);
                  }}
                />
              ) : null}

              {isShowCompareToPrev ? (
                <FeatureFlags flag={flags.headerFiltersTimeFrameCompareToPreviousPeriod}>
                  <div className={styles.compareToPrevWrapper}>
                    <Checkbox
                      onChange={() => setPreviousTimeframe(!isPreviousTimeframeEnable)}
                      checked={isPreviousTimeframeEnable}
                    >
                      <div>Compare to previous period</div>
                    </Checkbox>
                    <InfoMarker
                      containerClass={styles.tooltipIcon}
                      tooltipText="The percentage change (per metric) between the selected timeframe and the previous one."
                    />
                  </div>
                </FeatureFlags>
              ) : null}
            </div>
          </Popup>
        </div>
      ) : null}
    </div>
  );
}

export default withLDConsumer()(TimeframeDropdown);
