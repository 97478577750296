import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { ToastContainer, Slide, toast } from 'react-toastify';
import { injectStyle } from 'react-toastify/dist/inject-style';
import history from 'history';
import { Button } from '@infinigrow/libs';

import useStyles from 'hooks/useStyles';

import Dropdown from 'components/controls/Dropdown';
import Spinner from 'components/pages/journeys/Spinner';
import SearchInput from 'components/controls/SearchInput';
import FolderActionsPopups from 'components/pages/reports/FolderActionsPopups';
import TimeframeDropdown from 'components/common/TimeframeDropdown';
import MenuButton from 'components/common/MenuButton';
import Popup from 'components/Popup';
import FiltersIconWithIndication from 'components/pages/users/Filters/FiltersIconWithIndication';
import CustomFiltersPanel from 'components/pages/users/Filters/CustomFiltersPanel';

import { makeFilters } from 'components/utils/filters';
import { folderActionsKeys, folderOfUnassignedReports } from 'components/pages/reports/enums';
import { filterTagsColorThemeOptions } from 'components/pages/users/Filters/FilterPanel/enums';

import reportsStyle from 'styles/reports/reports.css';
import filtersStyle from 'styles/users/filters.css';
import { getParseDateRangeLabel } from 'stores/analyze/logic/timeUtils';
import interactionsStore from 'stores/interactionsStore';
import { Interactions } from 'trackers/interactions/enums';
import { applyFiltersInteraction } from 'stores/logic/attributionStore';

const styles = reportsStyle.locals;
const filtersStyles = filtersStyle.locals;

function ReportsHeaderMenu({
  reportsList,
  selectedReport,
  setSelectedReport,
  isReportHasNewChanges,
  discardWidgetsConfigurationChanges,
  saveWidgetsConfigurationChanges,
  isSaveWidgetsReportsLoaded,
  isUpdatingWidgetsReportsLoading,
  hasReportId,
  foldersList,
  updateSearchQuery,
  filtersData,
  updateReportConfiguration,
}) {
  useStyles([reportsStyle, filtersStyle], [injectStyle]);

  const [folderActionsPopupType, setFolderActionsPopupType] = useState(null);
  const [showGlobalConfigurationSection, setShowGlobalConfigurationSection] = useState(false);
  const reportId = selectedReport.value;

  const onChangeReport = (report) => {
    setSelectedReport(report);
    history.push(`/reports?reportId=${report.value}`);
  };

  const toastSuccessMessage = (message = 'Changes saved successfully!') => {
    toast.success(message, {
      position: 'bottom-center',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: 'light',
      toastId: message,
      style: { width: '320px', fontSize: '14px' },
    });
  };

  const onSaveWidgetsConfigurationChanges = async () => {
    await saveWidgetsConfigurationChanges({ reportId });
    toastSuccessMessage();
  };

  function updateReportTimeframe({ newTimeframe }) {
    const updatedTimeframeObject = { ...newTimeframe };
    delete updatedTimeframeObject.label;
    updateReportConfiguration({ configuration: { timeframe: updatedTimeframeObject }, reportId });

    const timeFrameLabel = getParseDateRangeLabel({ timeFrame: newTimeframe });
    interactionsStore.interactionTracker.trackConfig({
      type: Interactions.global.timeframeChanged.type,
      name: Interactions.global.timeframeChanged.name,
      description: timeFrameLabel,
    });
  }

  function updateReportFilters({ newFilters }) {
    const filtersForRequest = newFilters.map((filter) => ({ data: filter.data, kind: filter.config.kind, isUiOnly: filter.isUiOnly }));
    updateReportConfiguration({ configuration: { filters: filtersForRequest }, reportId });

    applyFiltersInteraction({ filters: newFilters });
  }

  const selectedFolder = foldersList.find((folder) => folder.value === selectedReport.folderId);
  let reportsByFolderOptions = [];
  if (selectedFolder?.value === folderOfUnassignedReports.value) {
    reportsByFolderOptions = reportsList.filter((report) => report.folderId == null);
  } else {
    reportsByFolderOptions = reportsList.filter((report) => report.folderId === selectedFolder?.value);
  }
  const reportsListOptions = [{
    label: selectedFolder?.label || folderOfUnassignedReports.label,
    options: reportsByFolderOptions,
  }];

  return (
    <>
      <div className={styles.headerMenuWrapper}>
        {hasReportId ? (
          <>

            <MenuButton
              onClick={() => setShowGlobalConfigurationSection((prev) => !prev)}
              rightIconRerender={(
                <FiltersIconWithIndication
                  activeNumber={selectedReport.filters?.length || 0}
                  numberClassname={filtersStyles.filtersActiveNumberOrange}
                />
              )}
              className={filtersStyles.filtersMenuButton}
              disabledClassName={filtersStyles.disabledFiltersMenuButton}
              withArrowIndication={false}
            />
            <Popup
              className={styles.filterSubMenu}
              hidden={!showGlobalConfigurationSection}
              onClose={() => setShowGlobalConfigurationSection(false)}
            >
              {showGlobalConfigurationSection ? (
                <CustomFiltersPanel
                  filters={makeFilters(selectedReport.filters || [], filtersData).map((filter) => ({ ...filter, filterTagColorTheme: filterTagsColorThemeOptions.orange }))}
                  setFilters={(newFilters) => updateReportFilters({ newFilters })}
                  buttonText="Add report filters"
                  addFilterButtonClassName={styles.addFilterButton}
                />
              ) : null}
            </Popup>

            <Dropdown
              options={reportsListOptions}
              selectedKey={selectedReport?.value}
              noOptionsMessage={() => 'No Reports Yet'}
              controlWidth={320}
              onChange={onChangeReport}
              bottomButton={{
                label: 'View all Reports',
                action: () => history.push('/reports'),
                className: styles.viewAllReportsButton,
              }}
              menuListMaxHeight="308px"
              isSearchable
            />

            <TimeframeDropdown
              onChange={(newTimeframe) => updateReportTimeframe({ newTimeframe })}
              timeframe={selectedReport.timeframe}
              titlePrefix="Timeframe: "
              setPreviousTimeframe={(newPreviousTimeframe) => {
                updateReportConfiguration({ configuration: { isCompareToPreviousEnabled: newPreviousTimeframe }, reportId });
              }}
              isPreviousTimeframeEnable={selectedReport.isCompareToPreviousEnabled}
              isShowCompareToPrev
              classNamePopup={styles.timeframeDropdownPopup}
            />

            <div className={styles.headerButtons}>
              <Button
                className={styles.headerButtonSave}
                type="primaryBlue"
                onClick={() => onSaveWidgetsConfigurationChanges()}
                disabled={isUpdatingWidgetsReportsLoading || !isReportHasNewChanges[reportId]}
              >
                Save changes
              </Button>

              {isReportHasNewChanges[reportId] && (
              <div
                onClick={() => discardWidgetsConfigurationChanges({ reportId })}
                className={`${styles.headerButtonDiscard} ${isUpdatingWidgetsReportsLoading ? styles.disabledDiscard : null}`}
                hidden={isUpdatingWidgetsReportsLoading}
              >
                Discard
              </div>
              )}
              {isSaveWidgetsReportsLoaded && <Spinner />}
            </div>
          </>
        ) : (
          <>
            <SearchInput
              debounce={0}
              onSearch={(search) => updateSearchQuery({ searchQuery: search })}
              placeholder="Search..."
              classes={{ input: styles.searchInput }}
            />
            <div className={styles.headerButtons}>
              <Button
                className={styles.headerButtonSave}
                type="secondaryBlue"
                onClick={() => setFolderActionsPopupType(folderActionsKeys.createNewFolder)}
              >
                New Folder
              </Button>
              <Button
                className={styles.headerButtonSave}
                type="primaryBlue"
                onClick={() => history.push('/reports/new')}
              >
                New Report
              </Button>
            </div>
          </>
        )}
      </div>

      {folderActionsPopupType && (
        <FolderActionsPopups
          updateActionType={(action) => setFolderActionsPopupType(action)}
          type={folderActionsPopupType}
          toastSuccessMessage={toastSuccessMessage}
          selectedFolder={selectedFolder}
          selectedReport={selectedReport}
          isAttachCurrentReportToFolder={false}
        />
      )}

      <ToastContainer
        style={{ width: '400px', fontSize: '12px' }}
        position="bottom-center"
        autoClose={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        transition={Slide}
      />
    </>
  );
}

export default inject(
  ({
    reportsStore: {
      reportsList,
      setSelectedReport,
      selectedReport,
      deleteReportRequest,
      isReportHasNewChanges,
      discardWidgetsConfigurationChanges,
      saveWidgetsConfigurationChanges,
      isSaveWidgetsReportsLoaded,
      isUpdatingWidgetsReportsLoading,
      foldersList,
      updateSearchQuery,
      updateReportConfiguration,
    },
    filterStore: {
      filtersData,
    },
  }) => ({
    reportsList,
    setSelectedReport,
    selectedReport,
    deleteReportRequest,
    isReportHasNewChanges,
    discardWidgetsConfigurationChanges,
    saveWidgetsConfigurationChanges,
    isSaveWidgetsReportsLoaded,
    isUpdatingWidgetsReportsLoading,
    foldersList,
    updateSearchQuery,
    filtersData,
    updateReportConfiguration,
  }),
  observer
)(ReportsHeaderMenu);
