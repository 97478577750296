import { camelCase } from 'lodash';

import StageBar from 'components/widgets/funnelAnalysis/StageBar';
import { mapChartIndicator } from 'components/utils/indicators';
import { revenueViewMetricsOptions } from 'components/widgets/funnelAnalysis/enums';

export default function RevenueView({
  data,
  isLoaded,
  fullTimeFrame,
  selectedStages,
  maxStageValue,
  supportedMetrics,
  openCurtain,
  getStageMenuParams,
  getNavigationMenuParams,
}) {
  return (
    <div data-testid="revenue-view">
      {selectedStages.map((stage, index) => {
        const currentStage = stage.value;
        const skeletonWidth = 100 - (index / (selectedStages.length - 1)) * 80;

        const volumeStage = data[currentStage];
        const costPerKey = camelCase(`cost per ${currentStage}`);
        const costPerValue = data[costPerKey]?.value;
        const averageDealSize = `${currentStage}AvgDealSize`;
        const averageDealSizeValue = data[averageDealSize]?.value;
        const ROI = `${currentStage}ROI`;
        const ROIValue = data[ROI]?.value;

        const originalStage = mapChartIndicator(currentStage);

        return (
          <StageBar
            key={currentStage}
            currentStage={currentStage}
            volume={volumeStage}
            costPer={costPerValue}
            fullTimeFrame={fullTimeFrame}
            stagesConfigs={data.stagesConfigs}
            isLoaded={isLoaded}
            maxStageValue={maxStageValue}
            skeletonWidth={skeletonWidth}
            supportedMetrics={supportedMetrics}
            averageDealSize={averageDealSizeValue}
            ROI={ROIValue}
            getStageMenuParams={() => getStageMenuParams({
              onClickOption: ({ selectedSegment, isSpotlightBreakdown }) => openCurtain({
                selectedStage: currentStage,
                selectedSegment,
                supportedMetrics: revenueViewMetricsOptions.map((metric) => metric.value),
                originalStage,
                isSpotlightBreakdown,
              }),
              currentStage: originalStage,
            })}
            getNavigationMenuParams={getNavigationMenuParams}
            isLogarithmicCalculate={false}
          />
        );
      })}
    </div>
  );
}
