import { useEffect, useRef, useState } from 'react';

import useStyles from 'hooks/useStyles';
import useWindowDimensions from 'components/utils/getWindowDimensions';

import userStore from 'stores/userStore';
import CurtainOverlay from 'components/common/CurtainOverlay';
import BreadcrumbsBudges from 'components/common/BreadcrumbsBudges';
import SegmentsDrilldown from 'components/widgets/segmentsDrilldown';
import StageOverview from 'components/widgets/segmentsDrilldown/StageOverview';

import { getBreadcrumbs, getDrilldownMetricsData } from 'components/widgets/segmentsDrilldown/logic/drilldownCurtain';
import { mergeDataWithUpliftData } from 'components/pages/analyze/AttribuitonTable/logic/AttributionSegmentsTableParseData';
import { customFieldKey } from 'components/common/logic/enums';
import { getSegmentLabel } from 'components/pages/analyze/SegmentsTab/logic/segments';

import style from 'styles/segmentsDrilldown/segmentsDrilldown.css';

const styles = style.locals || {};

export default function DrilldownCurtain({
  isLoaded,
  isLoadedUplift,
  isLoadedFunnelAnalysis,
  data,
  onClose,
  timeframe,
  fullTimeFrame,
  selectedStage,
  originalStage,
  breakdownSegment,
  updateBreakdownSegment,
  supportedMetrics,
  upliftColumnsKeys,
  updateUpliftColumnsKeys,
  drilldownMetricsOptions,
  defaultDrilldownSelectedMetric,
  isCompareToPrevious,
  onChangeCompareToPrevious,
  isSpotlightTable,
  onChangeSpotlightMetric,
  shouldUseFullScreenCurtain,
}) {
  useStyles([style]);

  const [breadcrumbs, setBreadcrumbs] = useState(getBreadcrumbs({
    selectedStage, breakdownSegment, onClickBreadcrumb, isSpotlightBreakdown: isSpotlightTable,
  }));
  const [tableTotals, setTableTotals] = useState({ [breakdownSegment.value]: data.totals || {} });

  const breadcrumbsBudgesContainerRef = useRef(null);

  useEffect(() => {
    setTableTotals({ [breakdownSegment.value]: data.totals || {} });
  }, [data.totals]);

  const { height: screenHeight } = useWindowDimensions();
  const breadCrumbsContainerHeight = breadcrumbsBudgesContainerRef?.current?.offsetHeight || 65;
  const segmentsDrilldownTableHeight = `${screenHeight - 163 - breadCrumbsContainerHeight}px`;

  function onClickBreadcrumb({ currentStepIndex, currentBreadcrumbs, isSpotlightBreakdown }) {
    onUpdateBreakdownSegment({ segment: currentBreadcrumbs[currentStepIndex], isSpotlightBreakdown });
  }

  function onUpdateBreakdownSegment({
    segment, selectedRow, shouldAddNewBreadcrumb = true, shouldSendRequest = true, isSpotlightBreakdown,
  }) {
    const shouldUpdateSessionFilters = shouldAddNewBreadcrumb;
    let updatedBreadcrumbs = [...breadcrumbs];

    updateBreakdownSegment({
      segment, selectedRow, shouldUpdateSessionFilters, shouldSendRequest, isSpotlightBreakdown,
    });

    if (isSpotlightTable && selectedRow) {
      let firstSegmentValue = selectedRow.segmentType;
      let firstSegmentLabel = selectedRow.segmentType;
      if (selectedRow.segmentType === customFieldKey) {
        firstSegmentValue = selectedRow.segmentId;
        firstSegmentLabel = getSegmentLabel({
          segment: selectedRow.segmentId,
          customFieldsIdToLabelMap: userStore.userAccount?.customFieldsIdToLabelMap,
          customUtmsWhitelist: userStore.userAccount?.customUtmsWhitelist,
        });
      }
      updatedBreadcrumbs = getBreadcrumbs({
        currentBreadcrumbs: breadcrumbs,
        selectedStage,
        breakdownSegment: { value: firstSegmentValue, label: firstSegmentLabel },
        onClickBreadcrumb,
        shouldAddNewBreadcrumb,
        isSpotlightBreakdown,
      });
    }

    const selectedOption = selectedRow ? (selectedRow.firstSegment || selectedRow.segmentValue) : null;
    updatedBreadcrumbs = getBreadcrumbs({
      currentBreadcrumbs: updatedBreadcrumbs,
      selectedStage,
      breakdownSegment: segment,
      selectedOption,
      onClickBreadcrumb,
      shouldAddNewBreadcrumb,
      isSpotlightBreakdown,
    });

    setBreadcrumbs(updatedBreadcrumbs);
    setTableTotals({ ...tableTotals, [segment.value]: selectedRow });
  }

  const tableDataWithUplift = mergeDataWithUpliftData({ tableData: data.tableData, upliftData: data.upliftData });
  const previousBreakdownSegments = breadcrumbs.map((breadcrumb) => breadcrumb.value);

  return (
    <CurtainOverlay onClose={onClose} shouldUseFullScreenCurtain={shouldUseFullScreenCurtain}>
      <div className={styles.headerContainer} ref={breadcrumbsBudgesContainerRef}>
        <BreadcrumbsBudges breadcrumbs={breadcrumbs} />
      </div>
      <div className={styles.curtainContent}>
        <div className={styles.curtainLeftSideContainer}>
          <StageOverview
            data={data.funnelAnalysisData}
            supportedMetrics={supportedMetrics}
            selectedStage={selectedStage}
            fullTimeFrame={fullTimeFrame}
            originalStage={originalStage}
            drilldownMetricsOptions={drilldownMetricsOptions}
            drilldownMetricsData={getDrilldownMetricsData({ drilldownMetricsOptions, data: data.funnelAnalysisData, isLoaded: isLoadedFunnelAnalysis })}
            isLoaded={isLoadedFunnelAnalysis}
          />
        </div>
        <div className={styles.curtainRightSideContainer}>
          <SegmentsDrilldown
            timeframe={timeframe}
            tableHeight={segmentsDrilldownTableHeight}
            data={tableDataWithUplift}
            isAttribution={data.isAttribution}
            selectedStage={originalStage || selectedStage}
            totals={tableTotals?.[breakdownSegment.value] || {}}
            upliftColumnsKeys={upliftColumnsKeys}
            updateUpliftColumnsKeys={updateUpliftColumnsKeys}
            isLoaded={isLoaded}
            isLoadedUplift={isLoadedUplift}
            breakdownSegment={breakdownSegment.value}
            previousBreakdownSegments={previousBreakdownSegments}
            updateBreakdownSegment={(updateData) => onUpdateBreakdownSegment(updateData)}
            defaultDrilldownSelectedMetric={defaultDrilldownSelectedMetric}
            isCompareToPrevious={isCompareToPrevious}
            onChangeCompareToPrevious={onChangeCompareToPrevious}
            isSpotlightTable={isSpotlightTable}
            onChangeSpotlightMetric={onChangeSpotlightMetric}
            totalStageAmount={data.funnelAnalysisData[selectedStage]?.value}
          />
        </div>
      </div>
    </CurtainOverlay>
  );
}
