exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".metric-stats-card__metricStatsCard-3YN{border-radius:12px;background:var(--colorsGeneralWhite);box-shadow:0 0 20px 0 #edeef2;color:#182033;display:flex;flex-direction:column;align-items:center;padding:24px 12px 12px;height:86px;text-align:center}.metric-stats-card__metricStatsCardLoading-S5r{padding:24px;justify-content:center;height:74px}.metric-stats-card__metricValue-1lM{font-size:var(--fontsSizesTitleH4);font-weight:var(--fontsWeightBold);margin-bottom:8px}.metric-stats-card__metricLabel-sLg{font-size:var(--fontsSizesBodyNormal);display:flex;gap:4px;align-items:center;text-transform:capitalize}.metric-stats-card__bottomMetric-A8r{color:#7983a8;font-size:var(--fontsSizesBodySmall);margin-top:auto;display:flex;gap:4px;align-items:center}", ""]);

// exports
exports.locals = {
	"metricStatsCard": "metric-stats-card__metricStatsCard-3YN",
	"metricStatsCard": "metric-stats-card__metricStatsCard-3YN",
	"metricStatsCardLoading": "metric-stats-card__metricStatsCardLoading-S5r",
	"metricStatsCardLoading": "metric-stats-card__metricStatsCardLoading-S5r",
	"metricValue": "metric-stats-card__metricValue-1lM",
	"metricValue": "metric-stats-card__metricValue-1lM",
	"metricLabel": "metric-stats-card__metricLabel-sLg",
	"metricLabel": "metric-stats-card__metricLabel-sLg",
	"bottomMetric": "metric-stats-card__bottomMetric-A8r",
	"bottomMetric": "metric-stats-card__bottomMetric-A8r"
};