import React, { useEffect, useMemo, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { camelCase } from 'lodash';

import useStyles from 'hooks/useStyles';

import userStore from 'stores/userStore';
import servicesStore from 'stores/servicesStore';

import AIAnswersAskAnythingBox from 'components/widgets/answers';
import FunnelAnalysis from 'components/widgets/funnelAnalysis';
import DrilldownCurtain from 'components/widgets/segmentsDrilldown/DrilldownCurtain';
import timeFrameModule from 'modules/timeframe';

import { Events } from 'trackers/analytics/enums';
import { widgetTypes } from 'components/pages/analyze/enums';
import {
  getWidgetsDataV2,
  getWidgetFullConfig,
  getWidgetsData, getRequestResponseData,
} from 'components/pages/analyze/widgetsRequest';
import { FREQUENCY_VALUES } from 'components/utils/frequency';
import { getParsedFunnelAnalysisData } from 'components/widgets/funnelAnalysis/logic/funnelStage';
import { historicalPerformanceMockData } from 'components/pages/home/demoMockData';
import { getStageOverviewMetricsOptions } from 'components/widgets/segmentsDrilldown/logic/stageOverview';
import { metricsTypes, upliftLabel, customFieldKey } from 'components/common/logic/enums';
import { getSessionFilters } from 'components/pages/home/logic/home';
import {
  getDefaultOptimizationMetric,
  getIsSpotlightTimeframeSupported,
} from 'components/widgets/segmentsDrilldown/logic/segmentsDrilldown';
import { suggestionsDropdownTypes } from 'components/pages/questions/enums';
import { getRevenueFunnel } from 'components/utils/indicators';
import { indicationStatuses } from 'components/common/enums';
import { filtersErrorTooltip, timeframeErrorTooltip } from 'components/widgets/segmentsDrilldown/enums';
import { getTSForTimezone } from 'stores/analyze/timeUtils';

import style from 'styles/home/home.css';

const styles = style.locals || {};

function Home({
  filters,
  funnels,
  attributionModel,
  autoFilter,
  timeFrame,
  getWidgetRequestId,
  historicalPerformance,
  accountOptimizationMetrics,
  spotlight,
  segmentsDrilldown,
  upliftBySegments,
  flags,
  setTimeFrameIndication,
  setFiltersIndication,
  region,
  isAccountMode,
}) {
  useStyles([style]);

  const [isLoaded, setIsLoaded] = useState({
    historicalPerformance: false,
    upliftBySegments: false,
    segmentsDrilldown: false,
    spotlight: false,
  });

  const [isFailedToLoad, setIsFailedToLoad] = useState({
    historicalPerformance: false,
    upliftBySegments: false,
    segmentsDrilldown: false,
  });
  const [upliftColumnsKeys, setUpliftColumnsKeys] = useState([]);
  const [currentStage, setCurrentStage] = useState(null);
  const [breakdownSegment, setBreakdownSegment] = useState(null);
  const [currentSessionFilters, setCurrentSessionFilters] = useState([]);
  const [isCompareToPrevious, setIsCompareToPrevious] = useState(false);
  const [isSpotlightTable, setIsSpotlightTable] = useState(false);

  const accountOptimizationMetricsKey = 'accountOptimizationMetricsSegmentsDrilldown';
  const spotlightKey = 'spotlightSegmentsDrilldown';

  useEffect(() => {
    if (isSpotlightTable && currentStage) {
      const isSupportedTimeframe = getIsSpotlightTimeframeSupported({ timeframe: timeFrame });
      if (!isSupportedTimeframe) {
        setTimeFrameIndication({
          status: indicationStatuses.error,
          tooltip: timeframeErrorTooltip,
        });
      } else {
        setTimeFrameIndication({ status: null, tooltip: null });
      }
    }
  }, [timeFrame, filters]);

  useEffect(() => {
    requestFunnelAnalysisData();
  }, [timeFrame, filters, attributionModel, autoFilter, region, isAccountMode]);

  useEffect(() => {
    if (isSpotlightTable || !currentStage) {
      return;
    }

    const originalFunnel = currentStage.originalStage || currentStage.selectedStage;
    requestUpliftData({});
    requestAccountOptimizationData({ originalFunnel });
    requestSegmentDrilldownData({});
  }, [timeFrame, filters, attributionModel, autoFilter, region, isAccountMode, currentStage]);

  const funnelAnalysisData = useMemo(() => {
    if (flags.funnelAnalysisDemoOnly) {
      setIsLoaded((prev) => ({ ...prev, historicalPerformance: true }));
      return historicalPerformanceMockData;
    }

    const funnelAnalysisResponseData = getRequestResponseData({
      currentWidgetConfig: {
        historicalPerformanceFrequency: FREQUENCY_VALUES.MONTH,
      },
      isPreviousTimeFrame: true,
      widget: widgetTypes.historicalPerformance,
      dataPerWidgetFromStore: historicalPerformance,
      getWidgetRequestId,
      setIsLoaded,
      setIsFailedToLoad,
    });
    return getParsedFunnelAnalysisData({ data: funnelAnalysisResponseData, funnels });
  }, [timeFrame, filters, attributionModel, autoFilter, region, isAccountMode, historicalPerformance]);

  useEffect(() => {
    if (currentStage && isSpotlightTable) {
      const currentMetric = currentStage.defaultSelectedMetric;
      const metricValue = funnelAnalysisData[currentMetric]?.value;

      if (isLoaded.spotlight) {
        setIsLoaded((prev) => ({ ...prev, spotlight: false }));
      }

      if (metricValue) {
        requestSpotlightData({ metric: currentMetric, metricValue });
      }
    }
  }, [isSpotlightTable, funnelAnalysisData[currentStage?.defaultSelectedMetric]?.value]);

  function requestFunnelAnalysisData() {
    getWidgetsDataV2({
      widget: widgetTypes.historicalPerformance,
      widgetConfig: {
        historicalPerformanceFrequency: FREQUENCY_VALUES.MONTH,
      },
      isPreviousTimeFrame: true,
    });
  }

  function requestSegmentDrilldownData({
    newBreakdownSegment = breakdownSegment,
    newSessionFilters = currentSessionFilters,
    newIsCompareToPrevious = isCompareToPrevious,
  }) {
    const originalFunnel = currentStage.originalStage || currentStage.selectedStage;
    getWidgetsDataV2({
      widget: widgetTypes.segmentsDrilldown,
      widgetConfig: {
        segmentsDrilldownParams: {
          funnel: originalFunnel,
          breakdown: newBreakdownSegment.value,
        },
        sessionFilters: newSessionFilters,
        filters: currentStage?.isSpotlightFlow ? [] : filters,
      },
      isPreviousTimeFrame: newIsCompareToPrevious,
    });
  }

  function requestUpliftData({
    newBreakdownSegment = breakdownSegment,
    newSessionFilters = currentSessionFilters,
    newUpliftColumnsKeys = upliftColumnsKeys,
  }) {
    if (newUpliftColumnsKeys.length > 0) {
      const config = {
        upliftBySegmentsParams: {
          selectedMetrics: newUpliftColumnsKeys,
          firstSegment: newBreakdownSegment?.value,
          secondSegment: null,
        },
        sessionFilters: newSessionFilters,
        filters: currentStage?.isSpotlightFlow ? [] : filters,
      };

      const fullWidgetConfig = getWidgetFullConfig({ widgetConfig: config, isPreviousTimeFrame: isCompareToPrevious });
      const requestId = getWidgetRequestId({ widget: widgetTypes.upliftBySegments, widgetConfig: fullWidgetConfig });

      getWidgetsDataV2({
        widget: widgetTypes.upliftBySegments,
        widgetConfig: config,
        parentRequestId: requestId,
      });
    }
  }

  function requestAccountOptimizationData({ originalFunnel }) {
    getWidgetsData({
      widgets: [widgetTypes.accountOptimizationMetrics],
      resultKeyName: accountOptimizationMetricsKey,
      configPerWidget: {
        [widgetTypes.accountOptimizationMetrics]: [{
          conversionIndicator: originalFunnel,
          filters: currentStage?.isSpotlightFlow ? [] : filters,
        }],
      },
    });
  }

  async function requestSpotlightData({ metric, metricValue = funnelAnalysisData[metric]?.value }) {
    const isTimeframeSupported = getIsSpotlightTimeframeSupported({ timeframe: timeFrame });
    if (!isTimeframeSupported) {
      setIsLoaded((prev) => ({ ...prev, spotlight: true }));
      return;
    }

    setIsLoaded((prev) => ({ ...prev, spotlight: false }));

    const timeFrameParams = timeFrameModule.getTimeframeParams({
      ...timeFrame,
      fiscalYearFirstMonth: userStore.userMonthPlan.fiscalYearFirstMonth,
    });

    await getWidgetsData({
      widgets: [widgetTypes.spotlight],
      configPerWidget: {
        [widgetTypes.spotlight]: [{
          timeFrame: {
            ...timeFrameParams,
            startDate: new Date(getTSForTimezone(timeFrameParams.startDate)),
            endDate: new Date(getTSForTimezone(timeFrameParams.endDate)),
            value: timeFrame.rollingValue || timeFrame.value,
          },
          filters: [],
          metric,
          spotlightParams: {
            metricValue: metricValue || 0,
            limit: 20,
            offset: 0,
          },
        }],
      },
      resultKeyName: spotlightKey,
    });
    setIsLoaded((prev) => ({ ...prev, spotlight: true }));
  }

  function onUpdateUpliftColumnsKeys({ columnKey, isSkipRequest }) {
    let updatedUpliftColumnsKeys = [...upliftColumnsKeys];
    if (upliftColumnsKeys.includes(columnKey)) {
      updatedUpliftColumnsKeys = upliftColumnsKeys.filter((key) => key !== columnKey);
    } else {
      updatedUpliftColumnsKeys.push(columnKey);
    }
    setUpliftColumnsKeys(updatedUpliftColumnsKeys);

    if (isSkipRequest) {
      return;
    }
    requestUpliftData({ newUpliftColumnsKeys: updatedUpliftColumnsKeys });
  }

  function updateBreakdownSegment({
    segment,
    selectedRow,
    shouldUpdateSessionFilters,
    shouldSendRequest = true,
    isSpotlightBreakdown,
  }) {
    let currentBreakdownSegment = breakdownSegment;
    setBreakdownSegment(segment);
    let newSessionFilters = currentSessionFilters;
    let selectedSegmentValue = selectedRow;

    if (isSpotlightTable && selectedRow) {
      const isCustomField = selectedRow.segmentType === customFieldKey;
      const breakdownSegmentValue = isCustomField ? selectedRow.segmentId : selectedRow.segmentType;

      currentBreakdownSegment = {
        value: breakdownSegmentValue,
        suggestionsDropdownType: isCustomField ? suggestionsDropdownTypes.customFields : null,
      };
      selectedSegmentValue = { firstSegment: selectedRow.segmentValue };
    }

    if (currentBreakdownSegment && shouldUpdateSessionFilters) {
      newSessionFilters = getSessionFilters({
        currentSessionFilters, selectedSegmentValue, currentBreakdownSegment, newBreakdownSegment: segment,
      });
      setCurrentSessionFilters(newSessionFilters);
    }

    if (shouldSendRequest && !isSpotlightBreakdown) {
      if (isSpotlightTable) {
        setIsSpotlightTable(false);
      }

      if (!accountOptimizationMetrics?.[accountOptimizationMetricsKey]?.isLoaded) {
        const originalFunnel = currentStage.originalStage || currentStage.selectedStage;
        requestAccountOptimizationData({ originalFunnel });
      }
      requestSegmentDrilldownData({ newBreakdownSegment: segment, newSessionFilters });
      requestUpliftData({ newBreakdownSegment: segment, newSessionFilters });
    }

    if (isSpotlightBreakdown) {
      setIsSpotlightTable(true);
    }
  }

  const segmentsDrilldownData = useMemo(() => {
    let segmentsDrilldownResponseData = {};
    if (currentStage) {
      segmentsDrilldownResponseData = getRequestResponseData({
        currentWidgetConfig: {
          segmentsDrilldownParams: {
            funnel: currentStage.originalStage || currentStage.selectedStage,
            breakdown: breakdownSegment.value,
          },
          sessionFilters: currentSessionFilters,
          filters: currentStage?.isSpotlightFlow ? [] : filters,
        },
        isPreviousTimeFrame: isCompareToPrevious,
        widget: widgetTypes.segmentsDrilldown,
        dataPerWidgetFromStore: segmentsDrilldown,
        getWidgetRequestId,
        setIsLoaded,
        setIsFailedToLoad,
      });
    }
    return segmentsDrilldownResponseData;
  }, [timeFrame, filters, attributionModel, autoFilter, region, isAccountMode, segmentsDrilldown, isCompareToPrevious, currentStage, breakdownSegment, currentSessionFilters, isLoaded.segmentsDrilldown]);

  const upliftData = useMemo(() => {
    let upliftResponseData = {};
    if (breakdownSegment) {
      upliftResponseData = getRequestResponseData({
        currentWidgetConfig: {
          upliftBySegmentsParams: {
            selectedMetrics: upliftColumnsKeys,
            firstSegment: breakdownSegment?.value,
            secondSegment: null,
          },
          sessionFilters: currentSessionFilters,
          filters: currentStage?.isSpotlightFlow ? [] : filters,
        },
        isPreviousTimeFrame: isCompareToPrevious,
        widget: widgetTypes.upliftBySegments,
        dataPerWidgetFromStore: upliftBySegments,
        getWidgetRequestId,
        setIsLoaded,
        setIsFailedToLoad,
      });
    }
    return upliftResponseData?.data;
  }, [timeFrame, filters, attributionModel, autoFilter, region, isAccountMode, upliftBySegments, breakdownSegment, isCompareToPrevious, upliftColumnsKeys, isLoaded.upliftBySegments]);

  const fullTimeFrame = timeFrameModule.getTimeframeParams({
    ...timeFrame,
    fiscalYearFirstMonth: userStore.userMonthPlan.fiscalYearFirstMonth,
    useEndOfToday: false,
  });

  function onOpenCurtain({
    selectedStage, selectedSegment, supportedMetrics, originalStage, isSpotlightBreakdown,
  }) {
    const selectedStageValue = originalStage || selectedStage;
    const metricsOptions = getStageOverviewMetricsOptions({ currentStage: selectedStageValue, stages: funnels });
    const selectedFirstOption = Object.values(metricsOptions)[0];
    let defaultSelectedMetric = camelCase(`${selectedFirstOption.value} ${metricsTypes.conversionRate}`);
    if (isSpotlightBreakdown) {
      defaultSelectedMetric = getDefaultOptimizationMetric({ stage: selectedStageValue });
    }

    if (upliftColumnsKeys.length === 0 && selectedStageValue !== getRevenueFunnel()) {
      onUpdateUpliftColumnsKeys({ columnKey: `${defaultSelectedMetric}${upliftLabel}`, isSkipRequest: true });
    }
    setIsSpotlightTable(isSpotlightBreakdown);

    if (isSpotlightBreakdown) {
      const isSupportedTimeframe = getIsSpotlightTimeframeSupported({ timeframe: timeFrame });
      if (!isSupportedTimeframe) {
        setTimeFrameIndication({
          status: indicationStatuses.error,
          tooltip: timeframeErrorTooltip,
        });
      }

      const updatedFiltersIndication = {
        disabled: true,
      };

      if (filters.length > 0) {
        updatedFiltersIndication.status = indicationStatuses.error;
        updatedFiltersIndication.tooltip = filtersErrorTooltip;
      }
      setFiltersIndication(updatedFiltersIndication);
    }

    setCurrentStage({
      selectedStage,
      originalStage,
      supportedMetrics,
      metricsOptions,
      defaultSelectedMetric,
      isSpotlightFlow: isSpotlightBreakdown,
    });
    setBreakdownSegment(selectedSegment);
  }

  function onCloseDrilldownCurtain() {
    setCurrentStage(null);
    setIsCompareToPrevious(false);
    setCurrentSessionFilters([]);
    setBreakdownSegment(null);
    setUpliftColumnsKeys([]);

    setTimeFrameIndication({ status: null, tooltip: null });
    setFiltersIndication({ status: null, tooltip: null, disabled: false });

    servicesStore.eventTracker.track({
      eventName: Events.funnelDrilldown.closeDrilldownPopup,
    });
  }

  function onChangeCompareToPrevious({ isCompare }) {
    requestSegmentDrilldownData({ newIsCompareToPrevious: isCompare });
    setIsCompareToPrevious(isCompare);
  }

  const isSpotlightLoaded = isSpotlightTable && (isLoaded.spotlight || spotlight?.[spotlightKey]?.isLoaded);
  const isSegmentsDrilldownLoaded = isLoaded.segmentsDrilldown && accountOptimizationMetrics?.[accountOptimizationMetricsKey]?.isLoaded;
  const spotlightData = isSpotlightTable ? spotlight?.[spotlightKey]?.data?.[breakdownSegment?.value] : null;
  const drilldownData = spotlightData || segmentsDrilldownData?.[currentStage?.originalStage || currentStage?.selectedStage];

  return (
    <div className={styles.wrapper}>
      <AIAnswersAskAnythingBox />

      <FunnelAnalysis
        timeframe={timeFrame}
        fullTimeFrame={fullTimeFrame}
        data={funnelAnalysisData}
        isFailedToLoad={isFailedToLoad.historicalPerformance}
        isLoaded={isLoaded.historicalPerformance}
        openCurtain={(stageData) => onOpenCurtain(stageData)}
        title="Funnel Analysis"
      />

      {currentStage ? (
        <DrilldownCurtain
          data={{
            tableData: drilldownData,
            totals: accountOptimizationMetrics?.[accountOptimizationMetricsKey]?.data,
            isAttribution: segmentsDrilldownData?.isAttribution,
            upliftData,
            funnelAnalysisData,
          }}
          isLoadedUplift={isLoaded.upliftBySegments}
          isLoadedFunnelAnalysis={isLoaded.historicalPerformance || isFailedToLoad.historicalPerformance}
          isLoaded={isSpotlightLoaded || isSegmentsDrilldownLoaded}
          upliftColumnsKeys={upliftColumnsKeys}
          updateUpliftColumnsKeys={({ columnKey }) => onUpdateUpliftColumnsKeys({ columnKey })}
          timeframe={timeFrame}
          fullTimeFrame={fullTimeFrame}
          selectedStage={currentStage.selectedStage}
          drilldownMetricsOptions={currentStage.metricsOptions}
          originalStage={currentStage.originalStage || currentStage.selectedStage}
          defaultDrilldownSelectedMetric={currentStage.defaultSelectedMetric}
          breakdownSegment={breakdownSegment}
          updateBreakdownSegment={(updateData) => updateBreakdownSegment(updateData)}
          supportedMetrics={currentStage.supportedMetrics}
          onClose={() => onCloseDrilldownCurtain()}
          isCompareToPrevious={isCompareToPrevious}
          onChangeCompareToPrevious={({ isCompare }) => onChangeCompareToPrevious({ isCompare })}
          isSpotlightTable={isSpotlightTable}
          onChangeSpotlightMetric={({ metric }) => requestSpotlightData({ metric })}
        />
      ) : null}
    </div>
  );
}
export default withLDConsumer()(inject(
  ({
    userStore: {
      funnels,
      userMonthPlan: {
        region,
      },
    },
    analysisStore: {
      dataPerWidget: {
        [widgetTypes.accountOptimizationMetrics]: accountOptimizationMetrics,
        [widgetTypes.spotlight]: spotlight,
      },
    },
    widgetsAnalysisStore: {
      getWidgetRequestId,
      dataPerWidget: {
        [widgetTypes.historicalPerformance]: historicalPerformance,
        [widgetTypes.segmentsDrilldown]: segmentsDrilldown,
        [widgetTypes.upliftBySegments]: upliftBySegments,
      },
    },
    attributionStore: {
      timeFrame,
      filters,
      attributionModel,
      autoFilter,
      setTimeFrameIndication,
      setFiltersIndication,
      isAccountMode,
    },
  }) => ({
    historicalPerformance,
    accountOptimizationMetrics,
    segmentsDrilldown,
    upliftBySegments,
    getWidgetRequestId,
    timeFrame,
    funnels,
    filters,
    attributionModel,
    autoFilter,
    spotlight,
    setTimeFrameIndication,
    setFiltersIndication,
    region,
    isAccountMode,
  }),
  observer
)(Home));
