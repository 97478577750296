import { useEffect, useState } from 'react';
import classnames from 'classnames';

import useStyles from 'hooks/useStyles';

import style from 'styles/common/CurtainOverlay.css';

const styles = style.locals;

export default function CurtainOverlay({ onClose, children, shouldUseFullScreenCurtain }) {
  useStyles([style]);

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    handleOpen();
  }, []);

  function handleOpen() {
    setTimeout(() => {
      setIsVisible(true);
    }, 50);
  }

  function onCloseCurtain() {
    setIsVisible(false);
    setTimeout(() => {
      onClose();
    }, 500);
  }

  return (
    <div className={classnames(styles.slidingCurtain, shouldUseFullScreenCurtain && styles.fullScreenCurtain, isVisible && styles.open)}>
      <div className={styles.curtainContent}>
        <div
          data-testid="closeIcon"
          className={styles.closeButton}
          onClick={onCloseCurtain}
        />
        {children}
      </div>
    </div>
  );
}
