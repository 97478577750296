import React from 'react';
import ReactDOM from 'react-dom';
import stores from 'stores';
import { Provider } from 'mobx-react';
import { Route, Router } from 'react-router';
import history from 'history';

import servicesStore from 'stores/servicesStore';
import userStore from 'stores/userStore';
import interactionsStore from 'stores/interactionsStore';

import Home from 'components/pages/home';
import Overview from 'components/pages/analyze/OverviewTab';
import Content from 'components/pages/analyze/ContentTab/Content';
import Channels from 'components/pages/analyze/ChannelsTab/Channels';
import CampaignsMeasure from 'components/pages/analyze/CampaignsTab/Campaigns';
import Segments from 'components/pages/analyze/SegmentsTab';
import Setup from 'components/pages/attribution/Setup';
import AttributionLink from 'components/pages/AttributionLink';
import Workflows from 'components/pages/settings/workflows';
import Actions from 'components/pages/settings/actions';
import QuickFilters from 'components/pages/settings/quickFilters';
import SiteStructure from 'components/pages/attribution/SiteStructure';
import AnnualTab from 'components/pages/plan/AnnualTab';
import Settings from 'components/pages/Settings';
import Login from 'components/pages/signIn/Login';
import ForgotPassword from 'components/pages/signIn/ForgotPassword';
import NoAnalyzeData from 'components/pages/analyze/NoAnalyzeData';
import ChannelsSettings from 'components/pages/settings/channels/Channels';
import ChannelsTab from 'components/pages/settings/channels/tabs/ChannelsTab';
import UnmappedChannelsTab from 'components/pages/settings/channels/tabs/UnmappedChannelsTab';
import Journeys from 'components/pages/journeys';
import GoalsTab from 'components/pages/settings/goals/GoalsTab';
import Analyze from 'components/pages/Analyze';
import PlannedVsActual from 'components/pages/PlannedVsActual';
import App from 'components/App';
import Platforms from 'components/pages/Platforms';
import CustomSegments from 'components/pages/settings/customSegments/CustomSegments';
import Plan from 'components/pages/Plan';
import Attribution from 'components/pages/Attribution';
import SignIn from 'components/pages/SignIn';
import Preferences from 'components/pages/Preferences';
import Welcome from 'components/pages/Welcome';
import Reports from 'components/pages/reports';
import InsightsOverview from 'components/pages/insights/overviewTab/InsightsOverview';
import AiPrompts from 'components/pages/insights/aiPromptsTab';
import Insights from 'components/pages/insights/Insights';
import Questions from 'components/pages/questions/Questions';
import AIAnswers from 'components/pages/questions/AIAnswers';
import QuestionResults from 'components/pages/questions/QuestionResults';
import WidgetBuilder from 'components/pages/widgetBuilder/WidgetBuilder';
import NewReport from 'components/pages/reports/NewReport';
import GettingStarted from 'components/pages/onboarding';
import WhatIfTab from 'components/pages/analyze/whatIfTab';
import OptimalJourneyTab from 'components/pages/analyze/optimalJourneyTab';
import SalesEnablement from 'components/pages/salesEnablement';
import IntentSignalsPage from 'components/pages/salesEnablement/intentSignalsPage';
import AccountsPriorityPage from 'components/pages/salesEnablement/accountsPriorityPage';
import Page404 from 'components/common/Page404';

import { tabsIds, roles } from 'enums';

import style from 'styles/global/main.css';

style.use();

const loginRedirect = (...routeArgs) => {
  const [nextState, replace] = routeArgs;
  const { query, pathname } = nextState.location;
  replace({
    pathname: '/',
    query: {
      ...query,
      redirect_to: encodeURI(pathname),
    },
  });
};

// validate authentication for private routes
const requireAdminAuth = (...routeArgs) => {
  if (!servicesStore.authService.isAuthenticated() || servicesStore.authService.getProfileSync()?.app_metadata?.roleName !== roles.admin) {
    servicesStore.authService.logout();
    loginRedirect(...routeArgs);
  }
};

async function checkPermissions({ pathName }) {
  const permissions = await userStore?.userPermissionsRequest();
  if (permissions.pagesToHideFromUser.includes(pathName)) {
    history.push('/');
  }
}

// validate authentication for private routes
const requireAuth = (...routeArgs) => {
  if (!servicesStore.authService.isAuthenticated()) {
    loginRedirect(...routeArgs);
    return;
  }

  const [pathState] = routeArgs;
  checkPermissions({ pathName: pathState?.location.pathname });
};

// only unauthenticated users may access these routes
const redirectIfAuthenticated = (...routeArgs) => {
  if (servicesStore.authService.isAuthenticated()) {
    const replace = routeArgs[1];
    replace({ pathname: '/' });
  }
};

const redirectOrAuthenticate = () => {
  if (servicesStore.authService.isAuthenticated()) {
    history.push('/');
  } else {
    history.push('/home');
  }
};

const onUpdate = () => {
  window.scrollTo(0, 0);
  servicesStore.eventTracker?.page();
  interactionsStore.interactionTracker?.page();
};

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <Provider {...stores}>
    <Router onUpdate={onUpdate} history={history}>
      <Route path="/login" component={Login} onEnter={redirectIfAuthenticated} />
      <Route path="/forgotPassword" component={ForgotPassword} onEnter={redirectIfAuthenticated} />
      <Route path="/" component={SignIn} />
      <Route path="/loginCallBack" onEnter={() => servicesStore.authService.crossOriginVerification()} />
      <Route component={AttributionLink} path="/attribution/:UID" />

      <Route component={App}>
        <Route component={Analyze}>
          <Route path="/home" component={Home} onEnter={requireAuth} tabName="Home" />
        </Route>

        <Route id={tabsIds.analyze} component={Analyze}>
          <Route path="/analyze/overview" component={Overview} onEnter={requireAuth} tabName="Overview" />
          <Route tabName="Breakdown by">
            <Route path="/analyze/channels" component={Channels} onEnter={requireAuth} tabName="Channels" />
            <Route path="/analyze/campaigns" component={CampaignsMeasure} onEnter={requireAuth} tabName="Campaigns" />
            <Route path="/analyze/content" component={Content} onEnter={requireAuth} tabName="Content" />
            <Route path="/analyze/segments" component={Segments} onEnter={requireAuth} tabName="Segments" />
          </Route>
          <Route path="/analyze/journeys" component={Journeys} onEnter={requireAuth} tabName="Journeys" />
          <Route path="/analyze/optimal-journey" component={OptimalJourneyTab} onEnter={requireAuth} tabName="Optimal Journey" />
          <Route path="/analyze/what-if" component={WhatIfTab} onEnter={requireAuth} tabName="What If" />
        </Route>

        <Route path="/reports/new" component={NewReport} tabName="Reports" />
        <Route id={tabsIds.reports} path="/reports" component={Reports} onEnter={requireAuth} tabName="Reports" />
        <Route path="/widget-builder" component={WidgetBuilder} onEnter={requireAuth} tabName="Reports" />

        <Route component={Insights}>
          <Route id={tabsIds.insights} path="/insights/overview" component={InsightsOverview} onEnter={requireAuth} tabName="Overview" />
          <Route path="/insights/ai-prompts" component={AiPrompts} onEnter={requireAuth} tabName="AI Prompts" />
        </Route>

        <Route id={tabsIds.salesEnablement} component={SalesEnablement}>
          <Route path="/sales-enablement/intent-signals" component={IntentSignalsPage} onEnter={requireAuth} tabName="Intent Signals" />
          <Route path="/sales-enablement/accounts-priority" component={AccountsPriorityPage} onEnter={requireAuth} tabName="Accounts Priority" />
        </Route>

        <Route id={tabsIds.plan} component={Plan}>
          <Route path="/plan/annual" component={AnnualTab} onEnter={requireAuth} tabName="Budget" />
          <Route path="/plan/plans-vs-actuals" component={PlannedVsActual} onEnter={requireAuth} tabName="Plans vs Actuals" />
        </Route>

        <Route id={tabsIds.ops} component={ChannelsSettings}>
          <Route path="/settings/ops/workflows" component={Workflows} onEnter={requireAuth} tabName="Workflows" />
          <Route path="/settings/ops/channels" component={ChannelsTab} onEnter={requireAuth} tabName="Channels" />
          <Route path="/settings/ops/custom-segments" component={CustomSegments} onEnter={requireAuth} tabName="Custom Segments" />
          <Route path="/settings/ops/unmapped" component={UnmappedChannelsTab} onEnter={requireAuth} tabName="Unmapped Attribution" />
          <Route path="/settings/ops/alerts" component={Actions} onEnter={requireAuth} tabName="Alerts" />
          <Route path="/settings/ops/quickFilters" component={QuickFilters} onEnter={requireAuth} tabName="Quick Filters" />
        </Route>

        <Route id={tabsIds.settings} component={Settings}>
          <Route path="/settings/account" tabName="Account" component={Welcome} onEnter={requireAuth} />
          <Route path="/settings/goals/goals-main" component={GoalsTab} onEnter={requireAuth} tabName="Goals" />
          <Route path="/settings/profile/preferences" component={Preferences} tabName="Preferences" onEnter={requireAuth} />
          <Route tabName="Website Traffic Settings" component={Attribution} onEnter={requireAuth}>
            <Route path="/settings/attribution/setup" component={Setup} onEnter={requireAuth} tabName="Setup" />
            <Route path="/settings/attribution/site-structure" component={SiteStructure} onEnter={requireAuth} tabName="Site Structure" />
          </Route>
          <Route path="/settings/profile/integrations" component={Platforms} onEnter={requireAuth} tabName="Integrations" />
        </Route>

        <Route id={tabsIds.answers} path="/answers" component={AIAnswers} onEnter={requireAuth} />
        <Route path="/questions" component={Questions} onEnter={requireAuth} />
        <Route path="/questions/results" component={QuestionResults} onEnter={requireAuth} />

        <Route path="/no-analyze-data" component={NoAnalyzeData} onEnter={requireAdminAuth} />
        <Route path="/getting-started" component={GettingStarted} onEnter={requireAuth} />
        <Route path="/dashboard/navigate" onEnter={redirectOrAuthenticate} />
        <Route path="*" component={Page404} onEnter={requireAuth} />
      </Route>

    </Router>
  </Provider>,
  document.querySelector('#main')
);
