exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".custom-radio__container-169{display:flex;align-items:center;cursor:pointer}.custom-radio__container-169:hover .custom-radio__radio-FH8{border:1px solid #b2bbd5;box-shadow:0 1px 2px #e6e8f0;border-radius:10px}.custom-radio__radio-FH8{background:var(--colorsGeneralWhite);border:1px solid var(--colorsInputGrayLine);box-shadow:0 1px 2px #e6e8f0;border-radius:10px;width:16px;height:16px}.custom-radio__container-169:hover .custom-radio__radio-FH8[data-checked],.custom-radio__radio-FH8[data-checked]{background:var(--colorsGeneralWhite);border:4px solid #5e8ff4;border-radius:10px;width:10px;height:10px}.custom-radio__container-169:hover .custom-radio__radio-FH8[data-disabled],.custom-radio__radio-FH8[data-disabled]{background:var(--colorsInputGrayLine);border-radius:10px}.custom-radio__children-1FV{font-size:var(--fontsSizesBodyNormal);color:#182033;cursor:pointer;margin-left:10px}.custom-radio__children-1FV[data-disabled]{color:#b2bbd5}", ""]);

// exports
exports.locals = {
	"container": "custom-radio__container-169",
	"container": "custom-radio__container-169",
	"radio": "custom-radio__radio-FH8",
	"radio": "custom-radio__radio-FH8",
	"children": "custom-radio__children-1FV",
	"children": "custom-radio__children-1FV"
};