exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".workflowTooltip__tooltipContainer-24i{display:flex;flex-direction:column;text-align:left;line-height:20px;margin:8px 0}.workflowTooltip__title-3dM{font-weight:var(--fontsWeightBold);font-size:var(--fontsSizesBodyLarge)}.workflowTooltip__section-34f:not(:last-child){margin-bottom:10px;padding-bottom:10px;border-bottom:1px solid var(--colorsTextDarkGray)}.workflowTooltip__sectionTitle-2X-{padding-bottom:10px;font-weight:var(--fontsWeightBold);font-size:var(--fontsSizesBodyNormal)}.workflowTooltip__sectionBody-3Ta{display:flex;flex-direction:column}.workflowTooltip__sectionBody-3Ta ul{margin:0 0 0 16px;padding:0}.workflowTooltip__highlight-1-m{background:var(--colorsGeneralBlue);display:inline;padding:0 4px;border-radius:4px;margin:2px;font-style:normal}", ""]);

// exports
exports.locals = {
	"tooltipContainer": "workflowTooltip__tooltipContainer-24i",
	"tooltipContainer": "workflowTooltip__tooltipContainer-24i",
	"title": "workflowTooltip__title-3dM",
	"title": "workflowTooltip__title-3dM",
	"section": "workflowTooltip__section-34f",
	"section": "workflowTooltip__section-34f",
	"sectionTitle": "workflowTooltip__sectionTitle-2X-",
	"sectionTitle": "workflowTooltip__sectionTitle-2X-",
	"sectionBody": "workflowTooltip__sectionBody-3Ta",
	"sectionBody": "workflowTooltip__sectionBody-3Ta",
	"highlight": "workflowTooltip__highlight-1-m",
	"highlight": "workflowTooltip__highlight-1-m"
};