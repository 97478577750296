import React from 'react';
import { inject, observer } from 'mobx-react';
import { v4 as uuidv4 } from 'uuid';

import Component from 'components/Component';
import PropertiesTab from 'components/pages/settings/goals/popups/PropertiesTab';
import MilestonesTab from 'components/pages/settings/goals/popups/MilestonesTab';
import serverCommunication from 'data/serverCommunication';

import { extractNumberFromBudget } from 'components/utils/budget';
import { compose } from 'components/utils/utils';
import { getMetricsOptions } from 'components/pages/settings/goals/logic/GoalPopup';
import { widgetTypes } from 'components/pages/analyze/enums';

import style from 'styles/onboarding/onboarding.css';
import popupStyle from 'styles/welcome/add-member-popup.css';
import navStyle from 'styles/profile/market-fit-popup.css';

const enhance = compose(
  inject((stores) => {
    const {
      userMetrics,
      getMetricNickname,
      userMonthPlan: {
        region,
      },
    } = stores.userStore;

    const {
      dataPerWidget: {
        [widgetTypes.goalsAnalysis]: goalsAnalysis,
      },
      getWidgetRequestId,
      updateWidgetResultData,
      updateWidgetStatusIndication,
    } = stores.widgetsAnalysisStore;

    return {
      userMetrics,
      getMetricNickname,
      region,
      getWidgetRequestId,
      goalsAnalysis,
      updateWidgetResultData,
      updateWidgetStatusIndication,
    };
  }),
  observer
);

const step = {
  properties: 0,
  milestones: 1,
};

class GoalPopup extends Component {
  style = style;

  styles = [popupStyle, navStyle];

  defaultData = {
    id: uuidv4(),
    metricName: '',
    metricType: '',
    cumulativeMetric: false,
    title: '',
    description: '',
    priority: 0,
    targetValue: '',
    timeframe: null,
    frequencyType: null,
    pickerTimeframe: null,
    milestonesConfig: null,
    milestones: [],
    filters: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      step: step.properties,
      ...this.defaultData,
    };
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (nextProps.hidden !== this.props.hidden) {
      if (!nextProps.selectedGoal) {
        this.setState({ id: uuidv4() });
      }

      if (nextProps.selectedGoal) {
        this.setState({
          id: nextProps.selectedGoal.id,
          metricName: nextProps.selectedGoal.metricName,
          metricType: nextProps.selectedGoal.metricType,
          cumulativeMetric: nextProps.selectedGoal.cumulativeMetric,
          title: nextProps.selectedGoal.title,
          description: nextProps.selectedGoal.description,
          priority: nextProps.selectedGoal.priority,
          timeframe: nextProps.selectedGoal.timeframe,
          frequencyType: nextProps.selectedGoal.frequencyType,
          milestones: nextProps.selectedGoal.milestones || [],
          milestonesConfig: nextProps.selectedGoal.milestonesConfig,
          targetValue: nextProps.selectedGoal.targetValue,
          filters: nextProps.selectedGoal.filters,
        });
      } else if (nextProps.hidden) {
        this.setState({
          ...this.defaultData,
          priority: nextProps.goalsList.length,
        });
      }
    }
  }

  getPriorities = (goalsCount) => [...Array(goalsCount + 1)].map((_, i) => ({ value: i, label: `#${i + 1}` }));

  setTargetValue = (targetValue, callback) => {
    this.setState({ targetValue: extractNumberFromBudget(targetValue) }, callback);
  };

  resetMilestones = () => {
    this.setState({ milestones: [], milestonesConfig: null });
  };

  moveToPropertiesTab = ({
    targetValue,
    milestonesConfig,
    milestones,
    shouldSaveChanges = false,
    removeMilestones = false,
  }) => {
    if (shouldSaveChanges) {
      this.setState({
        step: step.properties,
        targetValue,
        milestonesConfig,
        milestones,
      });

      return;
    }

    if (removeMilestones) {
      this.setState({
        step: step.properties,
        milestonesConfig: null,
        milestones: [],
      });

      return;
    }

    this.setState({ step: step.properties });
  };

  moveToMilestonesTab = ({
    metricName,
    metricType,
    cumulativeMetric,
    title,
    description,
    priority,
    timeframe,
    frequencyType,
    pickerTimeframe,
    targetValue,
  }) => {
    this.setState({
      step: step.milestones,
      metricName,
      metricType,
      cumulativeMetric,
      title: title !== '' ? title : this.props.getMetricNickname({ metric: metricName }),
      description,
      priority,
      timeframe,
      frequencyType,
      pickerTimeframe,
      targetValue,
    });
  };

  submit = async ({
    metricName,
    metricType,
    cumulativeMetric,
    title,
    description,
    priority,
    timeframe,
    frequencyType,
    targetValue,
    filters,
  }) => {
    const goalToUpdate = {
      id: this.state.id,
      metricName,
      metricType,
      cumulativeMetric,
      title: title !== '' ? title : this.props.getMetricNickname({ metric: metricName }),
      description,
      priority,
      timeframe,
      frequencyType,
      targetValue,
      milestonesConfig: this.state.milestonesConfig,
      milestones: this.state.milestones,
      filters,
    };

    if (this.props.onStartCreateNewGoal) {
      this.props.onStartCreateNewGoal();
    }

    this.props.close();

    this.props.updateWidgetStatusIndication({
      widget: widgetTypes.goalsAnalysis,
      widgetRequestId: this.props.goalsAnalysisRequestId,
      newStatusIndication: 'inProgress',
    });

    const response = await serverCommunication.serverRequest('PUT', 'goals', JSON.stringify({ goalToUpdate }), this.props.region);
    const responseData = await response.json();
    const updatedGoal = responseData.updatedGoal;

    const goalsAnalysisData = [...this.props.goalsList];

    const existingGoalIndex = goalsAnalysisData.findIndex((goal) => goal.id === updatedGoal.id);

    if (existingGoalIndex === -1) {
      goalsAnalysisData.push(updatedGoal);
    } else {
      goalsAnalysisData[existingGoalIndex] = updatedGoal;
    }

    this.props.updateWidgetResultData({
      widget: widgetTypes.goalsAnalysis,
      newWidgetData: {
        goals: goalsAnalysisData,
      },
      widgetRequestId: this.props.goalsAnalysisRequestId,
    });

    this.props.updateWidgetStatusIndication({
      widget: widgetTypes.goalsAnalysis,
      widgetRequestId: this.props.goalsAnalysisRequestId,
      newStatusIndication: 'finished',
    });

    if (this.props.onFinishCreateNewGoal) {
      this.props.onFinishCreateNewGoal();
    }
  };

  render() {
    const {
      status,
      close,
      hidden,
      editMode,
      userMetrics,
      goalsList,
    } = this.props;

    if (hidden) {
      return null;
    }

    const metricsOptions = getMetricsOptions({ userMetrics });
    const priorities = this.getPriorities(goalsList.length);

    return (
      <div hidden={this.props.hidden}>
        {this.state.step === step.properties ? (
          <PropertiesTab
            moveToMilestonesTab={(propertiesParamsObj) => this.moveToMilestonesTab(propertiesParamsObj)}
            resetMilestones={() => this.resetMilestones()}
            metricsOptions={metricsOptions}
            milestones={this.state.milestones}
            milestonesConfig={this.state.milestonesConfig}
            metricName={this.state.metricName}
            metricType={this.state.metricType}
            cumulativeMetric={this.state.cumulativeMetric}
            priority={this.state.priority}
            priorities={priorities}
            title={this.state.title}
            description={this.state.description}
            targetValue={this.state.targetValue}
            frequencyType={this.state.frequencyType}
            timeframe={this.state.timeframe}
            filters={this.state.filters}
            status={status}
            close={close}
            submit={this.submit}
            editMode={editMode}
            pickerTimeframe={this.state.pickerTimeframe}
          />
        ) : (
          <MilestonesTab
            moveToPropertiesTab={(milestonesParamsObj) => this.moveToPropertiesTab(milestonesParamsObj)}
            milestones={this.state.milestones}
            milestonesConfig={this.state.milestonesConfig}
            metricName={this.state.metricName}
            goalFrequencyType={this.state.frequencyType}
            targetValue={this.state.targetValue}
            setTargetValue={this.setTargetValue}
            timeframe={this.state.timeframe}
          />
        )}
      </div>
    );
  }
}

export default enhance(GoalPopup);
