export const timelineTab = 'timeline';
export const statsTab = 'stats';
export const touchpoints = 'touchpoints';
export const benchmark = 'benchmark';
export const contacts = 'contacts';

export const journeyTabsOptions = [
  { text: 'Timeline', value: timelineTab },
  { text: 'Stats', value: statsTab },
];

export const workflowKeys = {
  timestampAdjustment: 'timestampAdjustment',
  oppEnrichment: 'oppEnrichment',
  onlineSession: 'onlineSession',
  crmMultiTouch: 'crmMultitouch',
  deAnonymization: 'deAnonymization',
  dedup: 'dedup',
  leadToAccount: 'leadToAccountMatching',
};

export const workflowsTitles = {
  [workflowKeys.timestampAdjustment]: 'Adjust touchpoint timestamps',
  [workflowKeys.oppEnrichment]: 'Automate Contact-to-Opportunity association',
  [workflowKeys.onlineSession]: 'Online session',
  [workflowKeys.crmMultiTouch]: 'Log CRM field history',
  [workflowKeys.deAnonymization]: 'De-anonymization',
  [workflowKeys.dedup]: 'Deduplicate CRM records',
  [workflowKeys.leadToAccount]: 'Match leads to accounts',
};
