// eslint-disable-next-line import/no-cycle
import StatsSquaresWrapper from 'components/pages/reports/widgetsWrapper/StatsSquaresWrapper';
import StatsSquaresJourneysWrapper from 'components/pages/reports/widgetsWrapper/StatsSquaresJourneysWrapper';
import ImpactBySegmentWrapper from 'components/pages/reports/widgetsWrapper/ImpactBySegmentWrapper';
import AttributionTableSegmentsWrapper from 'components/pages/reports/widgetsWrapper/AttributionTableSegmentsWrapper';
import HistoricalPerformanceWrapper from 'components/pages/reports/widgetsWrapper/HistoricalPerformanceWrapper';
import PerformanceItemWrapper from 'components/pages/reports/widgetsWrapper/PerformanceItemWrapper';
import JourneysCampaignsWrapper from 'components/pages/reports/widgetsWrapper/JourneysCampaignsWrapper';
import JourneysChannelsWrapper from 'components/pages/reports/widgetsWrapper/JourneysChannelsWrapper';
import TrendAnalysisWrapper from 'components/pages/reports/widgetsWrapper/TrendAnalysisWrapper';
import FunnelViewWrapper from 'components/pages/reports/widgetsWrapper/FunnelViewWrapper';
import AudienceTableWrapper from 'components/pages/reports/widgetsWrapper/AudienceTableWrapper';
import GoalsAnalysisWrapper from 'components/pages/reports/widgetsWrapper/GoalsAnalysisWrapper';
import WhatIfWrapper from 'components/pages/reports/widgetsWrapper/WhatIfWrapper';
import FunnelAnalysisWrapper from 'components/pages/reports/widgetsWrapper/FunnelAnalysisWrapper';

import { concatSpotlightDataResult } from 'components/pages/insights/logic/Spotlight';
import { concatInspectDataResult } from 'components/pages/analyze/inspectData/logic/Inspect';

export const widgetTypes = {
  groupByMapping: 'groupByMapping',
  attributionDailyCost: 'attributionDailyCost',
  historicalPerformance: 'historicalPerformance',
  performanceItem: 'performanceItem',
  impactBySegment: 'impactBySegment',
  segmentsAnalysis: 'segmentsAnalysis',
  topConversionCampaignJourneys: 'campaignJourneys',
  topConversionContentCampaignJourneys: 'contentCampaignJourneys',
  campaignsImpactByFrequency: 'campaignsImpactByFrequency',
  topConversionChannelJourneys: 'channelJourneys',
  topConversionContentJourneys: 'contentJourneys',
  contentImpact: 'contentImpact',
  contentImpactByFrequency: 'contentImpactByFrequency',
  contentIndicatorsCount: 'contentIndicatorsCount',
  journeysOverview: 'journeysOverview',
  journeysTable: 'journeysTable',
  statsSquare: 'statsSquare',
  trendAnalysis: 'trendAnalysis',
  spotlight: 'spotlight',
  trendBySegments: 'trendBySegments',
  accountOptimizationMetrics: 'accountOptimizationMetrics',
  goalsAnalysis: 'goalsAnalysis',
  funnelView: 'funnelView',
  funnelTransitionsBySegments: 'funnelTransitionsBySegments',
  creditBySessions: 'creditBySessions',
  journeyPopup: 'journeyPopup',
  whatIf: 'whatIf',
  optimalJourney: 'optimalJourney',
  salesEnablement: 'salesEnablement',
  upliftBySegments: 'upliftBySegments',
  funnelAnalysis: 'funnelAnalysis',
  segmentsDrilldown: 'segmentsDrilldown',
  accountPerformance: 'accountPerformance',
};

export const flagsNames = {
  overviewStatsSquareWidget: 'overviewStatsSquareWidget',
  historicalPerformanceWidget: 'historicalPerformanceWidget',
  trendAnalysisWidget: 'trendAnalysisWidget',
  funnelViewWidget: 'funnelViewWidget',
  marketingVsSalesWidget: 'marketingVsSalesWidget',
  channelImpactBySegmentWidget: 'channelImpactBySegmentWidget',
  channelsAttributionTableWidget: 'channelsAttributionTableWidget',
  journeysChannelsWidget: 'journeysChannelsWidget',
  campaignsImpactBySegmentWidget: 'campaignsImpactBySegmentWidget',
  campaignsAttributionTableWidget: 'campaignsAttributionTableWidget',
  journeysCampaignsWidget: 'journeysCampaignsWidget',
  contentImpactBySegmentWidget: 'contentImpactBySegmentWidget',
  contentAttributionTableWidget: 'contentAttributionTableWidget',
  segmentAttributionTableWidget: 'segmentAttributionTableWidget',
  segmentsImpactBySegmentWidget: 'segmentsImpactBySegmentWidget',
  journeysCardsMetrics: 'journeysCardsMetrics',
  journeysTableWidget: 'journeysTableWidget',
  analyzeOverviewGoalsAnalysis: 'analyzeOverviewGoalsAnalysis',
  insightsTabSpotlight: 'insightsTabSpotlight',
  insightsOverviewSegmentAnalysis: 'insightsOverviewSegmentAnalysis',
  insightsOverviewImpactBySegment: 'insightsOverviewImpactBySegment',
  upliftColumnSegmentAnalysisTable: 'upliftColumnSegmentAnalysisTable',
};

export const widgetsConfig = {
  groupByMapping: {
    store: 'attribution',
    type: widgetTypes.groupByMapping,
    flag: null,
    widgetsToServerRequest: [widgetTypes.groupByMapping],
  },
  attributionDailyCost: {
    store: 'attribution',
    type: widgetTypes.attributionDailyCost,
    flag: null,
    widgetsToServerRequest: [widgetTypes.attributionDailyCost],
  },
  statsSquare: {
    store: 'overview',
    type: widgetTypes.statsSquare,
    flag: flagsNames.overviewStatsSquareWidget,
    widgetsToServerRequest: [widgetTypes.historicalPerformance],
    wrapperComponent: StatsSquaresWrapper,
  },
  impactBySegment: {
    store: 'overview',
    type: widgetTypes.impactBySegment,
    flag: null,
    widgetsToServerRequest: [widgetTypes.impactBySegment, widgetTypes.trendBySegments, widgetTypes.accountOptimizationMetrics],
    wrapperComponent: ImpactBySegmentWrapper,
  },
  historicalPerformance: {
    store: 'overview',
    type: widgetTypes.historicalPerformance,
    flag: null,
    widgetsToServerRequest: [widgetTypes.historicalPerformance],
    wrapperComponent: HistoricalPerformanceWrapper,
  },
  performanceItem: {
    type: widgetTypes.performanceItem,
    flag: null,
    widgetsToServerRequest: [widgetTypes.historicalPerformance],
    wrapperComponent: PerformanceItemWrapper,
  },
  funnelView: {
    type: widgetTypes.funnelView,
    flag: flagsNames.funnelViewWidget,
    widgetsToServerRequest: [widgetTypes.historicalPerformance],
    wrapperComponent: FunnelViewWrapper,
  },
  segmentsAnalysis: {
    store: 'overview',
    type: widgetTypes.segmentsAnalysis,
    flag: null,
    widgetsToServerRequest: [widgetTypes.segmentsAnalysis, widgetTypes.trendBySegments, widgetTypes.accountOptimizationMetrics, widgetTypes.upliftBySegments],
    wrapperComponent: AttributionTableSegmentsWrapper,
  },
  topConversionCampaignJourneys: {
    store: 'campaigns',
    type: widgetTypes.topConversionCampaignJourneys,
    flag: null,
    widgetsToServerRequest: [widgetTypes.topConversionContentCampaignJourneys, widgetTypes.topConversionCampaignJourneys],
    wrapperComponent: JourneysCampaignsWrapper,
  },
  topConversionContentCampaignJourneys: {
    store: 'campaigns',
    type: widgetTypes.topConversionContentCampaignJourneys,
    flag: null,
    widgetsToServerRequest: [widgetTypes.topConversionContentCampaignJourneys],
  },
  topConversionChannelJourneys: {
    store: 'channels',
    type: widgetTypes.topConversionChannelJourneys,
    flag: null,
    widgetsToServerRequest: [widgetTypes.topConversionChannelJourneys, widgetTypes.topConversionContentJourneys],
    wrapperComponent: JourneysChannelsWrapper,
  },
  topConversionContentJourneys: {
    store: 'channels',
    type: widgetTypes.topConversionContentJourneys,
    flag: null,
    widgetsToServerRequest: [widgetTypes.topConversionContentJourneys],
  },
  contentIndicatorsCount: {
    store: 'content',
    type: widgetTypes.contentIndicatorsCount,
    flag: null,
    widgetsToServerRequest: [widgetTypes.contentIndicatorsCount],
  },
  journeysOverview: {
    store: 'journeys',
    type: widgetTypes.journeysOverview,
    flag: null,
    widgetsToServerRequest: [widgetTypes.journeysOverview],
    wrapperComponent: StatsSquaresJourneysWrapper,
  },
  journeysTable: {
    store: 'journeys',
    type: widgetTypes.journeysTable,
    flag: null,
    widgetsToServerRequest: [widgetTypes.journeysTable, widgetTypes.journeysOverview],
    wrapperComponent: AudienceTableWrapper,
  },
  trendAnalysis: {
    type: widgetTypes.trendAnalysis,
    flag: flagsNames.trendAnalysisWidget,
    widgetsToServerRequest: [widgetTypes.trendAnalysis],
    wrapperComponent: TrendAnalysisWrapper,
  },
  spotlight: {
    type: widgetTypes.spotlight,
    flag: flagsNames.insightsTabSpotlight,
    widgetsToServerRequest: [widgetTypes.spotlight],
  },
  trendBySegments: {
    type: widgetTypes.trendBySegments,
    flag: null,
    widgetsToServerRequest: [widgetTypes.trendBySegments],
  },
  accountOptimizationMetrics: {
    type: widgetTypes.accountOptimizationMetrics,
    flag: null,
    widgetsToServerRequest: [widgetTypes.accountOptimizationMetrics],
  },
  goalsAnalysis: {
    type: widgetTypes.goalsAnalysis,
    flag: flagsNames.analyzeOverviewGoalsAnalysis,
    widgetsToServerRequest: [widgetTypes.goalsAnalysis],
    wrapperComponent: GoalsAnalysisWrapper,
  },
  whatIf: {
    type: widgetTypes.whatIf,
    flag: null,
    widgetsToServerRequest: [widgetTypes.whatIf, widgetTypes.historicalPerformance, widgetTypes.segmentsAnalysis],
    wrapperComponent: WhatIfWrapper,
  },
  funnelTransitionsBySegments: {
    type: widgetTypes.funnelTransitionsBySegments,
    flag: null,
    widgetsToServerRequest: [widgetTypes.funnelTransitionsBySegments],
  },
  creditBySessions: {
    type: widgetTypes.creditBySessions,
    flag: null,
    widgetsToServerRequest: [widgetTypes.creditBySessions],
  },
  upliftBySegments: {
    type: widgetTypes.upliftBySegments,
    flag: flagsNames.upliftColumnSegmentAnalysisTable,
    widgetsToServerRequest: [widgetTypes.upliftBySegments],
  },
  funnelAnalysis: {
    type: widgetTypes.funnelAnalysis,
    flag: null,
    widgetsToServerRequest: [widgetTypes.historicalPerformance],
    wrapperComponent: FunnelAnalysisWrapper,
  },
};

export const widgetsConcatResultFunction = {
  [widgetTypes.spotlight]: {
    concatResultFunction: concatSpotlightDataResult,
  },
  [widgetTypes.funnelTransitionsBySegments]: {
    concatResultFunction: concatInspectDataResult,
  },
};

export const widgetTypeToBlurImage = {
  [widgetTypes.statsSquare]: 'overviewStatsSquare',
  [widgetTypes.historicalPerformance]: 'historicalPerformance',
  [widgetTypes.funnelView]: 'funnelView',
  [widgetTypes.trendAnalysis]: 'trendAnalysis',
  [widgetTypes.segmentsAnalysis]: 'attributionTable',
  [widgetTypes.topConversionChannelJourneys]: 'topConversionJourneys',
  [widgetTypes.journeysOverview]: 'journeysCardsMetrics',
  [widgetTypes.journeysTable]: 'journeysTable',
  [widgetTypes.goalsAnalysis]: 'goalsAnalysis',
  [widgetTypes.funnelAnalysis]: 'funnelView',
};

export const defaultWidget = {
  content: 'To get access to this widget, contact Sales.',
  buttonText: 'Ask Sales about this widget',
  image: 'default',
  height: 400,
};

export const blurModeWidgets = {
  [flagsNames.overviewStatsSquareWidget]: {
    title: 'Overview Metrics',
    content: 'See how marketing is performing in terms of cost efficiency and revenue impact. Curious about this widget? ',
    image: widgetTypeToBlurImage[widgetTypes.statsSquare],
    height: 500,
  },
  [flagsNames.historicalPerformanceWidget]: {
    title: 'Historical performance',
    content: 'Understand how your funnel metric and unit economics perform over time.',
    image: widgetTypeToBlurImage[widgetTypes.historicalPerformance],
    height: 300,
  },
  [flagsNames.funnelViewWidget]: {
    title: 'Funnel View',
    content: 'See your funnel KPIs, conversion rates, and velocities.',
    image: widgetTypeToBlurImage[widgetTypes.funnelView],
    height: 300,
  },
  [flagsNames.marketingVsSalesWidget]: {
    title: 'Impact by Department',
    content: 'Compare Marketing and Sales contribution across funnel KPIs, and understand Marketing\'s role in converting accounts.',
    image: widgetTypeToBlurImage[widgetTypes.impactBySegment],
  },
  [flagsNames.trendAnalysisWidget]: {
    title: 'Trend Analysis',
    content: 'Analyze trends and see how spend correlates with different channels performance.',
    image: widgetTypeToBlurImage[widgetTypes.trendAnalysis],
  },
  [flagsNames.channelImpactBySegmentWidget]: {
    title: 'Impact by Channel',
    content: 'Analyze channel impact over time and uncover your top performing channels. ',
  },
  [flagsNames.channelsAttributionTableWidget]: {
    title: 'Channel Impact Analysis',
    content: 'Dive deep into your channels\' metrics to uncover optimizion opportunities. See attributed revenue, conversion rates, cost efficiency, velocity, deal size, and more.',
    image: widgetTypeToBlurImage[widgetTypes.segmentsAnalysis],
    height: 500,
  },
  [flagsNames.journeysChannelsWidget]: {
    title: 'Top Conversion Journeys',
    content: 'See which channels and content assets work well together. Uncover channel-to-channel and channel-to content combinations.',
    image: widgetTypeToBlurImage[widgetTypes.topConversionChannelJourneys],
  },
  [flagsNames.campaignsImpactBySegmentWidget]: {
    title: 'Impact by Campaign',
    content: 'Analyze campaign impact over time and uncover your top performing campaigns, across channels.',
  },
  [flagsNames.campaignsAttributionTableWidget]: {
    title: 'Campaigns Impact Analysis',
    content: 'Dive deep into your campaigns\' metrics to uncover optimizion opportunities. See attributed revenue, conversion rates, cost efficiency, velocity, deal size, and more.',
    image: widgetTypeToBlurImage[widgetTypes.segmentsAnalysis],
    height: 500,
  },
  [flagsNames.journeysCampaignsWidget]: {
    title: 'Top Conversion Journeys',
    content: 'See which channels and content assets work well together. Uncover channel-to-channel and channel-to content combinations.',
    image: widgetTypeToBlurImage[widgetTypes.topConversionChannelJourneys],
  },
  [flagsNames.contentImpactBySegmentWidget]: {
    title: 'Impact by Content',
    content: 'Analyze content impact over time and uncover your top performing content, across channels.',
  },
  [flagsNames.contentAttributionTableWidget]: {
    title: 'Content Impact Analysis',
    content: 'Dive deep into your content metrics to uncover optimizion opportunities. See attributed revenue, conversion rates, velocity, deal size, per content asset.',
    image: widgetTypeToBlurImage[widgetTypes.segmentsAnalysis],
    height: 500,
  },
  [flagsNames.segmentsImpactBySegmentWidget]: {
    title: 'Impact by Segment',
    content: 'Analyze any CRM segment\'s impact over time and uncover your segments you should double down on to improve your go-to-market strategy.',
  },
  [flagsNames.segmentAttributionTableWidget]: {
    title: 'Segment Analysis',
    content: 'Dive deep into any CRM segment\'s performance to uncover optimizion opportunities. See attributed revenue, conversion rates, cost efficiency, velocity, deal size, and more.',
    image: widgetTypeToBlurImage[widgetTypes.segmentsAnalysis],
    height: 500,
  },
  [flagsNames.journeysCardsMetrics]: {
    title: 'Journeys Metrics',
    content: 'See account-level interaction data.',
    image: widgetTypeToBlurImage[widgetTypes.journeysOverview],
    height: 240,
  },
  [flagsNames.journeysTableWidget]: {
    title: 'Journeys Analysis',
    content: 'Analyze the account journey of any account and identify accounts you should focus on.',
    image: widgetTypeToBlurImage[widgetTypes.journeysTable],
    height: 500,
  },
  [flagsNames.analyzeOverviewGoalsAnalysis]: {
    title: 'Goals Analysis',
    content: 'Keep track of your goals, pace, and trend, from a single widget.',
    image: widgetTypeToBlurImage[widgetTypes.goalsAnalysis],
    height: 240,
  },
};

export const widgetsForPollingRequest = [
  widgetTypes.segmentsAnalysis,
  widgetTypes.impactBySegment,
  widgetTypes.historicalPerformance,
  widgetTypes.funnelView,
  widgetTypes.performanceItem,
  widgetTypes.statsSquare,
  widgetTypes.trendAnalysis,
  widgetTypes.journeysTable,
  widgetTypes.goalsAnalysis,
  widgetTypes.whatIf,
  widgetTypes.upliftBySegments,
  widgetTypes.segmentsDrilldown,
  widgetTypes.accountPerformance,
  widgetTypes.funnelAnalysis,
];

export const widgetToServerRequest = {
  [widgetTypes.funnelView]: widgetTypes.historicalPerformance,
  [widgetTypes.performanceItem]: widgetTypes.historicalPerformance,
  [widgetTypes.statsSquare]: widgetTypes.historicalPerformance,
};

export const widgetsClassWidthByType = {
  [widgetTypes.performanceItem]: 'quarterWidth',
};

export const unifyKeyByWidgetType = {
  [widgetTypes.performanceItem]: ['selectedMetrics'],
};

export const unifyWidgets = Object.keys(unifyKeyByWidgetType);

export const queryParamsNames = {
  searchQuery: 'searchQuery',
  journeyId: 'journeyId',
  journeyTab: 'journeyTab',
  preventClickOutside: 'preventClickOutside',
  guideCompleted: 'guideCompleted',
  openSegmentAnalysisWhatsNextPopup: 'openSegmentAnalysisWhatsNextPopup',
  insightsOnboarding: 'insightsOnboarding',
};

export const onboardingTagsIds = {
  impactByMostEffectiveSegment: 'impactByMostEffectiveSegment',
  headerSettingsPopup: 'headerSettingsPopup',
  headerTimeframeDropdown: 'headerTimeframeDropdown',
  headerKpiFocusDropdown: 'headerKpiFocusDropdown',
  selectedGoal: 'selectedGoal',
  selectedOptimizationMetric: 'selectedOptimizationMetric',
  selectedSpotlight: 'selectedSpotlight',
};
