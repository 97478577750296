exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".multiLevelMenuPopup__menuPopup-3OU{box-shadow:0 4px 12px rgba(24,32,51,.07);background:var(--colorsTextWhite);border:1px solid var(--colorsInputGrayLine);border-radius:4px}.multiLevelMenuPopup__child-pIz{position:absolute;left:calc(100% + 6px);top:-7px}.multiLevelMenuPopup__childRtl-hio{position:absolute;right:calc(100% + 6px);top:-7px}.multiLevelMenuPopup__optionAsComponentContainer-33P{margin:12px;border-radius:4px}.multiLevelMenuPopup__disabled-2ru{opacity:.5}.multiLevelMenuPopup__optionStyle-3go{display:flex;justify-content:space-between;align-items:center;padding:0 12px;margin:6px;border-radius:4px;height:40px;white-space:nowrap;font-size:var(--fontsSizesBodyNormal);cursor:pointer;color:var(--colorsTextGray);position:relative}.multiLevelMenuPopup__optionStyle-3go:hover{background-color:var(--colorsBackgroundLightGray);border-radius:4px}.multiLevelMenuPopup__labelContainer-3YF{display:flex;align-items:center;gap:6px}.multiLevelMenuPopup__itemIconContainer-1n3{background-repeat:no-repeat;background-position:50%;height:20px;width:20px}", ""]);

// exports
exports.locals = {
	"menuPopup": "multiLevelMenuPopup__menuPopup-3OU",
	"menuPopup": "multiLevelMenuPopup__menuPopup-3OU",
	"child": "multiLevelMenuPopup__child-pIz",
	"child": "multiLevelMenuPopup__child-pIz",
	"childRtl": "multiLevelMenuPopup__childRtl-hio",
	"childRtl": "multiLevelMenuPopup__childRtl-hio",
	"optionAsComponentContainer": "multiLevelMenuPopup__optionAsComponentContainer-33P",
	"optionAsComponentContainer": "multiLevelMenuPopup__optionAsComponentContainer-33P",
	"disabled": "multiLevelMenuPopup__disabled-2ru",
	"disabled": "multiLevelMenuPopup__disabled-2ru",
	"optionStyle": "multiLevelMenuPopup__optionStyle-3go",
	"optionStyle": "multiLevelMenuPopup__optionStyle-3go",
	"labelContainer": "multiLevelMenuPopup__labelContainer-3YF",
	"labelContainer": "multiLevelMenuPopup__labelContainer-3YF",
	"itemIconContainer": "multiLevelMenuPopup__itemIconContainer-1n3",
	"itemIconContainer": "multiLevelMenuPopup__itemIconContainer-1n3"
};