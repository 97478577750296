exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".market-fit-popup__box-1-D{padding:24px}.market-fit-popup__title-2bw{font-size:17px;font-weight:var(--fontsWeightBold);letter-spacing:-.01em;color:#273142}.market-fit-popup__choose-QWS{margin:26px 0}.market-fit-popup__choose-QWS,.market-fit-popup__nav-Rnb{display:flex;justify-content:center}.market-fit-popup__next-icon-1uw{background:url(/assets/next-icon.png) 50% no-repeat;width:7px;height:11px;margin-left:12px}.market-fit-popup__back-icon-3TU{background:url(/assets/back-icon-accent.png) 50% no-repeat;width:6px;height:12px;margin-right:12px}", ""]);

// exports
exports.locals = {
	"box": "market-fit-popup__box-1-D",
	"box": "market-fit-popup__box-1-D",
	"title": "market-fit-popup__title-2bw",
	"title": "market-fit-popup__title-2bw",
	"choose": "market-fit-popup__choose-QWS",
	"choose": "market-fit-popup__choose-QWS",
	"nav": "market-fit-popup__nav-Rnb",
	"nav": "market-fit-popup__nav-Rnb",
	"next-icon": "market-fit-popup__next-icon-1uw",
	"nextIcon": "market-fit-popup__next-icon-1uw",
	"back-icon": "market-fit-popup__back-icon-3TU",
	"backIcon": "market-fit-popup__back-icon-3TU"
};