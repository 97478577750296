exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".loader__loading-1UK{position:fixed;top:35%;left:40%}.loader__popup-2Lr{width:300px;height:200px;display:flex;justify-content:center;align-items:center}.loader__spinner-text-1N5{height:24px;font-size:var(--fontsSizesBodyLarge);font-weight:var(--fontsWeightMedium);color:var(--colorsTextGray)}.loader__loaderPopup-2PS{background:var(--colorsGeneralWhite);border-radius:12px;box-shadow:0 4px 12px 0 rgba(0,0,0,.06);flex-direction:column;padding-bottom:16px}.loader__loadingLogo-2I-{height:80px}", ""]);

// exports
exports.locals = {
	"loading": "loader__loading-1UK",
	"loading": "loader__loading-1UK",
	"popup": "loader__popup-2Lr",
	"popup": "loader__popup-2Lr",
	"spinner-text": "loader__spinner-text-1N5",
	"spinnerText": "loader__spinner-text-1N5",
	"loaderPopup": "loader__loaderPopup-2PS",
	"loaderPopup": "loader__loaderPopup-2PS",
	"loadingLogo": "loader__loadingLogo-2I-",
	"loadingLogo": "loader__loadingLogo-2I-"
};