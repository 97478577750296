import {
  action, computed, decorate, observable, runInAction,
} from 'mobx';
import { persist } from 'mobx-persist';
import { isEmpty, cloneDeep, isEqual } from 'lodash';

import hydrate from 'stores/hydrate';
import userStore from 'stores/userStore';
import analysisStore from 'stores/analysisStore';
import interactionsStore from 'stores/interactionsStore';

import {
  FUNNEL1, isMoneyIndicator, getVelocityKey, getRevenueFunnel, getPipelineFunnel, NEW_FUNNEL_PREFIX,
} from 'components/utils/indicators';
import { TIMEFRAME_VALUES } from 'components/utils/timeframe';
import { attributionModelsItems } from 'attribution/models';
import { filterGivenRecords } from 'components/utils/filters';
import { getTSForTimezone } from 'stores/analyze/timeUtils';
import timeFrameModule from 'modules/timeframe';
import { mapObjToSelectOptions } from 'components/utils/utils';
import { isTimeframeChanged } from 'components/pages/analyze/logic/Tabs';
import { applyFiltersInteraction, getTimeframeFromQueryParams } from 'stores/logic/attributionStore';
import {
  kpiFocusOptionsKeys,
  kpiFocusOptionsLabels,
  optimalJourneyDefaultTimeframe,
} from 'components/widgets/optimalJourney/enums';
import { getParseDateRangeLabel } from 'stores/analyze/logic/timeUtils';
import { switcherInteractionTypes, Interactions } from 'trackers/interactions/enums';

const INIT_GROUP_BY = 'companies';

class AttributionStore {
  metricsOptions = [];

  constructor() {
    Object.assign(this, AttributionStore.initialVals);

    this.data = {};
    this.conversionIndicator = 'funnel1';
    this.previousTimeframe = false;
    this.autoFilter = true;
    this.isOtherCampaignsHidden = false;
    this.isAccountMode = false;
    this.appliedCustomFilters = [];
    this.appliedQuickFilters = [];
    this.optimalJourneyKpi = kpiFocusOptionsKeys.conversionRateToLast;
    this.optimalJourneyTimeframe = optimalJourneyDefaultTimeframe;
    this.hasOptimalJourneyTimeframeWarning = false;
    this.timeFrameIndication = { status: null, tooltip: null, disabled: false };
    this.filtersIndication = { status: null, tooltip: null, disabled: false };
  }

  resetToDefaultData() {
    Object.assign(this, AttributionStore.initialVals);
  }

  get isCalculateAdvancedMetrics() {
    return this.isCalculateAdvancedMetricsValue ?? analysisStore.LDFlags?.headerSettingsCalculateAdvancedMetrics;
  }

  get filters() {
    const parsedQuickFilters = this.appliedQuickFilters.map((quickFilter) => quickFilter.filter);
    return [...parsedQuickFilters, ...this.appliedCustomFilters];
  }

  get numberOfFilters() {
    return this.filters.length;
  }

  get velocityKey() {
    return getVelocityKey(this.conversionIndicator);
  }

  get customDateMode() {
    const prevTimeFrames = Object.values(TIMEFRAME_VALUES).filter((str) => str.includes('prev'));
    return [...prevTimeFrames, TIMEFRAME_VALUES.CUSTOM, TIMEFRAME_VALUES.WEEK].includes(this.timeFrame.value);
  }

  get timeFrameParams() {
    return timeFrameModule.getTimeframeParams({
      ...this.timeFrame,
      fiscalYearFirstMonth: userStore.userMonthPlan.fiscalYearFirstMonth,
    });
  }

  get dateRange() {
    return this.timeFrameParams;
  }

  get tsRange() {
    const {
      startDate, endDate, previousStartDate, previousEndDate,
    } = this.timeFrameParams;
    return {
      startTS: startDate.getTime(),
      endTS: endDate.getTime(),
      previousStartDate,
      previousEndDate,
    };
  }

  get formattedTimeParams() {
    const {
      startTS, endTS, previousStartDate, previousEndDate,
    } = this.tsRange;

    const startDate = new Date(getTSForTimezone(startTS));
    const endDate = new Date(getTSForTimezone(endTS));

    return {
      startDate,
      endDate,
      previousStartDate: new Date(getTSForTimezone(previousStartDate.getTime())),
      previousEndDate: new Date(getTSForTimezone(previousEndDate.getTime())),
    };
  }

  get isMoneyFormat() {
    return isMoneyIndicator(this.conversionIndicator);
  }

  get rawFilters() {
    return this.filters.map(({ config, data }) => ({ data, kind: config.kind }));
  }

  updateMultipleParameters({
    filters, timeframeValue, timeframeParams, attributionModel, conversionIndicator,
  }) {
    runInAction(() => {
      if (timeframeValue) {
        this.setTimeFrame(timeframeValue, timeframeParams, false);
      }
      if (filters) {
        this.setFilters(filters, false);
      }
      if (attributionModel) {
        this.setAttributionModel(attributionModel, false);
      }
      if (conversionIndicator) {
        this.setConversionIndicator(conversionIndicator);
      }
    });
  }

  updateStoreByQueryParams({ params }) {
    if (isEmpty((params))) {
      return;
    }

    const updatedParams = getTimeframeFromQueryParams({ params });

    if (params.kpiFocus) {
      updatedParams.conversionIndicator = params.kpiFocus;
    }

    if (params.filters) {
      const encodedFilters = Buffer.from(params.filters, 'base64');
      const decodedFilters = JSON.parse(encodedFilters.toString('utf8'));
      for (const filter of decodedFilters) {
        if (filter.data.timeFrame) {
          const { startDate: startTS, endDate: endTS } = timeFrameModule.getTimeframeParams({ ...filter.data.timeFrame, fiscalYearFirstMonth: userStore.userMonthPlan.fiscalYearFirstMonth });
          filter.data.timeFrame = {
            value: filter.data.timeFrame.value,
            startTS,
            endTS,
          };
        }
      }
      updatedParams.filters = decodedFilters;
    }

    this.updateMultipleParameters(updatedParams);
  }

  setFilters(filters = [], resetLoadingIndication = true) {
    this.appliedCustomFilters = cloneDeep(filters);

    if (resetLoadingIndication) {
      analysisStore.restIsLoadedWidgetIndication();
    }

    applyFiltersInteraction({ filters });
  }

  setQuickFilters(filters = [], resetLoadingIndication = true) {
    this.appliedQuickFilters = cloneDeep(filters);

    if (resetLoadingIndication) {
      analysisStore.restIsLoadedWidgetIndication();
    }

    applyFiltersInteraction({ filters: filters.map(({ filter }) => filter) });
  }

  setPreviousCheckbox(newIsCompareToPrevious) {
    this.previousTimeframe = newIsCompareToPrevious;
  }

  setAutoFilter(resetLoadingIndication = true) {
    const shouldUsePredefinedFilters = !this.autoFilter;
    this.autoFilter = shouldUsePredefinedFilters;
    if (resetLoadingIndication) {
      analysisStore.restIsLoadedWidgetIndication();
    }

    const predefinedFiltersLabel = shouldUsePredefinedFilters ? switcherInteractionTypes.on : switcherInteractionTypes.off;
    interactionsStore.interactionTracker.trackConfig({
      type: Interactions.global.predefinedFiltersSwitched.type,
      name: Interactions.global.predefinedFiltersSwitched.name,
      description: predefinedFiltersLabel,
    });
  }

  uiFilters = ({ filters = this.filters }) => filters.filter(({ isUiOnly }) => isUiOnly);

  getFilteredRecords = ({ records, getData = ([key]) => key, filters }) => filterGivenRecords(records, this.uiFilters({ filters }), getData);

  setAttributionModel(model, resetLoadingIndication = true) {
    this.attributionModel = {
      ...model,
    };
    if (resetLoadingIndication) {
      analysisStore.restIsLoadedWidgetIndication();
    }

    const attributionModelLabel = `${model.label} (${model.description})`;
    interactionsStore.interactionTracker.trackConfig({
      type: Interactions.global.attributionModelChanged.type,
      name: Interactions.global.attributionModelChanged.name,
      description: attributionModelLabel,
    });
  }

  setTimeFrame(value, additionalData, resetLoadingIndication = true) {
    const didTimeFrameChanged = isTimeframeChanged({ timeFrame: { value, ...additionalData }, prevTimeFrame: this.timeFrame });

    this.timeFrame = {
      value,
      ...additionalData,
    };

    if (resetLoadingIndication && didTimeFrameChanged) {
      analysisStore.restIsLoadedWidgetIndication();
    }

    const timeFrameLabel = getParseDateRangeLabel({ timeFrame: additionalData });
    interactionsStore.interactionTracker.trackConfig({
      type: Interactions.global.timeframeChanged.type,
      name: Interactions.global.timeframeChanged.name,
      description: timeFrameLabel,
    });
  }

  updateOtherCampaignsHidden() {
    this.isOtherCampaignsHidden = !this.isOtherCampaignsHidden;
  }

  get originalFunnelFromIndicator() {
    const metricByIndicator = this.metricsOptions.find((option) => option.value === this.conversionIndicator);
    return metricByIndicator?.originalFunnel || this.conversionIndicator;
  }

  setConversionIndicator(conversionIndicator = FUNNEL1) {
    const metricByIndicator = this.metricsOptions.find((option) => option.value === conversionIndicator);
    if (!metricByIndicator) {
      this.conversionIndicator = FUNNEL1;
      return;
    }
    this.conversionIndicator = conversionIndicator;

    const kpiFocusLabel = userStore.getMetricNickname({ metric: conversionIndicator });
    interactionsStore.interactionTracker.trackConfig({
      type: Interactions.global.kpiFocusChanged.type,
      name: Interactions.global.kpiFocusChanged.name,
      description: kpiFocusLabel,
    });
  }

  setZeroWeightsNotInTimeFrame(zeroWeightsNotInTimeFrame = false) {
    this.zeroWeightsNotInTimeFrame = zeroWeightsNotInTimeFrame;
  }

  getMetricOptions() {
    const metrics = {};
    userStore.userFunnels.forEach((funnel) => {
      metrics[funnel] = userStore.getMetricNickname({ metric: funnel });
    });
    this.isLoadedOnce = false;
    const metricsOptions = mapObjToSelectOptions(metrics);
    const { isPipelineStageHidden } = userStore.userMonthPlan || {};
    if (!isPipelineStageHidden) {
      metricsOptions.push({
        label: 'Pipeline',
        value: 'pipeline',
        originalFunnel: getPipelineFunnel(),
      });
    }
    metricsOptions.push({
      label: 'Revenue',
      value: 'revenue',
      originalFunnel: getRevenueFunnel(),
    });
    return metricsOptions;
  }

  setMetricOptions() {
    this.metricsOptions = this.getMetricOptions();
    this.setConversionIndicator(`${NEW_FUNNEL_PREFIX}1`);
  }

  setIsAccountMode() {
    const shouldUseAccountMode = !this.isAccountMode;
    this.isAccountMode = shouldUseAccountMode;
    analysisStore.restIsLoadedWidgetIndication();

    const accountModeLabel = shouldUseAccountMode ? switcherInteractionTypes.on : switcherInteractionTypes.off;
    interactionsStore.interactionTracker.trackConfig({
      type: Interactions.global.accountModeChanged.type,
      name: Interactions.global.accountModeChanged.name,
      description: accountModeLabel,
    });
  }

  setIsCalculateAdvancedMetrics({ isCalculateAdvancedMetrics }) {
    this.isCalculateAdvancedMetricsValue = isCalculateAdvancedMetrics;
  }

  getAnalyzeBaseConfig = ({ isPreviousTimeFrame = false }) => {
    const {
      attributionModel, filters, formattedTimeParams, autoFilter, conversionIndicator,
    } = this;

    const timeFrame = {
      endDate: formattedTimeParams.endDate,
      startDate: formattedTimeParams.startDate,
    };

    const payload = {
      attributionCredit: false,
      attributionModel,
      filters,
      timeFrame,
      shouldUsePredefinedFilters: autoFilter,
      conversionIndicator,
    };

    if (isPreviousTimeFrame) {
      payload.previousTimeFrame = {
        startDate: formattedTimeParams.previousStartDate,
        endDate: formattedTimeParams.previousEndDate,
      };

      payload.compareToPreviousTimeFrame = isPreviousTimeFrame;
    }

    return payload;
  };

  resetAppliedQuickFiltersAccordingFilterKind({ filterKindsToReset }) {
    const filtersToKeep = this.appliedQuickFilters.filter((quickFilter) => !filterKindsToReset.includes(quickFilter.kind));
    if (!isEqual(filtersToKeep, this.appliedQuickFilters)) {
      const isFilterUIOnly = filtersToKeep.some((filterToKeep) => filterToKeep.filter.isUiOnly);
      this.setQuickFilters(filtersToKeep, !isFilterUIOnly);
    }
  }

  setOptimalJourneyStages({ stages, isInitialState }) {
    this.optimalJourneyStages = stages;

    if (!isInitialState) {
      const stagesLabel = stages.map((stage) => stage.label).join(', ');
      interactionsStore.interactionTracker.trackConfig({
        type: Interactions.global.selectedStagesUpdated.type,
        name: Interactions.global.selectedStagesUpdated.name,
        description: stagesLabel,
      });
    }
  }

  setOptimalJourneyKpi({ kpi }) {
    this.optimalJourneyKpi = kpi;

    let kpiFocusLabel = kpiFocusOptionsLabels[kpi];
    if (kpi === kpiFocusOptionsKeys.conversionRateToLast) {
      const lastStageLabel = this.optimalJourneyStages[this.optimalJourneyStages.length - 1]?.label || '';
      kpiFocusLabel = `${kpiFocusLabel} ${lastStageLabel}`;
    }

    interactionsStore.interactionTracker.trackConfig({
      type: Interactions.global.kpiFocusChanged.type,
      name: Interactions.global.kpiFocusChanged.name,
      description: kpiFocusLabel,
    });
  }

  setOptimalJourneyTimeframe({ timeFrame }) {
    this.optimalJourneyTimeframe = timeFrame;

    const timeFrameLabel = getParseDateRangeLabel({ timeFrame });
    interactionsStore.interactionTracker.trackConfig({
      type: Interactions.global.timeframeChanged.type,
      name: Interactions.global.timeframeChanged.name,
      description: timeFrameLabel,
    });
  }

  setHasOptimalJourneyTimeframeWarning(hasWarning) {
    this.hasOptimalJourneyTimeframeWarning = hasWarning;
  }

  setTimeFrameIndication({
    status = this.timeFrameIndication.status,
    tooltip = this.timeFrameIndication.tooltip,
    disabled = this.timeFrameIndication.disabled,
  }) {
    this.timeFrameIndication = { status, tooltip, disabled };
  }

  setFiltersIndication({
    status = this.filtersIndication.status,
    tooltip = this.filtersIndication.tooltip,
    disabled = this.filtersIndication.disabled,
  }) {
    this.filtersIndication = { status, tooltip, disabled };
  }

  static initialVals = {
    attributionModel: attributionModelsItems[0],
    timeFrame: {
      value: TIMEFRAME_VALUES.QUARTER,
    },
    appliedCustomFilters: [],
    appliedQuickFilters: [],
    zeroWeightsNotInTimeFrame: false,
    isOtherCampaignsHidden: false,
    conversionIndicator: FUNNEL1,
    groupBy: INIT_GROUP_BY,
  };
}

decorate(AttributionStore, {
  data: observable.ref,
  prevData: observable.ref,
  timeFrame: observable.ref,
  timeFrameParams: computed,
  customDateMode: computed,
  zeroWeightsNotInTimeFrame: observable,
  attributionModel: observable.ref,
  conversionIndicator: observable.ref,
  metricsOptions: observable.ref,
  tsRange: computed,
  dateRange: computed,
  formattedTimeParams: computed,
  setAttributionModel: action.bound,
  setMetricOptions: action.bound,
  setTimeFrame: action.bound,
  setConversionIndicator: action.bound,
  originalFunnelFromIndicator: computed,
  setZeroWeightsNotInTimeFrame: action.bound,
  setFilters: action.bound,
  setQuickFilters: action.bound,
  updateMultipleParameters: action.bound,
  groupBy: observable,
  previousTimeframe: observable,
  autoFilter: observable,
  setPreviousCheckbox: action.bound,
  setAutoFilter: action.bound,
  getMetricOptions: action.bound,
  updateOtherCampaignsHidden: action.bound,
  isOtherCampaignsHidden: observable,
  numberOfFilters: computed,
  filters: computed,
  appliedCustomFilters: observable.ref,
  appliedQuickFilters: observable.ref,
  isAccountMode: observable,
  setIsAccountMode: action.bound,
  isCalculateAdvancedMetrics: computed,
  isCalculateAdvancedMetricsValue: observable,
  setIsCalculateAdvancedMetrics: action.bound,
  updateStoreByQueryParams: action.bound,
  resetToDefaultData: action.bound,
  resetAppliedQuickFiltersAccordingFilterKind: action.bound,
  optimalJourneyStages: observable.ref,
  setOptimalJourneyStages: action.bound,
  optimalJourneyKpi: observable.ref,
  setOptimalJourneyKpi: action.bound,
  optimalJourneyTimeframe: observable.ref,
  setOptimalJourneyTimeframe: action.bound,
  hasOptimalJourneyTimeframeWarning: observable,
  setHasOptimalJourneyTimeframeWarning: action.bound,
  timeFrameIndication: observable,
  setTimeFrameIndication: action.bound,
  filtersIndication: observable,
  setFiltersIndication: action.bound,
});

const schema = {
  attributionModel: {
    type: 'object',
  },
  timeFrame: {
    type: 'object',
  },
  appliedCustomFilters: {
    type: 'list',
  },
  appliedQuickFilters: {
    type: 'list',
  },
  zeroWeightsNotInTimeFrame: true,
  conversionIndicator: true,
  groupBy: true,
  isOtherCampaignsHidden: true,
  isCalculateAdvancedMetricsValue: true,
};

const store = persist(schema)(new AttributionStore());

hydrate('attributionStore', store);

export default store;
