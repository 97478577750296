import React from 'react';

import MenuButton from 'components/common/MenuButton';
import Switch from 'components/controls/Switch';

import { FREQUENCY_OPTIONS, FREQUENCY_MAP } from 'components/utils/frequency';

function MonthToDate({
  frequency, shouldUseRelativeTimeframe, updateShouldUseRelativeTimeframe, disabled,
}) {
  const frequencyAsNumber = parseInt(frequency, 10);

  const shouldShowMonthToDate = frequencyAsNumber !== FREQUENCY_OPTIONS.DAY.value;
  if (!shouldShowMonthToDate) {
    return null;
  }

  const selectedFrequencyObj = Object.values(FREQUENCY_OPTIONS).find((option) => option.value === frequencyAsNumber);
  const title = `${selectedFrequencyObj?.label} to date`;
  const tooltipText = `Switch on '${selectedFrequencyObj?.label} to date' to compare the same range of days (from the 1st to the current day) across different ${FREQUENCY_MAP[frequencyAsNumber]}s.`;

  return (
    <MenuButton
      title={title}
      tooltip={tooltipText}
      withArrowIndication={false}
      disabled={!updateShouldUseRelativeTimeframe || disabled}
      rightIconRerender={(
        <Switch
          onSwitchClick={updateShouldUseRelativeTimeframe}
          isActive={shouldUseRelativeTimeframe}
          dataTestId="switch-month-to-date"
          disabled={!updateShouldUseRelativeTimeframe || disabled}
        />
      )}
    />
  );
}

export default MonthToDate;
