import React from 'react';
import { sumBy, isNil, isEmpty } from 'lodash';

import useStyles from 'hooks/useStyles';

import Uplift from 'components/common/Uplift';
import Skeleton from 'components/common/Skeleton';
import timeFrameModule from 'modules/timeframe';
import userStore from 'stores/userStore';
import ComparisonValue from 'components/common/ComparisonValue';
import EllipsisTooltip from 'components/controls/EllipsisTooltip';
import Loading3Dots from 'components/controls/Loading3Dots';

import { columnControllersKeys } from 'components/widgets/segmentsDrilldown/enums';
import { formatNumberWithDecimalPoint } from 'components/utils/logic/budget';
import { getPacingCalculate, getItemLabelWithNoValue, getCostPerMetricLabel } from 'components/utils/logic/utils';
import { formatIndicatorDisplay } from 'components/utils/indicators';
import { getIsGrowthDesired, getGrowthValue } from 'components/pages/analyze/utils/comparisonUtils';
import { calculateAverage } from 'components/pages/analyze/AttribuitonTable/logic/AttributionSegmentsTableParseData';
import { formatBudget } from 'components/utils/budget';
import {
  upliftRangeLimit, costMetrics, metricsTypes,
} from 'components/common/logic/enums';
import { getSegmentLabel } from 'components/pages/analyze/SegmentsTab/logic/segments';

import style from 'styles/segmentsDrilldown/segmentsDrilldown.css';

const styles = style.locals || {};

export default function CellByColumn({
  value,
  isLoaded,
  columnKey,
  metric = columnKey,
  timeframe,
  tableData,
  isCompareToPrevious,
  growthPerMetric,
  isLoadedUplift,
  upliftMinMaxByMetric,
  breakdownSegment,
  stage,
  totalStageAmount,
  isAttribution,
}) {
  useStyles([style]);

  if (columnKey === columnControllersKeys.uplift && !isEmpty(upliftMinMaxByMetric)) {
    const { minValue, maxValue } = upliftMinMaxByMetric[`${metric}Uplift`];
    return (
      <Uplift
        value={value}
        minValue={minValue - 2}
        maxValue={maxValue + 2}
        isLoaded={isLoadedUplift}
        upliftRangeLimit={upliftRangeLimit}
      />
    );
  }

  if (!isLoaded && isNil(value)) {
    return (
      <div className={styles.skeletonWrapper}>
        <Skeleton height={22} width="100%" isLightTheme />
      </div>
    );
  }

  if (isLoaded && value === null) {
    return null;
  }

  if (columnKey === 'firstSegment') {
    const breakdownSegmentLabel = getSegmentLabel({ segment: breakdownSegment, customFieldsIdToLabelMap: userStore.userAccount?.customFieldsIdToLabelMap, customUtmsWhitelist: userStore.userAccount?.customUtmsWhitelist });
    const breakdownSegmentLabelWithNoValue = getItemLabelWithNoValue({ value, segment: breakdownSegment, segmentLabel: breakdownSegmentLabel });

    return (
      <div className={styles.breakdownSegmentLabel}>
        <EllipsisTooltip withPortal text={breakdownSegmentLabelWithNoValue}>
          {breakdownSegmentLabelWithNoValue}
        </EllipsisTooltip>
      </div>
    );
  }

  if (columnKey === columnControllersKeys.shareOfTotal) {
    let total = totalStageAmount;
    const isBiSegment = !isAttribution;
    if (!metric.includes('touched') || isBiSegment) {
      total = sumBy(tableData, (item) => item[metric]);
    }

    const shareOfTotal = formatNumberWithDecimalPoint((value / total) * 100, 1);
    return `${shareOfTotal}%`;
  }

  if (columnKey === columnControllersKeys.pacing) {
    const fullTimeFrame = timeFrameModule.getTimeframeParams({
      ...timeframe,
      fiscalYearFirstMonth: userStore.userMonthPlan.fiscalYearFirstMonth,
      useEndOfToday: false,
    });
    return getPacingCalculate({ timeframe: fullTimeFrame, actual: value });
  }

  if (columnKey === columnControllersKeys.compare) {
    const average = calculateAverage(tableData, metric);
    const compareValue = ((value - average) / average) * 100;
    return (
      <ComparisonValue
        value={formatNumberWithDecimalPoint(compareValue, 1)}
        isGrowthDesired={getIsGrowthDesired({ metricName: metric })}
        className={styles.compareValue}
      />
    );
  }

  const isCostMetric = costMetrics.includes(metric);
  let cellValue = formatIndicatorDisplay(metric, value || 0, true, true, true, 2);

  if (isCostMetric) {
    cellValue = formatBudget(value || 0);
  }

  if (columnKey === metricsTypes.efficiency) {
    cellValue = getCostPerMetricLabel({ value, stage });
  }

  const growthValue = growthPerMetric?.[metric];

  return (
    <div className={styles.cellFlex}>
      {cellValue}

      {isCompareToPrevious && isNil(growthValue) && !isLoaded ? (
        <Loading3Dots />
      ) : (
        <ComparisonValue
          value={getGrowthValue({
            metricName: metric,
            growthPerMetric: { [metric]: growthValue },
          })}
          isGrowthDesired={getIsGrowthDesired({ metricName: metric })}
        />
      )}
    </div>
  );
}
