exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".crm-popup__inner-t1-{padding:0 30px}.crm-popup__box-3aH{cursor:pointer;width:100%;height:65px;border-radius:5px;border:2px solid #dfdfdf}.crm-popup__salesforce-1ub{background:url(/assets/salesforce.png) 50% no-repeat;background-size:contain}.crm-popup__hubspot-1Ik{background:url(/assets/hubspot.png) 50% no-repeat;background-size:contain}.crm-popup__google-analytics-1Bq{background:url(/assets/google-analytics-logo.jpg) 50% no-repeat;background-size:contain}.crm-popup__stripe-SEo{background:url(/assets/stripe.png) 50% no-repeat;background-size:contain}.crm-popup__google-sheets-1hl{background:url(/assets/google-sheets.png) 50% no-repeat;background-size:contain}.crm-popup__linkedin-131{background:url(/assets/linkedin-logo.png) 50% no-repeat;background-size:contain}.crm-popup__adwords-1KC{background:url(/assets/words.png) 50% no-repeat;background-size:contain}.crm-popup__facebookads-1Rx{background:url(/assets/facebook-ads.svg) 50% no-repeat;background-size:contain}.crm-popup__bingads-2Mt{background:url(/assets/platform-icons/bing.png) 50% no-repeat;background-size:contain}.crm-popup__linkedinads-T0R{background:url(/assets/linkedin-ads.png) 50% no-repeat;background-size:contain}.crm-popup__twitterads-3t3{background:url(/assets/twitter-ads.png) 50% no-repeat;background-size:contain}.crm-popup__quoraads-2i-{background:url(/assets/quora-ads.png) 50% no-repeat;background-size:contain}.crm-popup__title-VYR{font-weight:700;font-size:18px;margin-bottom:15px;text-align:center}.crm-popup__sub-title-3XX{font-weight:var(--fontsWeightMedium);font-size:var(--fontsSizesBodyLarge);margin-bottom:15px;text-align:center}.crm-popup__row-c7j{margin-bottom:15px;display:flex;justify-content:center;width:100%}.crm-popup__close-dOd{position:relative;bottom:31px;left:278px;width:15px;height:15px;background:url(/assets/plan_icons/close.svg) 50% no-repeat;background-size:contain;cursor:pointer}", ""]);

// exports
exports.locals = {
	"inner": "crm-popup__inner-t1-",
	"inner": "crm-popup__inner-t1-",
	"box": "crm-popup__box-3aH",
	"box": "crm-popup__box-3aH",
	"salesforce": "crm-popup__salesforce-1ub crm-popup__box-3aH",
	"salesforce": "crm-popup__salesforce-1ub crm-popup__box-3aH",
	"hubspot": "crm-popup__hubspot-1Ik crm-popup__box-3aH",
	"hubspot": "crm-popup__hubspot-1Ik crm-popup__box-3aH",
	"google-analytics": "crm-popup__google-analytics-1Bq crm-popup__box-3aH",
	"googleAnalytics": "crm-popup__google-analytics-1Bq crm-popup__box-3aH",
	"stripe": "crm-popup__stripe-SEo crm-popup__box-3aH",
	"stripe": "crm-popup__stripe-SEo crm-popup__box-3aH",
	"google-sheets": "crm-popup__google-sheets-1hl crm-popup__box-3aH",
	"googleSheets": "crm-popup__google-sheets-1hl crm-popup__box-3aH",
	"linkedin": "crm-popup__linkedin-131 crm-popup__box-3aH",
	"linkedin": "crm-popup__linkedin-131 crm-popup__box-3aH",
	"adwords": "crm-popup__adwords-1KC crm-popup__box-3aH",
	"adwords": "crm-popup__adwords-1KC crm-popup__box-3aH",
	"facebookads": "crm-popup__facebookads-1Rx crm-popup__box-3aH",
	"facebookads": "crm-popup__facebookads-1Rx crm-popup__box-3aH",
	"bingads": "crm-popup__bingads-2Mt crm-popup__box-3aH",
	"bingads": "crm-popup__bingads-2Mt crm-popup__box-3aH",
	"linkedinads": "crm-popup__linkedinads-T0R crm-popup__box-3aH",
	"linkedinads": "crm-popup__linkedinads-T0R crm-popup__box-3aH",
	"twitterads": "crm-popup__twitterads-3t3 crm-popup__box-3aH",
	"twitterads": "crm-popup__twitterads-3t3 crm-popup__box-3aH",
	"quoraads": "crm-popup__quoraads-2i- crm-popup__box-3aH",
	"quoraads": "crm-popup__quoraads-2i- crm-popup__box-3aH",
	"title": "crm-popup__title-VYR",
	"title": "crm-popup__title-VYR",
	"sub-title": "crm-popup__sub-title-3XX",
	"subTitle": "crm-popup__sub-title-3XX",
	"row": "crm-popup__row-c7j",
	"row": "crm-popup__row-c7j",
	"close": "crm-popup__close-dOd",
	"close": "crm-popup__close-dOd"
};