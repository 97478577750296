exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".app__wrap-2w4{padding:120px 80px 48px 148px;background-color:var(--colorsBackgroundLightGray)}.app__wrap-2w4:has(.disabledGlobalPadding){padding:60px 0 0 68px}@media(max-width:1260px){.app__wrap-2w4{padding:90px 40px 40px 108px}.app__wrap-2w4:has(.disabledGlobalPadding){padding:60px 0 0 68px}}.app__wrap-2w4[data-loading]{filter:blur(8px)}.app__loader-XUn{background-color:rgba(24,32,51,.6);bottom:0;left:0;position:fixed;right:0;top:0;z-index:9999}::placeholder{color:var(--colorsTextGray);opacity:.5}", ""]);

// exports
exports.locals = {
	"wrap": "app__wrap-2w4",
	"wrap": "app__wrap-2w4",
	"loader": "app__loader-XUn",
	"loader": "app__loader-XUn"
};