import { useRef, useState } from 'react';
import classnames from 'classnames';

import useStyles from 'hooks/useStyles';
import useOnClickOutside from 'components/pages/users/useOnClickOutside';

import InfoMarker from 'components/pages/InfoMarker';

import { menuOpenDirections } from 'components/common/enums';

import style from 'styles/common/multiLevelMenuPopup.css';

import arrowPopupMenuGrey from 'assets/arrowPopupMenuGrey.svg';

const styles = style.locals || {};

export default function MultiLevelMenuPopup({
  onClosePopup,
  menuOptions,
  direction,
}) {
  useStyles([style]);

  return (
    <PopupMenu
      className={styles.menuPopup}
      items={menuOptions}
      onClosePopup={onClosePopup}
      direction={direction}
    />
  );
}

function PopupMenu({
  items, className, onClosePopup, direction,
}) {
  const menuRef = useRef(null);

  useOnClickOutside(menuRef, () => {
    if (onClosePopup) {
      onClosePopup(false);
    }
  });

  return (
    <div
      ref={menuRef}
      className={className}
    >
      {items.map((item, index) => {
        const itemKey = `item-menu-${item.label}-${index}`;
        const itemId = item.label ? `${item.label}-${index}` : index;
        return (
          <MenuItem
            direction={direction}
            item={item}
            key={itemKey}
            itemId={itemId}
          />
        );
      })}
    </div>
  );
}

function MenuItem({ item, itemId, direction }) {
  const [activeSubmenu, setActiveSubmenu] = useState(null);

  function handleMouseEnter({ subMenuId }) {
    setActiveSubmenu(subMenuId);
  }

  function onClickItem({ event, action }) {
    if (action) {
      action();
    }
    event.stopPropagation();
  }

  const {
    label, options, leftIcon, component, action, disabled, tooltip,
  } = item;

  const hasOptions = item.options?.length > 0;
  const hasComponent = !!item.component;
  const hasChildren = (hasComponent || hasOptions) && !disabled;
  const shouldRenderItemAsComponent = !label;

  return (
    <div
      key={itemId}
      onMouseEnter={() => (hasChildren ? handleMouseEnter({ subMenuId: itemId }) : null)}
      onMouseLeave={() => (hasChildren ? setActiveSubmenu(null) : null)}
    >
      {shouldRenderItemAsComponent ? (
        <div className={styles.optionAsComponentContainer}>
          {item}
        </div>
      ) : (
        <div
          className={styles.optionStyle}
          onClick={(event) => onClickItem({ event, action })}
        >
          <div className={classnames(styles.labelContainer, disabled && styles.disabled)}>
            {leftIcon ? (
              <div className={styles.itemIconContainer} data-icon={leftIcon} />
            ) : null}
            {label}
          </div>

          {tooltip ? (
            <InfoMarker
              tooltipText={tooltip}
            />
          ) : null}

          {hasChildren ? (
            <img src={arrowPopupMenuGrey} alt="right arrow" />
          ) : null}

          {hasChildren && activeSubmenu === itemId ? (
            <PopupMenu
              items={options || [component]}
              className={classnames(styles.menuPopup, direction === menuOpenDirections.rtl ? styles.childRtl : styles.child)}
            />
          ) : null}
        </div>
      )}
    </div>
  );
}
