exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".emptyStateGoalsAnalysis__wrapper-3CT{display:flex;flex-direction:column;justify-content:center;align-items:center;gap:16px;border-radius:12px;background:var(--colorsGeneralWhite);box-shadow:0 4px 24px 0 rgba(36,42,64,.06);min-height:120px;flex-shrink:0;padding:80px;margin-top:52px}.emptyStateGoalsAnalysis__title-Ul9{color:#182033;text-align:center;font-size:var(--fontsSizesTitleH3);font-weight:var(--fontsWeightBold)}.emptyStateGoalsAnalysis__subTitle-2nv{color:var(--colorsTextGray);text-align:center;font-size:var(--fontsSizesBodyNormal);font-weight:var(--fontsWeightNormal)}.emptyStateGoalsAnalysis__temporaryKpi-1EL{text-decoration:underline}.emptyStateGoalsAnalysis__kpiNotSelected-2o0:hover{cursor:pointer}", ""]);

// exports
exports.locals = {
	"wrapper": "emptyStateGoalsAnalysis__wrapper-3CT",
	"wrapper": "emptyStateGoalsAnalysis__wrapper-3CT",
	"title": "emptyStateGoalsAnalysis__title-Ul9",
	"title": "emptyStateGoalsAnalysis__title-Ul9",
	"subTitle": "emptyStateGoalsAnalysis__subTitle-2nv",
	"subTitle": "emptyStateGoalsAnalysis__subTitle-2nv",
	"temporaryKpi": "emptyStateGoalsAnalysis__temporaryKpi-1EL",
	"temporaryKpi": "emptyStateGoalsAnalysis__temporaryKpi-1EL",
	"kpiNotSelected": "emptyStateGoalsAnalysis__kpiNotSelected-2o0",
	"kpiNotSelected": "emptyStateGoalsAnalysis__kpiNotSelected-2o0"
};