import { metricsTypes, upliftLabel } from 'components/common/logic/enums';
import { spotlightTypes } from 'components/pages/insights/logic/enums';
import { TIMEFRAME_VALUES } from 'components/utils/timeframe';

export const columnControllersKeys = {
  uplift: 'uplift',
  pacing: 'pacing',
  compare: 'compare',
  shareOfTotal: 'shareOfTotal',
  diffFromAvg: 'diffFromAvg',
};

export const columnControllersLabels = {
  [columnControllersKeys.uplift]: {
    label: upliftLabel,
    shortLabel: upliftLabel,
  },
  [columnControllersKeys.pacing]: {
    label: 'Pacing for',
    shortLabel: 'Pacing for',
  },
  [columnControllersKeys.compare]: {
    label: 'Compare to avg.',
    shortLabel: 'Vs avg.',
  },
  [columnControllersKeys.shareOfTotal]: {
    label: 'Share of Total',
    shortLabel: '% of Total',
  },
  [columnControllersKeys.diffFromAvg]: {
    label: '% Diff from Avg',
    shortLabel: '% Diff from Avg',
  },
};

export const defaultSubColumnsOptionsByType = {
  [metricsTypes.attributed]: [
    columnControllersKeys.shareOfTotal,
    columnControllersKeys.pacing,
    columnControllersKeys.compare,
  ],
  [metricsTypes.touched]: [
    columnControllersKeys.shareOfTotal,
    columnControllersKeys.pacing,
    columnControllersKeys.compare,
  ],
  [metricsTypes.conversionRate]: [
    columnControllersKeys.compare,
    columnControllersKeys.uplift,
  ],
  [metricsTypes.velocity]: [
    columnControllersKeys.compare,
  ],
  [metricsTypes.costPer]: [
    columnControllersKeys.compare,
  ],
  [metricsTypes.revenue]: [
    columnControllersKeys.shareOfTotal,
    columnControllersKeys.pacing,
    columnControllersKeys.compare,
  ],
  [metricsTypes.avgDealSize]: [
    columnControllersKeys.compare,
  ],
  [metricsTypes.ROI]: [
    columnControllersKeys.compare,
  ],
};

export const defaultSelectedSubColumnsByType = {
  [metricsTypes.attributed]: [
    columnControllersKeys.shareOfTotal,
  ],
  [metricsTypes.conversionRate]: [
    columnControllersKeys.uplift,
  ],
};

export const spotlightOptionsFields = [
  { value: spotlightTypes.overPerforming, label: 'Top Performers' },
  { value: spotlightTypes.underPerforming, label: 'Worst Performers' },
  { value: spotlightTypes.mostTrending, label: 'Trending' },
  { value: spotlightTypes.mostDownTrending, label: 'Downtrending' },
];

export const spotlightColumnsKeys = {
  segmentType: 'segmentType',
  segmentValue: 'segmentValue',
  segmentId: 'segmentId',
  value: 'value',
  averageRatio: 'averageRatio',
};

export const spotlightColumnsLabels = {
  [spotlightColumnsKeys.segmentType]: 'Segment Type',
  [spotlightColumnsKeys.segmentValue]: 'Segment Value',
};

export const spotlightDefaultColumns = [spotlightColumnsKeys.segmentType, spotlightColumnsKeys.segmentValue, spotlightColumnsKeys.value];

export const spotlightDefaultSubColumns = {
  [spotlightColumnsKeys.value]: [
    columnControllersKeys.diffFromAvg,
  ],
};

export const spotlightSupportedTimeframes = [
  TIMEFRAME_VALUES.MONTH,
  TIMEFRAME_VALUES.QUARTER,
  TIMEFRAME_VALUES.YEAR,
  TIMEFRAME_VALUES.PREV_MONTH,
  TIMEFRAME_VALUES.PREV_QUARTER,
  TIMEFRAME_VALUES.PREV_YEAR,
];

export const emptyMessageGeneral = 'Try changing the breakdown or timeframe to view results.';
export const emptyMessageTimeframeNotSupported = 'Custom and Rolling timeframes aren’t supported in Spotlight. Change the timeframe to view results.';
export const spotlightTimeframeNotSupportedTooltip = 'This timeframe isn’t supported in Spotlight. Change it to view results.';

export const timeframeErrorTooltip = 'This timeframe isn’t supported in Spotlight. Change it to view results.';
export const filtersErrorTooltip = 'Your filters were removed since Spotlight doesn’t support filters.';
