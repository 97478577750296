import { useMemo } from 'react';
import { widgetTypeToBlurImage } from 'components/pages/analyze/enums';

import useStyles from 'hooks/useStyles';
import useWindowDimensions from 'components/utils/getWindowDimensions';
import { Events } from 'trackers/analytics/enums';

import ErrorIndication from 'components/common/ErrorIndication';

import style from 'styles/common/errorWidgetWithBlur.css';

const styles = style.locals || {};

export default function ErrorWidgetWithBlur({ status, widgetType }) {
  useStyles([style]);

  const { width: screenWidth } = useWindowDimensions();

  const imageSize = useMemo(() => {
    if (screenWidth > 1600) {
      return '-x3';
    }
    if (screenWidth > 1400) {
      return '-x2';
    }
    return '';
  }, [screenWidth]);

  const blurImage = widgetTypeToBlurImage[widgetType];
  const errorEvent = {
    eventName: Events.global.widgetError,
    properties: { widgetType },
  };

  return (
    <div
      data-testid="ErrorWidgetWithBlur"
      className={styles.wrapper}
      style={{
        backgroundImage: `url(/assets/blured-widgets/${blurImage}${imageSize}.svg)`,
        height: 400,
      }}
    >
      <ErrorIndication
        status={status}
        errorEvent={errorEvent}
      />
    </div>
  );
}
