import Component from 'components/Component';
import serverCommunication from 'data/serverCommunication';
import userStore from 'stores/userStore';
import servicesStore from 'stores/servicesStore';

const blobToBase64 = async (blob) => new Promise((resolve) => {
  const reader = new FileReader();
  reader.onload = () => {
    const dataUrl = reader.result;
    const [, base64] = dataUrl.split(',');
    resolve(base64);
  };
  reader.readAsDataURL(blob);
});

class G2PopupContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
    };
  }

  afterAuthStep = () => Promise.resolve({});

  afterDataRetrieved = (data) => new Promise((resolve) => {
    if (data && data.isConnected) {
      userStore.updateIntegrationsConfig({
        newIntegrationsConfig: {
          g2: {
            isConnected: true,
          },
        },
      });
    }

    resolve(false);
  });

  sendG2ApiKey = (apiKey) => new Promise((resolve, reject) => {
    const objectToSend = {
      platform: 'g2',
      code: apiKey,
    };

    serverCommunication.serverRequest(
      'post',
      this.props.apiEndpoint,
      JSON.stringify(objectToSend),
      userStore.userMonthPlan.region
    )
      .then((response) => {
        if (response.ok) {
          userStore.updateIntegrationsConfig({
            newIntegrationsConfig: {
              g2: {
                isConnected: true,
              },
            },
          });

          resolve(false);
        } else if (response.status === 401) {
          window.location.href = '/';
        } else {
          reject(new Error('Error connecting to G2'));
        }
      })
      .catch((error) => {
        servicesStore.logger.error('Error connecting to G2', { error: error.message });
      });
  });

  doneFirstStep = () => () => {
    const { file } = this.state;
    if (file) {
      this.props.onStateChange({
        isEmptyFileToUpload: false,
        fileUploadSuccessfully: false,
        fileUploadError: false,
      });
      this.props.loadingStarted();
      blobToBase64(file)
        .then(this.uploadFile)
        .then(() => {
          this.setState({
            file: null,
          });
          this.props.onStateChange({
            fileUploadSuccessfully: true,
          });
        })
        .catch((error) => {
          servicesStore.logger.error('failed to load G2 CSV', { error });
          this.props.loadingFinished();
          this.props.onStateChange({
            fileUploadError: true,
          });
        })
        .then(this.props.loadingFinished);
    } else {
      this.props.onStateChange({ isEmptyFileToUpload: true });
    }
  };

  updateDataFinal = () => {
    this.props.onStateChange({
      fileUploadSuccessfully: false,
      fileUploadError: false,
    });

    return Promise.resolve(false);
  };

  onFileAdd = ([file]) => {
    this.setState({ file });
    this.props.onStateChange({ isEmptyFileToUpload: false });
  };

  uploadFile = (base64) => serverCommunication.serverRequest(
    'post',
    'g2api/csv',
    JSON.stringify({
      platform: 'g2',
      fileData: base64,
      region: userStore.userMonthPlan.region,
    }),
    userStore.userMonthPlan.region
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else if (response.status === 401) {
        window.location.href = '/';
      }
      throw new Error('Error uploading CSV data');
    });

  deleteFile = () => {
    this.setState({ file: null });
    this.props.onStateChange({ fileUploadError: false });
  };

  render() {
    const { children } = this.props;
    return children({
      state: this.state,
      afterAuthStep: this.afterAuthStep,
      afterDataRetrieved: this.afterDataRetrieved,
      makeServerRequest: this.sendG2ApiKey,
      doneFirstStep: this.doneFirstStep,
      updateDataFinal: this.updateDataFinal,
      onFileAdd: this.onFileAdd,
      deleteFile: this.deleteFile,
    });
  }
}

export default G2PopupContainer;
