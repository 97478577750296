import classnames from 'classnames';

import useStyles from 'hooks/useStyles';

import { getWorkflowSectionByType } from 'components/pages/users/logic/workflowBudgeTooltip';

import style from 'styles/users/workflowTooltip.css';

const styles = style.locals || {};

export default function WorkflowBudgeTooltip({
  workflows = {}, email, companyName,
}) {
  useStyles([style]);

  return (
    <div className={styles.tooltipContainer}>
      <div className={classnames(styles.title, styles.section)}>
        Ops Workflows
      </div>
      {Object.entries(workflows).map(([workflowType, workflowData], index) => {
        const key = `${workflowType}-${email}-${index}`;

        return (
          <div key={key} className={styles.section}>
            {getWorkflowSectionByType({
              type: workflowType,
              email,
              companyName,
              values: workflowData.values,
              titleClassName: styles.sectionTitle,
              bodyClassName: styles.sectionBody,
              highlightClassName: styles.highlight,
            })}
          </div>
        );
      })}
    </div>
  );
}
