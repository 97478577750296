import React from 'react';

import useStyles from 'hooks/useStyles';

import userStore from 'stores/userStore';
import Skeleton from 'components/common/Skeleton';
import EllipsisTooltip from 'components/controls/EllipsisTooltip';
import ComparisonValue from 'components/common/ComparisonValue';

import { getItemLabelWithNoValue } from 'components/utils/logic/utils';
import { formatIndicatorDisplay } from 'components/utils/indicators';
import { getSegmentLabel } from 'components/pages/analyze/SegmentsTab/logic/segments';
import { spotlightColumnsKeys } from 'components/widgets/segmentsDrilldown/enums';
import { customFieldKey } from 'components/common/logic/enums';

import style from 'styles/segmentsDrilldown/segmentsDrilldown.css';

const styles = style.locals || {};

export default function CellByColumnSpotlight({
  value,
  isLoaded,
  columnKey,
  rowData,
  metric,
}) {
  useStyles([style]);

  if (!isLoaded) {
    return (
      <div className={styles.skeletonWrapper}>
        <Skeleton height={22} width="100%" isLightTheme />
      </div>
    );
  }

  if (isLoaded && value === null) {
    return null;
  }

  if (columnKey === spotlightColumnsKeys.averageRatio) {
    return (
      <ComparisonValue
        value={value}
        isGrowthDesired
        isShowTrendArrow={false}
        className={styles.comparisonValue}
      />
    );
  }

  if (columnKey === spotlightColumnsKeys.segmentType || columnKey === spotlightColumnsKeys.segmentValue) {
    let cellLabel = value;
    if (value === customFieldKey) {
      cellLabel = getSegmentLabel({ segment: rowData.segmentId, customFieldsIdToLabelMap: userStore.userAccount?.customFieldsIdToLabelMap, customUtmsWhitelist: userStore.userAccount?.customUtmsWhitelist });
    }

    if (columnKey === spotlightColumnsKeys.segmentValue) {
      const segment = rowData.segmentType;
      cellLabel = getItemLabelWithNoValue({ value, segment, segmentLabel: cellLabel });
    }

    return (
      <div className={styles.breakdownSegmentLabel}>
        <EllipsisTooltip withPortal text={cellLabel} />
      </div>
    );
  }

  const cellValue = formatIndicatorDisplay(metric, value || 0, true, true, true, 2);

  return (
    <div className={styles.cellFlex}>
      {cellValue}
    </div>
  );
}
