import React from 'react';
import { isEmpty } from 'lodash';
import history from 'history';
import { Slide, toast, ToastContainer } from 'react-toastify';
import { injectStyle } from 'react-toastify/dist/inject-style';
import { Button } from '@infinigrow/libs';

import serverCommunication from 'data/serverCommunication';

import Component from 'components/Component';
import Page from 'components/Page';
import NextButton from 'components/pages/profile/NextButton';
import SaveButton from 'components/pages/profile/SaveButton';
import Textfield from 'components/controls/Textfield';
import Label from 'components/ControlsLabel';
import Title from 'components/onboarding/Title';
import ReasonPopup from 'components/ReasonPopup';
import AddMemberPopup from 'components/pages/account/AddMemberPopup';
import Tabs from 'components/onboarding/Tabs';
import Avatar from 'components/Avatar';
import InfiniteScrollTable from 'components/common/InfiniteScrollTable';
import ConfirmPopup from 'components/ConfirmPopup';
import EllipsisTooltip from 'components/controls/EllipsisTooltip';
import servicesStore from 'stores/servicesStore';

import { isPopupMode, temporaryEnablePopupMode } from 'modules/popup-mode';
import { getMemberFullName } from 'components/utils/teamMembers';
import { DEFAULT_AUDIENCE_FIELDS } from 'components/utils/users';
import { DEFAULT_TIME_ZONE } from 'components/utils/timeframe';
import { FORECASTING_METHODS } from 'components/utils/utils';
import { getPermissionsTooltip } from 'components/pages/logic/welcome';
import { roles } from 'enums';

import style from 'styles/onboarding/onboarding.css';
import welcomeStyle from 'styles/welcome/welcome.css';
import PlannedVsActualstyle from 'styles/plan/planned-actual-tab.css';

const MEMBERS_TO_SKIP = 1;

export default class Welcome extends Component {
  style = style;

  styles = [welcomeStyle, PlannedVsActualstyle];

  static defaultProps = {
    userAccount: {
      companyName: '',
      firstName: '',
      lastName: '',
      companyWebsite: 'http://',
      competitorsWebsites: ['http://', 'http://', 'http://'],
      teamMembers: [],
    },
    defaultUpmFields: {
      technologyStack: ['googleSheets', 'moz'],
      pricingTiers: [],
      targetAudience: [{
        fields: {
          ...DEFAULT_AUDIENCE_FIELDS,
        },
        info: {
          weight: 100,
        },
      }],
      userProfile: {},
      blockedChannels: [],
      budgetConstraints: {},
      forecasterConfig: {},
      inHouseChannels: [],
      maxChannels: -1,
      preferences: {
        budgetType: 0,
        defaultAttributionCredit: false,
        defaultAttributionModel: 'default',
        isCheckAnnual: true,
        isDivideEqually: false,
        forecastMethod: FORECASTING_METHODS.COST_PER_FUNNEL,
      },
      fiscalYearFirstMonth: 0,
      currency: 'USD',
      CRMConfig: {
        timezone: DEFAULT_TIME_ZONE,
      },
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      showAddMemberPopup: false,
      validationError: false,
      selectedMember: null,
      isLoadingUpdateUserAccount: false,
      isLoadingCreateNewRegion: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.handleChangeArray = this.handleChangeArray.bind(this);
    this.addMember = this.addMember.bind(this);
    this.removeMember = this.removeMember.bind(this);
    this.onInviteOrUpdatedMember = this.onInviteOrUpdatedMember.bind(this);
    this.renderTabsContent = this.renderTabsContent.bind(this);
    this.onNextButtonClick = this.onNextButtonClick.bind(this);
    this.onCloseReasonPopup = this.onCloseReasonPopup.bind(this);
  }

  componentDidMount() {
    injectStyle();
    if (this.props.location.query.new) {
      const teamMembers = [{
        email: servicesStore.authService.getProfileSync().email,
        firstName: '',
        lastName: '',
        userId: servicesStore.authService.getProfileSync().user_id,
      }];
      this.props.createUserAccount({ teamMembers })
        .then(() => {
        })
        .catch((error) => {
          servicesStore.logger.error('failed creating user account', { error });
        });
    }
  }

  handleChange(parameter, event) {
    const update = { ...this.props.userAccount };
    update[parameter] = event.target.value;
    this.props.updateState({ userAccount: update });
  }

  // eslint-disable-next-line react/sort-comp
  updateSiteStructureIfNeeded = () => {
    if (isEmpty(this.props.siteStructure)) {
      const { companyWebsite } = this.props.userAccount;

      let landingPageURL;
      if (companyWebsite) {
        const websiteWithOutWWW = companyWebsite.replace('www.', '');
        const indexOfProtocol = websiteWithOutWWW.indexOf('://');
        if (indexOfProtocol > -1) {
          const endIndexOfProtocol = indexOfProtocol + 3;
          landingPageURL = `${websiteWithOutWWW.slice(0, endIndexOfProtocol)}lp.${websiteWithOutWWW.slice(endIndexOfProtocol)}`;
        } else {
          landingPageURL = `lp.${websiteWithOutWWW}`;
        }

        this.props.updateUserMonthPlan({
          siteStructure: {
            homepage: `${companyWebsite}/`,
            pricing: `${companyWebsite}/pricing`,
            blog: `${companyWebsite}/blog`,
            caseStudies: `${companyWebsite}/case-studies`,
            contact: `${companyWebsite}/contact`,
            aboutUs: `${companyWebsite}/company`,
            presentations: `${companyWebsite}/presentations`,
            eBooks: `${companyWebsite}/e-books`,
            whitepapers: `${companyWebsite}/whitepapers`,
            videos: `${companyWebsite}/videos`,
            landingPages: landingPageURL,
          },
        });
      }
    }
  };

  handleChangeName(property, index, event) {
    const update = { ...this.props.userAccount };
    update.teamMembers[index][property] = event.target.value;
    this.props.updateState({ userAccount: update });
  }

  handleChangeNumber(parameter, event) {
    let number = parseInt(event.target.value, 10);
    if (Number.isNaN(number)) {
      number = -1;
    }
    const update = { ...this.props.userAccount };
    update[parameter] = number;
    this.props.updateState({ userAccount: update });
  }

  handleChangeSelect(parameter, event) {
    const update = { ...this.props.userAccount };
    update[parameter] = event.value;
    this.props.updateState({ userAccount: update });
  }

  handleChangePhone(index, event) {
    const update = { ...this.props.userAccount };
    update.teamMembers[index].phone = event.target.value;
    this.props.updateState({ userAccount: update });
  }

  handleChangeIsShowGettingStartedPage({ index, isShowGettingStartedPage }) {
    const update = { ...this.props.userAccount };
    update.teamMembers[index].isShowGettingStartedPage = isShowGettingStartedPage;
    this.props.updateState({ userAccount: update });
  }

  handleChangeArray(parameter, index, event) {
    const update = { ...this.props.userAccount };
    update[parameter][index] = event.target.value;
    this.props.updateState({ userAccount: update });
  }

  addMember() {
    const update = { ...this.props.userAccount };
    update.teamMembers.push({
      firstName: '', lastName: '', email: '',
    });
    this.props.updateState({ userAccount: update });
  }

  validate(mainTeamMember) {
    const errorFields = [];

    if (!mainTeamMember?.firstName) {
      errorFields.push('firstName');
    }
    if (!mainTeamMember?.lastName) {
      errorFields.push('lastName');
    }
    if (!this.props.userAccount.companyName) {
      errorFields.push('companyName');
    }
    if (!this.props.userAccount.companyWebsite) {
      errorFields.push('companyWebsite');
    }
    // has errors
    if (errorFields && errorFields.length > 0) {
      // change order so user will be focused on first error
      errorFields.reverse().forEach((field) => this.refs[field].validationError());
      return false;
    } else {
      return true;
    }
  }

  toastMessage = (message, type = 'success') => {
    const options = {
      position: 'bottom-center',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: 'light',
      toastId: message,
      style: { width: '320px', fontSize: '14px' },
    };

    if (type === 'success') {
      toast.success(message, options);
    }
    if (type === 'error') {
      toast.error(message, options);
    }
  };

  removeMember(index) {
    const update = { ...this.props.userAccount };
    const member = update.teamMembers.splice(index + MEMBERS_TO_SKIP, 1);
    this.props.updateState({ userAccount: update });
    serverCommunication.serverRequest('DELETE', 'members', JSON.stringify(member[0]))
      .then((response) => {
        if (response.ok) {
          this.toastMessage('user has been removed successfully!');
        } else {
          this.toastMessage('failed to remove user', 'error');
        }
      })
      .catch((error) => {
        servicesStore.logger.error('failed removing a team member', { error });
      });
    this.setState({ isShowConfirmationPopup: false, indexToDelete: null });
  }

  async onInviteOrUpdatedMember({ member, isNewMember }) {
    await serverCommunication.serverRequest('PUT', 'members', JSON.stringify({
      memberData: member,
      isNewMember,
      admin: {
        name: getMemberFullName(this.props.userAccount.teamMembers[0]),
        company: this.props.userAccount.companyName,
      },
    }))
      .then((response) => {
        if (response.ok) {
          this.toastMessage('User has been invited or updated successfully!');
          this.setState({ showAddMemberPopup: false });
          response.json()
            .then((data) => {
              const { userAccount } = this.props;
              userAccount.teamMembers = data.teamMembers;
              this.props.updateState({ unsaved: false, teamMembers: data.teamMembers, userAccount });
            });
        } else {
          this.toastMessage('Failed to invite or update user', 'error');
        }
      })
      .catch((error) => {
        servicesStore.logger.error('failed inviting a team member', { error });
        this.toastMessage('Error while inviting or updating a user', 'error');
      });
  }

  getUserAccountFields = () => ({
    companyName: this.props.userAccount.companyName,
    teamMembers: this.props.userAccount.teamMembers,
    companyWebsite: this.props.userAccount.companyWebsite,
    competitorsWebsites: this.props.userAccount.competitorsWebsites,
  });

  createNewRegion() {
    this.setState({ isLoadingCreateNewRegion: true });

    return this.props.createUserMonthPlan({
      nickname: 'default',
      ...Welcome.defaultProps.defaultUpmFields,
    })
      .then(() => {
        this.setState({ isLoadingCreateNewRegion: false });
        temporaryEnablePopupMode();
        this.updateSiteStructureIfNeeded();
        history.push('/settings/profile/integrations');
      })
      .catch((error) => {
        this.setState({ isLoadingCreateNewRegion: false });
        servicesStore.logger.error('failed creating new region', { error });
      });
  }

  async onCloseReasonPopup({ reasonForUse }) {
    this.setState({ isLoadingUpdateUserAccount: true, showReasonPopup: false });
    await this.props.updateUserAccount({ reasonForUse });
    this.setState({ isLoadingUpdateUserAccount: false });

    await this.createNewRegion();
  }

  async onNextButtonClick() {
    const currentMember = this.props.userAccount.teamMembers
      .find((member) => member.userId === servicesStore.authService.getProfileSync().user_id);

    if (this.validate(currentMember)) {
      try {
        this.setState({ validationError: false, isLoadingUpdateUserAccount: true });
        await this.props.updateUserAccount(this.getUserAccountFields());
        this.setState({ isLoadingUpdateUserAccount: false });

        if (this.props.region) {
          history.push('/settings/profile/integrations');
        } else if (!this.props.userAccount.reasonForUse) {
          this.setState({ showReasonPopup: true });
        } else {
          this.createNewRegion();
        }
      } catch {
        this.setState({ isLoadingUpdateUserAccount: false });
      }
    } else {
      this.setState({ validationError: true });
    }
  }

  renderTabsContent({ userAccount, companyAccount }) {
    if (isPopupMode()) {
      return (
        <div className={this.classes.cols}>
          <div className={this.classes.colCenter} style={{ maxWidth: '707px' }}>
            {userAccount}
            {companyAccount}
          </div>
        </div>
      );
    } else {
      return (
        <Tabs
          ref="tabs"
          defaultSelected={0}
          defaultTabs={['Company Account', 'User Account']}
        >
          {({ index }) => (
            <div className={this.classes.cols}>
              <div className={this.classes.colCenter} style={{ maxWidth: '1000px' }}>
                {index
                  ? userAccount
                  : companyAccount}
              </div>
            </div>
          )}
        </Tabs>
      );
    }
  }

  render() {
    const tableData = this.props.userAccount.teamMembers.slice(MEMBERS_TO_SKIP);
    const title = isPopupMode() ? 'Welcome! Let\'s get you started' : 'Account';
    const memberIndex = this.props.userAccount.teamMembers.findIndex((member) => member.userId === servicesStore.authService.getProfileSync().user_id);
    const currentMember = this.props.userAccount.teamMembers[memberIndex];

    const userAccount = (
      <div>
        <div className={this.classes.row} data-testid="first-name-field">
          <Label>First Name</Label>
          <Textfield
            value={currentMember && currentMember.firstName}
            onChange={(event) => this.handleChangeName('firstName', memberIndex, event)}
            ref="firstName"
            withValidationError
          />
        </div>
        <div className={this.classes.row} data-testid="last-name-field">
          <Label>Last Name</Label>
          <Textfield
            value={currentMember && currentMember.lastName}
            onChange={(event) => this.handleChangeName('lastName', memberIndex, event)}
            ref="lastName"
            withValidationError
          />
        </div>
        <div className={this.classes.row}>
          <Label>Show Getting Started page</Label>
          <input
            type="checkbox"
            checked={currentMember && currentMember.isShowGettingStartedPage}
            onChange={() => (currentMember ? this.handleChangeIsShowGettingStartedPage({ index: memberIndex, isShowGettingStartedPage: !currentMember.isShowGettingStartedPage }) : null)}
          />
        </div>
        <div className={this.classes.row} data-testid="job-title-field">
          <Label>Job Title</Label>
          <Textfield
            value={currentMember && currentMember.roleData?.jobTitle}
            ref="jobTitle"
            disabled
          />
        </div>
        <div className={this.classes.row}>
          <Label>Phone</Label>
          <Textfield
            value={currentMember && currentMember.phone}
            onChange={(event) => this.handleChangePhone(memberIndex, event)}
            style={{ width: '283px' }}
            withValidationError
          />
        </div>
        <div className={this.classes.row} data-testid="email-field">
          <Label>Email</Label>
          <Textfield value={currentMember && currentMember.email} disabled />
        </div>
        <div className={this.classes.row}>
          <Label>Picture</Label>
          <Avatar member={currentMember} className={welcomeStyle.locals.userPicture} />
        </div>
      </div>
    );
    const companyAccount = (
      <div>
        <div className={this.classes.row} data-testid="company-name-field">
          <Label>Enter your brand/company name</Label>
          <Textfield
            value={this.props.userAccount.companyName}
            onChange={(event) => this.handleChange('companyName', event)}
            ref="companyName"
            withValidationError
          />
        </div>
        <div className={this.classes.row} data-testid="company-website-field">
          <Label>Company Website</Label>
          <Textfield
            value={this.props.userAccount.companyWebsite}
            ref="companyWebsite"
            onChange={(event) => this.handleChange('companyWebsite', event)}
            withValidationError
          />
        </div>
        {!isPopupMode() && (
          <div className={this.classes.row}>
            <Label>Team Members</Label>
            <div className={welcomeStyle.locals.innerBox}>
              <InfiniteScrollTable
                tableHeight="460"
                tableData={tableData}
                tableColumns={[
                  {
                    id: 'firstName',
                    header: 'First Name',
                    cell: 'firstName',
                    minWidth: 90,
                  },
                  {
                    id: 'lastName',
                    header: 'Last Name',
                    cell: 'lastName',
                    minWidth: 90,
                  },
                  {
                    id: 'email',
                    header: 'Email',
                    cell: 'email',
                    minWidth: 190,
                  },
                  {
                    id: 'jobTitle',
                    header: 'Job Title',
                    cell: (member) => (
                      <>
                        <EllipsisTooltip text={member?.roleData?.jobTitle}>
                          {member?.roleData?.jobTitle}
                        </EllipsisTooltip>
                      </>
                    ),
                    minWidth: 80,
                  },
                  {
                    id: 'admin',
                    header: 'Permissions',
                    tooltip: getPermissionsTooltip(),
                    cell: (member) => (
                      member.roleName === roles.admin ? 'Admin' : 'User'
                    ),
                    className: welcomeStyle.locals.center,
                    minWidth: 110,
                  },
                  {
                    id: 'onboarding',
                    header: 'Onboarding',
                    cell: (member) => (
                      member.isShowGettingStartedPage ? 'Yes' : 'No'
                    ),
                    minWidth: 100,
                    className: welcomeStyle.locals.center,
                  },
                  {
                    id: 'edit',
                    header: '',
                    cell: (member) => (
                      <div
                        onClick={() => this.setState({
                          selectedMember: member, showAddMemberPopup: true,
                        })}
                        className={welcomeStyle.locals.editButton}
                      />
                    ),
                    style: { overflow: 'visible' },
                    minWidth: 50,
                  },
                  {
                    id: 'remove',
                    header: '',
                    cell: (_, { index }) => (
                      <>
                        <div
                          className={welcomeStyle.locals.removeButton}
                          data-testid="trashIcon"
                          onClick={() => this.setState({ isShowConfirmationPopup: true, indexToDelete: index })}
                        />
                      </>
                    ),
                    style: { overflow: 'visible' },
                    minWidth: 50,
                  },
                ]}
                isLoaded
              />
            </div>
            <div>
              <div className={welcomeStyle.locals.center}>
                <Button
                  type="primaryBlue"
                  style={{ width: '75px', marginTop: '20px' }}
                  onClick={() => {
                    this.setState({ showAddMemberPopup: true, selectedMember: null });
                  }}
                >
                  +Add
                </Button>
              </div>
            </div>
          </div>
        )}
        <div className={this.classes.row}>
          <Label>Enter your main competitors&apos; website (up to 3)</Label>
          <Textfield
            value={this.props.userAccount.competitorsWebsites[0]}
            style={{ marginBottom: '16px' }}
            onChange={(event) => this.handleChangeArray('competitorsWebsites', 0, event)}
            withValidationError
          />
          <Textfield
            value={this.props.userAccount.competitorsWebsites[1]}
            style={{ marginBottom: '16px' }}
            onChange={(event) => this.handleChangeArray('competitorsWebsites', 1, event)}
            withValidationError
          />
          <Textfield
            value={this.props.userAccount.competitorsWebsites[2]}
            style={{ marginBottom: '16px' }}
            onChange={(event) => this.handleChangeArray('competitorsWebsites', 2, event)}
            withValidationError
          />
        </div>
        {this.state.isShowConfirmationPopup ? (
          <ConfirmPopup
            callback={(isCanceled) => (isCanceled ? this.setState({ isShowConfirmationPopup: false, indexToDelete: null }) : this.removeMember(this.state.indexToDelete))}
            confirmBtn="Delete"
            isTitleBold
          />
        ) : null}
      </div>
    );

    let pageClass = null;
    if (!isPopupMode()) {
      pageClass = this.classes.static;
    }

    return (
      <div>
        <ToastContainer
          style={{ width: '400px', fontSize: '12px' }}
          position="bottom-center"
          autoClose={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          transition={Slide}
        />
        <Page popup={isPopupMode()} className={pageClass} contentClassName={this.classes.content} innerClassName={this.classes.pageInner} width="100%">
          <Title
            title={title}
            subTitle="InfiniGrow is looking to better understand who you are so that it can adjust its recommendations to fit you"
          />
          {this.renderTabsContent({ userAccount, companyAccount })}
          <div style={{ height: '30px' }} />

          {isPopupMode()
            ? (
              <div className={this.classes.footerCols}>
                <div className={this.classes.footerLeft} />
                <div className={this.classes.footerRight} data-testid="next-button">
                  <div style={{ width: '30px' }} />
                  <div className={this.classes.almostFooter} data-testid="fill-required-fields-label">
                    <label hidden={!this.state.validationError} style={{ color: 'red' }}>
                      Please fill all the required
                      fields
                    </label>
                  </div>
                  <NextButton
                    loading={this.state.isLoadingCreateNewRegion || this.state.isLoadingUpdateUserAccount}
                    onClick={this.onNextButtonClick}
                  />
                </div>
              </div>
            )
            : (
              <div className={this.classes.footer}>
                <SaveButton
                  onClick={() => {
                    this.setState({ saveFail: false, saveSuccess: false });
                    this.props.updateUserAccount(this.getUserAccountFields());
                    this.updateSiteStructureIfNeeded();
                    this.setState({ saveSuccess: true });
                  }}
                  success={this.state.saveSuccess}
                  fail={this.state.saveFail}
                />
              </div>
            )}
        </Page>
        <ReasonPopup
          hidden={!this.state.showReasonPopup}
          userAccount={this.props.userAccount}
          closeAndUpdate={this.onCloseReasonPopup}
        />
        {this.state.showAddMemberPopup ? (
          <AddMemberPopup
            close={() => {
              this.setState({
                showAddMemberPopup: false, selectedMember: null,
              });
            }}
            onInviteOrUpdatedMember={this.onInviteOrUpdatedMember}
            teamMembers={this.props.userAccount.teamMembers}
            selectedMember={this.state.selectedMember}
            isSSO={this.props.isSSO}
          />
        ) : null}

      </div>
    );
  }
}
