import { useEffect, useMemo, useState } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { inject, observer } from 'mobx-react';
import history from 'history';

import OptimalJourney from 'components/widgets/optimalJourney/OptimalJourney';
import timeFrameModule from 'modules/timeframe';

import { disabledGlobalPadding } from 'components/classNamesEnums';
import { defaultSelectedBlocks, optimalJourneyDefaultTimeframe } from 'components/widgets/optimalJourney/enums';
import { widgetTypes } from 'components/pages/analyze/enums';
import { getWidgetFullConfig, getWidgetsDataV2 } from 'components/pages/analyze/widgetsRequest';
import { isSegmentBlock } from 'components/widgets/optimalJourney/logic/optimalJourney';
import { firstJourneySection } from 'components/widgets/optimalJourney/journeyCanvas/enums';
import {
  parseOverviewData,
  parseAccountPerformancesData,
  parseSegmentsAnalysisData,
} from 'components/pages/analyze/optimalJourneyTab/logic/optimalJourneysTab';
import { makeFunnelStagesFilter, VARIANTS } from 'components/utils/filters/make';
import {
  accountPerformanceMockData,
  segmentsAnalysisMockData,
} from 'components/pages/analyze/optimalJourneyTab/demoMockData';
import { getTSForTimezone } from 'stores/analyze/timeUtils';

function OptimalJourneyTab({
  flags,
  optimalJourneyStages,
  optimalJourneyKpi,
  segmentsAnalysis,
  getWidgetRequestId,
  filters,
  attributionModel,
  autoFilter,
  accountPerformance,
  optimalJourneyTimeframe,
  fiscalYearFirstMonth,
  region,
  timeFrame,
  setHasOptimalJourneyTimeframeWarning,
  isAccountMode,
}) {
  if (!flags.optimalJourneysAnalyzeTab) {
    history.push('/analyze/overview');
    return null;
  }

  const [selectedBlocks, setSelectedBlocks] = useState(defaultSelectedBlocks);
  const [isLoaded, setIsLoaded] = useState({});

  const timeframeConfig = useMemo(() => {
    const timeframeParams = timeFrameModule.getTimeframeParams({ ...optimalJourneyTimeframe, fiscalYearFirstMonth });

    return {
      timeFrame: {
        startDate: new Date(getTSForTimezone(timeframeParams.startDate)),
        endDate: new Date(getTSForTimezone(timeframeParams.endDate)),
      },
      previousTimeFrame: {
        startDate: new Date(getTSForTimezone(timeframeParams.previousStartDate)),
        endDate: new Date(getTSForTimezone(timeframeParams.previousEndDate)),
      },
    };
  }, [optimalJourneyTimeframe]);

  useEffect(() => {
    setHasOptimalJourneyTimeframeWarning(false);
  }, [optimalJourneyTimeframe.rollingValue]);

  useEffect(() => {
    const isAnalyzeTimeframeDifferentFromDefault = timeFrame.rollingValue !== optimalJourneyDefaultTimeframe.rollingValue;
    const isJourneyTimeframeDifferentFromDefault = optimalJourneyTimeframe.rollingValue === optimalJourneyDefaultTimeframe.rollingValue;

    if (isAnalyzeTimeframeDifferentFromDefault && isJourneyTimeframeDifferentFromDefault) {
      setHasOptimalJourneyTimeframeWarning(true);
    }
  }, []);

  useEffect(() => {
    requestSegmentsAnalysisData({ segments: selectedBlocks });
  }, [optimalJourneyTimeframe, filters, attributionModel, autoFilter, region, isAccountMode]);

  useEffect(() => {
    requestAccountPerformancesData();
  }, [optimalJourneyTimeframe, filters, attributionModel, autoFilter, region, isAccountMode, optimalJourneyStages[optimalJourneyStages.length - 1]]);

  function requestSegmentsAnalysisData({ segments }) {
    const segmentsBlocks = segments.filter((blockItem) => isSegmentBlock({ blockItem }));
    for (const segment of segmentsBlocks) {
      getWidgetsDataV2({
        widget: widgetTypes.segmentsAnalysis,
        widgetConfig: {
          ...timeframeConfig,
          segmentsAnalysisParams: { firstSegment: segment },
        },
        isPreviousTimeFrame: true,
      });
    }
  }

  function requestAccountPerformancesData() {
    const widgetFilter = makeFunnelStagesFilter(
      VARIANTS.BECAME_ONE_OF,
      [optimalJourneyStages[optimalJourneyStages.length - 1].value]
    );
    getWidgetsDataV2({
      widget: widgetTypes.accountPerformance,
      widgetConfig: {
        ...timeframeConfig,
        filters: [...filters, widgetFilter],
      },
      isPreviousTimeFrame: true,
    });
  }

  function onChangeSelectedBlocks({ newBlocks }) {
    setSelectedBlocks(newBlocks);
    requestSegmentsAnalysisData({ segments: newBlocks });
  }

  function getAccountPerformanceData() {
    const widgetFilter = makeFunnelStagesFilter(
      VARIANTS.BECAME_ONE_OF,
      [optimalJourneyStages[optimalJourneyStages.length - 1].value]
    );
    const widgetConfig = getWidgetFullConfig({
      widgetConfig: {
        ...timeframeConfig,
        filters: [...filters, widgetFilter],
      },
      isPreviousTimeFrame: true,
    });

    const requestId = getWidgetRequestId({ widget: widgetTypes.accountPerformance, widgetConfig });
    const isLoadedAccountPerformance = accountPerformance?.[requestId]?.status === 'finished' || accountPerformance?.[requestId]?.status === 'failed';
    setIsLoaded((prev) => ({ ...prev, accountPerformance: isLoadedAccountPerformance }));

    return accountPerformance?.[requestId]?.result ?? {};
  }

  const overviewData = useMemo(() => {
    let accountPerformanceData;
    if (flags.optimalJourneysDemoOnly) {
      setIsLoaded((prev) => ({ ...prev, accountPerformance: true }));
      accountPerformanceData = accountPerformanceMockData;
    } else {
      accountPerformanceData = getAccountPerformanceData();
    }

    return parseOverviewData({ data: accountPerformanceData, lastSelectedStageValue: optimalJourneyStages[optimalJourneyStages.length - 1].value });
  }, [optimalJourneyTimeframe, filters, attributionModel, autoFilter, isAccountMode, accountPerformance, optimalJourneyStages[optimalJourneyStages.length - 1]]);

  const accountPerformancesData = useMemo(() => {
    if (flags.optimalJourneysDemoOnly) {
      setIsLoaded((prev) => ({ ...prev, accountPerformance: true }));
      return accountPerformanceMockData;
    }

    return getAccountPerformanceData();
  }, [optimalJourneyTimeframe, filters, attributionModel, autoFilter, isAccountMode, accountPerformance]);

  const optimalJourneyData = useMemo(() => {
    const stagesWithFirstStage = [firstJourneySection, ...optimalJourneyStages];
    const segmentsBlocks = selectedBlocks.filter((blockItem) => isSegmentBlock({ blockItem }));
    let segmentsDataByStages = {};

    for (const segment of segmentsBlocks) {
      let segmentData;
      if (flags.optimalJourneysDemoOnly && segmentsAnalysisMockData[segment]) {
        setIsLoaded((prev) => ({ ...prev, [segment]: true }));
        segmentData = segmentsAnalysisMockData[segment];
      } else {
        const widgetConfig = getWidgetFullConfig({
          widgetConfig: {
            ...timeframeConfig,
            segmentsAnalysisParams: { firstSegment: segment },
          },
          isPreviousTimeFrame: true,
        });
        const requestId = getWidgetRequestId({ widget: widgetTypes.segmentsAnalysis, widgetConfig });
        const isLoadedSegment = segmentsAnalysis?.[requestId]?.status === 'finished' || segmentsAnalysis?.[requestId]?.status === 'failed';
        setIsLoaded((prev) => ({ ...prev, [segment]: isLoadedSegment }));

        segmentData = segmentsAnalysis?.[requestId]?.result?.dataByFunnel ?? {};
      }

      segmentsDataByStages = parseSegmentsAnalysisData({
        segment,
        segmentData,
        stages: stagesWithFirstStage,
        kpiFocus: optimalJourneyKpi,
        dataByStages: segmentsDataByStages,
        filters,
      });
    }

    segmentsDataByStages = parseAccountPerformancesData({ performancesData: accountPerformancesData, stages: stagesWithFirstStage, dataByStages: segmentsDataByStages });

    return segmentsDataByStages;
  }, [optimalJourneyTimeframe, filters, attributionModel, autoFilter, isAccountMode, segmentsAnalysis, optimalJourneyKpi, optimalJourneyStages, selectedBlocks, accountPerformancesData]);

  return (
    <div className={disabledGlobalPadding}>
      <OptimalJourney
        optimalJourneyKpi={optimalJourneyKpi}
        optimalJourneyStages={optimalJourneyStages}
        selectedBlocks={selectedBlocks}
        onChangeSelectedBlocks={({ newBlocks }) => onChangeSelectedBlocks({ newBlocks })}
        isLoaded={isLoaded}
        data={optimalJourneyData}
        overviewData={overviewData}
      />
    </div>
  );
}

export default withLDConsumer()(inject(
  ({
    userStore: {
      userMonthPlan: {
        fiscalYearFirstMonth,
        region,
      },
    },
    attributionStore: {
      optimalJourneyStages,
      optimalJourneyKpi,
      filters,
      timeFrame,
      attributionModel,
      autoFilter,
      optimalJourneyTimeframe,
      setHasOptimalJourneyTimeframeWarning,
      isAccountMode,
    },
    widgetsAnalysisStore: {
      getWidgetRequestId,
      dataPerWidget: {
        [widgetTypes.segmentsAnalysis]: segmentsAnalysis,
        [widgetTypes.accountPerformance]: accountPerformance,
      },
    },
  }) => ({
    optimalJourneyStages,
    optimalJourneyKpi,
    segmentsAnalysis,
    filters,
    attributionModel,
    autoFilter,
    timeFrame,
    getWidgetRequestId,
    accountPerformance,
    optimalJourneyTimeframe,
    fiscalYearFirstMonth,
    region,
    setHasOptimalJourneyTimeframeWarning,
    isAccountMode,
  }),
  observer
)(OptimalJourneyTab));
