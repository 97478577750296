exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".trend-mark__center-1QX{display:flex;justify-content:center;flex-direction:row;align-items:center;margin-left:13px}.trend-mark__history-arrow-1A6{content:\"\";width:0;height:0;border-style:solid;border-width:0 6px 9px;border-color:transparent transparent #24b10e;align-self:center}.trend-mark__history-arrow-1A6[data-decline]{transform:rotate(180deg)}.trend-mark__history-arrow-1A6[data-negative]{border-color:transparent transparent red}.trend-mark__history-grow-2fl{font-size:13px;font-weight:var(--fontsWeightMedium);color:#24b10e;margin-left:2px;align-self:center}.trend-mark__history-grow--big-1Gd{font-size:var(--fontsSizesBodyLarge);font-weight:var(--fontsWeightBold)}.trend-mark__history-grow-2fl[data-negative]{color:red}", ""]);

// exports
exports.locals = {
	"center": "trend-mark__center-1QX",
	"center": "trend-mark__center-1QX",
	"history-arrow": "trend-mark__history-arrow-1A6",
	"historyArrow": "trend-mark__history-arrow-1A6",
	"history-grow": "trend-mark__history-grow-2fl",
	"historyGrow": "trend-mark__history-grow-2fl",
	"history-grow--big": "trend-mark__history-grow--big-1Gd",
	"historyGrowBig": "trend-mark__history-grow--big-1Gd"
};