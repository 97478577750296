export const roleDataPopupTexts = {
  warningMessage: 'Action Required',
  title: 'Fill-in your Roles & Responsibilities',
  jobTitleTextFieldLabel: 'What is your Job Title?',
  jobTitleTextFieldPlaceHolder: 'CMO / Marketing Ops Manager...',
  teamTextFieldLabel: 'What team are you on?',
  teamFieldPlaceHolder: 'Demand Generation / Marketing Ops...',
  additionalCommentsTextFieldLabel: 'Additional comments on your primary objective',
  objectiveDropDownLabel: 'What is your role\'s primary objective?',
  errorMessage: 'Something went wrong! Please try again',
};
export const objectiveDropDownOptions = [
  { value: 'pipeline', label: 'Drive pipeline' },
  { value: 'topOfTheFunnelTraffic', label: 'Drive top-of-funnel traffic / leads' },
  { value: 'selfServeConversion', label: 'Drive self-serve conversions' },
  { value: 'analyzeMarketingPerformance', label: 'Analyze marketing performance' },
  { value: 'abmEfforts', label: 'ABM efforts' },
  { value: 'convertOpportunities', label: 'Convert opportunities to Closed Wons' },
  { value: 'other', label: 'Other' },
];
export const roleDataKeys = {
  jobTitle: 'jobTitle',
  team: 'team',
  objective: 'objective',
  additionalComments: 'additionalComments',
};
export const requiredFieldsInRoleData = [roleDataKeys.jobTitle, roleDataKeys.team, roleDataKeys.objective];
