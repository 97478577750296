import { decorate, observable, action } from 'mobx';
import { cloneDeep, isEqual } from 'lodash';
import { persist } from 'mobx-persist';

import hydrate from 'stores/hydrate';
import InteractionTracker from 'trackers/interactions/InteractionTracker';

class InteractionsStore {
  constructor() {
    this.widgetsAIData = {
      current: null,
      prev: null,
    };
  }

  createInteractionTracker() {
    this.interactionTracker = new InteractionTracker();
  }

  updateWidgetData({ widgetId, currentData }) {
    if (!this.widgetsAIData[widgetId]) {
      this.widgetsAIData[widgetId] = {
        current: currentData,
        prev: null,
      };

      return;
    }

    this.widgetsAIData[widgetId].prev = cloneDeep(this.widgetsAIData[widgetId].current);
    this.widgetsAIData[widgetId].current = currentData;
  }

  isWidgetDataChanged({ widgetId }) {
    const relevantWidget = this.widgetsAIData[widgetId];

    return !isEqual(relevantWidget.prev, relevantWidget.current);
  }
}

decorate(InteractionsStore, {
  widgetsAIData: observable.ref,
  interactionTracker: observable,
  createInteractionTracker: action.bound,
  updateWidgetData: action.bound,
  isWidgetDataChanged: action.bound,
});

const schema = {
  widgetsAIData: { type: 'object' },
};

const store = persist(schema)(new InteractionsStore());

hydrate('interactionsStore', store);

export default store;
