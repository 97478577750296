import React from 'react';
import history from 'history';
import { v4 as uuidv4 } from 'uuid';

import config from 'components/utils/Configuration';
import Component from 'components/Component';
import SignInForm from 'components/pages/signIn/SignInForm';
import Spinner from 'components/pages/journeys/Spinner';
import serverCommunication from 'data/serverCommunication';
import servicesStore from 'stores/servicesStore';
import { localStorageKeys } from 'enums';

import style from 'styles/signin/login.css';

export default class Login extends Component {
  style = style;

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      error: undefined,
      isLoading: false,
    };
  }

  inputValueChanged = (key, value) => {
    const state = { ...this.state };
    state[key] = value;

    if (key === 'email') {
      state.passwordInputShouldBeDisplayed = false;
      state.error = undefined;
      state.SSO = {};
    }

    this.setState(state);
  };

  async getSSOConfig() {
    const response = await serverCommunication.serverRequestWithoutProfile('POST', 'members/SSO', JSON.stringify({ email: this.state.email }));

    if (response.ok) {
      const result = await response.json();

      return result.SSO || {};
    }

    return {};
  }

  getInputs() {
    const inputs = [{
      label: 'Work email',
      key: 'email',
      placeHolder: 'Email',
      type: 'email',
      value: this.state.email,
    }];

    if (this.state.passwordInputShouldBeDisplayed) {
      inputs.push({
        label: 'Password',
        key: 'password',
        placeHolder: 'Password',
        type: 'password',
        value: this.state.password,
      });
    }

    return inputs;
  }

  render() {
    let buttonText = 'Continue';
    let buttonAction = async () => {
      this.setState({
        isLoading: true,
      });

      const SSO = await this.getSSOConfig();
      if (!SSO.active) {
        this.setState({
          passwordInputShouldBeDisplayed: true,
          isLoading: false,
        });
      } else {
        servicesStore.authService.SSOLogin({ connectionType: SSO.connection });
      }
    };

    let bottomComponent;

    if (this.state.passwordInputShouldBeDisplayed) {
      buttonText = 'Sign in';
      buttonAction = () => {
        if (this.state.isLoading) {
          return;
        }

        this.setState({
          error: undefined,
          isLoading: true,
        });

        localStorage.setItem(localStorageKeys.sessionData, JSON.stringify({ sessionId: uuidv4(), sessionStartTime: new Date().getTime() }));
        servicesStore.authService.login({
          email: this.state.email,
          password: this.state.password,
          connectionType: config.authConnectionType,
          callback: (error) => {
            if (error) {
              this.setState({ error: error.description });
            }

            this.setState({ isLoading: false });
          },
        });
      };

      bottomComponent = (
        <div onClick={() => history.push('/forgotPassword')}>
          Forgot your password? Send yourself a new one.
        </div>
      );
    }

    return (
      <div>
        <SignInForm
          title="Welcome back!"
          buttonAction={buttonAction}
          buttonText={buttonText}
          buttonDisabled={false}
          inputValueChanged={this.inputValueChanged}
          inputs={this.getInputs()}
          bottomComponent={bottomComponent}
          error={this.state.error}
        />
        { this.state.isLoading && (
          <div className={this.classes.spinner}>
            <Spinner />
          </div>
        )}
      </div>
    );
  }
}
