import ColorMix from 'colormix/src';
import isNaN from 'lodash/isNaN';
import Gradient from 'javascript-color-gradient';

export const COLORS = [
  '#4D91FC',
  '#00D1FF',
  '#FF932F',
  '#4CAF50',
  '#ED86FF',
  '#FFC107',
  '#009688',
  '#9370DB',
  '#546EF3',
  '#FF4F52',
  '#8BC34A',
  '#FF7F50',
  '#FF00FF',
  '#40E0D0',
  '#FF1493',
  '#FF6700',
  '#59DB95',
  '#707EA7',
  '#B14EFF',
  '#00B4D8',
  '#F08080',
  '#CDDC39',
  '#03A9F4',
  '#BA55D3',
  '#DC143C',
  '#00FF9F',
  '#FFBF65',
  '#FF5722',
  '#835BFE',
  '#E91E63',
  '#8A2BE2',
  '#3F51B5',
  '#673AB7',
  '#C05780',
  '#FFEB3B',
  '#39FF14',
];

const BLUE = '#4D91FC';
const LIGHT_BLUE = '#D2E0FF';
const DARK_BLUE = '#083C8F';

export function getMixOfColors({ length, topColor = BLUE, bottomColor = DARK_BLUE }) {
  const colorGradient = new Gradient();
  colorGradient.setGradient(topColor, bottomColor);
  colorGradient.setMidpoint(length);
  return colorGradient.getArray();
}

export function getBlueColor() {
  return BLUE;
}

export function getLightBlueColor() {
  return LIGHT_BLUE;
}

export function getColor(index = 0) {
  return COLORS[index % COLORS.length];
}

export function hexToRgb(hex) {
  return hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`)
    .substring(1).match(/.{2}/g)
    .map((x) => parseInt(x, 16));
}

export const GOAL_RED = new ColorMix.Color(255, 69, 0);
export const GOAL_GREEN = new ColorMix.Color(60, 202, 63);

export const mixColors = (percentRate) => {
  let resulting = GOAL_GREEN;
  if (!isNaN(percentRate) && percentRate >= 0 && percentRate <= 100) {
    try {
      resulting = ColorMix.mix([GOAL_GREEN, GOAL_RED], [percentRate, 100 - percentRate]);
    } catch (e) {
      console.log('Error mixing colors: ', e);
    }
  }
  const { red, green, blue } = resulting;
  return `rgb(${red}, ${green}, ${blue})`;
};
