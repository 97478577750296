exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".timeframeDropdown__wrapper-1qP{border:1px solid rgba(178,187,213,.5);box-shadow:0 0 5px 0 rgb(0 0 0/10%);position:absolute;top:55px;right:0;left:auto;background:#fff;width:550px;border-radius:4px;color:var(--colorsGeneralBlack)}.timeframeDropdown__rangeCalendarContainter-2h4{padding:24px;display:flex;gap:10px;flex-direction:column;position:relative}.timeframeDropdown__rangeCalendarContainter-2h4 .timeframeDropdown__rangeCalendar-nzU{box-shadow:none;border:none;top:0}.timeframeDropdown__rangeCalendar-nzU footer{padding:24px 0 12px}.timeframeDropdown__tooltipIcon-uTI{margin-left:6px}.timeframeDropdown__compareToPrevWrapper-dF-{display:flex;align-items:center}.timeframeDropdown__filterDatePickerContainer-XUC{position:relative}", ""]);

// exports
exports.locals = {
	"wrapper": "timeframeDropdown__wrapper-1qP",
	"wrapper": "timeframeDropdown__wrapper-1qP",
	"rangeCalendarContainter": "timeframeDropdown__rangeCalendarContainter-2h4",
	"rangeCalendarContainter": "timeframeDropdown__rangeCalendarContainter-2h4",
	"rangeCalendar": "timeframeDropdown__rangeCalendar-nzU",
	"rangeCalendar": "timeframeDropdown__rangeCalendar-nzU",
	"tooltipIcon": "timeframeDropdown__tooltipIcon-uTI",
	"tooltipIcon": "timeframeDropdown__tooltipIcon-uTI",
	"compareToPrevWrapper": "timeframeDropdown__compareToPrevWrapper-dF-",
	"compareToPrevWrapper": "timeframeDropdown__compareToPrevWrapper-dF-",
	"filterDatePickerContainer": "timeframeDropdown__filterDatePickerContainer-XUC",
	"filterDatePickerContainer": "timeframeDropdown__filterDatePickerContainer-XUC"
};