exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".paging__title-box-jjI{display:flex;align-items:center;justify-content:center;width:100%}.paging__arrow-3PS{position:relative;width:40px;height:40px;border-radius:3px;box-shadow:0 1px 2px 0 #e6e8f0;border:1px solid rgba(178,187,213,.5);background-image:linear-gradient(0deg,#fafafc,var(--colorsGeneralWhite))}.paging__arrow-3PS:focus{outline:none}.paging__arrow-left-2fT{transform:rotateY(180deg)}.paging__arrow-left-2fT,.paging__arrow-right-2dn{position:absolute;top:0;left:0;width:100%;height:100%;background:url(/assets/plan_icons/paging-arrow.svg) 50% no-repeat}.paging__title-text-2gG{padding:0 24px;text-align:center;flex-grow:0;flex-shrink:0;color:#202b45;font-size:var(--fontsSizesTitleH3);font-weight:var(--fontsWeightMedium);line-height:1.33}", ""]);

// exports
exports.locals = {
	"title-box": "paging__title-box-jjI",
	"titleBox": "paging__title-box-jjI",
	"arrow": "paging__arrow-3PS",
	"arrow": "paging__arrow-3PS",
	"arrow-left": "paging__arrow-left-2fT",
	"arrowLeft": "paging__arrow-left-2fT",
	"arrow-right": "paging__arrow-right-2dn",
	"arrowRight": "paging__arrow-right-2dn",
	"title-text": "paging__title-text-2gG",
	"titleText": "paging__title-text-2gG"
};