import React from 'react';
import { get, startCase } from 'lodash';

import useStyles from 'hooks/useStyles';

import userStore from 'stores/userStore';
import servicesStore from 'stores/servicesStore';

import { Events } from 'trackers/analytics/enums';
import { VARIANTS_CONFIGS } from 'components/utils/filters';
import { mappedGroupBy, mappedCRMRules, funnelReportSyncMessages } from 'components/widgets/funnelAnalysis/enums';

import style from 'styles/funnelAnalysis/funnelAnalysis.css';

const styles = style.locals || {};

export default function FunnelStageTooltipContent({
  funnelStageConfiguration = {},
}) {
  useStyles([style]);

  const {
    reportSyncData = {},
    groupByType,
    mappingRules,
    predefinedFilters,
    currentFunnelStage,
  } = funnelStageConfiguration;

  function onClickReportSyncLink({ event }) {
    event.stopPropagation();
    window.open(reportSyncData.reportUrl, '_blank');
    servicesStore.eventTracker.track({
      eventName: Events.funnelAnalysis.clickedOnFunnelStagesReportLink,
      properties: {
        funnelStage: currentFunnelStage,
      },
    });
  }

  const funnelStageName = userStore.getMetricNickname({ metric: currentFunnelStage });
  const isReportSynced = reportSyncData?.isSynced;
  const hasPredefinedFilters = predefinedFilters?.filters?.length;
  const hasMappingRules = mappingRules?.length;

  return (
    <div className={styles.funnelStageTooltip}>
      <div className={styles.tooltipHeader}>
        {funnelStageName}
      </div>

      {isReportSynced ? (
        <>
          <hr />
          <div className={styles.tooltipReportSync}>
            <div className={styles.checkedIcon} />
            <div>
              <b>{funnelReportSyncMessages.success}</b>
              <br />
              <div
                className={styles.reportSyncLink}
                onClick={(event) => onClickReportSyncLink({ event })}
              >
                Report link
              </div>
            </div>
          </div>
        </>
      ) : null}

      <hr />
      <div>
        <b>Group by: </b>
        {startCase(mappedGroupBy[groupByType])}
      </div>

      {hasMappingRules ? (
        <>
          <hr />
          <div className={styles.tooltipMappingRules}>
            <b>
              {'Count one new '}
              {userStore.getMetricNickname({ metric: currentFunnelStage, isSingular: true })}
              {' when:'}
            </b>
            {mappingRules.map((rule, index) => {
              const rulesValues = rule.values.map((value) => mappedCRMRules[value] || value);
              const mappingKey = `${rule.objectType}-${rule.field}-${rule.values.join('-')}-${index}`;

              return (
                <div key={mappingKey}>
                  {'Field '}
                  <i>{rule.field}</i>
                  {rule.objectType ? (
                    <>
                      {' from object '}
                      <i className={styles.purple}>{rule.objectType}</i>
                    </>
                  ) : null}
                  {' changes to'}
                  {rulesValues.map((value) => (
                    <i key={value} className={styles.gray}>{value}</i>
                  ))}
                  <br />
                  {rule.dateField ? (
                    <li>
                      {'Set the conversion timestamp to: '}
                      <i>{rule.dateField}</i>
                    </li>
                  ) : null}
                </div>
              );
            })}
          </div>
        </>
      ) : null}

      {hasPredefinedFilters ? (
        <>
          <hr />
          <div className={styles.tooltipFiltersWrapper}>
            <b>Apply pre-defined filters: </b>
            {predefinedFilters?.logicExpression ? (
              <div className={styles.tooltipLogicExpression}>
                {predefinedFilters?.logicExpression}
              </div>
            ) : null}

            {predefinedFilters.filters.map((filter, index) => {
              const { data: { variant, fieldId, selectedOptions = [] } } = filter;
              const fieldName = userStore.userAccount?.customFieldsIdToLabelMap[fieldId];
              const variantLabel = get(VARIANTS_CONFIGS, [variant, 'label'], '');
              const filterKey = `${variant}-${fieldId}-${selectedOptions.join('-')}`;
              const hasMultiFields = predefinedFilters.filters.length > 1;
              return (
                <div
                  data-testid="pre-defined-filters"
                  key={filterKey}
                  className={styles.tooltipPredefinedFilters}
                >
                  {hasMultiFields
                    ? <b>{index + 1}</b>
                    : null}
                  <div>
                    {variantLabel}
                    {' '}
                    {fieldName ? (
                      <i>{fieldName}</i>
                    ) : null}
                    {' : '}
                    {selectedOptions.map((value) => (
                      <i key={value} className={styles.gray}>{value}</i>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        </>
      ) : null}
    </div>
  );
}
