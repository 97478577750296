exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "@keyframes state-selection__fadeIn-2wP{0%{opacity:0}to{opacity:1}}.state-selection__state-selection-box-tap{width:fit-content;height:88px;border-radius:112px;display:inline-flex;box-shadow:0 4px 4px 0 rgba(0,0,0,.06);background-color:var(--colorsGeneralWhite);border:1.5px solid hsla(0,0%,71%,.3);z-index:5;animation:state-selection__fadeIn-2wP .4s;position:absolute;bottom:-100px;left:-50px}.state-selection__tooltip-1hn{pointer-events:auto!important;background-color:transparent!important;opacity:1!important;padding:0!important}.state-selection__tooltip-1hn:after{content:none!important}.state-selection__reaction-icon-2Tw{width:60px;height:60px;border-radius:20px;text-align:center;cursor:pointer;transition:transform .2s cubic-bezier(.23,1,.32,1);background-position:50%;background-repeat:no-repeat;background-size:60px;margin:12px 9px;position:relative}.state-selection__state-selection-wrap-3rw{position:relative}.state-selection__reaction-icon-2Tw:hover{transform:scale(1.5);transform-origin:bottom}.state-selection__reaction-label-UzY{padding:3px 5px;position:absolute;top:-24px;border-radius:10px;font-size:11px;color:var(--colorsGeneralWhite);background:#333;visibility:hidden;white-space:nowrap;transform:translateX(-50%)}.state-selection__reaction-icon-2Tw:hover .state-selection__reaction-label-UzY{visibility:visible}.state-selection__button-icon-3PR{background:none;border:none;cursor:pointer;display:inline-block;outline:none;padding:0;font-size:0}.state-selection__button-icon--none-1n-{opacity:0}.state-selection__icon-2cg{background-position:50%;background-repeat:no-repeat;background-size:contain;display:inline-block;height:18px;width:18px}", ""]);

// exports
exports.locals = {
	"state-selection-box": "state-selection__state-selection-box-tap",
	"stateSelectionBox": "state-selection__state-selection-box-tap",
	"fadeIn": "state-selection__fadeIn-2wP",
	"fadeIn": "state-selection__fadeIn-2wP",
	"tooltip": "state-selection__tooltip-1hn",
	"tooltip": "state-selection__tooltip-1hn",
	"reaction-icon": "state-selection__reaction-icon-2Tw",
	"reactionIcon": "state-selection__reaction-icon-2Tw",
	"state-selection-wrap": "state-selection__state-selection-wrap-3rw",
	"stateSelectionWrap": "state-selection__state-selection-wrap-3rw",
	"reaction-label": "state-selection__reaction-label-UzY",
	"reactionLabel": "state-selection__reaction-label-UzY",
	"button-icon": "state-selection__button-icon-3PR",
	"buttonIcon": "state-selection__button-icon-3PR",
	"button-icon--none": "state-selection__button-icon--none-1n-",
	"buttonIconNone": "state-selection__button-icon--none-1n-",
	"icon": "state-selection__icon-2cg",
	"icon": "state-selection__icon-2cg"
};