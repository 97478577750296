exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".goalsAnalysis__wrapper-3TJ{display:flex;flex-direction:column;gap:8px;transition:all .6s ease-in-out}.goalsAnalysis__title-36p{color:#192032;font-size:30px;font-weight:var(--fontsWeightBold);line-height:20px;margin-bottom:10px}.goalsAnalysis__subTitle-3FH{color:var(--colorsTextGray);font-size:var(--fontsSizesBodyLarge);font-weight:var(--fontsWeightNormal);margin-bottom:20px}.goalsAnalysis__goalsStatsWrapper-l2N{gap:12px;display:flex;min-height:200px}", ""]);

// exports
exports.locals = {
	"wrapper": "goalsAnalysis__wrapper-3TJ",
	"wrapper": "goalsAnalysis__wrapper-3TJ",
	"title": "goalsAnalysis__title-36p",
	"title": "goalsAnalysis__title-36p",
	"subTitle": "goalsAnalysis__subTitle-3FH",
	"subTitle": "goalsAnalysis__subTitle-3FH",
	"goalsStatsWrapper": "goalsAnalysis__goalsStatsWrapper-l2N",
	"goalsStatsWrapper": "goalsAnalysis__goalsStatsWrapper-l2N"
};