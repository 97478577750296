exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".audience-tabs__box-2iq{width:100%}.audience-tabs__tabs-2y3{height:72px;display:flex;position:relative;overflow:auto;max-width:100%}.audience-tabs__tabs-2y3:before{content:\"\";position:absolute;left:0;bottom:0;height:1px;width:100%;background:#e7e9ed;z-index:0}.audience-tabs__tab-1F5{height:72px;padding:0 24px;font-size:18px;font-weight:var(--fontsWeightBold);align-items:center;justify-content:center;text-align:center;position:relative;display:flex;cursor:pointer;z-index:1;-webkit-user-select:none;user-select:none;flex-grow:0;flex-shrink:0;box-sizing:border-box;padding-bottom:4px}.audience-tabs__tab-1F5:hover{background-color:#f2f9fc}.audience-tabs__tab-selected-2MJ{cursor:default}.audience-tabs__tab-selected-2MJ:after{content:\"\";position:absolute;left:0;bottom:0;height:4px;width:100%;background:var(--colorsTextDarkGray)}.audience-tabs__add-tab-3CQ{color:#1165a3}.audience-tabs__content-box-1QB{margin:24px 0}.audience-tabs__remove-tab-2-0{width:14px;height:14px;background-size:60% 60%;background-repeat:no-repeat;background-position:50%;background-image:url(\"/assets/remove.svg\");cursor:pointer;margin-left:20px;border-radius:20px;position:absolute;right:5px}.audience-tabs__remove-tab-2-0:hover{background-color:#e6e8f0}", ""]);

// exports
exports.locals = {
	"box": "audience-tabs__box-2iq",
	"box": "audience-tabs__box-2iq",
	"tabs": "audience-tabs__tabs-2y3",
	"tabs": "audience-tabs__tabs-2y3",
	"tab": "audience-tabs__tab-1F5",
	"tab": "audience-tabs__tab-1F5",
	"tab-selected": "audience-tabs__tab-selected-2MJ audience-tabs__tab-1F5",
	"tabSelected": "audience-tabs__tab-selected-2MJ audience-tabs__tab-1F5",
	"add-tab": "audience-tabs__add-tab-3CQ audience-tabs__tab-1F5",
	"addTab": "audience-tabs__add-tab-3CQ audience-tabs__tab-1F5",
	"content-box": "audience-tabs__content-box-1QB",
	"contentBox": "audience-tabs__content-box-1QB",
	"remove-tab": "audience-tabs__remove-tab-2-0",
	"removeTab": "audience-tabs__remove-tab-2-0"
};