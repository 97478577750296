import React, { useEffect, useMemo, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { orderBy } from 'lodash';

import useStyles from 'hooks/useStyles';

import userStore from 'stores/userStore';
import servicesStore from 'stores/servicesStore';
import { Events } from 'trackers/analytics/enums';
import InfiniteScrollTable from 'components/common/InfiniteScrollTable';
import WidgetHeader from 'components/common/WidgetHeader';
import Switch from 'components/controls/Switch';
import MenuButton from 'components/common/MenuButton';
import EmptyStateWithImage from 'components/pages/analyze/EmptyStateWithImage';
import CheckListWithPresets from 'components/controls/CheckListWithPresets';
import Dropdown from 'components/controls/Dropdown';
import MultiLevelMenuPopup from 'components/common/MultiLevelMenuPopup';
import SpotlightSegmentTypeDropdown from 'components/controls/SpotlightSegmentTypeDropdown';

import { navigateBetweenAnalyzeTabs } from 'stores/analyze/logic/navigations';
import {
  getDefaultSelectedSubColumns,
  getColumnsOptionsByStage,
  getTableColumnByMetricKey,
  getTableColumnBySpotlightKey,
  getIsSpotlightTimeframeSupported,
} from 'components/widgets/segmentsDrilldown/logic/segmentsDrilldown';
import { tableDataWithFiltersByColumns, getUpliftMinMaxByMetric } from 'components/pages/analyze/AttribuitonTable/logic/AttributionSegmentsTableParseData';
import {
  columnControllersKeys,
  spotlightOptionsFields,
  spotlightDefaultColumns,
  emptyMessageGeneral,
  emptyMessageTimeframeNotSupported,
  spotlightDefaultSubColumns,
} from 'components/widgets/segmentsDrilldown/enums';
import {
  groupBySegmentsOptions,
  getOptionsWithSegmentMapping,
  getFiltersForViewJourneysRequest,
} from 'components/pages/analyze/SegmentsTab/logic/segments';
import { getSegmentsOptionsFields } from 'components/pages/analyze/OverviewTab/logic';
import { segmentsKeys, upliftLabel, metricsTypes } from 'components/common/logic/enums';
import { funnelNextActions } from 'components/widgets/funnelAnalysis/enums';

import style from 'styles/segmentsDrilldown/segmentsDrilldown.css';

const styles = style.locals || {};

function SegmentsDrilldown({
  data,
  isAttribution,
  totals,
  isLoaded,
  isLoadedUplift,
  timeframe,
  isCompareToPrevious,
  onChangeCompareToPrevious,
  selectedStage,
  breakdownSegment,
  updateBreakdownSegment,
  customFieldsIdToLabelMap,
  customUtmsWhitelist,
  funnels,
  tableHeight,
  upliftColumnsKeys,
  updateUpliftColumnsKeys,
  defaultDrilldownSelectedMetric,
  previousBreakdownSegments = [],
  isSpotlightTable,
  onChangeSpotlightMetric,
  totalStageAmount,
}) {
  useStyles([style]);

  const [isSupportedTimeframe, setIsSupportedTimeframe] = useState(true);
  const [columnsOptions, setColumnsOptions] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [selectedSubColumns, setSelectedSubColumns] = useState({});
  const [sortByColumn, setSortByColumn] = useState({ id: isSpotlightTable ? 'value' : 'attributed', desc: true });
  const [filtersByColumn, setFiltersByColumn] = useState({});
  const [tableData, setTableData] = useState(data || []);
  const [spotlightMetric, setSpotlightMetric] = useState(defaultDrilldownSelectedMetric);
  const [menuPopupData, setMenuPopupData] = useState({
    isShow: false,
    position: {},
    rowData: {},
  });

  useEffect(() => {
    setTableData(data);
  }, [data]);

  useEffect(() => {
    if (isSpotlightTable) {
      setIsSupportedTimeframe(getIsSpotlightTimeframeSupported({ timeframe }));
    }
  }, [timeframe]);

  useEffect(() => {
    if (isSpotlightTable) {
      setSelectedColumns(spotlightDefaultColumns);
      setSelectedSubColumns(spotlightDefaultSubColumns);
      return;
    }

    const options = getColumnsOptionsByStage({ stage: selectedStage, breakdownSegment, isAttribution });
    const updatedSelectedColumns = [segmentsKeys.firstSegment, ...options.map(({ value }) => value)];
    setColumnsOptions(options);
    setSelectedColumns(updatedSelectedColumns);
    setSelectedSubColumns(getDefaultSelectedSubColumns({ columns: updatedSelectedColumns, metrics: [metricsTypes.attributed, defaultDrilldownSelectedMetric] }));
  }, [selectedStage, isAttribution, breakdownSegment]);

  function onUpdateFiltersByColumn({ filters }) {
    const filteredByColumnsItems = tableDataWithFiltersByColumns({ tableData: data, filtersByColumn: filters, isRemoveFunnelKey: false });
    setTableData(filteredByColumnsItems);
    setFiltersByColumn(filters);
  }

  function onChangeSubColumnsSelection({ parentColumn, subColumn }) {
    const selectedByColumn = selectedSubColumns[parentColumn] || [];
    if (subColumn === columnControllersKeys.uplift) {
      updateUpliftColumnsKeys({ columnKey: `${parentColumn}${upliftLabel}` });
    }

    if (selectedByColumn.includes(subColumn)) {
      const shouldRemoveSubColumnFromObject = selectedByColumn.length === 1;
      if (shouldRemoveSubColumnFromObject) {
        const updatedSelectedOptionsColumnController = { ...selectedSubColumns };
        delete updatedSelectedOptionsColumnController[parentColumn];
        setSelectedSubColumns(updatedSelectedOptionsColumnController);
        return;
      }
      setSelectedSubColumns((prev) => ({
        ...prev,
        [parentColumn]: selectedByColumn.filter((item) => item !== subColumn),
      }));
      return;
    }

    const updatedColumns = [...selectedByColumn, subColumn];
    setSelectedSubColumns((prev) => ({
      ...prev,
      [parentColumn]: updatedColumns,
    }));

    servicesStore.eventTracker.track({
      eventName: Events.funnelDrilldown.selectReferencesButton,
      properties: {
        column: parentColumn,
        isUpliftChecked: updatedColumns.includes(columnControllersKeys.uplift),
        isShareOfTotalChecked: updatedColumns.includes(columnControllersKeys.shareOfTotal),
        isCompareToAverageChecked: updatedColumns.includes(columnControllersKeys.compare),
        isPacingForChecked: updatedColumns.includes(columnControllersKeys.pacing),
      },
    });
  }

  function onChangeColumnsSelection({ columns }) {
    setSelectedColumns([segmentsKeys.firstSegment, ...columns]);

    servicesStore.eventTracker.track({
      eventName: Events.funnelDrilldown.selectColumns,
      properties: {
        metricsCount: columns.length,
        metrics: columns,
      },
    });
  }

  let tableColumns = [];
  if (isSpotlightTable) {
    tableColumns = selectedColumns.map((columnKey) => getTableColumnBySpotlightKey({
      columnKey,
      metric: spotlightMetric,
      sortByColumn,
      selectedSubColumns,
      setSortByColumn,
      filtersByColumn,
      onUpdateFiltersByColumn,
      onChangeSubColumnsSelection,
      tableData,
      isLoaded,
      breakdownSegment,
      subColumnClassName: {
        header: styles.breakdownTableSubHeadCell,
        cell: styles.breakdownTableInnerCell,
        icon: styles.breakdownTableSubHeadIcon,
        tooltip: styles.breakdownTableSubHeadTooltip,
      },
    }));
  } else {
    const upliftMinMaxByMetric = getUpliftMinMaxByMetric({ data: tableData, upliftColumnsKeys });
    tableColumns = selectedColumns.map((columnKey) => getTableColumnByMetricKey({
      columnKey,
      upliftMinMaxByMetric,
      selectedSubColumns,
      selectedStage,
      timeframe,
      breakdownSegment,
      sortByColumn,
      setSortByColumn,
      filtersByColumn,
      onUpdateFiltersByColumn,
      onChangeSubColumnsSelection,
      tableData,
      totals,
      isLoaded,
      isLoadedUplift,
      isCompareToPrevious,
      subColumnClassName: {
        header: styles.breakdownTableSubHeadCell,
        cell: styles.breakdownTableInnerCell,
        icon: styles.breakdownTableSubHeadIcon,
        tooltip: styles.breakdownTableSubHeadTooltip,
      },
      totalStageAmount,
      isAttribution,
    }));
  }

  const orderedTableColumns = orderBy(tableColumns, ['priority'], ['asc']);

  function onClickDrilldownRow({ rowData, event }) {
    if (!rowData.firstSegment && !rowData.segmentType) {
      return;
    }

    const marginClickPosition = 84;
    const left = event.clientX - marginClickPosition;
    const top = event.clientY - marginClickPosition;
    setMenuPopupData({ isShow: true, position: { left, top }, rowData });
  }

  function onChangeBreakdownSegment({
    segment, shouldAddNewBreadcrumb = true, shouldSendRequest = true, isDrilldown, isSpotlightBreakdown,
  }) {
    const dataBySelectedRow = menuPopupData.rowData;
    updateBreakdownSegment({
      segment, selectedRow: dataBySelectedRow, shouldAddNewBreadcrumb, shouldSendRequest, isSpotlightBreakdown,
    });

    if (isDrilldown) {
      servicesStore.eventTracker.track({
        eventName: Events.funnelDrilldown.nextActionFromSegment,
        properties: {
          currentBreakdown: breakdownSegment,
          nextAction: `${funnelNextActions.breakdown} ${segment.suggestionsDropdownType}`,
        },
      });
    } else {
      servicesStore.eventTracker.track({
        eventName: Events.funnelDrilldown.selectBreakdown,
        properties: {
          dimension: segment.suggestionsDropdownType,
        },
      });
    }
  }

  function onViewJourneysClickOption() {
    navigateBetweenAnalyzeTabs({
      filters: getFiltersForViewJourneysRequest({
        firstSegment: breakdownSegment,
        value: menuPopupData.rowData?.firstSegment,
        kpiFocus: selectedStage,
        funnels,
      }),
      tabName: 'journeys',
      timeFrame: timeframe,
    });

    servicesStore.eventTracker.track({
      eventName: Events.funnelDrilldown.nextActionFromSegment,
      properties: {
        currentBreakdown: breakdownSegment,
        nextAction: funnelNextActions.journeys,
      },
    });
  }

  function onToggleCompareTo() {
    const isCompareToPreviousAfterChange = !isCompareToPrevious;
    onChangeCompareToPrevious({ isCompare: isCompareToPreviousAfterChange });

    servicesStore.eventTracker.track({
      eventName: Events.funnelDrilldown.toggledCompareTo,
      properties: {
        status: isCompareToPreviousAfterChange ? 'enabled' : 'disabled',
      },
    });
  }

  function closeMenuPopup() {
    setMenuPopupData({ isShow: false, position: {}, rowData: {} });
  }

  function getDrilldownMenuParams() {
    const menuParams = [
      {
        label: funnelNextActions.breakdown,
        component: (
          <Dropdown
            options={breakdownSegmentsOptions}
            selectedKey=""
            onChange={(segment) => {
              onChangeBreakdownSegment({ segment, isDrilldown: true });
              closeMenuPopup();
            }}
            menuIsOpen
            isSearchable
            classNameMenu={styles.multiLevelMenuDropdownMenu}
            isShowDropdownIndicator={false}
            placeholder="Search..."
            autoFocus
            menuListMaxHeight="184px"
            menuShouldScrollIntoView={false}
            menuListWidth="240px"
          />),
        leftIcon: 'breakdown:arrow',
      },
    ];

    if (!isSpotlightTable) {
      menuParams.push({
        label: funnelNextActions.journeys,
        leftIcon: 'breakdown:queryStats',
        action: () => onViewJourneysClickOption(),
      });
    }

    return menuParams;
  }

  const breakdownSegmentsOptions = useMemo(() => {
    let spotlightSegmentToDisabled = null;
    if (isSpotlightTable) {
      const spotlightData = menuPopupData.rowData;
      spotlightSegmentToDisabled = spotlightData.segmentType === 'customField' ? spotlightData.segmentId : spotlightData.segmentType;
    }

    const disabledOptions = [...previousBreakdownSegments];
    if (spotlightSegmentToDisabled) {
      disabledOptions.push(spotlightSegmentToDisabled);
    }

    return (
      getOptionsWithSegmentMapping({
        options: groupBySegmentsOptions({ options: getSegmentsOptionsFields({ customFieldsIdToLabelMap, customUtmsWhitelist }) }),
        disabledOptions,
      })
    );
  }, [previousBreakdownSegments, customFieldsIdToLabelMap, customUtmsWhitelist, menuPopupData.rowData]);

  const relevantForMetrics = userStore.getAllRelevantForMetrics({ metric: selectedStage });
  const orderedRelevantForMetrics = orderBy(relevantForMetrics, ['orderInGroup']);
  const relevantForMetricsOptions = orderedRelevantForMetrics.map((metric) => ({ value: metric.metricName, label: metric.nickname }));

  return (
    <div>
      <WidgetHeader
        isHideAddToReport
        widgetHeaderConfig={{}}
      >
        {isSpotlightTable ? (
          <>
            <Dropdown
              selectedKey={breakdownSegment}
              options={spotlightOptionsFields}
              onChange={(segment) => onChangeBreakdownSegment({
                segment, shouldAddNewBreadcrumb: false, shouldSendRequest: false, isSpotlightBreakdown: true,
              })}
              dropdownLabel="Breakdown by"
              isSearchable
              className={styles.segmentDropdownMenu}
            />
            <Dropdown
              selectedKey={spotlightMetric}
              options={relevantForMetricsOptions}
              onChange={(metric) => {
                setSpotlightMetric(metric.value);
                onChangeSpotlightMetric({ metric: metric.value });
              }}
              dropdownLabel="Drilldown metric"
              isSearchable
              className={styles.segmentDropdownMenu}
            />
            <SpotlightSegmentTypeDropdown
              onSave={() => onChangeSpotlightMetric({ metric: spotlightMetric })}
              istSelectOptionsAsLabel
              checkListToggleLabelPrefix="Segment type "
            />
          </>
        ) : (
          <>
            <Dropdown
              selectedKey={breakdownSegment}
              options={breakdownSegmentsOptions}
              onChange={(segment) => onChangeBreakdownSegment({ segment, shouldAddNewBreadcrumb: false, isDrilldown: false })}
              dropdownLabel="Breakdown by"
              isSearchable
              className={styles.segmentDropdownMenu}
            />
            <CheckListWithPresets
              options={columnsOptions}
              checkListTitle="Manage columns and metrics"
              checkListToggleLabel="Select Columns"
              checkListToggleLabelPrefix="Metrics "
              istSelectOptionsAsLabel
              selectedOptions={selectedColumns}
              setSelectedOptions={(columns) => onChangeColumnsSelection({ columns })}
            />
            <MenuButton
              title="Compare to prev. period"
              withArrowIndication={false}
              rightIconRerender={(
                <Switch
                  onSwitchClick={() => onToggleCompareTo({ isCompareToPrevious })}
                  isActive={isCompareToPrevious}
                  dataTestId="switch-compare-to-prev"
                />
              )}
            />
          </>
        )}
      </WidgetHeader>

      {(isLoaded && data.length === 0) || !isSupportedTimeframe ? (
        <EmptyStateWithImage
          imageClassName={styles.answersEmptyStateImage}
          title="No Data to Show"
          subTitle={isSupportedTimeframe ? emptyMessageGeneral : emptyMessageTimeframeNotSupported}
          height="460px"
          isContentCentered
        />
      ) : (
        <InfiniteScrollTable
          tableData={tableData}
          tableColumns={orderedTableColumns}
          sortByColumn={sortByColumn}
          isLoaded={isLoaded}
          className={styles.breakdownWrapper}
          tableClassName={styles.breakdownTable}
          cellClassName={styles.breakdownTableCell}
          tableHeight={tableHeight}
          shouldShowColumnsOnNoValues
          onRowClick={(rowData, rowIndex, event) => onClickDrilldownRow({ rowData, event })}
        />
      )}

      {menuPopupData.isShow && (
      <div className={styles.multiLevelMenuContainer} style={{ ...menuPopupData.position }}>
        <MultiLevelMenuPopup
          menuOptions={getDrilldownMenuParams()}
          onClosePopup={() => closeMenuPopup()}
        />
      </div>
      )}
    </div>
  );
}

export default inject(
  ({
    userStore: {
      funnels,
      userAccount: {
        customFieldsIdToLabelMap,
        customUtmsWhitelist,
      },
    },
  }) => ({
    customFieldsIdToLabelMap,
    customUtmsWhitelist,
    funnels,
  }),
  observer
)(SegmentsDrilldown);
