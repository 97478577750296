import { Fragment } from 'react';

import useStyles from 'hooks/useStyles';

import InfoMarker from 'components/pages/InfoMarker';

import { kpiFocusOptionsKeys } from 'components/widgets/optimalJourney/enums';
import { isSegmentBlock } from 'components/widgets/optimalJourney/logic/optimalJourney';

import style from 'styles/analyze/optimal-journey.css';

const styles = style.locals || {};

export default function JourneyQuestion({
  blocksItems,
  optimalJourneyKpi,
  optimalJourneyStages,
  colorsBySegment,
}) {
  useStyles([style]);

  const segmentsBlocks = blocksItems.filter((blockItem) => isSegmentBlock({ blockItem: blockItem.value }));

  if (segmentsBlocks.length === 0) {
    return null;
  }

  const lastStageLabel = optimalJourneyStages[optimalJourneyStages.length - 1]?.label || '';
  const tooltipBlocksItems = segmentsBlocks.map((blockItem, index) => {
    if (index === segmentsBlocks.length - 1) {
      return `and ${blockItem.label}`;
    }
    return blockItem.label;
  }).join(', ');

  const blocksTags = (
    <>
      {segmentsBlocks.map((blockItem, index) => (
        <Fragment key={`blocks-${blockItem.value}`}>
          {segmentsBlocks.length > 1 && index === segmentsBlocks.length - 1 && 'and '}
          <span key={blockItem.value} style={{ backgroundColor: colorsBySegment[blockItem.value] }}>
            {blockItem.label}
          </span>
        </Fragment>
      ))}
    </>
  );

  if (optimalJourneyKpi === kpiFocusOptionsKeys.conversionRateToLast) {
    return (
      <div className={styles.journeyQuestion}>
        {'What '}
        {blocksTags}
        {'have the highest Conversion Rate to '}
        {lastStageLabel}
        , at each funnel stage?

        <InfoMarker
          TooltipProps={{ className: styles.infoMarker }}
          containerClass={styles.infoMarkerContainer}
          content={(
            <div>
              {'KPI is set to '}
              <b>
                {'Conversion Rate to '}
                {lastStageLabel}
              </b>
              .
              As a result, InfiniGrow shows you:
              <ol>
                <li>
                  {'Journey statistics for '}
                  <b>{lastStageLabel}</b>
                  {' accounts, in the selected timeframe.'}
                </li>
                <li>
                  {'Which '}
                  <b>{tooltipBlocksItems}</b>
                  {` has the highest Conversion Rate to ${lastStageLabel} in every stage of the journey`}
                </li>
              </ol>
            </div>
          )}
        />
      </div>
    );
  }

  if (optimalJourneyKpi === kpiFocusOptionsKeys.conversionRateToNext) {
    return (
      <div className={styles.journeyQuestion}>
        {'What '}
        {blocksTags}
        {' converts best at each stage of the journey?'}

        <InfoMarker
          TooltipProps={{ className: styles.infoMarker }}
          containerClass={styles.infoMarkerContainer}
          content={(
            <div>
              {'Metric is set to '}
              <b>Conversion Rate to Next Stage,</b>
              .  As a result, InfiniGrow shows you:
              <ol>
                <li>
                  {'Journey statistics for '}
                  <b>{lastStageLabel}</b>
                  {' accounts, in the selected timeframe.'}
                </li>
                <li>
                  {'Which '}
                  <b>{tooltipBlocksItems}</b>
                  {' best converts to the next stage, in each stage of the journey'}
                </li>
              </ol>
            </div>
          )}
        />
      </div>
    );
  }

  if (optimalJourneyKpi === kpiFocusOptionsKeys.shareOfTotal) {
    return (
      <div className={styles.journeyQuestion}>
        {'What are the most common '}
        {blocksTags}
        {' on each stage of the journey to '}
        {lastStageLabel}
        ?
        <InfoMarker
          TooltipProps={{ className: styles.infoMarker }}
          content={(
            <div>
              {'KPI is set to '}
              <b>Share of Total (Most Common)</b>
              . As a result, InfiniGrow shows you:
              <ol>
                <li>
                  {'Journey statistics for '}
                  <b>{lastStageLabel}</b>
                  {' accounts, in the selected timeframe.'}
                </li>
                <li>
                  {'Which '}
                  <b>{tooltipBlocksItems}</b>
                  {' '}
                  {segmentsBlocks.length > 1 ? 'are' : 'is'}
                  {` most common on each stage of ${lastStageLabel} journeys (has the most touchpoints, per stage).`}
                </li>
              </ol>
            </div>
          )}
        />
      </div>
    );
  }

  return null;
}
