import {
  stageVelocity,
  numberOfContacts,
  numberOfTouchpoints,
} from 'components/widgets/optimalJourney/enums';

export const journeyInsightsTitle = 'Journey insights';

export const journeyInsightsSkeletons = [220, 140, 80, 120];

export const journeyInsightTypes = {
  segments: {
    all: 'All',
    most: 'Most',
    sequence: 'Sequence',
    mostFrequent: 'Most frequent',
  },
  optimizationMetrics: {
    highRatio: 'High ratio',
    highGrowthRatio: 'High growth ratio',
    topAndBottom: 'Top and bottom',
  },
  noInsight: 'No insight',
};

export const optimizationMetrics = [stageVelocity, numberOfContacts, numberOfTouchpoints];

export const currentStageKey = 'currentStage';

export const blockTypesBlacklist = [currentStageKey, 'destinationStage', 'nextStage', 'lastStage'];

export const timeStatus = {
  faster: 'faster',
  slower: 'slower',
};

export const ratioThreshold = {
  full: 1,
  most: 0.7,
};

export const journeyInsightsConditionMetadata = {
  velocity: {
    [journeyInsightTypes.optimizationMetrics.highRatio]: 10,
    [journeyInsightTypes.optimizationMetrics.highGrowthRatio]: 100,
    shouldUseAbs: true,
    positiveIsFaster: false,
  },
  numberOfSessions: {
    [journeyInsightTypes.optimizationMetrics.highRatio]: 4,
    [journeyInsightTypes.optimizationMetrics.highGrowthRatio]: 100,
    shouldUseAbs: false,
    positiveIsFaster: false,
  },
  numberOfContacts: {
    [journeyInsightTypes.optimizationMetrics.highRatio]: 3,
    [journeyInsightTypes.optimizationMetrics.highGrowthRatio]: 100,
    shouldUseAbs: false,
    positiveIsFaster: false,
  },
};
