exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".notSupportedWidget__wrapper-1Ll{display:flex;flex-direction:column;justify-content:center;align-items:center;height:100%;gap:16px}.notSupportedWidget__title-3lm{color:#192032;font-size:var(--fontsSizesBodyLarge);font-weight:var(--fontsWeightBold)}.notSupportedWidget__subTitle-3QE{color:#192032;text-align:center;font-size:var(--fontsSizesBodyNormal);max-width:304px}", ""]);

// exports
exports.locals = {
	"wrapper": "notSupportedWidget__wrapper-1Ll",
	"wrapper": "notSupportedWidget__wrapper-1Ll",
	"title": "notSupportedWidget__title-3lm",
	"title": "notSupportedWidget__title-3lm",
	"subTitle": "notSupportedWidget__subTitle-3QE",
	"subTitle": "notSupportedWidget__subTitle-3QE"
};