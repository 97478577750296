import { useEffect, useMemo, useState } from 'react';
import { inject, observer } from 'mobx-react';

import useStyles from 'hooks/useStyles';

import servicesStore from 'stores/servicesStore';
import JourneyQuestion from 'components/widgets/optimalJourney/JourneyQuestion';
import JourneyBlocksBar from 'components/widgets/optimalJourney/JourneyBlocksBar';
import JourneyCanvas from 'components/widgets/optimalJourney/journeyCanvas/JourneyCanvas';
import OptimalJourneyOverview from 'components/widgets/optimalJourney/OptimalJourneyOverview';
import JourneyInsights from 'components/widgets/optimalJourney/JourneyInsights';

import { Events } from 'trackers/analytics/enums';
import { COLORS } from 'components/utils/colors';
import { getDefaultAndSelectedBlocksItems, isSegmentBlock } from 'components/widgets/optimalJourney/logic/optimalJourney';
import { getInsights } from 'components/widgets/optimalJourney/insights/logic/insights';

import style from 'styles/analyze/optimal-journey.css';

const styles = style.locals || {};

function OptimalJourney({
  data,
  overviewData,
  isLoaded,
  commonSegments,
  customFieldsIdToLabelMap,
  customUtmsWhitelist,
  selectedBlocks,
  optimalJourneyKpi,
  optimalJourneyStages,
  onChangeSelectedBlocks,
}) {
  useStyles([style]);

  const [blocksItems, setBlocksItems] = useState([]);
  const [colorsBySegment, setColorsBySegment] = useState({});

  function updateColorsBySegment({ segment }) {
    if (colorsBySegment[segment] || !isSegmentBlock({ blockItem: segment })) {
      return;
    }

    for (const color of COLORS) {
      if (!Object.values(colorsBySegment).includes(color)) {
        colorsBySegment[segment] = color;
        setColorsBySegment(colorsBySegment);
        return;
      }
    }
  }

  useEffect(() => {
    const defaultAndSelectedBlocksItems = getDefaultAndSelectedBlocksItems({ commonSegments, selectedBlocks });
    setBlocksItems(defaultAndSelectedBlocksItems);
    selectedBlocks.forEach((block) => {
      updateColorsBySegment({ segment: block });
    });
  }, [commonSegments]);

  function updateSelectedBlocks({ newBlocks }) {
    const selectedBlocksItems = newBlocks.map((blockItem) => blockItem.isSelected && blockItem.value);
    onChangeSelectedBlocks({ newBlocks: selectedBlocksItems.filter((block) => block) });
  }

  function onClickBlockItem({ blockItem }) {
    const updatedBlocksItems = [...blocksItems];
    const blockItemIndex = updatedBlocksItems.findIndex((item) => item.value === blockItem.value);
    if (blockItemIndex === -1) {
      return;
    }
    const shouldRemoveBlockItem = blockItem.isSelected && !blockItem.isStatic;
    if (shouldRemoveBlockItem) {
      updatedBlocksItems.splice(blockItemIndex, 1);

      servicesStore.eventTracker.track({
        eventName: Events.optimalJourney.removedBlock,
        properties: {
          selectedJourneyBlock: blockItem.label,
          isShownByDefault: blockItem.isStatic || false,
        },
      });
    } else {
      const updatedIsSelected = !updatedBlocksItems[blockItemIndex].isSelected;
      updatedBlocksItems[blockItemIndex].isSelected = updatedIsSelected;
      if (updatedIsSelected) {
        updateColorsBySegment({ segment: blockItem.value });
      }

      servicesStore.eventTracker.track({
        eventName: updatedIsSelected ? Events.optimalJourney.addedBlock : Events.optimalJourney.removedBlock,
        properties: {
          selectedJourneyBlock: blockItem.label,
          isShownByDefault: blockItem.isStatic || false,
        },
      });
    }
    setBlocksItems(updatedBlocksItems);
    updateSelectedBlocks({ newBlocks: updatedBlocksItems });
  }

  function onClickAddBlock({ blockItem }) {
    const updatedBlocksItems = [...blocksItems];
    blockItem.isSelected = true;
    updatedBlocksItems.push(blockItem);
    updateColorsBySegment({ segment: blockItem.value });

    setBlocksItems(updatedBlocksItems);
    updateSelectedBlocks({ newBlocks: updatedBlocksItems });

    servicesStore.eventTracker.track({
      eventName: Events.optimalJourney.addedBlock,
      properties: {
        selectedJourneyBlock: blockItem.label,
        isShownByDefault: blockItem.isStatic || false,
      },
    });
  }

  const selectedBlocksItems = blocksItems.filter((blockItem) => blockItem.isSelected);

  const insights = useMemo(() => getInsights({
    data,
    optimalJourneyKpi,
    optimalJourneyStages,
    selectedBlocksItems,
    customFieldsIdToLabelMap,
    customUtmsWhitelist,
  }), [data, optimalJourneyKpi, optimalJourneyStages]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.subHeader}>
        <JourneyBlocksBar
          blocksItems={blocksItems}
          onClickBlockItem={({ blockItem }) => onClickBlockItem({ blockItem })}
          onClickAddBlock={({ blockItem }) => onClickAddBlock({ blockItem })}
        />
      </div>
      <div className={styles.container}>
        <div className={styles.leftPanel}>
          <JourneyInsights
            isLoaded={isLoaded}
            blocksItems={selectedBlocksItems}
            insightsItems={insights}
          />
        </div>
        <div className={styles.rightPanel}>
          <OptimalJourneyOverview
            overviewData={overviewData}
            isLoaded={isLoaded.accountPerformance}
            lastSelectedStage={optimalJourneyStages[optimalJourneyStages.length - 1]}
          />
          <div className={styles.journeyCanvas}>
            <JourneyQuestion
              blocksItems={selectedBlocksItems}
              optimalJourneyStages={optimalJourneyStages}
              optimalJourneyKpi={optimalJourneyKpi}
              colorsBySegment={colorsBySegment}
            />
            <JourneyCanvas
              blocksItems={selectedBlocksItems}
              optimalJourneyStages={optimalJourneyStages}
              colorsBySegment={colorsBySegment}
              optimalJourneyKpi={optimalJourneyKpi}
              isLoaded={isLoaded}
              data={data}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default inject(
  ({
    userStore: {
      commonSegments,
      userAccount: {
        customFieldsIdToLabelMap,
        customUtmsWhitelist,
      } = {},
    } = {},
  }) => ({
    commonSegments,
    customFieldsIdToLabelMap,
    customUtmsWhitelist,
  }),
  observer
)(OptimalJourney);
