exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".TextField-module__box___30kQK{height:auto;align-items:center}.TextField-module__input___233FW{box-shadow:none;background:var(--colorsGeneralWhite);padding:0 14px;font-size:var(--fontsSizesBodyNormal);line-height:16px;color:var(--colorsGeneralBlack);border:solid 1px var(--colorsInputGrayLine);border-radius:4px;height:40px;width:100%;box-sizing:border-box}.TextField-module__input___233FW::placeholder{color:var(--colorsTextGray);opacity:.6}.TextField-module__input___233FW:disabled{background:var(--colorsBackgroundLightGray)}.TextField-module__inputError___MMy9E{border:var(--colorsGeneralRed) 1px solid;box-shadow:none}.TextField-module__inputErrorMessage___2xDGr{color:var(--colorsGeneralRed);font-size:var(--fontsSizesBodySmall);padding-top:6px}.TextField-module__sign___1Sw4A{position:absolute;right:8px;top:10px;flex-grow:1}.TextField-module__inputWrapper___KKmtx{position:relative;flex-grow:1}.TextField-module__inputWithLabelWrapper___2wSZx{display:flex;flex-direction:column;gap:20px}.TextField-module__fieldLabel___3VTqf{display:flex}.TextField-module__requiredAsterisk___38PBc{color:var(--colorsInputErrorRed)}", ""]);

// exports
exports.locals = {
	"box": "TextField-module__box___30kQK",
	"input": "TextField-module__input___233FW",
	"inputError": "TextField-module__inputError___MMy9E",
	"inputErrorMessage": "TextField-module__inputErrorMessage___2xDGr",
	"sign": "TextField-module__sign___1Sw4A",
	"inputWrapper": "TextField-module__inputWrapper___KKmtx",
	"inputWithLabelWrapper": "TextField-module__inputWithLabelWrapper___2wSZx",
	"fieldLabel": "TextField-module__fieldLabel___3VTqf",
	"requiredAsterisk": "TextField-module__requiredAsterisk___38PBc"
};