exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".business-unit-popup__wrapper-1_g{display:flex;flex-direction:column;gap:24px;text-align:center;color:#182033;font-size:var(--fontsSizesBodyNormal)}.business-unit-popup__header-1mB b{font-size:18px}.business-unit-popup__content-2m-,.business-unit-popup__header-1mB{display:flex;gap:12px;flex-direction:column}.business-unit-popup__footerButtons-2g1{display:flex;justify-content:space-between}.business-unit-popup__checkboxLabel-3Bn{font-size:var(--fontsSizesBodyNormal);text-transform:none;font-weight:var(--fontsWeightNormal);color:#182033}.business-unit-popup__footerOneButton-2vf{display:flex;justify-content:end}", ""]);

// exports
exports.locals = {
	"wrapper": "business-unit-popup__wrapper-1_g",
	"wrapper": "business-unit-popup__wrapper-1_g",
	"header": "business-unit-popup__header-1mB",
	"header": "business-unit-popup__header-1mB",
	"content": "business-unit-popup__content-2m-",
	"content": "business-unit-popup__content-2m-",
	"footerButtons": "business-unit-popup__footerButtons-2g1",
	"footerButtons": "business-unit-popup__footerButtons-2g1",
	"checkboxLabel": "business-unit-popup__checkboxLabel-3Bn",
	"checkboxLabel": "business-unit-popup__checkboxLabel-3Bn",
	"footerOneButton": "business-unit-popup__footerOneButton-2vf",
	"footerOneButton": "business-unit-popup__footerOneButton-2vf"
};