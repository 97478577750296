exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".performance-chart__chart-holder-36P{width:100%;flex-grow:1;height:260px;padding:20px 0}.performance-chart__chart-tooltip-1bs{position:relative;min-width:110px;box-shadow:0 4px 16px 0 rgba(24,32,51,.12);border:1px solid rgba(178,187,213,.5);background-color:var(--colorsGeneralWhite);border-radius:5px;padding:12px 8px;text-align:center;line-height:1.33;font-weight:var(--fontsWeightMedium);box-sizing:border-box;top:-30px}.performance-chart__chart-tooltip-1bs:before{content:\"\";position:absolute;bottom:0;left:50%;width:0;height:0;border:8px solid transparent;border-top-color:rgba(178,187,213,.5);border-bottom:0;margin-left:-4px;margin-bottom:-9px}.performance-chart__chart-tooltip-1bs:after{content:\"\";position:absolute;bottom:0;left:50%;width:0;height:0;border:8px solid transparent;border-top-color:var(--colorsGeneralWhite);border-bottom:0;margin-left:-4px;margin-bottom:-8px}.performance-chart__tooltip-label-2fZ{font-size:var(--fontsSizesBodySmall);color:var(--colorsTextGray)}.performance-chart__tooltip-value-3xh{font-size:var(--fontsSizesTitleH3);color:var(--colorsGeneralBlack)}.performance-chart__tooltipContent-3aj{display:flex;align-items:flex-end;gap:4px;justify-content:center}.performance-chart__comparisonValue-2jk{line-height:2.66}", ""]);

// exports
exports.locals = {
	"chart-holder": "performance-chart__chart-holder-36P",
	"chartHolder": "performance-chart__chart-holder-36P",
	"chart-tooltip": "performance-chart__chart-tooltip-1bs",
	"chartTooltip": "performance-chart__chart-tooltip-1bs",
	"tooltip-label": "performance-chart__tooltip-label-2fZ",
	"tooltipLabel": "performance-chart__tooltip-label-2fZ",
	"tooltip-value": "performance-chart__tooltip-value-3xh",
	"tooltipValue": "performance-chart__tooltip-value-3xh",
	"tooltipContent": "performance-chart__tooltipContent-3aj",
	"tooltipContent": "performance-chart__tooltipContent-3aj",
	"comparisonValue": "performance-chart__comparisonValue-2jk",
	"comparisonValue": "performance-chart__comparisonValue-2jk"
};