import React from 'react';
import classnames from 'classnames';

import useStyles from 'hooks/useStyles';

import Tooltip from 'components/controls/Tooltip';

import { indicationStatuses } from 'components/common/enums';

import style from 'styles/users/filters.css';

const styles = style.locals || {};

export default function FiltersIconWithIndication({
  activeNumber = 0,
  numberClassname,
  filtersIndication = {},
}) {
  useStyles([style]);

  if (filtersIndication.status) {
    return (
      <Tooltip
        id="tooltip-error"
        tip={filtersIndication.tooltip}
        place="right"
      >
        <div className={styles.filtersIconWithIndication}>
          {filtersIndication.status === indicationStatuses.error ? (
            <div data-testid="error-filter" className={classnames(styles.filtersActiveNumber, styles.error)}>!</div>
          ) : (
            <div data-testid="warning-filter" className={classnames(styles.filtersActiveNumber, styles.warning)}>i</div>
          )}
        </div>
      </Tooltip>
    );
  }

  return (
    <div className={styles.filtersIconWithIndication}>
      {activeNumber ? (
        <div data-testid="active-number" className={classnames(styles.filtersActiveNumber, numberClassname)}>
          {activeNumber}
        </div>
      ) : null}
    </div>
  );
}
