import React, { useState } from 'react';

import useStyles from 'hooks/useStyles';

import CompareMetricsBox from 'components/common/CompareMetricsBox';
import InspectPercentageOfMetricPopup from 'components/pages/settings/workflows/demoInspectPopups/InspectPercentageOfMetricPopup';
import InspectConversionRatePopup from 'components/pages/settings/workflows/demoInspectPopups/InspectConversionRatePopup';
import InspectDrillDownPopup from 'components/pages/settings/workflows/demoInspectPopups/InspectDrillDownPopup';

import { TIMEFRAME_LABELS } from 'components/utils/timeframe';
import { getCompareEnrichedMetricsMockData, getDisplaySignByMetricTypeOrDisplayType } from 'components/pages/settings/workflows/logic/compareEnrichedMetrics';
import { compareMetricsKinds, compareMetricsTooltips } from 'components/pages/settings/workflows/logic/enums';

import style from 'styles/settings/compare-enriched-metrics/compare-enriched-metrics.css';

const styles = style.locals || {};

function CompareEnrichedMetrics({
  timeFrameLabel = TIMEFRAME_LABELS.PREV_QUARTER,
}) {
  useStyles([style]);

  const compareMetrics = getCompareEnrichedMetricsMockData();

  const [showInspectByTypePopup, setShowInspectByTypePopup] = useState(null);
  const [drillDownPopupEntityId, setDrillDownPopupEntityId] = useState(null);

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.compareMetricsBoxes}>
          {compareMetrics.map((compareValues, index) => {
            const tooltip = compareMetricsTooltips[compareValues.kind];
            const displayType = getDisplaySignByMetricTypeOrDisplayType({ metricType: compareValues.metricType });
            const compareKey = `compareMetric-${index}-${compareValues.title}-${compareValues.kind}-${displayType}`;

            const compareProps = {
              title: compareValues.title,
              enrichedMetrics: compareValues.enrichedMetrics,
              crmMetrics: compareValues.crmMetrics,
              metric: compareValues.metric,
              tooltip,
              formatValue: (value) => `${value}${displayType}`,
            };

            return (
              <div key={compareKey} onClick={() => setShowInspectByTypePopup(compareValues.kind)}>
                <CompareMetricsBox {...compareProps} key={`box-${compareKey}`} />
              </div>
            );
          })}
        </div>
        <div className={styles.sampleDate}>
          {'Sample date range: '}
          {timeFrameLabel}
        </div>
      </div>

      {showInspectByTypePopup === compareMetricsKinds.percentageOfMetric ? (
        <InspectPercentageOfMetricPopup
          onClose={() => setShowInspectByTypePopup(null)}
          timeFrameLabel={timeFrameLabel}
          onClick={(row) => {
            setDrillDownPopupEntityId({
              id: row.identityId,
              name: row.displayName,
            });
            setShowInspectByTypePopup(null);
          }}
        />
      ) : null}

      {showInspectByTypePopup === compareMetricsKinds.conversionRate ? (
        <InspectConversionRatePopup
          onClose={() => setShowInspectByTypePopup(null)}
          timeFrameLabel={timeFrameLabel}
        />
      ) : null}

      {drillDownPopupEntityId ? (
        <InspectDrillDownPopup
          onGoBack={() => {
            setShowInspectByTypePopup(compareMetricsKinds.percentageOfMetric);
            setDrillDownPopupEntityId(null);
          }}
          onClose={() => setDrillDownPopupEntityId(null)}
          title={drillDownPopupEntityId.name}
        />
      ) : null}
    </>
  );
}

export default CompareEnrichedMetrics;
