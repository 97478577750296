exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".segment-tag__segmentTag-3si{border-radius:4px;background:#edeef2;padding:4px 8px;color:#6a7694;font-size:var(--fontsSizesBodyNormal);display:flex;align-items:center;gap:8px}.segment-tag__channelIcon-1Va{margin:0;height:20px;width:20px;min-height:20px;min-width:20px;margin-left:-4px}.segment-tag__categoryIcon-3W2{margin:0 0 0 -4px;height:20px;width:20px;min-height:20px;min-width:20px;background-color:var(--colorsGeneralWhite);background-size:initial;border-radius:4px;background-repeat:no-repeat;background-position:50%}", ""]);

// exports
exports.locals = {
	"segmentTag": "segment-tag__segmentTag-3si",
	"segmentTag": "segment-tag__segmentTag-3si",
	"channelIcon": "segment-tag__channelIcon-1Va",
	"channelIcon": "segment-tag__channelIcon-1Va",
	"categoryIcon": "segment-tag__categoryIcon-3W2",
	"categoryIcon": "segment-tag__categoryIcon-3W2"
};