import { useEffect, useState } from 'react';

import Switch from 'components/controls/Switch';

import { shouldUsePredefinedFiltersTitle } from 'components/pages/reports/enums';

export default function PredefinedFiltersMenuOption({
  shouldUsePredefinedFilters,
  updateShouldUsePredefinedFilter,
  containerClassName,
}) {
  const [isActive, setIsActive] = useState(shouldUsePredefinedFilters);

  useEffect(() => {
    if (isActive !== shouldUsePredefinedFilters) {
      setIsActive(shouldUsePredefinedFilters);
    }
  }, [shouldUsePredefinedFilters]);

  return (
    <div className={containerClassName} onClick={(e) => e.stopPropagation()}>
      {shouldUsePredefinedFiltersTitle}

      <Switch
        dataTestId="switch-predefined-filters"
        onSwitchClick={() => {
          setIsActive((prevState) => !prevState);
          updateShouldUsePredefinedFilter();
        }}
        isActive={isActive}
      />
    </div>
  );
}
