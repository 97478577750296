import Page from 'components/Page';
import classnames from 'classnames';
import { Button } from '@infinigrow/libs';

import useStyles from 'hooks/useStyles';

import { drillDownData } from 'components/pages/settings/workflows/demoInspectPopups/mockData';

import EventsTimeline from 'components/pages/users/EventsTimeline';

import style from 'styles/settings/workflows/compareEnrichedMetrics.css';
import drillDownStyle from 'styles/inspectData/drillDownPopup.css';
import inspectStyle from 'styles/analyze/inspectData/inspect.css';

const styles = style.locals || {};
const drillDownStyles = drillDownStyle.locals || {};
const inspectStyles = inspectStyle.locals || {};

export default function InspectDrillDownPopup({
  onClose,
  onGoBack,
  title,
}) {
  useStyles([style, inspectStyle, drillDownStyle]);

  return (
    <Page
      popup
      width="700px"
      otherProps={{ id: 'inspect-drilldown-Popup' }}
      contentClassName={styles.drillDownWrapper}
    >
      <div
        className={inspectStyles.close}
        onClick={() => { onClose(); }}
      />

      <div className={drillDownStyles.row} onClick={() => onGoBack()}>
        <div className={drillDownStyles.arrowLeftIcon} />
        <div className={classnames(drillDownStyles.text, drillDownStyles.header, styles.drillDownTitle)}>
          {title}
        </div>
      </div>

      <EventsTimeline
        key="events-timeline"
        groupedEvents={drillDownData.userEvents}
        emails={drillDownData.emails}
        wrapperClassname={styles.eventsTimeline}
      />
      <Button
        className={styles.viewAccountButton}
        type="primaryBlue"
        onClick={() => {
          window.open('/analyze/journeys?journeyId=4f124d45-b1b9-4b2e-bbc4-99a11040f04b', '_blank');
        }}
      >
        View Account journey
      </Button>
    </Page>
  );
}
