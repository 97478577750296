export function getToggleTabConfigLabel({ configKey, configValue }) {
  if (configKey === 'isCategoryView') {
    return configValue ? 'Category' : 'Channel';
  }

  if (configKey === 'isContentPages') {
    return configValue ? 'Content' : 'Content Type';
  }

  return '';
}
