import { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { isEmpty } from 'lodash';
import { Button } from '@infinigrow/libs';

import useStyles from 'hooks/useStyles';

import ChannelIcon from 'components/common/ChannelIcon';
import EventsTimelineTooltip from 'components/pages/users/EventsTimelineTooltip';
import MessagePopup from 'components/MessagePopup';
import Tooltip from 'components/controls/Tooltip';
import UnmappedPopupFromJourneys from 'components/pages/users/UnmappedPopupFromJourneys';
import WorkflowBudgeTooltip from 'components/pages/users/WorkflowBudgeTooltip';
import FeatureFlags from 'components/common/FeatureFlags';

import { getChannelIcon } from 'components/utils/filters/channels';
import { getChannelNicknameWithDirect, UNKNOWN_CHANNEL } from 'components/utils/channels';
import { getItemLabelWithNoValue } from 'components/utils/logic/utils';
import { getSegmentLabel } from 'components/pages/analyze/SegmentsTab/logic/segments';
import { getFilteredWorkflows } from 'components/pages/users/logic/workflowBudgeTooltip';

import userStore from 'stores/userStore';

import style from 'styles/users/users-popup.css';

const styles = style.locals || {};

function EventsTimelineSessionItem({
  sessions = [],
  sessionsChannel,
  stringifyDate,
  leadSourcesIdToLabelMap,
  customUtmsWhitelist,
  isLastInteractionEventItem,
  credit,
  metricThatGotCredit,
  isShowChannelTooltip,
  segments,
  customFieldsIdToLabelMap,
  journeyName,
  flags,
  shouldShowOppEnrichmentWorkflow,
}) {
  useStyles([style]);

  useEffect(() => {
    setEventSessions(sessions);
  }, [sessions]);

  const [mappingPopupObject, setMappingPopupObject] = useState({ isShow: false });
  const [isShowEvents, setIsShowEvents] = useState(false);
  const [isShowSavedMessagePopup, setIsShowSavedMessagePopup] = useState(false);
  const [eventSessions, setEventSessions] = useState([]);

  const onClickToggleEvents = () => {
    setIsShowEvents((prevShowEvents) => !prevShowEvents);
  };
  const toggleButtonText = isShowEvents ? 'Hide' : 'Show';
  const isUnmapped = sessionsChannel === UNKNOWN_CHANNEL;

  const onFinishSaveMapping = (index) => {
    setIsShowSavedMessagePopup(true);
    const updateEventSessions = [...eventSessions];
    updateEventSessions[index].isAlreadyMapped = true;
    setEventSessions(updateEventSessions);
  };

  return (
    <>
      <div data-last-interaction-event={isLastInteractionEventItem} className={styles.eventLine}>
        <div className={styles.iconContainer}>
          <ChannelIcon
            className={styles.iconContainer}
            channelIcon={getChannelIcon(sessionsChannel)}
            channel={sessionsChannel}
            tooltip={isShowChannelTooltip ? getChannelNicknameWithDirect(sessionsChannel) : null}
          />
        </div>
        <div className={styles.sessionsContainer}>
          {eventSessions.map((session, index) => {
            const isOffline = session.sessionType !== 'online';
            const isHideConsolidateTPWithoutFirstItem = !isShowEvents && index !== 0;
            if (isHideConsolidateTPWithoutFirstItem) {
              return false;
            }
            const unmappedButton = {};
            if (isUnmapped) {
              unmappedButton.disabled = session.isAlreadyMapped || false;
              unmappedButton.action = setMappingPopupObject;
              unmappedButton.text = 'Map Touchpoint';
            }
            const tooltipKey = `tooltip-${sessionsChannel}${index}-${session.startTime}-${session.identityId}`;
            const tooltipContentKey = `${sessionsChannel}${index}-${session.startTime}-${session.identityId}`;
            const filteredWorkflows = getFilteredWorkflows({ workflows: session.workflows, shouldShowOppEnrichmentWorkflow });
            const hasWorkflows = !isEmpty(filteredWorkflows);

            return (
              <div className={styles.eventText}>
                <div className={styles.eventTitle}>
                  {segments ? (
                    <Tooltip
                      clickable
                      id={tooltipKey}
                      TooltipProps={{
                        delayHide: 50,
                      }}
                      content={(
                        <EventsTimelineTooltip
                          data={session}
                          index={index}
                          key={tooltipContentKey}
                          isOffline={isOffline}
                          leadSourcesIdToLabelMap={userStore.userAccount?.leadSourcesIdToLabelMap}
                          customFieldsIdToLabelMap={userStore.userAccount?.customFieldsIdToLabelMap}
                          customUtmsWhitelist={userStore.userAccount?.customUtmsWhitelist}
                          unmappedButton={unmappedButton}
                        />
                              )}
                      key={tooltipKey}
                    >
                      <>
                        {segments.firstSegment && (
                        <div>
                          {`${getSegmentLabel({
                            segment: segments.firstSegment,
                            customFieldsIdToLabelMap,
                            customUtmsWhitelist,
                          })}: `}
                          <span className={styles.bold}>
                            {getItemLabelWithNoValue({
                              value: segments.firstSegmentValue,
                              segment: segments.firstSegment,
                              segmentLabel: getSegmentLabel({
                                segment: segments.firstSegment,
                                customFieldsIdToLabelMap,
                                customUtmsWhitelist,
                              }),
                            })}
                          </span>
                        </div>
                        )}
                        {segments.secondSegment && (
                        <div>
                          {`${getSegmentLabel({
                            segment: segments.secondSegment,
                            customFieldsIdToLabelMap,
                            customUtmsWhitelist,
                          })}: `}
                          <span className={styles.bold}>
                            {getItemLabelWithNoValue({
                              value: segments.secondSegmentValue,
                              segment: segments.secondSegment,
                              segmentLabel: getSegmentLabel({
                                segment: segments.secondSegment,
                                customFieldsIdToLabelMap,
                                customUtmsWhitelist,
                              }),
                            })}
                          </span>
                        </div>
                        )}
                      </>
                    </Tooltip>
                  ) : (
                    <div className={styles.channelRow}>
                      <Tooltip
                        clickable
                        id={tooltipKey}
                        TooltipProps={{
                          delayHide: 50,
                        }}
                        content={(
                          <EventsTimelineTooltip
                            data={session}
                            index={index}
                            key={tooltipContentKey}
                            isOffline={isOffline}
                            leadSourcesIdToLabelMap={userStore.userAccount?.leadSourcesIdToLabelMap}
                            customFieldsIdToLabelMap={userStore.userAccount?.customFieldsIdToLabelMap}
                            customUtmsWhitelist={userStore.userAccount?.customUtmsWhitelist}
                            unmappedButton={unmappedButton}
                          />
                            )}
                        key={tooltipKey}
                      >
                        <>
                          {isOffline ? 'Interacted through ' : 'Visited website through '}
                          <span className={styles.bold}>
                            {getChannelNicknameWithDirect(sessionsChannel)}
                          </span>
                        </>
                      </Tooltip>
                    </div>
                  )}
                  {hasWorkflows
                    ? (
                      <FeatureFlags flag={flags?.workflowsBudges}>
                        <Tooltip
                          place="right"
                          clickable
                          id={`${tooltipKey}-workflows`}
                          TooltipProps={{
                            delayHide: 50,
                            className: styles.tooltipWrapper,
                          }}
                          content={(
                            <WorkflowBudgeTooltip
                              workflows={filteredWorkflows}
                              key={`${tooltipContentKey}workflow`}
                              email={session.email}
                              companyName={journeyName}
                            />
                          )}
                          key={`${tooltipKey}-workflows`}
                        >
                          <div className={styles.workflowBadge} data-testid="workflow-badge" />
                        </Tooltip>
                      </FeatureFlags>
                    )
                    : null}
                </div>
                <div className={styles.eventTime}>
                  {stringifyDate(session.startTime)}
                  {session.email ? `, ${session.email}` : null}
                </div>
              </div>
            );
          })}
          {eventSessions.length > 1 && (
          <Button
            className={styles.sessionToggleButton}
            type="secondaryBlue"
            onClick={() => onClickToggleEvents()}
          >
            {toggleButtonText}
            {' '}
            (
              {eventSessions.length - 1}
            )
          </Button>
          )}
        </div>
        {credit && metricThatGotCredit && (<div className={styles.boldBlue}>{`${credit} ${metricThatGotCredit}`}</div>)}
      </div>

      {isUnmapped
        && (
          <>
            <UnmappedPopupFromJourneys
              mappingPopupObject={mappingPopupObject}
              setMappingPopupObject={setMappingPopupObject}
              leadSourcesIdToLabelMap={leadSourcesIdToLabelMap}
              customUtmsWhitelist={customUtmsWhitelist}
              onFinishSaveMapping={onFinishSaveMapping}
            />
            <MessagePopup
              hidden={!isShowSavedMessagePopup}
              popupTitle="This touchpoint was mapped"
              popupSubTitle="The new mapping will be updated within one hour"
              onClosePopup={() => setIsShowSavedMessagePopup(false)}
            />
          </>
        )}
    </>
  );
}

export default withLDConsumer()(inject(
  ({
    userStore: {
      userAccount: {
        leadSourcesIdToLabelMap,
        customUtmsWhitelist,
        customFieldsIdToLabelMap,
      } = {},
    } = {},
  }) => ({
    leadSourcesIdToLabelMap,
    customUtmsWhitelist,
    customFieldsIdToLabelMap,
  }),
  observer
)(EventsTimelineSessionItem));
