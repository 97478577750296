import React from 'react';
import { isEmpty } from 'lodash';
import { inject, observer } from 'mobx-react';
import { Button } from '@infinigrow/libs';
import { Slide, ToastContainer } from 'react-toastify';

import useStyles from 'hooks/useStyles';

import LastUpdatedTimes from 'components/navigationMenu/LastUpdatedTimes';
import RegionsMenu from 'components/navigationMenu/RegionsMenu';
import AnalyzeHeader from 'components/pages/analyze/AnalyzeHeader';
import ReportsHeaderMenu from 'components/pages/reports/ReportsHeaderMenu';
import CreateReportHeaderMenu from 'components/pages/reports/CreateReportHeaderMenu';
import WidgetBuilderHeaderMenu from 'components/pages/widgetBuilder/WidgetBuilderHeaderMenu';
import AskQuestion from 'components/pages/questions/AskQuestion';
import SettingsPopup from 'components/pages/analyze/SettingsPopup';
import ReportActionMenu from 'components/pages/reports/ReportActionMenu';

import { getQueryParams } from 'components/utils/UrlParamsProvider';
import { getHeaderMenuKindByPath } from 'components/navigationMenu/logic/headerBar';
import { headerMenuKinds } from 'components/navigationMenu/enums';

import style from 'styles/navigationMenu/headerBar.css';

const styles = style.locals || {};

function HeaderBar({
  getUserMonthPlan,
  createUserMonthPlan,
  updateParentState,
  updateUserAccount,
  userMonthPlan,
  updateUserMonthPlan,
  aiAnswersDataResults,
  setAIAnswersDataResults,
  setCurrentRequestId,
}) {
  useStyles([style]);

  const currentPath = window.location.pathname;
  const headerMenuKind = getHeaderMenuKindByPath({ path: currentPath });

  const hasAccountView = userMonthPlan?.accountViewRegion;

  let hasReportId = false;
  if (headerMenuKind === headerMenuKinds.reports || headerMenuKind === headerMenuKinds.widgetBuilder) {
    const reportId = getQueryParams({ queryParamKey: 'reportId' });
    if (reportId) {
      hasReportId = true;
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.leftMenu}>

        {headerMenuKind === headerMenuKinds.reports ? (
          <ReportsHeaderMenu hasReportId={hasReportId} />
        ) : null}

        {headerMenuKind === headerMenuKinds.createNewReport ? (
          <CreateReportHeaderMenu />
        ) : null}

        {headerMenuKind === headerMenuKinds.widgetBuilder && hasReportId ? (
          <WidgetBuilderHeaderMenu />
        ) : null}

        {(headerMenuKind === headerMenuKinds.analyze || headerMenuKind === headerMenuKinds.home) && !isEmpty(userMonthPlan) ? (
          <AnalyzeHeader
            updateUserMonthPlan={updateUserMonthPlan}
            path={currentPath}
            region={userMonthPlan?.region}
          />
        ) : null}

        {headerMenuKind === headerMenuKinds.questionsResults ? (
          <AskQuestion
            shouldOpenResultsPopup={false}
            className={styles.headerQuestions}
            inputClassName={styles.headerQuestionsinput}
            displayShowMoreSuggestionsButton={false}
          />
        ) : null}

        {headerMenuKind === headerMenuKinds.aiAnswers && !isEmpty(aiAnswersDataResults) ? (
          <Button
            type="secondaryBlue"
            onClick={() => {
              setAIAnswersDataResults({ data: {} });
              setCurrentRequestId({ requestId: null });
            }}
          >
            + Start from scratch
          </Button>
        ) : null}
      </div>

      <ToastContainer
        style={{ width: '400px', fontSize: '12px' }}
        position="bottom-center"
        autoClose={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        transition={Slide}
      />

      <div className={styles.rightMenu}>
        <RegionsMenu
          getUserMonthPlan={getUserMonthPlan}
          createUserMonthPlan={createUserMonthPlan}
          updateParentState={updateParentState}
          updateUserAccount={updateUserAccount}
        />
        <LastUpdatedTimes />

        {headerMenuKind === headerMenuKinds.analyze || headerMenuKind === headerMenuKinds.home ? (
          <SettingsPopup />
        ) : null}

        {headerMenuKind === headerMenuKinds.dashboard && hasAccountView ? (
          <SettingsPopup
            isShowAttributionModel={false}
            isShowPredefinedFilters={false}
            isShowHideOtherCampaigns={false}
            isShowCalculateAdvancedMetrics={false}
          />
        ) : null}

        {headerMenuKind === headerMenuKinds.reports && hasReportId ? (
          <ReportActionMenu
            hasReportId={hasReportId}
          />
        ) : null}
      </div>
    </div>
  );
}

export default inject(
  ({
    questionsStore: {
      aiAnswersDataResults,
      setAIAnswersDataResults,
      setCurrentRequestId,
    },
    userStore: {
      userMonthPlan,
    } = {},
  }) => ({
    userMonthPlan,
    aiAnswersDataResults,
    setAIAnswersDataResults,
    setCurrentRequestId,
  }),
  observer
)(HeaderBar);
