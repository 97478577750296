import React, { useState } from 'react';
import history from 'history';
import { inject, observer } from 'mobx-react';
import { Button } from '@infinigrow/libs';

import useStyles from 'hooks/useStyles';

import useWindowDimensions from 'components/utils/getWindowDimensions';
import WidgetBuilderProperties from 'components/pages/widgetBuilder/WidgetBuilderProperties';
import WidgetBuilderPreview from 'components/pages/widgetBuilder/WidgetBuilderPreview';
import Spinner from 'components/pages/journeys/Spinner';

import { getQueryParams } from 'components/utils/UrlParamsProvider';
import { reportIdParam } from 'components/pages/reports/enums';
import {
  getWidgetConfigByType, getWidgetsBuilderDataResults, getDefaultWidgetProperties, checkIfAllFieldsAreFilled,
} from 'components/pages/widgetBuilder/logic/widgetBuilder';
import { getComponentProps } from 'components/pages/reports/logic/reports';
import { widgetNameErrorMessage, propertiesToKeepOnChangeKind } from 'components/pages/widgetBuilder/enums';
import { Events } from 'trackers/analytics/enums';

import servicesStore from 'stores/servicesStore';

import style from 'styles/reports/widget-builder.css';

const styles = style.locals || {};

function WidgetBuilder({
  addWidgetToReportRequest, reportsWithWidgetsData,
}) {
  useStyles([style]);

  const [widgetName, setWidgetName] = useState({ name: '', errorMessage: null });
  const [isLoading, setIsLoading] = useState(false);
  const [selectedWidgetProperties, setSelectedWidgetProperties] = useState(getDefaultWidgetProperties());
  const [widgetDataResults, setWidgetDataResults] = useState({});

  const widgetConfig = getWidgetConfigByType({ type: selectedWidgetProperties.widgetKind });
  const { height: screenHeight } = useWindowDimensions();
  const widgetWrapperHeight = `${screenHeight - 260}px`;

  const reportIdFromURL = getQueryParams({ queryParamKey: reportIdParam });
  if (!reportIdFromURL) {
    history.push({ pathname: '/reports' });
    return null;
  }

  const widgetsOfCurrentReport = reportsWithWidgetsData.find((report) => report.id === reportIdFromURL);
  const allWidgetsNamesFromReport = widgetsOfCurrentReport?.widgets.map((widget) => widget.title) || [];

  async function onSelectedWidgetProperties({ values, isRequestServerData = true }) {
    setSelectedWidgetProperties((prev) => ({ ...prev, ...values }));
    const allValues = { ...selectedWidgetProperties, ...values };
    const isAllFieldsAreFilled = checkIfAllFieldsAreFilled({ values: allValues, requiredFields: widgetConfig.requiredFieldsForRequest });
    if (isAllFieldsAreFilled) {
      if (!widgetName.name) {
        setWidgetName((prev) => ({ ...prev, errorMessage: widgetNameErrorMessage.nameIsMissing }));
      }
      if (isRequestServerData) {
        const widgetProps = getComponentProps({
          widgetData: {
            type: widgetConfig.widgetType,
            id: widgetConfig.widgetId,
            configuration: allValues,
          },
        });
        const dataResponse = await getWidgetsBuilderDataResults({ widget: widgetProps });
        setWidgetDataResults(dataResponse);
      }
    }
  }

  function onChangeWidgetName({ value }) {
    let errorMessage = null;
    if (allWidgetsNamesFromReport.includes(value)) {
      errorMessage = widgetNameErrorMessage.duplicateName;
    }
    if (isAllFilledAreFilledForRequest && !value) {
      errorMessage = widgetNameErrorMessage.nameIsMissing;
    }
    setWidgetName({ name: value, errorMessage });
  }

  function onChangeWidgetKind({ kind }) {
    const widgetPropertiesToKeep = {};
    for (const property of propertiesToKeepOnChangeKind) {
      widgetPropertiesToKeep[property] = selectedWidgetProperties[property];
    }
    setSelectedWidgetProperties({ ...getDefaultWidgetProperties(), ...widgetPropertiesToKeep, widgetKind: kind });
  }

  async function onCreateNewWidget({ widgetPropsData }) {
    setIsLoading(true);

    const widgetConfigForRequest = widgetPropsData.widgetConfig;
    delete widgetConfigForRequest.isInReports;
    delete widgetConfigForRequest.isReadOnly;
    delete widgetConfigForRequest.isEnableNavigationMenu;
    delete widgetConfigForRequest.widgetKind;

    await addWidgetToReportRequest({
      reportId: reportIdFromURL,
      title: widgetName.name,
      type: widgetPropsData.type,
      timeFrame: widgetPropsData.timeFrame,
      filters: widgetPropsData.filters,
      attributionModel: widgetPropsData.attributionModel,
      shouldUsePredefinedFilters: widgetPropsData.shouldUsePredefinedFilters,
      widgetConfig: widgetConfigForRequest,
      isCompareToPreviousEnabled: false,
    });
    history.goBack();
    setIsLoading(false);

    trackWidgetCreation();
  }

  function trackWidgetCreation() {
    servicesStore.eventTracker.track({
      eventName: Events.widgetCreated,
      properties: {
        widgetKind: selectedWidgetProperties.widgetKind,
        widgetType: widgetConfig.widgetType,
      },
    });

    servicesStore.eventTracker.track({
      eventName: Events.addToReport,
      properties: {
        widgetType: widgetConfig.widgetType,
        isNewReport: false,
      },
    });
  }

  const isAllFilledAreFilledForRequest = checkIfAllFieldsAreFilled({ values: selectedWidgetProperties, requiredFields: widgetConfig.requiredFieldsForRequest });
  const isAllFilledAreFilledForRender = checkIfAllFieldsAreFilled({ values: selectedWidgetProperties, requiredFields: widgetConfig.requiredFieldsForRender });
  const widgetProps = getComponentProps({
    widgetData: {
      type: widgetConfig.widgetType,
      configuration: selectedWidgetProperties,
    },
    isInReports: false,
    isShowTimeframeSelect: true,
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.widgetBuilderFrame} style={{ minHeight: widgetWrapperHeight }}>
        <WidgetBuilderProperties
          selectedWidgetProperties={selectedWidgetProperties}
          onSelectedWidgetProperties={({ values, isRequestServerData }) => onSelectedWidgetProperties({ values, isRequestServerData })}
          PropertiesComponent={widgetConfig?.PropertiesComponent}
          widgetName={widgetName}
          setWidgetName={({ value }) => onChangeWidgetName({ value })}
          onChangeWidgetKind={(kind) => onChangeWidgetKind({ kind })}
          widgetProps={widgetProps}
          isAllFilledAreFilledForRequest={isAllFilledAreFilledForRequest}
        />
        <div className={styles.widgetPreviewWrapper}>
          <WidgetBuilderPreview
            selectedWidgetProperties={selectedWidgetProperties}
            onSelectedWidgetProperties={({ values, isRequestServerData }) => onSelectedWidgetProperties({ values, isRequestServerData })}
            widgetConfig={widgetConfig}
            isAllFilledAreFilledForRequest={isAllFilledAreFilledForRequest}
            isAllFilledAreFilledForRender={isAllFilledAreFilledForRender}
            widgetProps={widgetProps}
            widgetDataResults={widgetDataResults}
          />
          <div className={styles.widgetPreviewButton}>
            {isLoading ? (
              <Spinner />
            ) : null}
            <Button
              type="primaryBlue"
              onClick={() => onCreateNewWidget({ widgetPropsData: widgetProps })}
              disabled={widgetName.errorMessage || isLoading || !isAllFilledAreFilledForRequest}
              dataTestId="create-widget"
            >
              Create widget
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default inject(
  ({
    reportsStore: {
      addWidgetToReportRequest,
      reportsWithWidgetsData,
    },
  }) => ({
    addWidgetToReportRequest,
    reportsWithWidgetsData,
  }),
  observer
)(WidgetBuilder);
