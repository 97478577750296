export default function SankeyShape({
  width = 500,
  leftHeight = 300,
  rightHeight = 100,
  leftY = 0,
  fillColor,
}) {
  const halfWidth = width / 2;
  const fullLeftHeight = leftHeight + leftY;
  return (
    <svg width={width} height={fullLeftHeight}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d={`M${width} 0 C${halfWidth} 0 ${halfWidth} ${leftY} 0 ${leftY} V${fullLeftHeight} C${halfWidth} ${fullLeftHeight} ${halfWidth} ${rightHeight} ${width} ${rightHeight}`}
        fill={fillColor}
      />
    </svg>
  );
}
