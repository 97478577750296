import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { toast } from 'react-toastify';
import { startCase } from 'lodash';

import useStyles from 'hooks/useStyles';

import CheckboxListPopup from 'components/common/CheckboxListPopup';
import serverCommunication from 'data/serverCommunication';
import servicesStore from 'stores/servicesStore';

import spotlightStyle from 'styles/insightsPage/spotlight.css';

const styles = spotlightStyle.locals || {};

function SpotlightSegmentTypeDropdown({
  UID,
  customFieldsIdToLabelMap,
  onSave,
  istSelectOptionsAsLabel,
  checkListToggleLabel,
  checkListToggleLabelPrefix,
}) {
  useStyles([spotlightStyle]);

  const [spotlightOptions, setSpotlightOptions] = useState([]);
  const [selectedSpotlightOptions, setSelectedSpotlightOptions] = useState([]);

  useEffect(() => {
    getSpotlightSelectedSegments();
  }, []);

  async function onSaveSelectedOptions({ selectedItems }) {
    try {
      await serverCommunication.serverRequest('PUT', 'spotlightSelectedSegments', JSON.stringify({ spotlightSelectedSegments: selectedItems }));
      if (onSave) {
        setSelectedSpotlightOptions(selectedItems);
        onSave();
      }
    } catch (error) {
      toast.error('Failed to save selection. Please refresh the page and try again.', {
        toastId: 'failed-setSpotlightSelectedSegments',
      });
      servicesStore.logger.error('failed to set spotlightSelectedSegments', {
        error,
        UID,
      });
    }
  }

  function parseSpotlightOptions({ data }) {
    const options = [];
    const selectedOptions = [];
    const dataKeys = Object.keys(data);
    for (const item of dataKeys) {
      const itemLabel = customFieldsIdToLabelMap[item] || startCase(item);
      options.push({ value: item, label: itemLabel });
      if (data[item]) {
        selectedOptions.push(item);
      }
    }
    return { options, selectedOptions };
  }

  async function getSpotlightSelectedSegments() {
    try {
      const response = await serverCommunication.serverRequest('GET', 'spotlightSelectedSegments');
      if (response.ok) {
        const responseData = await response.json();
        const { options, selectedOptions } = parseSpotlightOptions({ data: responseData });
        setSpotlightOptions(options);
        setSelectedSpotlightOptions(selectedOptions);
      }
    } catch (error) {
      servicesStore.logger.error('failed to get spotlightSelectedSegments', {
        error,
        UID,
      });
    }
  }

  return (
    <CheckboxListPopup
      onSave={(items) => onSaveSelectedOptions({ selectedItems: items })}
      options={spotlightOptions}
      selectedOptions={selectedSpotlightOptions}
      title="Customize segment types"
      className={styles.widgetCogwheelPopup}
      searchInputClass={styles.searchInput}
      withSearch
      istSelectOptionsAsLabel={istSelectOptionsAsLabel}
      checkListToggleLabel={checkListToggleLabel}
      checkListToggleLabelPrefix={checkListToggleLabelPrefix}
    />
  );
}

export default inject(
  ({
    userStore: {
      userAccount: {
        customFieldsIdToLabelMap,
      },
      userMonthPlan: {
        UID,
      },
    },
  }) => ({
    customFieldsIdToLabelMap,
    UID,
  }),
  observer
)(SpotlightSegmentTypeDropdown);
