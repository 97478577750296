import { useState, useCallback } from 'react';
import { inject, observer } from 'mobx-react';
import { Button } from '@infinigrow/libs';

import useStyles from 'hooks/useStyles';

import MultiLevelMenuPopup from 'components/common/MultiLevelMenuPopup';
import Dropdown from 'components/controls/Dropdown';

import { getOptionsWithSegmentMapping, groupBySegmentsOptions } from 'components/pages/analyze/SegmentsTab/logic/segments';
import { getSegmentsOptionsFields } from 'components/pages/analyze/OverviewTab/logic';

import style from 'styles/analyze/optimal-journey.css';

const styles = style.locals || {};

function JourneyBlocksBar({
  blocksItems = [],
  onClickBlockItem,
  onClickAddBlock,
  customFieldsIdToLabelMap,
  customUtmsWhitelist,
}) {
  useStyles([style]);

  const [showNavigationMenuPopup, setShowNavigationMenuPopup] = useState(false);

  const getStageMenuParams = useCallback(() => {
    const segmentsOptionsFields = getSegmentsOptionsFields({ customFieldsIdToLabelMap, customUtmsWhitelist });
    const interactionsOptions = segmentsOptionsFields.filter((option) => option.isInteraction);
    const segmentsOptions = segmentsOptionsFields.filter((option) => !option.isInteraction);
    const disabledOptions = blocksItems.map((blockItem) => blockItem.value);

    const interactionsWithDisabledOptions = interactionsOptions.map((option) => ({
      ...option,
      disabled: disabledOptions.includes(option.value),
    }));

    const groupedSegmentsWithDisabledOptions = getOptionsWithSegmentMapping({
      options: groupBySegmentsOptions({ options: segmentsOptions }),
      disabledOptions,
    });

    const commonDropdownProps = {
      isSearchable: true,
      menuIsOpen: true,
      isShowDropdownIndicator: false,
      autoFocus: true,
      menuListMaxHeight: '184px',
      menuShouldScrollIntoView: false,
      menuListWidth: '240px',
      placeholder: 'Search...',
      classNameMenu: styles.multiLevelMenuDropdownMenu,
      onChange: (blockItem) => {
        onClickAddBlock({ blockItem });
        setShowNavigationMenuPopup(false);
      },
    };

    return ([
      {
        label: 'Interactions',
        component: (
          <Dropdown
            {...commonDropdownProps}
            options={interactionsWithDisabledOptions}
          />),
        leftIcon: 'menuIcons:mousePointerClick',
      },
      {
        label: 'Segments',
        leftIcon: 'menuIcons:users',
        component: (
          <Dropdown
            {...commonDropdownProps}
            options={groupedSegmentsWithDisabledOptions}
          />),
      }]
    );
  }, [blocksItems]);

  return (
    <>
      <div className={styles.journeyBlocksTitle}>Journey blocks</div>

      <div>
        <Button
          type="primaryBlue"
          onClick={() => setShowNavigationMenuPopup(true)}
        >
          + Add block
        </Button>
        {showNavigationMenuPopup ? (
          <div className={styles.multiLevelMenuContainer}>
            <MultiLevelMenuPopup
              menuOptions={getStageMenuParams()}
              onClosePopup={(showNavigationPopup) => setShowNavigationMenuPopup(showNavigationPopup)}
            />
          </div>
        ) : null}
      </div>

      {blocksItems.map((blockItem, index) => {
        const { value, label, isSelected } = blockItem;
        const blockKey = `block-${index}-${value}`;
        return (
          <Button
            key={blockKey}
            type="secondaryWhite"
            onClick={() => onClickBlockItem({ blockItem })}
            selected={isSelected}
            className={styles.blockButton}
          >
            {label}
          </Button>
        );
      })}
    </>
  );
}

export default inject(
  ({
    userStore: {
      userAccount: {
        customFieldsIdToLabelMap,
        customUtmsWhitelist,
      },
    },
  }) => ({
    customFieldsIdToLabelMap,
    customUtmsWhitelist,
  }),
  observer
)(JourneyBlocksBar);
