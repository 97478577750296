exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".home__wrapper-iCd{display:flex;flex-direction:column;gap:40px}.home__wrapper-iCd:has(.stageBarPopup){margin-bottom:180px}[data-icon=\"breakdown:arrow\"]{background-image:url(\"/assets/multi-level-menu-icons/arrowBreakdownRight.svg\")}[data-icon=\"breakdown:queryStats\"]{background-image:url(\"/assets/multi-level-menu-icons/queryStats.svg\")}[data-icon=\"breakdown:ai\"]{background-image:url(\"/assets/multi-level-menu-icons/aiIconGray.svg\")}", ""]);

// exports
exports.locals = {
	"wrapper": "home__wrapper-iCd",
	"wrapper": "home__wrapper-iCd"
};