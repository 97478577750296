exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".tags__a-2-A{font-size:var(--fontsSizesBodyLarge);line-height:16px;font-weight:var(--fontsWeightBold);color:#24b10e;border-bottom:1px solid;text-decoration:none;cursor:pointer}", ""]);

// exports
exports.locals = {
	"a": "tags__a-2-A",
	"a": "tags__a-2-A"
};