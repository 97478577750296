exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".lastUpdatedTimes__popupLastUpdatedTimes-275{min-width:260px;top:56px;right:0;left:auto;padding:12px;min-height:auto;display:flex;gap:4px;flex-direction:column}.lastUpdatedTimes__box-2Bn{width:260px;margin:8px;font-size:var(--fontsSizesBodyNormal);line-height:1.71}.lastUpdatedTimes__boxHeader-ZCg{margin-bottom:4px}.lastUpdatedTimes__boxHeader-ZCg,.lastUpdatedTimes__boxRow-136 b{font-weight:var(--fontsWeightBold)}.lastUpdatedTimes__boxFooter-FG3{margin-top:12px}.lastUpdatedTimes__boxRow-136{display:flex;gap:4px}", ""]);

// exports
exports.locals = {
	"popupLastUpdatedTimes": "lastUpdatedTimes__popupLastUpdatedTimes-275",
	"popupLastUpdatedTimes": "lastUpdatedTimes__popupLastUpdatedTimes-275",
	"box": "lastUpdatedTimes__box-2Bn",
	"box": "lastUpdatedTimes__box-2Bn",
	"boxHeader": "lastUpdatedTimes__boxHeader-ZCg",
	"boxHeader": "lastUpdatedTimes__boxHeader-ZCg",
	"boxRow": "lastUpdatedTimes__boxRow-136",
	"boxRow": "lastUpdatedTimes__boxRow-136",
	"boxFooter": "lastUpdatedTimes__boxFooter-FG3",
	"boxFooter": "lastUpdatedTimes__boxFooter-FG3"
};