import userStore from 'stores/userStore';

import { getChannelNickname } from 'components/utils/filters/channels';
import { filterKinds } from 'components/utils/filters/logic';

export function getAiFilterLabel({ prefixLabel, kind, selectedOptions = [] }) {
  if (selectedOptions.length < 2) {
    return prefixLabel;
  }

  const isChannelFilter = kind === filterKinds.CHANNELS || kind === filterKinds.CHANNELS_BEFORE_STAGES;
  const isFunnelFilter = kind === filterKinds.FUNNEL_STAGES;

  const selectedFilterOptionsLabel = selectedOptions.map((item) => {
    if (isChannelFilter) {
      return getChannelNickname(item);
    } else if (isFunnelFilter) {
      return userStore.getMetricNickname({ metric: item });
    } else {
      return item;
    }
  }).join(', ');

  return `${prefixLabel} (${selectedFilterOptionsLabel})`;
}
