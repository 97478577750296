import { metricsTypes, metricTypeToLabelMapping } from 'components/common/logic/enums';
import { TIMEFRAME_VALUES } from 'components/utils/timeframe';

export const journeyInsightsTitle = 'Journey insights';
export const journeyStart = 'first';
export const keyChannels = 'channel';
export const keyJobTitles = 'jobTitle';
export const numberOfTouchpoints = 'numberOfSessions';
export const keyPatterns = 'pattern';
export const stageVelocity = 'velocity';
export const numberOfContacts = 'numberOfContacts';
export const pipelineAvgDealSize = 'pipelineAvgDealSize';
export const currentFunnelStage = 'currentFunnelStage';
export const salesCycle = metricsTypes.averageSalesCycle;
export const avgDealSize = metricsTypes.avgDealSize;

export const overviewMetrics = {
  salesCycle,
  avgDealSize,
  pipelineAvgDealSize,
};

export const metricKeysToLabelMapping = {
  [journeyStart]: 'Journey start',
  [keyChannels]: 'Key Channels',
  [keyJobTitles]: 'Key Job Titles',
  [numberOfTouchpoints]: '# of Touchpoints',
  [numberOfContacts]: '# of Contacts',
  [keyPatterns]: 'Key patterns',
  [stageVelocity]: 'Stage velocity',
  [salesCycle]: 'Sales Cycle',
  [avgDealSize]: metricTypeToLabelMapping.avgDealSize,
};

export const kpiFocusOptionsKeys = {
  conversionRateToLast: 'conversionRateToLast',
  conversionRateToNext: 'conversionRateToNext',
  shareOfTotal: 'shareOfTotal',
};

export const kpiFocusOptionsLabels = {
  [kpiFocusOptionsKeys.conversionRateToLast]: 'Conversion rate to',
  [kpiFocusOptionsKeys.conversionRateToNext]: 'Conversion Rate to Next Stage',
  [kpiFocusOptionsKeys.shareOfTotal]: 'Share of total (Most common)',
};

export function getKpiFocusOptions({ lastStageLabel }) {
  return [
    {
      value: kpiFocusOptionsKeys.conversionRateToLast,
      label: `${kpiFocusOptionsLabels.conversionRateToLast} ${lastStageLabel}`,
    },
    {
      value: kpiFocusOptionsKeys.conversionRateToNext,
      label: kpiFocusOptionsLabels.conversionRateToNext,
    },
    // TODO: comment this until the BE request support this
    // {
    //   value: kpiFocusOptionsKeys.shareOfTotal,
    //   label: kpiFocusOptionsLabels.shareOfTotal,
    // },
  ];
}

export const generalBlocks = [
  { value: stageVelocity, label: metricKeysToLabelMapping[stageVelocity] },
  { value: numberOfTouchpoints, label: metricKeysToLabelMapping[numberOfTouchpoints] },
  // TODO: comment this until the BE request support this
  // { value: numberOfContacts, label: metricKeysToLabelMapping[numberOfContacts] },
];

export const defaultSelectedBlocks = ['channel', stageVelocity];

export const optimalJourneyDefaultTimeframe = {
  value: TIMEFRAME_VALUES.ROLLING,
  rollingValue: 'last_12_month_month',
};
