exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".editable-label__wrapper-19o{display:flex;align-items:center;width:100%}.editable-label__editIcon-3Dc{background:url(/assets/edit-blue.svg) 50% no-repeat;width:16px;height:16px;display:none;margin-left:auto;cursor:pointer}.editable-label__wrapper-19o:hover .editable-label__editIcon-3Dc{display:block}.editable-label__label-RLt{height:36px;display:flex;align-items:center}.editable-label__textfield-mVL{width:100%}.editable-label__textfield-mVL input{height:36px;padding:0 8px;color:var(--colorsGeneralBlack);margin-left:-9px;width:calc(100% + 9px)}", ""]);

// exports
exports.locals = {
	"wrapper": "editable-label__wrapper-19o",
	"wrapper": "editable-label__wrapper-19o",
	"editIcon": "editable-label__editIcon-3Dc",
	"editIcon": "editable-label__editIcon-3Dc",
	"label": "editable-label__label-RLt",
	"label": "editable-label__label-RLt",
	"textfield": "editable-label__textfield-mVL",
	"textfield": "editable-label__textfield-mVL"
};