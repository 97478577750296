export const inspectTableData = [
  {
    identityId: '4f124d45-b1b9-4b2e-bbc4-99a11040f04b',
    displayName: 'Jfrog',
    domain: 'jfrog.com',
    uniqChannels: [
      'Email - Nurture',
      'events_offlineEvents_showcase',
      'G2',
      'Sales',
      'advertising_searchMarketing_SEM_googleAdwords',
      'advertising_searchMarketing_SEO',
      'Email - Sales',
    ],
    opportunity: 'Jfrog - q3 2024',
    opportunitySource: 'Direct',
  },
  {
    identityId: 'a14bd7b9-4f41-4742-9989-624ee8c4d3ae',
    displayName: 'Tesla',
    domain: 'tesla.com',
    uniqChannels: [
      'Sales',
    ],
    opportunity: 'Tesla - q1 2024',
    opportunitySource: 'None',
  },
  {
    identityId: '03692cdf-03e5-4f16-8fef-4050f14b8938',
    displayName: 'Apple',
    domain: 'apple.com',
    uniqChannels: [
      'advertising_socialAds_linkedinAdvertising',
      'Gartner Digital (Captera)',
      'advertising_searchMarketing_SEM_googleAdwords',
      'advertising_socialAds_twitterAdvertising',
      'email',
      'advertising_socialAds_facebookAdvertising',
      'Sales',
      'web_companyWebsite',
      'advertising_searchMarketing_SEO',
      'advertising_searchMarketing_SEM_bing',
    ],
    opportunity: 'Apple - q3 2024',
    opportunitySource: 'Direct',
  },
  {
    identityId: 'd17e90e0-ff88-431d-89b1-b27a84e8439b',
    displayName: 'HubSpot',
    domain: 'hubspot.com',
    uniqChannels: [
      'advertising_searchMarketing_SEM_googleAdwords',
    ],
    opportunity: 'HS - q1 2024',
    opportunitySource: 'Direct',
  },
  {
    identityId: 'c9575669-1258-45f8-81b1-e20672e42622',
    displayName: 'ups.com',
    domain: 'zckst.com',
    uniqChannels: [
      'advertising_socialAds_linkedinAdvertising',
      'advertising_socialAds_instagramAdvertising',
      'advertising_socialAds_facebookAdvertising',
      'Sales',
      'email',
    ],
    opportunity: 'UPS - q1 2024',
    opportunitySource: 'N/A',
  },
  {
    identityId: 'f9438810-dd42-4992-847f-57c9561390cf',
    displayName: 'Nvidia',
    domain: 'nvidia.com',
    uniqChannels: [
      'advertising_socialAds_linkedinAdvertising',
      'advertising_searchMarketing_SEM_googleAdwords',
      'events_offlineEvents_sponsorship',
      'advertising_socialAds_facebookAdvertising',
      'direct',
      'email',
    ],
    opportunity: 'Nvidia - q3 2024',
    opportunitySource: 'Direct',
  },
  {
    identityId: '6cd9813a-eed7-4ae3-b330-b4da24ffd041',
    displayName: 'Slack',
    domain: 'slack.com',
    uniqChannels: [
      'advertising_searchMarketing_SEM_googleAdwords',
    ],
    opportunity: 'Slack - q2 2024',
    opportunitySource: 'None',
  },
  {
    identityId: '0b1c5658-9b92-454f-a787-73620c099149',
    displayName: 'ghostmarket.io',
    domain: 'qckhtrhjb.com',
    uniqChannels: [
      'advertising_socialAds_linkedinAdvertising',
    ],
    opportunity: 'Ghostmarket - q1 2024',
    opportunitySource: 'Direct',
  },
  {
    identityId: '4c6cdddf-191c-4702-8dcb-53da9c8c8f36',
    displayName: 'rottentomatoes.com',
    domain: 'gqurtbh.com',
    uniqChannels: [
      'advertising_socialAds_linkedinAdvertising',
      'Sales',
    ],
    opportunity: 'Rotten Tomatoes - q1 2024',
    opportunitySource: 'N/A',
  },
  {
    identityId: 'd263f9f2-0a59-4343-910e-73ea40ccbe9c',
    displayName: 'dreamstime.com',
    domain: 'dreamstime.com',
    uniqChannels: [
      'email',
      'advertising_socialAds_facebookAdvertising',
      'Sales',
      'web_companyWebsite',
      'advertising_searchMarketing_SEO',
      'advertising_searchMarketing_SEM_bing',
    ],
    opportunity: 'Dreamstime - q3 2024',
    opportunitySource: 'Direct',
  },
];

export const drillDownData = {
  userEvents: {
    'Mar 30, 2024': [
      {
        selectedChangedBy: 'Jfrog - q3 2022',
        startTime: '2024-03-30T18:15:00Z',
        endTime: '2024-03-30T18:15:00Z',
        isFunnelStage: true,
        funnelStage: 'funnel3',
        nickname: 'Opp',
        previousFunnelStageNickname: 'MQL',
        startTimeDate: '2024-03-30T18:15:00.000Z',
        eventTypeOrder: 4,
        index: 2,
        amount: 30000,
      },
    ],
    'Mar 20, 2024': [
      {
        channel: 'Email - Sales',
        pages: [],
        sessions: [
          {
            crmPlatform: 'salesforce',
            email: 'ashley@jfrog.com',
            endTime: '2024-03-20T19:00:00Z',
            identityId: '4f124d45-b1b9-4b2e-bbc4-99a11040f04b',
            leadSources: {
              '154d4350-a600-4133-974c-322d26b4ad6a': [
                'Email',
              ],
              '6c5e9cf4-87ec-483d-8a3b-9de22d06ec10': [
                'Sales',
              ],
            },
            sessionType: 'crm',
            startTime: '2024-03-20T19:00:00Z',
            utms: {},
          },
          {
            crmPlatform: 'salesforce',
            email: 'john@jfrog.com',
            endTime: '2024-03-20T19:00:00Z',
            identityId: '4f124d45-b1b9-4b2e-bbc4-99a11040f04b',
            leadSources: {
              '154d4350-a600-4133-974c-322d26b4ad6a': [
                'Email',
              ],
              '6c5e9cf4-87ec-483d-8a3b-9de22d06ec10': [
                'Sales',
              ],
            },
            sessionType: 'crm',
            startTime: '2024-03-20T19:00:00Z',
            utms: {},
          },
        ],
        minStartTime: '2024-03-20T19:00:00Z',
        eventIndex: 1,
        startTime: '2024-03-20T19:00:00Z',
        startTimeDate: '2024-03-20T19:00:00.000Z',
        eventTypeOrder: 3,
      },
    ],
    'Mar 14, 2024': [
      {
        channel: 'events_offlineEvents_showcase',
        pages: [],
        sessions: [
          {
            crmPlatform: 'salesforce',
            email: 'ashley@jfrog.com',
            endTime: '2024-03-14T19:00:00Z',
            identityId: '4f124d45-b1b9-4b2e-bbc4-99a11040f04b',
            leadSources: {
              '154d4350-a600-4133-974c-322d26b4ad6a': [
                'Showcase',
              ],
              '6c5e9cf4-87ec-483d-8a3b-9de22d06ec10': [
                'NMKI - mega event 2022',
              ],
            },
            sessionType: 'crm',
            startTime: '2024-03-14T19:00:00Z',
            utms: {},
          },
          {
            crmPlatform: 'salesforce',
            email: 'john@jfrog.com',
            endTime: '2024-03-14T19:00:00Z',
            identityId: '4f124d45-b1b9-4b2e-bbc4-99a11040f04b',
            leadSources: {
              '154d4350-a600-4133-974c-322d26b4ad6a': [
                'Showcase',
              ],
              '6c5e9cf4-87ec-483d-8a3b-9de22d06ec10': [
                'NMKI - mega event 2022',
              ],
            },
            sessionType: 'crm',
            startTime: '2024-03-14T19:00:00Z',
            utms: {},
          },
          {
            crmPlatform: 'salesforce',
            email: 'matt@jfrog.com',
            endTime: '2024-03-14T19:00:00Z',
            identityId: '4f124d45-b1b9-4b2e-bbc4-99a11040f04b',
            leadSources: {
              '154d4350-a600-4133-974c-322d26b4ad6a': [
                'Showcase',
              ],
              '6c5e9cf4-87ec-483d-8a3b-9de22d06ec10': [
                'NMKI - mega event 2022',
              ],
            },
            sessionType: 'crm',
            startTime: '2024-03-14T19:00:00Z',
            utms: {},
          },
        ],
        minStartTime: '2024-03-14T19:00:00Z',
        eventIndex: 2,
        startTime: '2024-03-14T19:00:00Z',
        startTimeDate: '2024-03-14T19:00:00.000Z',
        eventTypeOrder: 3,
      },
    ],
    'Jan 10, 2024': [
      {
        channel: 'G2',
        pages: [],
        sessions: [
          {
            crmPlatform: 'salesforce',
            email: 'matt@jfrog.com',
            endTime: '2024-01-10T11:50:36Z',
            identityId: '4f124d45-b1b9-4b2e-bbc4-99a11040f04b',
            leadSources: {
              '154d4350-a600-4133-974c-322d26b4ad6a': [
                'G2',
              ],
              '6c5e9cf4-87ec-483d-8a3b-9de22d06ec10': [
                'Referral',
              ],
            },
            sessionType: 'crm',
            startTime: '2024-01-10T11:50:36Z',
            utms: {},
          },
        ],
        minStartTime: '2024-01-10T11:50:36Z',
        eventIndex: 3,
        startTime: '2024-01-10T11:50:36Z',
        startTimeDate: '2024-01-10T11:50:36.000Z',
        eventTypeOrder: 3,
      },
    ],
    'Dec 21, 2023': [
      {
        channel: 'Email - Nurture',
        pages: [],
        sessions: [
          {
            crmPlatform: 'salesforce',
            email: 'ashley@jfrog.com',
            endTime: '2023-12-21T13:00:12Z',
            identityId: '4f124d45-b1b9-4b2e-bbc4-99a11040f04b',
            leadSources: {
              '154d4350-a600-4133-974c-322d26b4ad6a': [
                'Email',
              ],
              '159227gf-f9j3-kd03-ncj3-jdj83ge3owee': [
                'Nurture, request a demo',
              ],
              '6c5e9cf4-87ec-483d-8a3b-9de22d06ec10': [
                'Nurture - request a demo',
              ],
            },
            sessionType: 'crm',
            startTime: '2023-12-21T13:00:12Z',
            utms: {},
          },
          {
            crmPlatform: 'salesforce',
            email: 'matt@jfrog.com',
            endTime: '2023-12-21T13:00:12Z',
            identityId: '4f124d45-b1b9-4b2e-bbc4-99a11040f04b',
            leadSources: {
              '154d4350-a600-4133-974c-322d26b4ad6a': [
                'Email',
              ],
              '159227gf-f9j3-kd03-ncj3-jdj83ge3owee': [
                'Nurture, request a demo',
              ],
              '6c5e9cf4-87ec-483d-8a3b-9de22d06ec10': [
                'Nurture - request a demo',
              ],
            },
            sessionType: 'crm',
            startTime: '2023-12-21T13:00:12Z',
            utms: {},
          },
        ],
        minStartTime: '2023-12-21T13:00:12Z',
        eventIndex: 4,
        startTime: '2023-12-21T13:00:12Z',
        startTimeDate: '2023-12-21T13:00:12.000Z',
        eventTypeOrder: 3,
      },
    ],
    'Dec 13, 2023': [
      {
        channel: 'advertising_searchMarketing_SEO',
        pages: [],
        sessions: [
          {
            crmPlatform: 'salesforce',
            email: 'ashley@jfrog.com',
            endTime: '2023-12-13T18:33:13Z',
            identityId: '4f124d45-b1b9-4b2e-bbc4-99a11040f04b',
            leadSources: {
              '154d4350-a600-4133-974c-322d26b4ad6a': [
                'Organic_Search',
              ],
              '6c5e9cf4-87ec-483d-8a3b-9de22d06ec10': [
                'Unkowm Keywords (SSL)',
              ],
              '9f922116-607f-4890-9892-d159dfb77bb5': [
                'Google',
              ],
            },
            sessionType: 'crm',
            startTime: '2023-12-13T18:33:13Z',
            utms: {},
          },
        ],
        minStartTime: '2023-12-13T18:33:13Z',
        eventIndex: 5,
        startTime: '2023-12-13T18:33:13Z',
        startTimeDate: '2023-12-13T18:33:13.000Z',
        eventTypeOrder: 3,
      },
    ],
    'Dec 9, 2023': [
      {
        classes: [
          'hs-form-private',
          'hsForm_fba00d96-6a70-4654-bdc9-1516c7c36kk9',
          'hs-form-fba00d96-6a70-4654-bdc9-1516c7c36kk9',
          'hs-form-fba00d96-6a70-4654-bdc9-1516c7c36kk9_d2e4f227-f017-4866-a8c8-9adc95dd4111',
          'hs-form',
          'stacked',
        ],
        email: 'ashley@jfrog.com',
        id: 'hsForm_fba00d96-6a70-4654-bdc9-1516c7c36kk9',
        path: '/request-demo',
        timestamp: '2023-12-09T18:34:20.000Z',
        uniqueFormHash: '3a92720933910d24d5b92a3148f6c5d2845cd97df0cd0e96a983719b3bfbeb1d',
        isForm: true,
        startTimeDate: '2023-12-09T18:34:20.000Z',
        eventTypeOrder: 2,
      },
      {
        channel: 'advertising_searchMarketing_SEM_googleAdwords',
        pages: [
          {
            contentChannel: 'content_contentCreation_eBook',
            endTime: '2023-12-09 18:33:13.000',
            path: '/',
            startTime: '2023-12-09 18:33:13.000',
            title: 'Jfrog',
            url: '/',
          },
          {
            contentChannel: 'content_contentCreation_eBook',
            endTime: '2023-12-09 18:34:20.000',
            path: '/request-demo',
            startTime: '2023-12-09 18:34:13.000',
            title: 'Request Demo',
            url: '/request-demo',
          },
        ],
        sessions: [
          {
            browser: 'Chrome',
            campaign: 'AMER brand',
            device: 'Computer',
            email: 'ashley@jfrog.com',
            endTime: '2023-12-09T18:34:20Z',
            identityId: '4f124d45-b1b9-4b2e-bbc4-99a11040f04b',
            leadSources: {},
            os: 'Windows',
            referrer: 'www.google.com',
            sessionType: 'online',
            startTime: '2023-12-09T18:33:13Z',
            utms: {
              utm_campaign: [
                'AMER brand',
              ],
              utm_medium: [
                'PPC',
              ],
              utm_source: [
                'Paid search',
              ],
            },
          },
        ],
        minStartTime: '2023-12-09T18:33:13Z',
        eventIndex: 6,
        startTime: '2023-12-09T18:33:13Z',
        startTimeDate: '2023-12-09T18:33:13.000Z',
        eventTypeOrder: 3,
      },
    ],
    'Dec 1, 2023': [
      {
        channel: 'advertising_searchMarketing_SEM_googleAdwords',
        pages: [
          {
            contentChannel: 'content_contentCreation_eBook',
            endTime: '2023-12-01 12:09:37.000',
            path: '/benifit-lp1',
            startTime: '2023-12-01 12:09:37.000',
            title: 'Benifit-lp1',
            url: '/benifit-lp1',
          },
          {
            contentChannel: 'content_contentCreation_eBook',
            endTime: '2023-12-01 12:10:37.000',
            path: '/our_solution',
            startTime: '2023-12-01 12:10:37.000',
            title: 'Our Solution',
            url: '/our_solution',
          },
          {
            contentChannel: 'content_contentCreation_eBook',
            endTime: '2023-12-01 12:10:37.000',
            path: '/',
            startTime: '2023-12-01 12:10:37.000',
            title: 'Jfrog',
            url: '/',
          },
        ],
        sessions: [
          {
            browser: 'Chrome',
            campaign: 'AMER brand',
            device: 'Computer',
            email: 'ashley@jfrog.com',
            endTime: '2023-12-01T12:10:37Z',
            identityId: '4f124d45-b1b9-4b2e-bbc4-99a11040f04b',
            leadSources: {},
            os: 'Windows',
            sessionType: 'online',
            startTime: '2023-12-01T12:09:37Z',
            utms: {
              utm_campaign: [
                'AMER brand',
              ],
              utm_medium: [
                'PPC',
              ],
              utm_source: [
                'Paid search',
              ],
            },
          },
        ],
        minStartTime: '2023-12-01T12:09:37Z',
        eventIndex: 7,
        startTime: '2023-12-01T12:09:37Z',
        startTimeDate: '2023-12-01T12:09:37.000Z',
        eventTypeOrder: 3,
      },
    ],
    'Oct 21, 2023': [
      {
        channel: 'Sales',
        pages: [],
        sessions: [
          {
            crmPlatform: 'salesforce',
            email: 'matt@jfrog.com',
            endTime: '2023-10-21T12:05:12Z',
            identityId: '4f124d45-b1b9-4b2e-bbc4-99a11040f04b',
            leadSources: {
              '154d4350-a600-4133-974c-322d26b4ad6a': [
                'SDR',
              ],
              '6c5e9cf4-87ec-483d-8a3b-9de22d06ec10': [
                'Nate Q1 22',
              ],
            },
            sessionType: 'crm',
            startTime: '2023-10-21T12:05:12Z',
            utms: {},
          },
        ],
        minStartTime: '2023-10-21T12:05:12Z',
        eventIndex: 8,
        startTime: '2023-10-21T12:05:12Z',
        startTimeDate: '2023-10-21T12:05:12.000Z',
        eventTypeOrder: 3,
      },
    ],
  },
  emails: [
    'matt@jfrog.com',
    'ashley@jfrog.com',
    'Anonymous',
    'john@jfrog.com',
  ],
};
