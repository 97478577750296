exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".aiPrompts__promptsWrap-1yj{gap:12px;padding:20px 0;display:grid;grid-template-columns:1fr 1fr 1fr}.aiPrompts__title-X2j{color:#182033;font-size:var(--fontsSizesTitleH4);font-weight:var(--fontsWeightBold);line-height:21px}.aiPrompts__subTitle-1Ua{color:var(--colorsTextGray);font-size:var(--fontsSizesBodyLarge);font-weight:var(--fontsWeightNormal);padding-top:12px;max-width:882px}", ""]);

// exports
exports.locals = {
	"promptsWrap": "aiPrompts__promptsWrap-1yj",
	"promptsWrap": "aiPrompts__promptsWrap-1yj",
	"title": "aiPrompts__title-X2j",
	"title": "aiPrompts__title-X2j",
	"subTitle": "aiPrompts__subTitle-1Ua",
	"subTitle": "aiPrompts__subTitle-1Ua"
};