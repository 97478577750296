import React from 'react';
import Dropzone from 'react-dropzone';

import Component from 'components/Component';
import G2PopupContainer from 'components/importCampaignsPopups/G2PopupContainer';
import AuthorizationIntegrationPopup from 'components/common/AuthorizationIntegrationPopup';
import Textfield from 'components/controls/Textfield';
import Title from 'components/onboarding/Title';

import style from 'styles/onboarding/onboarding.css';
import salesForceStyle from 'styles/indicators/salesforce-automatic-popup.css';
import CRMStyle from 'styles/indicators/crm-popup.css';
import excelStyle from 'styles/plan-from-excel.css';

export default class G2Popup extends Component {
  style = style;

  styles = [salesForceStyle, CRMStyle, excelStyle];

  constructor(props) {
    super(props);
    this.authPopupRef = React.createRef();
    this.state = {
      g2ApiKey: '',
      showCsvUpload: false,
      fileUploadSuccessfully: false,
      fileUploadError: false,
      isEmptyFileToUpload: false,
    };
  }

  handleStateChange = (newState) => {
    this.setState(newState);
  };

  open = () => {
    if (this.authPopupRef.current) {
      this.authPopupRef.current.open();
    }
  };

  openCsvUpload = () => {
    this.setState({ showCsvUpload: true }, this.open);
  };

  render() {
    const {
      setDataAsState, close, data, afterAuthorizationApi,
      loadingStarted, loadingFinished, updateState,
    } = this.props;
    return (
      <G2PopupContainer
        setDataAsState={setDataAsState}
        close={close}
        data={data}
        apiEndpoint={afterAuthorizationApi}
        loadingStarted={loadingStarted}
        loadingFinished={loadingFinished}
        updateState={updateState}
        onStateChange={this.handleStateChange}
      >
        {({
          afterAuthStep,
          afterDataRetrieved,
          objectsMapping,
          makeServerRequest,
          state,
          doneFirstStep,
          updateDataFinal,
          onFileAdd,
          deleteFile,
        }) => (
          <AuthorizationIntegrationPopup
            objectsMapping={objectsMapping}
            ref={this.authPopupRef}
            usingUrlOAuth={false}
            data={this.props.data}
            api={this.props.api}
            haveStepAfterAuthorizationBeforeMapping
            afterAuthorizationBeforeMappingStep={afterAuthStep}
            afterAuthorizationApi={afterAuthorizationApi}
            afterDataRetrieved={afterDataRetrieved}
            makeServerRequest={this.state.showCsvUpload ? updateDataFinal : () => makeServerRequest(this.state.g2ApiKey)}
            width={this.state.showCsvUpload ? '1100px' : '50%'}
            innerClassName={salesForceStyle.locals.inner}
            contentClassName={salesForceStyle.locals.content}
            loadingStarted={loadingStarted}
            loadingFinished={loadingFinished}
            autorizationRef={this.refs}
            validateBeforeDone={() => true}
            doneButtonText={this.state.showCsvUpload ? (this.state.fileUploadSuccessfully ? 'Done' : 'Upload') : 'Save'}
            doneButtonAction={this.state.showCsvUpload ? (this.state.fileUploadSuccessfully ? null : doneFirstStep) : null}
            platformTitle="G2"
            isLoading={this.props.isLoading}
          >
            <div>
              <Title className={salesForceStyle.locals.title} title={this.state.showCsvUpload ? 'G2 - Upload CSV' : 'G2 API Key'} />
              {this.state.showCsvUpload ? (
                <div className={this.classes.container}>
                  <Dropzone
                    onDropAccepted={onFileAdd}
                    className={this.classes.dropZone}
                    activeClassName={excelStyle.locals.dropZoneActive}
                    rejectClassName={excelStyle.locals.dropZoneReject}
                    accept=".csv"
                  >
                    <div className={excelStyle.locals.inner}>
                      <div className={excelStyle.locals.iconWrap}>
                        <div className={excelStyle.locals.icon} />
                      </div>
                      <div className={excelStyle.locals.innerText}>Drag & drop your file here, or browse.</div>
                    </div>
                  </Dropzone>
                  <div className={excelStyle.locals.uploadActions}>
                    {state.file && (
                    <div className={excelStyle.locals.row}>
                      <div className={this.classes.fileName}>
                        {state.file.name}
                      </div>
                      <div
                        className={this.classes.closeIcon}
                        onClick={deleteFile}
                      />
                    </div>
                    )}
                    {this.state.fileUploadError && (
                    <div className={this.classes.errorText}>
                      Error in parsing CSV file
                    </div>
                    )}
                    {this.state.fileUploadSuccessfully && (
                    <div className={this.classes.successText}>
                      Successfully uploaded CSV file
                    </div>
                    )}
                    {this.state.isEmptyFileToUpload && (
                    <div className={this.classes.errorText}>
                      No CSV file to upload
                    </div>
                    )}
                  </div>
                </div>
              ) : (
                <>
                  <div className={salesForceStyle.locals.container}>
                    {this.state.showCsvUpload
                      ? (
                        <ui>
                          <>Upload a CSV file with G2 data</>
                        </ui>
                      ) : (
                        <>
                          <ui><>1. Log in to your G2 account</></ui>
                          <ui> 2. Find or generate your API Key. It will be a unique identifier string.</ui>
                        </>
                      )}
                  </div>
                  <Textfield
                    placeHolder="Your G2 API Key"
                    value={this.state.g2ApiKey}
                    className={salesForceStyle.locals.textField}
                    onChange={(e) => {
                      this.setState({ g2ApiKey: e?.currentTarget?.value });
                    }}
                  />
                </>
              )}
              <div />
            </div>
          </AuthorizationIntegrationPopup>
        )}
      </G2PopupContainer>
    );
  }
}
