exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".product-launch-popup__box-2iD{padding:24px}.product-launch-popup__title-2rL{font-size:17px;font-weight:var(--fontsWeightBold);letter-spacing:-.01em;color:#273142;text-align:center;padding-bottom:15px;border-bottom:1px solid #dddee5}.product-launch-popup__choose-1wQ{margin:26px 0;align-items:center;white-space:pre-wrap;text-transform:none;text-align:left}.product-launch-popup__choose-1wQ,.product-launch-popup__nav-2wa{display:flex;justify-content:center}.product-launch-popup__text-3Mq{color:#354052;font-size:var(--fontsSizesBodyLarge);font-weight:var(--fontsWeightBold)}.product-launch-popup__range-icon-5tN{background:url(/assets/range-icon.png) 50% no-repeat;width:11px;height:22px;margin-left:22px}.product-launch-popup__next-icon-3B9{background:url(/assets/next-icon.png) 50% no-repeat;width:7px;height:11px;margin-left:12px}.product-launch-popup__back-icon-1ff{background:url(/assets/back-icon-accent.png) 50% no-repeat;width:6px;height:12px;margin-right:12px}", ""]);

// exports
exports.locals = {
	"box": "product-launch-popup__box-2iD",
	"box": "product-launch-popup__box-2iD",
	"title": "product-launch-popup__title-2rL",
	"title": "product-launch-popup__title-2rL",
	"choose": "product-launch-popup__choose-1wQ",
	"choose": "product-launch-popup__choose-1wQ",
	"nav": "product-launch-popup__nav-2wa",
	"nav": "product-launch-popup__nav-2wa",
	"text": "product-launch-popup__text-3Mq",
	"text": "product-launch-popup__text-3Mq",
	"range-icon": "product-launch-popup__range-icon-5tN",
	"rangeIcon": "product-launch-popup__range-icon-5tN",
	"next-icon": "product-launch-popup__next-icon-3B9",
	"nextIcon": "product-launch-popup__next-icon-3B9",
	"back-icon": "product-launch-popup__back-icon-1ff",
	"backIcon": "product-launch-popup__back-icon-1ff"
};