exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".errorWidgetWithBlur__wrapper-3XV{border-radius:12px;box-shadow:0 4px 12px 0 #e6e8f0;margin-bottom:40px;display:flex;align-items:center;justify-content:center;flex-direction:column;gap:24px;padding:32px;background-color:var(--colorsGeneralWhite);background-position:50%;background-size:contain;background-repeat:no-repeat}", ""]);

// exports
exports.locals = {
	"wrapper": "errorWidgetWithBlur__wrapper-3XV",
	"wrapper": "errorWidgetWithBlur__wrapper-3XV"
};