import React from 'react';
import ReactDOMServer from 'react-dom/server';
import {
  camelCase, isEmpty, isNaN, sumBy, orderBy,
} from 'lodash';

import userStore from 'stores/userStore';
import CellByColumn from 'components/widgets/segmentsDrilldown/CellByColumn';
import timeFrameModule from 'modules/timeframe';
import InfoMarker from 'components/pages/InfoMarker';
import CellByColumnSpotlight from 'components/widgets/segmentsDrilldown/CellByColumnSpotlight';

import { getColumnOptionsByData, isShowCostMetric } from 'components/pages/analyze/AttribuitonTable/logic/AttributionSegmentsTableParseData';
import { getColumnMetricNickName } from 'components/pages/analyze/AttribuitonTable/logic/AttributionTable';
import {
  segmentsKeys, upliftLabel, nonCumulativeMetricTypes, costMetrics, mappingMetricsKeys, metricsTypes, columnsBlackList, customFieldKey,
} from 'components/common/logic/enums';
import { formatIndicatorDisplay, getRevenueFunnel, getPipelineFunnel } from 'components/utils/indicators';
import {
  columnControllersLabels,
  columnControllersKeys,
  defaultSubColumnsOptionsByType,
  defaultSelectedSubColumnsByType,
  spotlightColumnsKeys,
  spotlightColumnsLabels, spotlightSupportedTimeframes,
} from 'components/widgets/segmentsDrilldown/enums';
import { formatBudget } from 'components/utils/budget';
import { getSegmentLabel } from 'components/pages/analyze/SegmentsTab/logic/segments';
import { attributionMetrics } from 'components/pages/analyze/AttribuitonTable/enums';
import { getCostPerMetricLabel } from 'components/utils/logic/utils';
import { spotlightTypes } from 'components/pages/insights/logic/enums';
import { TIMEFRAME_VALUES } from 'components/utils/timeframe';

export function getColumnTypeByMetric({ metric }) {
  return Object.keys(metricsTypes).find((key) => metric.toLowerCase().includes(key.toLowerCase()));
}

export function getSubColumnsOptionsByColumnKey({ columnKey, timeframe }) {
  const fullTimeFrame = timeFrameModule.getTimeframeParams({
    ...timeframe,
    fiscalYearFirstMonth: userStore.userMonthPlan.fiscalYearFirstMonth,
    useEndOfToday: false,
  });
  const isPastFrame = fullTimeFrame.endDate <= new Date();

  const columnType = getColumnTypeByMetric({ metric: columnKey });
  let subColumnsOptions = defaultSubColumnsOptionsByType[columnType];

  if (subColumnsOptions) {
    if (isPastFrame) {
      subColumnsOptions = subColumnsOptions.filter((option) => option !== columnControllersKeys.pacing);
    }
    return subColumnsOptions.map((option) => ({ value: option, label: columnControllersLabels[option].label }));
  }

  return [];
}

export function getDefaultSelectedSubColumns({ columns, metrics }) {
  const selectedColumnController = {};

  for (const columnKey of columns) {
    const columnType = getColumnTypeByMetric({ metric: columnKey });
    const selectedSubColumns = defaultSelectedSubColumnsByType[columnType];
    if (selectedSubColumns && metrics.includes(columnKey)) {
      selectedColumnController[columnKey] = selectedSubColumns;
    }
  }

  return selectedColumnController;
}

export function getTotalsByColumn({
  tableData, totals = {}, columnKey, shouldCalculateTotals, stage,
}) {
  if (columnKey.includes('touched')) {
    return '-';
  }

  let totalByColumn = totals[columnKey];
  const columnType = getColumnTypeByMetric({ metric: columnKey });
  const avoidCalculate = nonCumulativeMetricTypes.includes(columnType);

  if ((shouldCalculateTotals && !avoidCalculate) || !totalByColumn) {
    totalByColumn = sumBy(tableData, (item) => item[columnKey]);
  }

  let totalValue = totalByColumn;
  if (!totalValue) {
    totalValue = 0;
  }

  if (columnKey === metricsTypes.efficiency) {
    return getCostPerMetricLabel({ value: totalValue, stage });
  }

  const isCostMetric = costMetrics.includes(columnKey);
  if (isCostMetric) {
    return formatBudget(totalValue);
  }

  return formatIndicatorDisplay(columnKey, totalValue, true, true, true, 1);
}

export function getColumnsOptionsByStage({ stage, breakdownSegment, isAttribution }) {
  const groupedUserMetrics = userStore.getUserDynamicMetricsPerFunnel(stage);
  const metricsByStage = groupedUserMetrics[stage] || [];
  const metricsColumns = metricsByStage.filter((metric) => !columnsBlackList.includes(metric));

  const columnsOfSelectedMetric = [{
    value: 'touched',
    label: userStore.getMetricNickname({ metric: camelCase(`touched ${stage}`) }),
  }];

  if (isAttribution) {
    columnsOfSelectedMetric.push({
      value: 'attributed',
      label: userStore.getMetricNickname({ metric: camelCase(`attributed ${stage}`) }),
    });
  }

  const showCostMetric = isShowCostMetric({ firstSegmentValue: breakdownSegment });
  if (showCostMetric) {
    columnsOfSelectedMetric.push({
      label: 'Cost',
      value: 'cost',
    });
  }

  for (const column of metricsColumns) {
    const columnLabel = userStore.getMetricNickname({ metric: column });
    if (column.includes('costPer')) {
      if (showCostMetric) {
        columnsOfSelectedMetric.push({
          value: metricsTypes.efficiency,
          label: columnLabel,
        });
      }
      continue;
    }

    if (!isAttribution && attributionMetrics.some((attributedMetric) => column.includes(attributedMetric))) {
      continue;
    }

    columnsOfSelectedMetric.push({
      value: mappingMetricsKeys[column] || column,
      label: columnLabel,
    });
  }

  if (stage === getPipelineFunnel()) {
    columnsOfSelectedMetric.push({
      value: 'touchedPipeline',
      label: 'Touched Pipeline',
    });
  }

  if (stage === getRevenueFunnel()) {
    columnsOfSelectedMetric.push({
      value: 'touchedRevenue',
      label: 'Touched Revenue',
    });
  }

  return columnsOfSelectedMetric;
}

export function getColumnPriorityBySMetric({ metric }) {
  if (metric === metricsTypes.cost) {
    return 0;
  }

  if (metric === metricsTypes.attributed || metric === metricsTypes.touched) {
    return 1;
  }

  if (metric === metricsTypes.efficiency) {
    return 6;
  }

  const lowerMetric = metric.toLowerCase();
  const funnelsInMetric = lowerMetric.match(/funnel(\d+)/g);

  if (funnelsInMetric) {
    const funnelNumbers = funnelsInMetric.map((match) => parseInt(match.replace('funnel', ''), 10));
    const maxFunnelNumber = Math.max(...funnelNumbers);

    if (!isNaN(maxFunnelNumber)) {
      const priorityByFunnelNumber = maxFunnelNumber * 0.1;
      const basePriority = 1.01;
      return priorityByFunnelNumber + basePriority;
    }
  }

  return 5;
}

export function getTableColumnBySpotlightKey({
  columnKey,
  metric,
  sortByColumn,
  setSortByColumn,
  filtersByColumn,
  onUpdateFiltersByColumn,
  tableData,
  isLoaded,
  breakdownSegment,
  selectedSubColumns,
  subColumnClassName,
  onChangeSubColumnsSelection,
}) {
  const columnTitle = getColumnMetricNickName({ metric });

  const parentColumn = {
    id: columnKey,
    accessor: columnKey,
    header: columnTitle,
    inlineFiltersParams: {
      columnKey,
      selectedSegments: {
        firstSegment: metric,
      },
      sortByColumn,
      filtersByColumn,
      setSortByColumn: ({ id, desc }) => setSortByColumn({ id, desc }),
      updateFiltersByColumn: ({ filters }) => onUpdateFiltersByColumn({ filters }),
      isNumeric: true,
    },
    columns: [{
      sortable: false,
      accessor: columnKey,
      Header: '',
      Cell: (row) => (
        <CellByColumnSpotlight
          value={row.value}
          isLoaded={isLoaded}
          columnKey={columnKey}
          rowData={row.original}
          metric={metric}
        />
      ),
      minWidth: 160,
    }],
  };

  if (columnKey === spotlightColumnsKeys.segmentType) {
    parentColumn.inlineFiltersParams = undefined;
    parentColumn.header = spotlightColumnsLabels[columnKey];
  }

  if (columnKey === spotlightColumnsKeys.segmentValue) {
    parentColumn.inlineFiltersParams.columnFiltersOptions = getColumnOptionsByData({ selectedSegments: { [columnKey]: { value: 'channel', label: 'channel' } }, tableData, columnKey });
    parentColumn.inlineFiltersParams.isNumeric = false;
    parentColumn.header = spotlightColumnsLabels[columnKey];
  }

  if ((breakdownSegment === spotlightTypes.mostDownTrending || breakdownSegment === spotlightTypes.mostTrending) && columnKey === spotlightColumnsKeys.value) {
    parentColumn.header = `${columnTitle} Trend`;
  }

  if (columnKey === spotlightColumnsKeys.value) {
    const subColumnOption = { value: columnControllersKeys.diffFromAvg, label: columnControllersLabels.diffFromAvg.label };
    const subColumnsByColumnKey = selectedSubColumns[columnKey];

    parentColumn.inlineColumnControllerParams = {
      options: [subColumnOption],
      selectedOptions: subColumnsByColumnKey || [],
      onChange: (subColumn) => onChangeSubColumnsSelection({ parentColumn: columnKey, subColumn }),
    };

    if (subColumnsByColumnKey) {
      parentColumn.columns = [
        {
          sortable: false,
          accessor: columnKey,
          Header: (<div className={subColumnClassName.header}>Value</div>),
          Cell: (row) => (
            <CellByColumnSpotlight
              value={row.value}
              isLoaded={isLoaded}
              columnKey={columnKey}
              rowData={row.original}
              metric={metric}
            />
          ),
        },
        {
          sortable: false,
          accessor: spotlightColumnsKeys.averageRatio,
          Header: (<div className={subColumnClassName.header}>{columnControllersLabels[columnControllersKeys.diffFromAvg].label}</div>),
          Cell: (row) => (
            <CellByColumnSpotlight
              value={row.value}
              isLoaded={isLoaded}
              columnKey={spotlightColumnsKeys.averageRatio}
            />
          ),
          getProps: () => ({
            className: subColumnClassName.cell,
          }),
        },
      ];
    }
  }

  return parentColumn;
}

export function getTableColumnByMetricKey({
  columnKey,
  upliftMinMaxByMetric,
  selectedSubColumns,
  selectedStage,
  timeframe,
  breakdownSegment,
  sortByColumn,
  setSortByColumn,
  filtersByColumn,
  onUpdateFiltersByColumn,
  onChangeSubColumnsSelection,
  tableData,
  totals,
  isLoaded,
  isLoadedUplift,
  isCompareToPrevious,
  subColumnClassName,
  totalStageAmount,
  isAttribution,
}) {
  let columnTitle = getColumnMetricNickName({ metric: columnKey });

  if (columnKey === 'attributed' || columnKey === 'touched') {
    columnTitle = `${columnTitle} ${userStore.getMetricNickname({ metric: selectedStage })}`;
  }

  const subColumnsByColumnKey = selectedSubColumns[columnKey];
  const shouldCalculateTotals = !isEmpty(filtersByColumn);

  const totalByColumn = getTotalsByColumn({
    tableData, totals, columnKey, shouldCalculateTotals, stage: selectedStage,
  });

  const inlineSubColumnsOptions = getSubColumnsOptionsByColumnKey({ columnKey, timeframe });

  const parentColumn = {
    id: columnKey,
    accessor: columnKey,
    header: columnTitle,
    priority: getColumnPriorityBySMetric({ metric: columnKey }),
    inlineFiltersParams: {
      columnKey,
      selectedSegments: {
        firstSegment: breakdownSegment,
      },
      sortByColumn,
      filtersByColumn,
      setSortByColumn: ({ id, desc }) => setSortByColumn({ id, desc }),
      updateFiltersByColumn: ({ filters }) => onUpdateFiltersByColumn({ filters }),
      isNumeric: true,
    },
    inlineColumnControllerParams: inlineSubColumnsOptions.length > 0 ? {
      options: inlineSubColumnsOptions,
      selectedOptions: subColumnsByColumnKey || [],
      onChange: (subColumn) => onChangeSubColumnsSelection({ parentColumn: columnKey, subColumn }),
    } : undefined,
    columns: [{
      sortable: false,
      accessor: columnKey,
      Header: '',
      Cell: (row) => (
        <CellByColumn
          value={row.value}
          isCompareToPrevious={isCompareToPrevious}
          growthPerMetric={isCompareToPrevious ? row.original.growthPerMetric : null}
          isLoaded={isLoaded}
          columnKey={columnKey}
          breakdownSegment={breakdownSegment}
          stage={selectedStage}
        />
      ),
      Footer: totalByColumn,
      minWidth: 160,
    }],
  };

  if (subColumnsByColumnKey) {
    const subColumns = subColumnsByColumnKey.map((subColumnKey) => {
      const headerLabel = columnControllersLabels[subColumnKey]?.shortLabel || subColumnKey;
      const isUpliftColumn = subColumnKey === columnControllersKeys.uplift;
      const tooltip = getSubHeaderTooltip({
        columnKeyLabel: columnTitle, subHeaderLabel: headerLabel, subColumnKey, segmentName: breakdownSegment,
      });
      return ({
        sortable: false,
        accessor: isUpliftColumn ? `${columnKey}${upliftLabel}` : columnKey,
        Header: (
          <div className={subColumnClassName.header}>
            {headerLabel}
            {tooltip ? (
              <InfoMarker
                tooltipText={tooltip}
                iconClassName={subColumnClassName.icon}
                TooltipProps={{ className: subColumnClassName.tooltip }}
              />
            ) : null}
          </div>
        ),
        Cell: (row) => (
          <CellByColumn
            value={row.value}
            isLoaded={isLoaded}
            isLoadedUplift={isLoadedUplift}
            columnKey={subColumnKey}
            timeframe={timeframe}
            tableData={tableData}
            metric={columnKey}
            upliftMinMaxByMetric={upliftMinMaxByMetric}
            totalStageAmount={totalStageAmount}
            isAttribution={isAttribution}
          />
        ),
        Footer: '',
        getProps: () => ({
          className: subColumnClassName.cell,
        }),
        minWidth: isUpliftColumn ? 200 : 124,
      });
    });

    parentColumn.columns = [
      {
        sortable: false,
        accessor: columnKey,
        Header: (<div className={subColumnClassName.header}>Volume</div>),
        Cell: (row) => (
          <CellByColumn
            value={row.value}
            isLoaded={isLoaded}
            columnKey={columnKey}
            isCompareToPrevious={isCompareToPrevious}
            growthPerMetric={isCompareToPrevious ? row.original.growthPerMetric : null}
            breakdownSegment={breakdownSegment}
            stage={selectedStage}
          />
        ),
        Footer: totalByColumn,
        minWidth: isCompareToPrevious ? 140 : 100,
      },
      ...subColumns,
    ];
  }

  if (columnKey === segmentsKeys.firstSegment) {
    parentColumn.inlineFiltersParams.columnFiltersOptions = getColumnOptionsByData({ selectedSegments: { firstSegment: breakdownSegment }, tableData, columnKey });
    parentColumn.inlineFiltersParams.isNumeric = false;
    parentColumn.fixed = 'left';
    parentColumn.columns[0].Footer = 'Total';
    parentColumn.columns[0].minWidth = 200;
    parentColumn.header = getSegmentLabel({ segment: breakdownSegment, customFieldsIdToLabelMap: userStore.userAccount?.customFieldsIdToLabelMap, customUtmsWhitelist: userStore.userAccount?.customUtmsWhitelist });
  }

  if (columnKey === metricsTypes.efficiency) {
    parentColumn.header = `Cost per ${userStore.getMetricNickname({ metric: selectedStage })}`;
  }

  return parentColumn;
}

export function getSubHeaderTooltip({
  columnKeyLabel, subHeaderLabel, subColumnKey, segmentName,
}) {
  let body;
  switch (subColumnKey) {
    case columnControllersKeys.uplift:
      body = 'Measures the impact of the segment by comparing conversion rates of journeys that include it vs. those that don’t.';
      break;
    case columnControllersKeys.pacing:
      body = `Projected ${columnKeyLabel} by the period’s end.`;
      break;
    case columnControllersKeys.compare:
      body = `Difference in ${columnKeyLabel} compared to the average.`;
      break;
    case columnControllersKeys.shareOfTotal: {
      const isAttributed = columnKeyLabel.includes('Attributed');
      if (isAttributed) {
        body = `The share of ${columnKeyLabel} out of the total number of ${columnKeyLabel}.`;
        break;
      }
      body = `How common is a ${segmentName}, in terms of influence on total ${columnKeyLabel}`;
      break;
    }
    default:
      return null;
  }

  return ReactDOMServer.renderToStaticMarkup(
    <>
      <div style={{ fontSize: '14px', fontWeight: 600, paddingBottom: '4px' }}>
        {subHeaderLabel}
      </div>
      <div className="body">
        {body}
      </div>
    </>
  );
}

export function getSpotlightColumnOptionsByData({ tableData = [], columnKey }) {
  if (tableData?.length === 0) {
    return [];
  }

  const uniqueColumnData = [];
  const uniqueValues = new Set();

  for (const tableRow of tableData) {
    if (!tableRow[columnKey]) {
      continue;
    }

    const optionKey = tableRow[columnKey];
    const optionLabel = optionKey === customFieldKey ? getSegmentLabel({ segment: tableRow.segmentId, customFieldsIdToLabelMap: userStore.userAccount?.customFieldsIdToLabelMap, customUtmsWhitelist: userStore.userAccount?.customUtmsWhitelist }) : optionKey;
    const optionValue = optionKey === customFieldKey ? tableRow.segmentId : optionKey;
    const optionItem = { label: optionLabel, value: optionValue };

    if (uniqueValues.has(optionItem.value)) {
      continue;
    }
    uniqueValues.add(optionItem.value);
    uniqueColumnData.push(optionItem);
  }

  return uniqueColumnData.sort((a, b) => a.label?.localeCompare(b.label, undefined, { numeric: true, sensitivity: 'base' }));
}

export function getDefaultOptimizationMetric({ stage }) {
  const relevantForMetrics = userStore.getAllRelevantForMetrics({ metric: stage });
  const orderedRelevantForMetrics = orderBy(relevantForMetrics, ['orderInGroup']);
  const conversionRateMetrics = orderedRelevantForMetrics.find((metric) => metric.metricType === metricsTypes.conversionRate);
  return conversionRateMetrics.metricName;
}

export function getIsSpotlightTimeframeSupported({ timeframe = {} }) {
  const isRollingTimeframe = timeframe?.value === TIMEFRAME_VALUES.ROLLING && timeframe?.rollingValue;
  if (isRollingTimeframe) {
    const isMonthRelativeRolling = timeframe?.rollingValue?.includes('monthRelative');
    const isDayRolling = timeframe.rollingValue?.includes('day');
    const extractRollingNumber = parseInt(timeframe.rollingValue.match(/\d+/)?.[0], 10);
    const isValidMonth = extractRollingNumber >= 1 && extractRollingNumber <= 12;
    return isValidMonth && !isMonthRelativeRolling && !isDayRolling;
  }

  return spotlightSupportedTimeframes.includes(timeframe?.value);
}
