exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".search-input__search-2PK{display:flex;align-items:center;box-sizing:border-box;padding:2px 0;width:fit-content;height:40px;border-radius:4px;border:1px solid var(--colorsInputGrayLine);background-color:#fff;position:relative}.search-input__searchInput-2s9{width:160px;height:100%;font-size:var(--fontsSizesBodyNormal);-webkit-user-select:none;user-select:none;white-space:pre;border:0;border-radius:4px;outline:none}.search-input__searchIcon-ldX{width:14px;height:14px;background:url(/assets/search.svg) 50% no-repeat;background-size:contain;margin-right:8px;margin-left:8px;cursor:text}.search-input__searchClear-TFJ{width:14px;height:14px;background:url(/assets/x.svg) 50% no-repeat;background-size:contain;margin-left:8px;margin-right:8px;cursor:pointer;opacity:.6;position:absolute;top:14px;right:2px}.search-input__disabled-3Jj,.search-input__searchInput-2s9[disabled]{background:#fbfbfd!important}", ""]);

// exports
exports.locals = {
	"search": "search-input__search-2PK",
	"search": "search-input__search-2PK",
	"searchInput": "search-input__searchInput-2s9",
	"searchInput": "search-input__searchInput-2s9",
	"searchIcon": "search-input__searchIcon-ldX",
	"searchIcon": "search-input__searchIcon-ldX",
	"searchClear": "search-input__searchClear-TFJ",
	"searchClear": "search-input__searchClear-TFJ",
	"disabled": "search-input__disabled-3Jj",
	"disabled": "search-input__disabled-3Jj"
};