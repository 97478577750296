import React from 'react';
import moment from 'moment-timezone';

import userStore from 'stores/userStore';

import { suggestionsDropdownTypes } from 'components/pages/questions/enums';
import { getNickname } from 'components/utils/channels';
import { contentTypeSegmentValue } from 'components/pages/analyze/SegmentsTab/logic/enums';
import { formatBudget } from 'components/utils/budget';

export const numberWithZeroDefault = (getter) => (arg, ...other) => getter(arg, ...other) || 0;

export const getMappingOptions = (idToLabelMap = {}) => {
  const mappingOptions = [];
  for (const [id, label] of Object.entries(idToLabelMap)) {
    const optionObject = { value: id, label, suggestionsDropdownType: suggestionsDropdownTypes.customFields };
    const customSegmentMapping = userStore.userAccount?.customSegmentsMapping?.[id];
    if (customSegmentMapping) {
      optionObject.disabled = !customSegmentMapping.enabled;
      optionObject.icon = (<img alt="customSegment" src="/assets/analyze-icons/custom-segment.svg" />);
    }
    mappingOptions.push(optionObject);
  }

  return mappingOptions;
};

export function getGroupedUserMetrics({ userMetrics = [] } = {}) {
  const groupLabels = {};
  const groups = {};
  const { isPipelineStageHidden } = userStore.userMonthPlan || {};

  for (const metricData of userMetrics) {
    const {
      relevantFor, title, metricName, isRelevantForTarget, nickname, enabled, metricType,
    } = metricData;

    if (isPipelineStageHidden && metricName.toLowerCase().includes('pipeline')) {
      continue;
    }

    const optionItem = {
      label: nickname,
      value: metricName,
      enabled,
      metricType,
      relevantFor,
    };

    const relevantForValue = relevantFor || metricName;
    if (!groups[relevantForValue]) {
      groups[relevantForValue] = [];
    }

    groups[relevantForValue].push(optionItem);

    if (isRelevantForTarget || relevantForValue === metricName) {
      groupLabels[relevantForValue] = title;
    }
  }

  const groupedMetrics = Object.entries(groups).map(([groupName, options]) => {
    const label = groupLabels[groupName] || groupName;
    return {
      label,
      groupName,
      options,
    };
  });
  return groupedMetrics;
}

export function camelCaseToReadable({ camelCaseString }) {
  if (typeof camelCaseString !== 'string') {
    return '';
  }

  const isStringAlreadyReadable = /\s/.test(camelCaseString);
  if (isStringAlreadyReadable) {
    return camelCaseString;
  }

  return camelCaseString
  // Insert a space before all caps
    .replace(/([A-Z])/g, ' $1')
  // Insert a space before numbers only if preceded by a letter
    .replace(/([a-zA-Z])(\d)/g, '$1 $2')
  // Remove the first space if it exists
    .replace(/^ /, '')
  // Handle consecutive uppercase letters (like in acronyms)
    .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
  // Capitalize the first letter
    .replace(/^./, (str) => str.toUpperCase());
}

export function getUniqueTitle({ baseTitle, listOfNames }) {
  let copyNumber = 1;
  let newTitle = `${baseTitle} (${`${copyNumber}`.padStart(2, '0')})`;

  while (listOfNames.includes(newTitle)) {
    copyNumber++;
    newTitle = `${baseTitle} (${`${copyNumber}`.padStart(2, '0')})`;
  }

  return newTitle;
}

export function calculateLogarithmicPercent({ currentValue, maxValue }) {
  if (currentValue === 0 || maxValue === 0) {
    return 0;
  }
  const normalizedCurrent = currentValue / maxValue;
  const power = 0.8;
  const adjustedValue = normalizedCurrent ** power;
  const logValue = Math.log(1 + (adjustedValue * 24));
  const logMax = Math.log(25);

  return (logValue / logMax) * 100;
}

export function getPacingCalculate({ timeframe, actual }) {
  const startDate = moment(timeframe.startDate);
  const endDate = moment(timeframe.endDate);

  const daysSinceStarted = moment().diff(startDate, 'days') || 1;
  const totalDuration = Math.round(endDate.diff(startDate, 'days', true)) || 1;
  const extrapolateConst = Math.min(daysSinceStarted, totalDuration) / totalDuration;

  return Math.round(actual / extrapolateConst);
}

export function getItemLabelWithNoValue({ value, segment, segmentLabel }) {
  let itemLabel = value;
  if (value === 'No Value' || !value) {
    itemLabel = `No ${segmentLabel}`;
  }
  if (segment === 'channel' || segment === contentTypeSegmentValue) {
    itemLabel = getNickname(value);
  }
  return itemLabel;
}

export function getCostPerMetricLabel({ value, stage }) {
  return `${formatBudget(value)} per ${userStore.getMetricNickname({ metric: stage })}`;
}

export function reorderArrayByArray({ orderByArray, arrayToOrder }) {
  return arrayToOrder.sort((a, b) => {
    const indexOfA = orderByArray.indexOf(a);
    const indexOfB = orderByArray.indexOf(b);
    if (indexOfA === -1) { return 1; }
    if (indexOfB === -1) { return -1; }
    return indexOfA - indexOfB;
  });
}
