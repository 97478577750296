import { isNil } from 'lodash';

import useStyles from 'hooks/useStyles';

import Skeleton from 'components/common/Skeleton';
import Tooltip from 'components/controls/Tooltip';

import { metricsFormatter } from 'components/widgets/optimalJourney/logic/optimalJourney';
import { numberOfTouchpoints } from 'components/widgets/optimalJourney/enums';

import style from 'styles/analyze/journey-canvas.css';

const styles = style.locals || {};

export default function TouchpointsPill({
  isLoaded,
  data = {},
  currentStage,
  nextStage,
  lastStage,
}) {
  useStyles([style]);

  if (!isLoaded && isNil(data.value)) {
    return (
      <div className={styles.pillSkeleton}>
        <Skeleton height={24} width="100%" borderRadius={20} />
      </div>
    );
  }

  const touchpointValue = metricsFormatter({ metricKey: numberOfTouchpoints, value: data.value }) || 0;
  const touchpointGrowthValue = metricsFormatter({ growth: data.growth });

  const touchpointsTooltipText = (
    <div>
      <div>
        {'# of Touchpoints: '}
        <b>{touchpointValue}</b>
        <br />
        {'between '}
        <b>{currentStage?.label}</b>
        {' and '}
        <b>{nextStage?.label}</b>
      </div>
      <ul>
        <li>
          {lastStage.label}
          {' journeys take '}
          {data.growth === 0 ? (
            <>
              {' the same number of touchpoints to convert from '}
            </>
          ) : (
            <>
              <b>
                {touchpointGrowthValue}
                %
              </b>
              {' more touchpoints to convert from '}
            </>
          )}
          {`${currentStage.label} to ${nextStage.label}`}
          , compared to the previous period.
        </li>
      </ul>
    </div>
  );

  return (
    <div className={styles.touchpointsPillWrapper}>
      <Tooltip
        id={`touchpoints-pill-${currentStage.value}-${nextStage.value}-${lastStage.value}-${currentStage.value}`}
        content={touchpointsTooltipText}
        TooltipProps={{
          className: styles.clickableTooltip,
        }}
      >
        <b data-testid="touchpoints-label">
          {touchpointValue}
        </b>
        {' '}
        Touchpoints
      </Tooltip>
    </div>
  );
}
