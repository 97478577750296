import React, { useState, useMemo } from 'react';
import { isEmpty, cloneDeep } from 'lodash';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';

import useStyles from 'hooks/useStyles';

import { Events } from 'trackers/analytics/enums';
import servicesStore from 'stores/servicesStore';

import AddGoalStats from 'components/pages/insights/overviewTab/goals/AddGoalStats';
import CarouselArrowNavigation from 'components/common/CarouselArrowNavigation';
import StatsMetricWithGoal from 'components/pages/insights/overviewTab/goals/StatsMetricWithGoal';
import WidgetHeader from 'components/common/WidgetHeader';
import IndicationTag from 'components/common/IndicationTag';
import ActionPopup from 'components/pages/settings/actions/actionPopup';
import ErrorWidgetWithBlur from 'components/common/ErrorWidgetWithBlur';

import { widgetTypes } from 'components/pages/analyze/enums';
import { setTagIdOnGoalByPacingAndMostAdvancedStage, getGoalsWithPacing } from 'components/pages/insights/overviewTab/goals/logic/goalsAnalysis';
import { actionTriggerTypes } from 'components/pages/settings/actions/enums';
import { activityStatusValues, goalSkeletonId } from 'components/pages/settings/goals/logic/enums';

import style from 'styles/insightsPage/goals/goalsAnalysis.css';
import analyzeStyle from 'styles/analyze/analyze.css';

const styles = style.locals;
const analyzeStyles = analyzeStyle.locals;

function GoalsAnalysis({
  goals = [],
  selectedGoal = {},
  setSelectedGoal,
  onStartCreateNewGoal,
  onFinishCreateNewGoal,
  frequencyType,
  timeframe,
  customTimeframeUpdate,
  widgetHeaderConfig = {
    title: 'Goal analysis',
    type: widgetTypes.goalsAnalysis,
    goalsAnalysisParams: {
      supportFilters: true,
      status: activityStatusValues.ALL,
      frequencyType,
      timeframe,
    },
  },
  widgetHeaderProps = {},
  isHideAddToReport,
  isInsightsPage,
  isEnableCreateNewGoal,
  isGoalsSupportSelectedTimeframe = true,
  isHavingApplyingFilters = false,
  isOnboarding,
  isShowAddAlertInPopupNavigationMenu,
  userRegionsNicknames,
  actions,
  navigationMenuPopupParams,
  isFailedToLoad,
  isLoaded,
  goalsAnalysisRequestId,
}) {
  useStyles([style, analyzeStyle]);

  const [actionPopupWithData, setActionPopupWithData] = useState(null);

  const goalsWithEmptyState = useMemo(() => {
    let goalsWithEmpty = !isLoaded ? goals.concat({ id: goalSkeletonId }) : cloneDeep(goals);
    if (isOnboarding) {
      const goalsWithPacing = getGoalsWithPacing({ goals });
      goalsWithEmpty = setTagIdOnGoalByPacingAndMostAdvancedStage({ goals: goalsWithPacing });
    }

    if (isEnableCreateNewGoal) {
      goalsWithEmpty = goalsWithEmpty.concat({});
    }

    return goalsWithEmpty;
  }, [goals, isLoaded]);

  function getNavigationMenuPopupParams({ goalId }) {
    const navigationParams = [];

    if (isShowAddAlertInPopupNavigationMenu) {
      navigationParams.push({
        title: 'Create an Alert',
        subTitle: 'Get an alert to help you track this goal',
        navigationFunction: () => {
          setActionPopupWithData({
            triggerType: actionTriggerTypes.goalsTracking,
            triggerRules: [{ goalId }],
          });
          servicesStore.eventTracker.track({
            eventName: Events.alertsNewAlertClicked,
          });
        },
      });
    }

    if (navigationMenuPopupParams) {
      navigationParams.push(...navigationMenuPopupParams);
    }

    return navigationParams;
  }

  const hasNavigationMenuPopupParams = isShowAddAlertInPopupNavigationMenu || navigationMenuPopupParams?.length > 0;

  if (isFailedToLoad) {
    return (
      <ErrorWidgetWithBlur
        status="error"
        widgetType={widgetTypes.goalsAnalysis}
      />
    );
  }

  return (
    <div className={classNames(styles.wrapper, isInsightsPage ? null : analyzeStyles.widgetSpace)}>
      {isInsightsPage ? (
        <div className={styles.title}>
          Goal analysis
        </div>
      ) : (
        <WidgetHeader
          {...widgetHeaderProps}
          widgetHeaderConfig={widgetHeaderConfig}
          isHideAddToReport={isHideAddToReport}
          customTimeframeUpdate={customTimeframeUpdate}
          isShowFilterOptions={false}
          isShowAttributionModelOptions={false}
        >
          {(!isGoalsSupportSelectedTimeframe || isHavingApplyingFilters) && (
          <IndicationTag
            icon={!isGoalsSupportSelectedTimeframe ? 'indicationTag:timeframeNotSupported' : 'indicationTag:unfilter'}
            text={!isGoalsSupportSelectedTimeframe ? 'Timeframe not supported' : 'Unfiltered'}
            colorType="orange"
          />
          )}
        </WidgetHeader>
      )}
      {isInsightsPage && (
      <div className={styles.subTitle}>
        Click a goal at risk to explore related metrics and insights
      </div>
      )}
      {isGoalsSupportSelectedTimeframe && (
      <CarouselArrowNavigation
        list={goalsWithEmptyState}
        maxItemsInLine={4}
      >
        {({ availableList }) => (
          <div className={styles.goalsStatsWrapper}>
            {availableList.map((goal) => (isEmpty(goal) ? (
              <AddGoalStats
                key="AddGoalStats"
                onStartCreateNewGoal={onStartCreateNewGoal}
                onFinishCreateNewGoal={onFinishCreateNewGoal}
                goalsList={goals}
                goalsAnalysisRequestId={goalsAnalysisRequestId}
              />
            ) : (
              <StatsMetricWithGoal
                key={goal.id}
                setSelectedGoal={setSelectedGoal ? () => setSelectedGoal({ id: goal.id, value: goal.metricName }) : null}
                data={goal}
                isSelected={goal.id === selectedGoal.id}
                hasTimeframe={!!timeframe}
                navigationMenuPopupParams={hasNavigationMenuPopupParams ? (goalId) => getNavigationMenuPopupParams({ goalId }) : null}
                isLoaded={goal.id !== goalSkeletonId}
                showTrendTag={false}
              />
            )))}
          </div>
        )}
      </CarouselArrowNavigation>
      )}

      {actionPopupWithData ? (
        <ActionPopup
          onClose={() => setActionPopupWithData(null)}
          actionData={actionPopupWithData}
          isMultiRegionsAccount={userRegionsNicknames?.length > 1}
          userActionNames={actions?.map((action) => action.name)}
        />
      ) : null}
    </div>
  );
}

export default inject(
  ({
    userStore: {
      actions,
      userRegionsNicknames,
    } = {},
  }) => ({
    actions,
    userRegionsNicknames,
  }),
  observer
)(GoalsAnalysis);
