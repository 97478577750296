exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".configPopup__popup-35L>div>div{width:920px;margin:0 auto}.configPopup__childrenWrapper-1YF{border-radius:4px}.configPopup__popupWrap-1yE{border:1px solid var(--colorsInputGrayLine)}.configPopup__row-3rv{display:flex;padding:16px;flex-direction:row;gap:8px;align-self:stretch;color:#182033;font-size:var(--fontsSizesBodyLarge);font-weight:var(--fontsWeightNormal);align-items:baseline;flex-wrap:wrap}.configPopup__tag-3e_{display:flex;padding:4px 12px;align-items:center;gap:4px;border-radius:4px;background:#e3e9fb;color:var(--colorsTextGray);text-align:center;font-size:var(--fontsSizesBodyNormal);font-weight:var(--fontsWeightNormal)}.configPopup__selectedItem-3Tw{display:flex;padding:10px 12px;align-items:center;gap:10px;border-radius:4px;border:1px solid var(--colorsInputGrayLine);background:#fbfbfd}.configPopup__viewOnlyWrapper-_Uw{display:flex;flex-direction:row;color:#182033;font-size:var(--fontsSizesBodyNormal);font-weight:var(--fontsWeightNormal);padding:4px 8px 4px 28px;align-items:center;gap:4px;border-radius:4px;background:#e3e9fb url(/assets/lock.svg) 8px 5px no-repeat}.configPopup__header-2ZH{display:flex;flex-direction:row;gap:8px}.configPopup__title-vCi{color:#182033;font-size:var(--fontsSizesTitleH4);font-weight:var(--fontsWeightBold);line-height:20px}.configPopup__subTitle-1Wv{align-self:stretch;color:var(--colorsTextGray);font-size:var(--fontsSizesBodyNormal);font-weight:var(--fontsWeightNormal);line-height:20px;padding:8px 0 32px}.configPopup__closeButton-eT7{position:absolute;top:12px;right:12px;width:28px;height:28px;background:url(/assets/close-icon-big.svg) 50% no-repeat;cursor:pointer;z-index:10}.configPopup__infoIcon-1MU:hover{cursor:auto}", ""]);

// exports
exports.locals = {
	"popup": "configPopup__popup-35L",
	"popup": "configPopup__popup-35L",
	"childrenWrapper": "configPopup__childrenWrapper-1YF",
	"childrenWrapper": "configPopup__childrenWrapper-1YF",
	"popupWrap": "configPopup__popupWrap-1yE",
	"popupWrap": "configPopup__popupWrap-1yE",
	"row": "configPopup__row-3rv",
	"row": "configPopup__row-3rv",
	"tag": "configPopup__tag-3e_",
	"tag": "configPopup__tag-3e_",
	"selectedItem": "configPopup__selectedItem-3Tw",
	"selectedItem": "configPopup__selectedItem-3Tw",
	"viewOnlyWrapper": "configPopup__viewOnlyWrapper-_Uw",
	"viewOnlyWrapper": "configPopup__viewOnlyWrapper-_Uw",
	"header": "configPopup__header-2ZH",
	"header": "configPopup__header-2ZH",
	"title": "configPopup__title-vCi",
	"title": "configPopup__title-vCi",
	"subTitle": "configPopup__subTitle-1Wv",
	"subTitle": "configPopup__subTitle-1Wv",
	"closeButton": "configPopup__closeButton-eT7",
	"closeButton": "configPopup__closeButton-eT7",
	"infoIcon": "configPopup__infoIcon-1MU",
	"infoIcon": "configPopup__infoIcon-1MU"
};