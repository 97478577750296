function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
var colors = {
  general: {
    black: '#222A41',
    white: '#FFFFFF',
    blue: '#4D91FC',
    lightBlue: '#E4EEFF',
    pacificBlue: '#0099CC',
    green: '#2FCF5F',
    red: '#E43A54',
    yellow: '#F6D31E',
    lightYellow: '#FFF7D0',
    orange: '#F09848',
    purple: '#835BFE'
  },
  text: {
    darkGray: '#4B515E',
    gray: '#707EA7',
    IgGray: '#99A4C2',
    lightGray100: '#C1CFDA',
    white: '#FFFFFF'
  },
  background: {
    white: '#FFFFFF',
    purple: '#F0EBFF',
    lightGray: '#F6F7FB',
    lightBlue: '#E1EDFF'
  },
  input: {
    errorRed: '#E60000',
    grayLine: '#DCE1EF'
  },
  tag: {
    aiPurple: {
      background: '#F3EFFF',
      text: '#835BFE'
    },
    warning: {
      background: '#FFF1E1',
      text: '#F09848'
    }
  },
  button: {
    primaryBlue: {
      primary: '#4D91FC',
      hover: '#3E7DE3',
      selected: '#3168C1',
      disabled: '#4D91FC80'
    },
    secondaryBlue: {
      primary: '#E4EEFF',
      hover: '#D1E1FC',
      selected: '#BBD2FA',
      disabled: '#E4EEFF80'
    },
    primaryRed: {
      primary: '#E43A54',
      hover: '#D93750',
      selected: '#CE344B',
      disabled: '#E43A5480'
    },
    secondaryRed: {
      primary: '#FADCE0',
      hover: '#FBD1D6',
      selected: '#FFBAC2',
      disabled: '#FADCE080'
    },
    success: {
      primary: '#2FCF5F',
      hover: '#19C268',
      selected: '#14AE5C',
      disabled: '#2FCF5F80'
    },
    secondaryWhite: {
      primary: '#FFFFFF',
      hover: '#FCFDFF',
      selected: '#F4F7FD',
      selectedWithIcon: '#D9EFFF',
      disabled: '#DCE1EF80'
    }
  },
  navigationMenu: {
    hover: '#455073',
    active: '#2F4278',
    activeText: '#58A1F5',
    textHover: '#ACD2FF'
  }
};
var fonts = {
  sizes: {
    title: {
      h1: '38px',
      h2: '32px',
      h3: '24px',
      h4: '20px'
    },
    body: {
      large: '16px',
      normal: '14px',
      small: '12px'
    }
  },
  weight: {
    normal: '400',
    medium: '500',
    bold: '600'
  }
};
var shadows = {
  small: '0 1px 3px 0 #E6E8F0',
  average: '0px 2px 4px 0px #E6E8F0',
  medium: '0px 4px 12px 0px #E6E8F0'
};
var transitions = {
  transform: {
    slow: 'transform 0.3s ease',
    regular: 'transform 0.2s ease',
    fast: 'transform 0.15s ease'
  }
};

// Function to convert string to camelCase
var toCamelCase = function toCamelCase(str) {
  return str.replace(/^\w|[A-Z]|\b\w/g, function (letter, index) {
    return index === 0 ? letter.toLowerCase() : letter.toUpperCase();
  }).replace(/\s+/g, '');
};

// Function to flatten nested objects with camelCase support from the first level
var _flattenObject = function flattenObject(obj) {
  var prefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  return Object.keys(obj).reduce(function (acc, key) {
    var camelKey = toCamelCase(key);
    var newKey = prefix ? "".concat(prefix).concat(camelKey.charAt(0).toUpperCase() + camelKey.slice(1)) : camelKey;
    if (_typeof(obj[key]) === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
      Object.assign(acc, _flattenObject(obj[key], newKey));
    } else {
      acc[newKey] = obj[key];
    }
    return acc;
  }, {});
};

// Create CSS variables
var createCssVariables = function createCssVariables(tokenGroups) {
  return Object.entries(tokenGroups).reduce(function (acc, _ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      groupName = _ref2[0],
      group = _ref2[1];
    var flatGroup = _flattenObject(group);
    Object.entries(flatGroup).forEach(function (_ref3) {
      var _ref4 = _slicedToArray(_ref3, 2),
        key = _ref4[0],
        value = _ref4[1];
      acc["".concat(toCamelCase(groupName)).concat(key.charAt(0).toUpperCase() + key.slice(1))] = value;
    });
    return acc;
  }, {});
};
var cssVariables = createCssVariables({
  colors: colors,
  shadows: shadows,
  fonts: fonts,
  transitions: transitions
});
export { colors, shadows, fonts, transitions, cssVariables };