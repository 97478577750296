import servicesStore from 'stores/servicesStore';
import userStore from 'stores/userStore';
import reportsStore from 'stores/reports/reportsStore';

import { localStorageKeys } from 'enums';
import { Interactions } from 'trackers/interactions/enums';
import { getQueryParams } from 'components/utils/UrlParamsProvider';

export default class InteractionTracker {
  page() {
    const { name, type } = Interactions.global.pageChanged;
    const { page } = InteractionTracker.getPageMetadata();

    this.trackConfig({ name, type, description: `${name} to ${page}` });
  }

  trackConfig({
    name, type, description, widgetTitle,
  }) {
    const { UID, region } = userStore.userMonthPlan;
    const { email } = servicesStore.authService.getProfileSync();
    const { sessionId } = JSON.parse(localStorage.getItem(localStorageKeys.sessionData));
    const { page, path } = InteractionTracker.getPageMetadata();

    // TODO: Remove log and uncomment sendInteraction call
    console.log({
      interactionPath: 'config',
      UID,
      body: {
        region,
        sessionId,
        email,
        page,
        path,
        interactionTitle: name,
        interactionType: type,
        interactionText: description,
        widgetTitle,
      },
    });

    // this.sendInteraction({
    //   interactionPath: 'config',
    //   UID,
    //   body: {
    //     region,
    //     sessionId,
    //     email,
    //     page,
    //     path,
    //     interactionTitle: name,
    //     interactionType: type,
    //     interactionText: description,
    //     widgetTitle,
    //   },
    // }).then();
  }

  trackWidget({ widgetHeader, widgetData }) {
    const { UID, region } = userStore.userMonthPlan;
    const { email } = servicesStore.authService.getProfileSync();
    const { sessionId } = JSON.parse(localStorage.getItem(localStorageKeys.sessionData));
    const { page, path } = InteractionTracker.getPageMetadata();

    // TODO: Remove log and uncomment sendInteraction call
    console.log({
      interactionPath: 'widget',
      UID,
      body: {
        region,
        sessionId,
        email,
        page,
        path,
        widgetHeader,
        widgetData,
      },
    });

    // this.sendInteraction({
    //   interactionPath: 'widget',
    //   UID,
    //   body: {
    //     region,
    //     sessionId,
    //     email,
    //     page,
    //     path,
    //     widgetHeader,
    //     widgetData,
    //   },
    // }).then();
  }

  async sendInteraction({ interactionPath, UID, body }) {
    try {
      await servicesStore.serverCommunication.serverRequest({
        method: 'POST',
        route: `interactions/${interactionPath}`,
        body,
      });
    } catch (exception) {
      servicesStore.logger.error('failed to send interaction', {
        error: exception.message,
        UID,
        body,
      });
    }
  }

  static getPageMetadata() {
    const pathname = window.location.pathname;
    const trimmedPath = pathname.replace(/^\/+|\/+$/g, '');
    const segments = trimmedPath.split('/');

    let page = segments.join(' > ');
    let path = pathname;

    const reportId = getQueryParams({ queryParamKey: 'reportId' });
    if (reportId) {
      const reportData = reportsStore.reportsWithWidgetsData.find((report) => report.id === reportId);
      page = `report: ${reportData?.name || reportId}`;
      path = `${pathname}?reportId=${reportId}`;
    }

    return { page, path };
  }
}
