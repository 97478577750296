exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".NewMemberRoleDataPopup__inner-3SA{padding:0;margin:0}.NewMemberRoleDataPopup__wrapper-3Eg{display:flex;flex-direction:column;gap:8px}.NewMemberRoleDataPopup__content-6Y7{display:flex;flex-direction:column;gap:24px}.NewMemberRoleDataPopup__warningMessage-B2U{display:flex;color:var(--colorsTextWhite);background-color:var(--colorsGeneralRed);border-radius:10px;font-weight:var(--fontsWeightBold);padding:0 8px;height:22px;align-items:center;width:fit-content}.NewMemberRoleDataPopup__title-3rB{font-size:22px;font-weight:var(--fontsWeightBold)}.NewMemberRoleDataPopup__subTitle-Xff{font-size:var(--fontsSizesBodyNormal)}.NewMemberRoleDataPopup__textField-3V7{display:flex;flex-direction:column;gap:12px}.NewMemberRoleDataPopup__textField-3V7 .NewMemberRoleDataPopup__textFieldLabel-2se{display:flex;font-weight:var(--fontsWeightBold);font-size:var(--fontsSizesBodyNormal);gap:4px}.NewMemberRoleDataPopup__textField-3V7 .NewMemberRoleDataPopup__textFieldLabel-2se i{font-weight:var(--fontsWeightNormal)}.NewMemberRoleDataPopup__textField-3V7 .NewMemberRoleDataPopup__textFieldLabel-2se .NewMemberRoleDataPopup__required-3_Z{color:var(--colorsInputErrorRed)}.NewMemberRoleDataPopup__footer-AXX{display:flex;justify-content:end;align-items:center;gap:12px}.NewMemberRoleDataPopup__footer-AXX .NewMemberRoleDataPopup__error-3on{color:var(--colorsInputErrorRed);font-size:var(--fontsSizesBodySmall)}body:has(.NewMemberRoleDataPopup__roleDataPopup-iFY){overflow:hidden}", ""]);

// exports
exports.locals = {
	"inner": "NewMemberRoleDataPopup__inner-3SA",
	"inner": "NewMemberRoleDataPopup__inner-3SA",
	"wrapper": "NewMemberRoleDataPopup__wrapper-3Eg",
	"wrapper": "NewMemberRoleDataPopup__wrapper-3Eg",
	"content": "NewMemberRoleDataPopup__content-6Y7",
	"content": "NewMemberRoleDataPopup__content-6Y7",
	"warningMessage": "NewMemberRoleDataPopup__warningMessage-B2U",
	"warningMessage": "NewMemberRoleDataPopup__warningMessage-B2U",
	"title": "NewMemberRoleDataPopup__title-3rB",
	"title": "NewMemberRoleDataPopup__title-3rB",
	"subTitle": "NewMemberRoleDataPopup__subTitle-Xff",
	"subTitle": "NewMemberRoleDataPopup__subTitle-Xff",
	"textField": "NewMemberRoleDataPopup__textField-3V7",
	"textField": "NewMemberRoleDataPopup__textField-3V7",
	"textFieldLabel": "NewMemberRoleDataPopup__textFieldLabel-2se",
	"textFieldLabel": "NewMemberRoleDataPopup__textFieldLabel-2se",
	"required": "NewMemberRoleDataPopup__required-3_Z",
	"required": "NewMemberRoleDataPopup__required-3_Z",
	"footer": "NewMemberRoleDataPopup__footer-AXX",
	"footer": "NewMemberRoleDataPopup__footer-AXX",
	"error": "NewMemberRoleDataPopup__error-3on",
	"error": "NewMemberRoleDataPopup__error-3on",
	"roleDataPopup": "NewMemberRoleDataPopup__roleDataPopup-iFY",
	"roleDataPopup": "NewMemberRoleDataPopup__roleDataPopup-iFY"
};