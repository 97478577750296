exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".quickFiltersTable__rowWrapper-362{color:var(--colorsTextGray);font-size:var(--fontsSizesBodyNormal);font-weight:var(--fontsWeightNormal);display:flex;min-width:140px}.quickFiltersTable__clickableItem-Hz-:hover{cursor:pointer;color:var(--colorsGeneralBlue);-webkit-text-decoration-line:underline;text-decoration-line:underline;border-radius:12px}.quickFiltersTable__icon-3Z3{width:42px;height:42px;background-repeat:no-repeat;background-position:50%;min-width:revert}.quickFiltersTable__switchCell-JvB *{box-sizing:content-box!important}.quickFiltersTable__aiType-2zi{color:var(--colorsGeneralPurple);background:url(/assets/ai/purpleAIStars.svg) 0 no-repeat;padding-left:28px}.quickFiltersTable__sectionDisabled--AZ{opacity:.5;cursor:none}.quickFiltersTable__cardRowsTableWrapper-2Op{padding:16px 0 0}[data-icon=\"quickFilters:delete\"]{background-image:url(\"/assets/userActions-icons/trash.svg\")}[data-icon=\"quickFilters:pin\"]{background-image:url(\"/assets/pin.svg\")}", ""]);

// exports
exports.locals = {
	"rowWrapper": "quickFiltersTable__rowWrapper-362",
	"rowWrapper": "quickFiltersTable__rowWrapper-362",
	"clickableItem": "quickFiltersTable__clickableItem-Hz-",
	"clickableItem": "quickFiltersTable__clickableItem-Hz-",
	"icon": "quickFiltersTable__icon-3Z3",
	"icon": "quickFiltersTable__icon-3Z3",
	"switchCell": "quickFiltersTable__switchCell-JvB",
	"switchCell": "quickFiltersTable__switchCell-JvB",
	"aiType": "quickFiltersTable__aiType-2zi",
	"aiType": "quickFiltersTable__aiType-2zi",
	"sectionDisabled": "quickFiltersTable__sectionDisabled--AZ",
	"sectionDisabled": "quickFiltersTable__sectionDisabled--AZ",
	"cardRowsTableWrapper": "quickFiltersTable__cardRowsTableWrapper-2Op",
	"cardRowsTableWrapper": "quickFiltersTable__cardRowsTableWrapper-2Op"
};