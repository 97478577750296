exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".table-popup__popup-25F{top:62px;width:456px;box-sizing:border-box}.table-popup__popup-content-container-y3B{display:flex;align-items:flex-start}.table-popup__popup-content-column-1q1{display:grid;width:100%;margin-left:12px}.table-popup__popup-content-column-1q1+.table-popup__popup-content-column-1q1{margin-left:20px}.table-popup__popup-content-column-title-3nH{font-size:11px;font-weight:var(--fontsWeightBold);color:var(--colorsTextIgGray);margin:0 0 15px;text-transform:uppercase}.table-popup__checkboxContainer-3Vl+.table-popup__checkboxContainer-3Vl{margin-top:10px}.table-popup__checkboxContainer-3Vl .table-popup__checkbox-3VX{width:17px;height:17px;margin:0 8px 0 0;border-radius:4px;box-shadow:0 1px 2px 0 #e6e8f0;border:1px solid rgba(178,187,213,.5);background-image:linear-gradient(0deg,#fafafc,var(--colorsGeneralWhite))}.table-popup__checkboxContainer-3Vl .table-popup__checkbox-3VX[data-checked]{background:var(--colorsGeneralBlue)}.table-popup__checkboxContainer-3Vl .table-popup__checkbox-mark-37j{background:url(/assets/analyze-icons/channels-checkmark.svg) 50% no-repeat;background-size:10px 7px}.table-popup__checkbox-label-2-8{font-size:var(--fontsSizesBodyNormal);color:var(--colorsGeneralBlack);font-weight:var(--fontsWeightNormal)}.table-popup__checkListWrapper-3ui{display:flex;gap:12px;flex-direction:column}.table-popup__checkListItem-1fw{padding:6px 0;position:relative;margin-left:-16px;padding-left:4px}.table-popup__checkListItem-1fw:hover{background:#f5f6fb;border-radius:4px}.table-popup__dragAndDropIcon-1sn{background:url(/assets/platform-icons/dragAndDropIcon.svg) 50% no-repeat;width:9px;height:15px;position:absolute;left:-12px;top:8px;z-index:1;background-size:cover;display:none}.table-popup__checkListItem-1fw:hover .table-popup__dragAndDropIcon-1sn{display:block}", ""]);

// exports
exports.locals = {
	"popup": "table-popup__popup-25F",
	"popup": "table-popup__popup-25F",
	"popup-content-container": "table-popup__popup-content-container-y3B",
	"popupContentContainer": "table-popup__popup-content-container-y3B",
	"popup-content-column": "table-popup__popup-content-column-1q1",
	"popupContentColumn": "table-popup__popup-content-column-1q1",
	"popup-content-column-title": "table-popup__popup-content-column-title-3nH",
	"popupContentColumnTitle": "table-popup__popup-content-column-title-3nH",
	"checkboxContainer": "table-popup__checkboxContainer-3Vl",
	"checkboxContainer": "table-popup__checkboxContainer-3Vl",
	"checkbox": "table-popup__checkbox-3VX",
	"checkbox": "table-popup__checkbox-3VX",
	"checkbox-mark": "table-popup__checkbox-mark-37j",
	"checkboxMark": "table-popup__checkbox-mark-37j",
	"checkbox-label": "table-popup__checkbox-label-2-8",
	"checkboxLabel": "table-popup__checkbox-label-2-8",
	"checkListWrapper": "table-popup__checkListWrapper-3ui",
	"checkListWrapper": "table-popup__checkListWrapper-3ui",
	"checkListItem": "table-popup__checkListItem-1fw",
	"checkListItem": "table-popup__checkListItem-1fw",
	"dragAndDropIcon": "table-popup__dragAndDropIcon-1sn",
	"dragAndDropIcon": "table-popup__dragAndDropIcon-1sn"
};