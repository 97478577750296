exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".preferences__channels-row-1LY{display:flex;margin-bottom:16px;align-items:center}.preferences__channels-remove-2rp{margin-top:36px;margin-left:6px;flex-shrink:0;flex-grow:0;width:28px}.preferences__error-hHK{color:red;margin-top:40px;margin-left:11px;flex-shrink:0;flex-grow:0;width:200px}.preferences__blocked-label-2Or{font-weight:var(--fontsWeightBold);color:#24b10e;margin-bottom:0}.preferences__divide-equally-Brc{margin-left:38px;margin-top:12px}.preferences__advanced-button--AF{margin-top:55px;font-weight:700;cursor:pointer;font-size:var(--fontsSizesBodyLarge);margin-bottom:20px;color:#1165a3;text-decoration:underline}.preferences__add-objective-2n5{background:url(/assets/plus.svg) 50% no-repeat;background-size:24px auto;width:28px;height:28px;cursor:pointer}.preferences__objective-text-3Nc{font-weight:var(--fontsWeightBold)}.preferences__homePageSelect-2n1{align-items:center;z-index:6;position:relative}", ""]);

// exports
exports.locals = {
	"channels-row": "preferences__channels-row-1LY",
	"channelsRow": "preferences__channels-row-1LY",
	"channels-remove": "preferences__channels-remove-2rp",
	"channelsRemove": "preferences__channels-remove-2rp",
	"error": "preferences__error-hHK",
	"error": "preferences__error-hHK",
	"blocked-label": "preferences__blocked-label-2Or",
	"blockedLabel": "preferences__blocked-label-2Or",
	"divide-equally": "preferences__divide-equally-Brc",
	"divideEqually": "preferences__divide-equally-Brc",
	"advanced-button": "preferences__advanced-button--AF",
	"advancedButton": "preferences__advanced-button--AF",
	"add-objective": "preferences__add-objective-2n5",
	"addObjective": "preferences__add-objective-2n5",
	"objective-text": "preferences__objective-text-3Nc",
	"objectiveText": "preferences__objective-text-3Nc",
	"homePageSelect": "preferences__homePageSelect-2n1",
	"homePageSelect": "preferences__homePageSelect-2n1"
};