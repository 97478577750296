import {
  camelCase, isEqual, isObject, get, sortBy, isEmpty,
} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { Button } from '@infinigrow/libs';

import AttributionTablePopup from 'components/pages/analyze/AttribuitonTable/AttributionTablePopup';
import AttributionTableWrapper from 'components/pages/analyze/AttribuitonTable/AttributionTableWrapper';
import Component from 'components/Component';
import ManageColumnsButton from 'components/pages/plan/ManageColumnsButton';
import SearchInput from 'components/controls/SearchInput';
import Spinner from 'components/pages/journeys/Spinner';
import Table from 'components/controls/Table';
import WidgetHeader from 'components/common/WidgetHeader';
import ErrorWidgetWithBlur from 'components/common/ErrorWidgetWithBlur';
import StageSelector, { stageType } from 'components/pages/analyze/AttribuitonTable/StageSelector';
import SegmentsSelectorWrapper from 'components/pages/analyze/AttribuitonTable/SegmentsSelectorWrapper';
import CheckboxWithFrame from 'components/common/CheckboxWithFrame';

import { getSegmentType } from 'components/pages/analyze/SegmentsTab/logic/segments';
import { getWidgetDefinitions } from 'widgetDefinitions';
import { ATTRIBUTION_KEY } from 'components/utils/utils';
import { DEFAULT_TIME_ZONE } from 'components/utils/timeframe';
import {
  attributionColumnsLocalStorageKeys, crossFunnel,
} from 'components/pages/analyze/AttribuitonTable/enums';
import { createSheetImpactDataTable, getXLSLib } from 'components/utils/excelExport';
import { getPipelineFunnel, getRevenueFunnel } from 'components/utils/indicators';
import { stringifyDateWithTimeZone } from 'components/utils/date';
import {
  getReorderColumnsForStage,
  getAllColumnOptionsAndGroupedOptions,
  getSavedFromLocalStorage,
  filterDataAccordingActiveColumns,
  defaultFirstAndSecondColumns,
  reorderActiveColumnsByUplift,
  createTableSheet,
  createTableForAI, getWidgetTitle,
} from 'components/pages/analyze/AttribuitonTable/logic/AttributionTable';
import { widgetTypes } from 'components/pages/analyze/enums';
import { segmentsKeys, upliftLabel } from 'components/common/logic/enums';
import { isShowCostMetric } from 'components/pages/analyze/AttribuitonTable/logic/AttributionSegmentsTableParseData';
import { Events } from 'trackers/analytics/enums';
import { Interactions } from 'trackers/interactions/enums';

import attributionStore from 'stores/attributionStore';
import userStore from 'stores/userStore';
import servicesStore from 'stores/servicesStore';
import interactionsStore from 'stores/interactionsStore';

import style from 'styles/analyze/analyze.css';
import checkboxStyle from 'styles/controls/multi-check-select.css';
import attributionStyle from 'styles/analyze/analyze-attribution-table.css';

class AttributionTable extends Component {
  style = style;

  styles = [attributionStyle, checkboxStyle];

  static propTypes = {
    selectedStageKey: PropTypes.string.isRequired,
    updateStageKey: PropTypes.func.isRequired,
    allColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
    stagesData: PropTypes.arrayOf(stageType).isRequired,
    tableColumns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    tableData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    isShowStageSelector: PropTypes.bool,
  };

  static defaultProps = {
    isShowStageSelector: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      reorderColumns: this.getInitialReorderColumns({
        type: props.type,
        selectedStageKey: props.selectedStageKey,
        widgetColumnsConfig: props.widgetColumnsConfig,
      }),
      inactiveColumns: this.getInitialInactiveColumns({
        selectedStageKey: props.selectedStageKey,
        widgetColumnsConfig: props.widgetColumnsConfig,
        type: props.type,
      }),
      isExportLoading: false,
      isConsolidateCampaigns: this.isCampaignFirstSegment && props.selectedSegments?.secondSegment?.value === null,
    };
    this.reactTableChildRef = React.createRef();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.selectedStageKey, this.props.selectedStageKey)) {
      this.updateActiveColumnsObject(nextProps);
    }
    if (!isEqual(nextProps.widgetColumnsConfig?.reorderColumns, this.props.widgetColumnsConfig?.reorderColumns)) {
      this.setState({
        reorderColumns: nextProps.widgetColumnsConfig?.reorderColumns,
      });
    }

    if (!isEqual(nextProps.widgetColumnsConfig?.inactiveColumns, this.props.widgetColumnsConfig?.inactiveColumns)) {
      this.setState({
        inactiveColumns: nextProps.widgetColumnsConfig?.inactiveColumns,
      });
    }
  }

  getInitialReorderColumns({
    type,
    selectedStageKey,
    widgetColumnsConfig,
  }) {
    if (widgetColumnsConfig?.reorderColumns) {
      return widgetColumnsConfig.reorderColumns;
    }
    const { UID, region } = userStore.userMonthPlan;
    const localStorageKey = `${UID}-${region}-${type}-${ATTRIBUTION_KEY}-${attributionColumnsLocalStorageKeys.reorder}`;
    const initialColumns = getSavedFromLocalStorage({ localStorageKey });

    if (!initialColumns[selectedStageKey]) {
      initialColumns[selectedStageKey] = [];
    }

    return initialColumns;
  }

  getInitialInactiveColumns({
    selectedStageKey,
    widgetColumnsConfig,
    type,
  }) {
    let initialColumns = {};
    let funnelsWithSavedInactiveColumns = [];

    const { UID, region } = userStore.userMonthPlan;
    const localStorageKey = `${UID}-${region}-${type}-${ATTRIBUTION_KEY}-${attributionColumnsLocalStorageKeys.inactive}`;
    const initialInactiveColumnsFromLocalStorage = getSavedFromLocalStorage({ localStorageKey });

    if (widgetColumnsConfig?.inactiveColumns) {
      funnelsWithSavedInactiveColumns = Object.keys(widgetColumnsConfig.inactiveColumns);
      initialColumns = widgetColumnsConfig.inactiveColumns;

      for (const [funnel, inactiveColumns] of Object.entries(widgetColumnsConfig.inactiveColumns)) {
        const allMetricRelevantForOfInactiveColumns = new Set();
        for (const inactiveColumn of inactiveColumns) {
          const metricRelevantFor = userStore.getMetricRelevantFor({ metric: inactiveColumn });
          allMetricRelevantForOfInactiveColumns.add(metricRelevantFor);
        }

        if (allMetricRelevantForOfInactiveColumns.size === 1 && allMetricRelevantForOfInactiveColumns.has(funnel)) {
          funnelsWithSavedInactiveColumns = funnelsWithSavedInactiveColumns.filter((funnelsWithSavedInactiveColumn) => funnelsWithSavedInactiveColumn !== funnel);
        }
      }
    } else if (!isEmpty(initialInactiveColumnsFromLocalStorage)) {
      funnelsWithSavedInactiveColumns = Object.keys(initialInactiveColumnsFromLocalStorage);
      initialColumns = initialInactiveColumnsFromLocalStorage;
    }

    const showCostMetric = isShowCostMetric({ firstSegmentValue: this.props.selectedSegments.firstSegment?.value, secondSegmentValue: this.props.selectedSegments.secondSegment?.value });

    const allGroupedOptions = getAllColumnOptionsAndGroupedOptions({ isAttribution: this.props.isAttribution, selectedSegments: this.props.selectedSegments }).groupedUserMetrics;
    const disabledMetrics = [];
    for (const groupOfOptions of allGroupedOptions) {
      if (groupOfOptions.groupName !== selectedStageKey) {
        disabledMetrics.push(...groupOfOptions.options.map((option) => option.value));
        continue;
      }

      for (const option of groupOfOptions.options) {
        if (option.enabled) {
          continue;
        }
        disabledMetrics.push(option.value);
      }
    }

    if (!initialColumns[selectedStageKey]) {
      initialColumns[selectedStageKey] = [];
    }

    const pipelineFunnel = getPipelineFunnel();
    const revenueFunnel = getRevenueFunnel();

    if (selectedStageKey !== pipelineFunnel) {
      initialColumns[selectedStageKey].push(camelCase(`pRevenue ${selectedStageKey}`));
    }

    if (selectedStageKey !== pipelineFunnel) {
      initialColumns[selectedStageKey].push('touchedPipeline');
    }

    if (selectedStageKey !== revenueFunnel) {
      initialColumns[selectedStageKey].push('touchedRevenue');
    }

    if (initialColumns[selectedStageKey] && !funnelsWithSavedInactiveColumns.includes(selectedStageKey)) {
      initialColumns[selectedStageKey].push(...disabledMetrics);
      if (showCostMetric) {
        initialColumns[selectedStageKey] = initialColumns[selectedStageKey].filter((column) => column !== 'cost');
      }
      return initialColumns;
    }

    for (const stage of Object.keys(initialColumns)) {
      if (!funnelsWithSavedInactiveColumns.includes(stage)) {
        initialColumns[stage].push(...disabledMetrics);
        if (showCostMetric) {
          initialColumns[stage] = initialColumns[stage].filter((column) => column !== 'cost');
        }
      }
    }

    return initialColumns;
  }

  get activeColumnsForStage() {
    const { allColumnOptions } = getAllColumnOptionsAndGroupedOptions({
      isAttribution: this.props.isAttribution,
      selectedSegments: this.props.selectedSegments,
    });

    const hideColumnsFromDefault = allColumnOptions.filter((column) => column.hideColumnFromDefault).map((column) => column.value) || [];
    const disabledOptions = allColumnOptions?.filter((column) => column.isDisabled).map((column) => column.value) || [];

    const inactiveColumnsFromStage = [
      ...(this.state.inactiveColumns[this.props.selectedStageKey] || []),
      ...disabledOptions,
      ...hideColumnsFromDefault,
    ];

    return this.props.allColumns.filter((column) => !inactiveColumnsFromStage.includes(column));
  }

  updateActiveColumnsObject = ({ selectedStageKey }) => {
    const allGroupedOptions = getAllColumnOptionsAndGroupedOptions({ isAttribution: this.props.isAttribution, selectedSegments: this.props.selectedSegments }).groupedUserMetrics;
    let defaultActiveColumnForStage = [];
    for (const groupOfOptions of allGroupedOptions) {
      if (groupOfOptions.groupName !== selectedStageKey) {
        continue;
      }

      for (const option of groupOfOptions.options) {
        if (!option.enabled) {
          continue;
        }
        defaultActiveColumnForStage.push(option.value);
      }
    }

    if (selectedStageKey === crossFunnel) {
      defaultActiveColumnForStage.push(...userStore.userFunnels.map((userFunnel) => camelCase(`attributed ${userFunnel}`)));
    }

    const showCostMetric = isShowCostMetric({ firstSegmentValue: this.props.selectedSegments.firstSegment?.value, secondSegmentValue: this.props.selectedSegments.secondSegment?.value });
    if (showCostMetric) {
      defaultActiveColumnForStage.push('cost', 'channels');
    }

    const pipelineFunnel = getPipelineFunnel();
    const revenueFunnel = getRevenueFunnel();
    if (selectedStageKey === pipelineFunnel) {
      defaultActiveColumnForStage.push('touchedPipeline');
    }

    if (selectedStageKey === revenueFunnel) {
      defaultActiveColumnForStage.push('touchedRevenue');
    }

    if (selectedStageKey !== pipelineFunnel) {
      defaultActiveColumnForStage = defaultActiveColumnForStage.filter((column) => column !== camelCase(`pRevenue ${selectedStageKey}`));
    }

    this.setActiveColumns({ newActiveColsForStage: defaultActiveColumnForStage, selectedStageKey, isSave: false });
  };

  setActiveColumns = ({
    newActiveColsForStage = [], newReorderColsForStage, selectedStageKey = this.props.selectedStageKey, isSave,
  }) => {
    const {
      updateWidgetColumns, isReadOnly,
    } = this.props;
    const { UID, region } = userStore.userMonthPlan;

    this.setState((prevState) => {
      const newColumnsState = {
        reorderColumns: prevState.reorderColumns,
        inactiveColumns: prevState.inactiveColumns,
      };

      if (newReorderColsForStage) {
        newColumnsState.reorderColumns = {
          ...newColumnsState.reorderColumns,
          [selectedStageKey]: newReorderColsForStage,
        };
      }

      newActiveColsForStage.push(...defaultFirstAndSecondColumns);
      const activeColumnsSet = new Set(newActiveColsForStage);
      const localStorageKey = `${UID}-${region}-${this.props.type}-${ATTRIBUTION_KEY}-${attributionColumnsLocalStorageKeys.inactive}`;
      const initialInactiveColumnsFromLocalStorage = getSavedFromLocalStorage({ localStorageKey });
      let newInactiveColsForStage = !isSave && initialInactiveColumnsFromLocalStorage[selectedStageKey];
      if (!newInactiveColsForStage) {
        newInactiveColsForStage = this.props.allColumns.filter((column) => !activeColumnsSet.has(column));
      }

      newColumnsState.inactiveColumns = {
        ...newColumnsState.inactiveColumns,
        [selectedStageKey]: newInactiveColsForStage,
      };

      if (isSave) {
        localStorage.setItem(`${UID}-${region}-${this.props.type}-${ATTRIBUTION_KEY}-${attributionColumnsLocalStorageKeys.reorder}`, JSON.stringify(newColumnsState.reorderColumns));
        localStorage.setItem(`${UID}-${region}-${this.props.type}-${ATTRIBUTION_KEY}-${attributionColumnsLocalStorageKeys.inactive}`, JSON.stringify(newColumnsState.inactiveColumns));
      }

      if (!isReadOnly && updateWidgetColumns) {
        updateWidgetColumns(newColumnsState);
      }

      this.setState({ ...newColumnsState });
    });
  };

  openPopup = () => this.popup.open();

  closePopup = (newSelection, reorderedColumns) => {
    if (reorderedColumns) {
      const mappedReorderedColumns = reorderedColumns.map((item) => item.id);
      this.setActiveColumns({ newActiveColsForStage: newSelection, newReorderColsForStage: mappedReorderedColumns, isSave: true });
    } else if (newSelection) {
      this.setActiveColumns({ newActiveColsForStage: newSelection, isSave: true });

      interactionsStore.interactionTracker.trackConfig({
        type: Interactions.segmentsAnalysisTable.columnsChanged.type,
        name: Interactions.segmentsAnalysisTable.columnsChanged.name,
        widgetTitle: getWidgetTitle({ title: this.props.title, selectedStage: this.props.selectedStageKey }),
      });
    }
    this.popup.close();
  };

  getLabel = ({ header, id, headerKey }) => {
    if (id === 'on-click') {
      return 'Show Journeys';
    }
    if (headerKey) {
      return headerKey;
    }
    return isObject(header) ? get(header, 'props.children', '') : header;
  };

  onExportData = () => {
    this.setState({ isExportLoading: true });

    setTimeout(async () => {
      const { title, stagesData, selectedStageKey } = this.props;
      const { dateRange: { endDate, startDate } } = attributionStore;
      const timezone = get(userStore, 'userMonthPlan.CRMConfig.timezone', DEFAULT_TIME_ZONE);
      const stringifyDate = stringifyDateWithTimeZone(timezone);
      const fileName = `IG_${camelCase(title)}_${stringifyDate(startDate, 'MM.DD.YYYY')}-${stringifyDate(endDate, 'MM.DD.YYYY')}`;
      const originalSelectedStageKey = selectedStageKey;

      const tableSheetData = [];
      for (const stage of stagesData) {
        await this.props.updateStageKey(stage.key, () => {
          tableSheetData.push({
            label: stage.name,
            data: createTableSheet({
              currentTableData: this.reactTableChildRef.current.getResolvedState().resolvedData,
              tableColumns: this.props.tableColumns,
              activeColumnsForStage: this.activeColumnsForStage,
            }),
          });
        });
      }

      getXLSLib().then((lib) => {
        createSheetImpactDataTable(lib, tableSheetData, fileName);
      });

      this.props.updateStageKey(originalSelectedStageKey);
      this.setState({ isExportLoading: false });
    }, 100);
  };

  onWidgetDataLoaded({ currentTableData }) {
    if (!currentTableData || isEmpty(this.props.stagesData)) {
      return;
    }

    const widgetId = this.props.widgetHeaderConfig?.widgetId || this.props.widgetId;
    const widgetData = createTableForAI({
      currentTableData,
      tableColumns: this.props.tableColumns,
      activeColumnsForStage: this.activeColumnsForStage,
    });

    interactionsStore.updateWidgetData({ widgetId, currentData: widgetData });

    const widgetHeader = getWidgetTitle({ title: this.props.title, selectedStage: this.props.selectedStageKey });
    if (interactionsStore.isWidgetDataChanged({ widgetId })) {
      interactionsStore.interactionTracker.trackWidget({ widgetHeader, widgetData });
    }
  }

  onChangeConsolidateCampaigns() {
    const { isConsolidateCampaigns } = this.state;
    const newIsConsolidateCampaigns = !isConsolidateCampaigns;
    const {
      selectedSegments, setSelectedSegments, updateFiltersByColumn, filtersByColumn,
    } = this.props;

    const updateSelectedSegments = { ...selectedSegments };
    const updatedFiltersByColumn = { ...filtersByColumn };

    if (newIsConsolidateCampaigns) {
      updateSelectedSegments.secondSegment = { label: 'None', value: null };
      if (filtersByColumn?.secondSegment) {
        updatedFiltersByColumn.channels = filtersByColumn.secondSegment;
        delete updatedFiltersByColumn.secondSegment;
        updateFiltersByColumn({ filters: updatedFiltersByColumn });
      }
    } else {
      updateSelectedSegments.secondSegment = { label: 'Channel', value: 'channel' };
      if (filtersByColumn?.channels) {
        updatedFiltersByColumn.secondSegment = filtersByColumn.channels;
        delete updatedFiltersByColumn.channels;
        updateFiltersByColumn({ filters: updatedFiltersByColumn });
      }
    }
    setSelectedSegments(updateSelectedSegments);
    this.setState({ isConsolidateCampaigns: newIsConsolidateCampaigns });
  }

  trackSegmentsEvent({ segments }) {
    const selectorTypeMapping = {
      [segmentsKeys.firstSegment]: 'Primary',
      [segmentsKeys.secondSegment]: 'Secondary',
    };
    const {
      selectedSegments,
    } = this.props;

    const changedSegment = segments.firstSegment.value !== selectedSegments.firstSegment.value ? segmentsKeys.firstSegment : segmentsKeys.secondSegment;

    const { value } = segments[changedSegment];

    const widgetTitle = getWidgetDefinitions({ widget: widgetTypes.segmentsAnalysis }).eventsWidgetName;

    servicesStore.eventTracker.track({
      eventName: Events.selectedSegment,
      properties: {
        widget: widgetTitle,
        segmentType: getSegmentType({ segmentId: value }),
        selectorType: selectorTypeMapping[changedSegment],
        selectedField: userStore.userAccount.customFieldsIdToLabelMap[value] || value,
      },
    });
  }

  onChangeSelectedSegments({ segments }) {
    this.trackSegmentsEvent({ segments });
    const { setSelectedSegments, filtersByColumn, updateFiltersByColumn } = this.props;

    if (this.isCampaignFirstSegment) {
      this.setState({ isConsolidateCampaigns: segments.secondSegment.value === null });
    } else if (filtersByColumn.channels || filtersByColumn.secondSegment) {
      const updatedFiltersByColumn = { ...filtersByColumn };
      delete updatedFiltersByColumn.channels;
      delete updatedFiltersByColumn.secondSegment;
      updateFiltersByColumn({ filters: updatedFiltersByColumn });
    }
    setSelectedSegments(segments);

    const firstSegmentLabel = `Primary: ${segments.firstSegment.label}`;
    const secondSegmentLabel = segments.secondSegment.value ? `, Secondary: ${segments.secondSegment.label}` : '';
    interactionsStore.interactionTracker.trackConfig({
      type: Interactions.segmentsAnalysisTable.segmentsChanged.type,
      name: Interactions.segmentsAnalysisTable.segmentsChanged.name,
      description: `${firstSegmentLabel}${secondSegmentLabel}`,
      widgetTitle: getWidgetTitle({ title: this.props.title, selectedStage: this.props.selectedStageKey }),
    });
  }

  get isCampaignFirstSegment() {
    return this.props.selectedSegments?.firstSegment?.value === 'campaign';
  }

  render() {
    const {
      title,
      TableProps,
      selectedStageKey,
      updateStageKey,
      stagesData,
      tableColumns,
      searchBar,
      exportMode,
      segmentsFields,
      secondSegmentsOptionsFields,
      selectedSegments,
      tableData,
      onSearchData,
      allColumns,
      disableUpdateSegmentsAnalysisParam,
      widgetHeaderConfigType,
      isContentPages,
      isOtherCampaignsHidden,
      isPrimarySegmentDisable,
      hideNoValuesRows,
      isShowStageSelector,
      shouldRequestAllFunnelStagesData = false,
      isCalculateAdvancedMetrics,
      widgetHeaderConfig = {
        title,
        conversionIndicator: shouldRequestAllFunnelStagesData ? null : selectedStageKey,
        defaultStageKey: selectedStageKey,
        type: widgetHeaderConfigType,
        segmentsAnalysisParams: {
          firstSegment: selectedSegments.firstSegment.value,
          secondSegment: selectedSegments.secondSegment.value,
        },
        trendBySegmentsParams: {
          firstSegment: selectedSegments.firstSegment.value,
          secondSegment: selectedSegments.secondSegment.value,
        },
        isContentPages,
        isOtherCampaignsHidden,
        hideNoValuesRows,
        sortByColumn: this.props.sortByColumn,
        filtersByColumn: this.props.filtersByColumn,
        isPrimarySegmentDisable,
        isShowStageSelector,
        reorderColumns: this.state.reorderColumns,
        inactiveColumns: this.state.inactiveColumns,
        tableTypeName: this.props.type,
        isCalculateAdvancedMetrics,
        upliftBySegmentsParams: {
          selectedMetrics: this.props.upliftColumnsKeys,
          firstSegment: selectedSegments.firstSegment.value,
          secondSegment: selectedSegments.secondSegment.value,
        },
      },
      isHideAddToReport,
      navigateToAnalyzeSegmentFunction,
      isShowHideNoValue,
      isShowNavigateToAnalyze,
      onChangeHideNoValuesRows,
      isLoaded,
      isSegmentAnalysisFailedToLoad,
      isCompareToPreviousEnabled,
      isReadOnly,
      isUIFilterOrSearch,
      widgetHeaderProps = {},
      isShowWidgetHeader = true,
      isAttribution,
      isShowHideOtherCampaigns,
      onChangeHideOtherCampaigns,
      upliftColumnsKeys,
    } = this.props;

    if (TableProps?.infiniteScroll) {
      TableProps.infiniteScrollSelector = 'div > div.rt-table';
    }

    const { isExportLoading, reorderColumns } = this.state;
    const reorderColumnsForStage = getReorderColumnsForStage(reorderColumns, selectedStageKey, allColumns);
    const reorderTableColumns = sortBy(tableColumns, (column) => reorderColumnsForStage.indexOf(column.id));
    const tableColumnsWithoutUplift = reorderTableColumns.filter(({ id }) => !id.includes(upliftLabel));
    let activeTableColumns = reorderTableColumns.filter(({ id }) => this.activeColumnsForStage.includes(id) || id.includes(upliftLabel));

    const disabledColumns = getAllColumnOptionsAndGroupedOptions({ isAttribution, selectedSegments }).allColumnOptions.filter((column) => column.isDisabled).map((column) => column.value);
    const isWidgetEditableAndSegmentIsCampaign = !isReadOnly || selectedSegments.firstSegment.value === 'campaign';

    if (upliftColumnsKeys?.length > 0) {
      activeTableColumns = reorderActiveColumnsByUplift({ columns: activeTableColumns });
    }

    function NoDataComponent(componentData) {
      return componentData?.isLoaded ? (<div className={attributionStyle.locals?.attributionTableNoValues}>No values</div>) : null;
    }

    const widgetHeaderConfigsOptions = [];
    if (exportMode && !isExportLoading) {
      widgetHeaderConfigsOptions.unshift({ label: 'Export data', action: this.onExportData });
    }

    if (isSegmentAnalysisFailedToLoad) {
      return (
        <ErrorWidgetWithBlur
          status="error"
          widgetType={widgetTypes.segmentsAnalysis}
        />
      );
    }

    const widgetHeaderChildren = (
      <>
        <div className={attributionStyle.locals?.manageColumnsWrapper}>
          <ManageColumnsButton
            onClick={this.openPopup}
          />

          <AttributionTablePopup
            setPopupRef={(el) => {
              this.popup = el;
            }}
            selectedColumnsInitial={this.activeColumnsForStage}
            closePopup={this.closePopup}
            selectedStageKey={selectedStageKey}
            options={tableColumnsWithoutUplift.map((col) => ({ id: col.id, label: this.getLabel(col), isDisabled: disabledColumns.includes(col.id) }))}
          />
        </div>

        <SegmentsSelectorWrapper
          setSelectedSegments={(segments) => this.onChangeSelectedSegments({ segments })}
          selectedSegments={selectedSegments}
          segmentsFields={segmentsFields}
          secondSegmentsOptionsFields={secondSegmentsOptionsFields}
          disabled={isReadOnly || disableUpdateSegmentsAnalysisParam}
          isPrimarySegmentDisable={isReadOnly || isPrimarySegmentDisable}
        />

        {searchBar ? (
          <SearchInput
            dataTestId="attributionTableSearchInput"
            onSearch={(value) => onSearchData(searchBar.searchColumn, value)}
            placeholder={searchBar.placeholder}
            classes={{
              input: attributionStyle.locals?.searchInput,
            }}
          />
        ) : null}

        {isShowHideNoValue ? (
          <CheckboxWithFrame
            checked={hideNoValuesRows}
            onChange={onChangeHideNoValuesRows}
            text="Hide segments without values"
          />
        ) : null}

        {isShowHideOtherCampaigns && isWidgetEditableAndSegmentIsCampaign ? (
          <CheckboxWithFrame
            checked={isOtherCampaignsHidden}
            onChange={onChangeHideOtherCampaigns}
            text='Hide "other" campaigns'
            disabled={selectedSegments.firstSegment.value !== 'campaign'}
          />
        ) : null}

        {this.isCampaignFirstSegment ? (
          <CheckboxWithFrame
            checked={this.state.isConsolidateCampaigns}
            onChange={() => this.onChangeConsolidateCampaigns()}
            text="Consolidate cross-channel campaigns"
          />
        ) : null}

        {isShowNavigateToAnalyze ? (
          <Button type="secondaryBlue" onClick={navigateToAnalyzeSegmentFunction}>
            Analyze in Segments
          </Button>
        ) : null}

        {exportMode && isExportLoading && <Spinner />}
      </>
    );

    const filteredData = filterDataAccordingActiveColumns({
      tableData,
      activeColumns: activeTableColumns,
      filtersByColumn: this.props.filtersByColumn,
    });

    // TODO: Uncomment after the deployment
    // if (this.props.isLoaded && (!exportMode || !isExportLoading)) {
    //   this.onWidgetDataLoaded({ currentTableData: filteredData });
    // }

    return (
      <div className={classnames(this.classes.rows, attributionStyle.locals?.attributionTableWrapper)}>
        {isShowWidgetHeader ? (
          <div className={attributionStyle.locals?.attributionTableTop}>
            <WidgetHeader
              {...widgetHeaderProps}
              cogwheelOptions={widgetHeaderConfigsOptions}
              widgetHeaderConfig={widgetHeaderConfig}
              isHideAddToReport={isHideAddToReport}
            >
              {widgetHeaderChildren}
            </WidgetHeader>
          </div>
        ) : null}

        {this.props.emptyStateComponent && isLoaded && tableData.length === 0 ? (
          this.props.emptyStateComponent
        ) : (
          <div className={this.classes.item}>
            {isShowStageSelector ? (
              <StageSelector
                stages={stagesData}
                selectedKey={selectedStageKey}
                selectStage={updateStageKey}
                isCompareToPreviousEnabled={isCompareToPreviousEnabled}
                firstSegment={selectedSegments.firstSegment?.value}
                isHideComparisonNumber={isUIFilterOrSearch}
              />
            ) : null}

            <Table
              withFixedColumns
              ref={this.reactTableChildRef}
              style={{ height: 440 }}
              bodyCustomClass={this.classes.customBodyClass}
              className={classnames(this.classes.channelsImpactsTable, this.classes.attributionTable)}
              rowGroupClassName={this.classes.rowGroup}
              rowClassName={this.classes.row}
              headerClassName={this.classes.header}
              headRowClassName={this.classes.headerRow}
              footerClassName={this.classes.footer}
              footRowClassName={this.classes.footerRow}
              cellClassName={this.classes.cell}
              data={filteredData}
              columns={activeTableColumns}
              defaultSorted={[this.props.sortByColumn]}
              noPadding
              minRows={0}
              exportMode={exportMode}
              NoDataComponent={(componentData) => NoDataComponent(componentData)}
              defaultMinWidth={this.props.defaultMinWidth}
              {...TableProps}
              isLoadingIntialData={tableData.length === 0 && !isLoaded}
            />
          </div>
        )}
      </div>
    );
  }
}

export default AttributionTableWrapper(AttributionTable);
