exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".confirmation-popup__title-1Hm{font-weight:var(--fontsWeightBold);font-size:var(--fontsSizesBodyLarge);line-height:20px;margin-bottom:20px;color:#4c515d}.confirmation-popup__row-2Gz{font-weight:var(--fontsWeightNormal);font-size:var(--fontsSizesBodyNormal);line-height:20px;margin-bottom:20px;color:#4c515d}.confirmation-popup__footer-2n1{display:flex}", ""]);

// exports
exports.locals = {
	"title": "confirmation-popup__title-1Hm",
	"title": "confirmation-popup__title-1Hm",
	"row": "confirmation-popup__row-2Gz",
	"row": "confirmation-popup__row-2Gz",
	"footer": "confirmation-popup__footer-2n1",
	"footer": "confirmation-popup__footer-2n1"
};