exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".rollingTimeframeSelect__wrapper-2s9{display:flex;flex-direction:column;gap:16px;padding-top:8px;padding-bottom:8px}.rollingTimeframeSelect__row-3N0{display:flex;align-items:center;gap:16px}.rollingTimeframeSelect__datePickerRow-3za{display:flex;align-items:center;width:100%}.rollingTimeframeSelect__dropdown-2wt{width:100%}.rollingTimeframeSelect__text-TQY{color:#242a3f;font-size:var(--fontsSizesBodyNormal);font-weight:var(--fontsWeightNormal);line-height:20px}.rollingTimeframeSelect__textField-1bc{width:34%}.rollingTimeframeSelect__errorMessage-32V{color:var(--colorsGeneralRed);font-size:var(--fontsSizesBodySmall);font-weight:var(--fontsWeightNormal);line-height:20px}.rollingTimeframeSelect__checkboxContainer-4Ki{border-bottom:1px solid var(--colorsInputGrayLine);padding-bottom:16px}.rollingTimeframeSelect__footer-5oZ{display:flex;justify-content:space-between}.rollingTimeframeSelect__applyButton-2fE{border-radius:3px;background:var(--colorsGeneralBlue)}.rollingTimeframeSelect__applyButtonText-3hN{font-weight:var(--fontsWeightMedium)}.rollingTimeframeSelect__cancelButton-2hC{border-radius:3px;background:var(--colorsGeneralLightBlue);border:none}.rollingTimeframeSelect__cancelButtonText-2Rs{color:var(--colorsGeneralBlue);font-weight:var(--fontsWeightMedium)}", ""]);

// exports
exports.locals = {
	"wrapper": "rollingTimeframeSelect__wrapper-2s9",
	"wrapper": "rollingTimeframeSelect__wrapper-2s9",
	"row": "rollingTimeframeSelect__row-3N0",
	"row": "rollingTimeframeSelect__row-3N0",
	"datePickerRow": "rollingTimeframeSelect__datePickerRow-3za",
	"datePickerRow": "rollingTimeframeSelect__datePickerRow-3za",
	"dropdown": "rollingTimeframeSelect__dropdown-2wt",
	"dropdown": "rollingTimeframeSelect__dropdown-2wt",
	"text": "rollingTimeframeSelect__text-TQY",
	"text": "rollingTimeframeSelect__text-TQY",
	"textField": "rollingTimeframeSelect__textField-1bc",
	"textField": "rollingTimeframeSelect__textField-1bc",
	"errorMessage": "rollingTimeframeSelect__errorMessage-32V",
	"errorMessage": "rollingTimeframeSelect__errorMessage-32V",
	"checkboxContainer": "rollingTimeframeSelect__checkboxContainer-4Ki",
	"checkboxContainer": "rollingTimeframeSelect__checkboxContainer-4Ki",
	"footer": "rollingTimeframeSelect__footer-5oZ",
	"footer": "rollingTimeframeSelect__footer-5oZ",
	"applyButton": "rollingTimeframeSelect__applyButton-2fE",
	"applyButton": "rollingTimeframeSelect__applyButton-2fE",
	"applyButtonText": "rollingTimeframeSelect__applyButtonText-3hN",
	"applyButtonText": "rollingTimeframeSelect__applyButtonText-3hN",
	"cancelButton": "rollingTimeframeSelect__cancelButton-2hC",
	"cancelButton": "rollingTimeframeSelect__cancelButton-2hC",
	"cancelButtonText": "rollingTimeframeSelect__cancelButtonText-2Rs",
	"cancelButtonText": "rollingTimeframeSelect__cancelButtonText-2Rs"
};