import { isNil } from 'lodash';

import useStyles from 'hooks/useStyles';

import MetaDataBox from 'components/common/MetaDataBox';
import Skeleton from 'components/common/Skeleton';
import ComparisonValue from 'components/common/ComparisonValue';
import Loading3Dots from 'components/controls/Loading3Dots';

import {
  directions,
  skeletonSmallTextSizes,
} from 'components/common/enums';
import { getOverviewMetricLabel, metricsFormatter } from 'components/widgets/optimalJourney/logic/optimalJourney';
import { getIsGrowthDesired } from 'components/pages/analyze/utils/comparisonUtils';
import { noValue } from 'components/common/logic/enums';

import style from 'styles/analyze/optimal-journey.css';

const styles = style.locals || {};

export default function OptimalJourneyOverview({
  overviewData = {},
  isLoaded,
  lastSelectedStage,
}) {
  useStyles([style]);

  function renderOption({
    headerLabel,
    metricKey,
    metricValue,
  }) {
    const formattedValue = metricsFormatter({ metricKey, value: metricValue.value }) || noValue;
    const formattedGrowth = metricsFormatter({ growth: metricValue.growth });

    return (
      <div className={styles.metricItem}>
        <div className={styles.headerLabel}>
          {!isLoaded && isNil(headerLabel)
            ? <Skeleton {...skeletonSmallTextSizes} isLightTheme />
            : headerLabel}
        </div>
        <div className={styles.metricValue}>
          <div className={styles.valueNumber}>
            {!isLoaded && isNil(metricValue?.value)
              ? <Skeleton width={60} height={32} />
              : formattedValue}
          </div>
          {!isLoaded && isNil(metricValue?.growth)
            ? <Loading3Dots />
            : (
              <ComparisonValue
                value={formattedGrowth}
                className={styles.growth}
                isGrowthDesired={getIsGrowthDesired({ metricName: metricKey })}
              />
            )}
        </div>
      </div>
    );
  }

  return (
    <MetaDataBox
      className={styles.overviewWrapper}
      childClass={styles.metricItemContainer}
      options={Object.entries(overviewData)}
      renderChild={([metricKey, metricValue]) => renderOption({
        headerLabel: getOverviewMetricLabel({ metricKey, lastSelectedStage }),
        metricKey,
        metricValue,
      })}
      direction={directions.horizontal}
    />
  );
}
