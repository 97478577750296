exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".funnel-view__wrapper-2O9{background-color:var(--colorsGeneralWhite);padding:24px;border-radius:12px;display:flex;align-items:center;height:280px;box-shadow:0 4px 12px 0 #e6e8f0;justify-content:center;margin-bottom:40px}.funnel-view__wrapper-2O9>div{max-width:1200px}.funnel-view__reportWrapper-1ha .funnel-view__wrapper-2O9{height:100%;min-height:180px;padding:32px 24px}", ""]);

// exports
exports.locals = {
	"wrapper": "funnel-view__wrapper-2O9",
	"wrapper": "funnel-view__wrapper-2O9",
	"reportWrapper": "funnel-view__reportWrapper-1ha",
	"reportWrapper": "funnel-view__reportWrapper-1ha"
};