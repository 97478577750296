import { suggestionsDropdownTypes } from 'components/pages/questions/enums';

export const folderActionsKeys = {
  moveToFolder: 'moveToFolder',
  createNewFolder: 'createNewFolder',
  renameFolder: 'renameFolder',
  deleteFolder: 'deleteFolder',
  deleteReport: 'deleteReport',
};

export const folderOfUnassignedReports = {
  value: 'unassigned',
  label: 'Unassigned Reports',
};

export const dateFormat = 'MMM, DD YYYY';

export const errorMessagesNameExists = 'This name is already in use, please select another name';

export const reportIdParam = 'reportId';

export const reportTemplateIconMapping = {
  demandGen: 'demandGen',
  salesReport: 'salesReport',
  contentAnalysis: 'contentAnalysis',
  channelDrilldown: 'channelDrilldown',
  closedWonAnalysis: 'closedWonAnalysis',
  accountBasedMarketing: 'accountBasedMarketing',
  paidAcquisitionReport: 'paidAcquisitionReport',
};

export const reportTemplatePropsMapping = {
  [suggestionsDropdownTypes.segment]: {
    label: 'Breakdown Segment',
    description: 'The segment used for breakdown analysis',
  },
  [suggestionsDropdownTypes.channels]: {
    label: 'Channel',
    description: 'The channel you\'d like to analyze',
  },
  [suggestionsDropdownTypes.kpiFocus]: {
    label: 'KPI',
    description: 'The key metric for the report',
  },
  [suggestionsDropdownTypes.timeframe]: {
    label: 'Timeframe',
    getIsValid: ({ value }) => value.value !== '',
    description: 'The default timeframe for the report',
    placeholder: 'Select...',
  },
  [suggestionsDropdownTypes.customFields]: {
    label: '',
    description: '',
    getIsValid: ({ value }) => value.value !== '' && value.id !== '',
  },
};

export const suggestionTypesWithObjectValues = new Set([
  suggestionsDropdownTypes.timeframe,
  suggestionsDropdownTypes.customFields,
]);

export const reportTemplateTypes = {
  scratch: 'Scratch',
  template: 'Template',
  onboarding: 'Onboarding',
};

export const shouldUsePredefinedFiltersTitle = 'Pre-defined filters';
