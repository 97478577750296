import React from 'react';
import { cloneDeep } from 'lodash';
import { inject, observer } from 'mobx-react';

import HistoricalPerformance from 'components/pages/analyze/OverviewTab/HistoricalPerformance';

import { navigateToJourneys } from 'stores/analyze/logic/journeysStore';
import { widgetTypes } from 'components/pages/analyze/enums';
import { getWidgetConfigForServerRequestId } from 'components/pages/reports/logic/widgetsWrapper';

function HistoricalPerformanceWrapper({
  historicalPerformance = {},
  historicalPerformanceFrequency,
  widgetHeaderConfig,
  timeFrame,
  updateWidgetHistoricalPerformanceFrequency,
  updateHistoricalPerformanceDisplayedMetrics,
  selectedReport,
  activeIndicator,
  activePage,
  widgetConfig,
  getWidgetRequestId,
  widgetHeaderProps,
  emptyStateComponent,
}) {
  const navigateFromHistoricalPerformanceToJourneys = (funnelStage) => {
    navigateToJourneys({ indicator: funnelStage, timeFrame: timeFrame.value, timeFrameParams: timeFrame }, cloneDeep(widgetHeaderConfig.filters));
  };

  const onChangeFrequency = (value) => {
    const reportId = selectedReport.value;
    const { widgetId } = widgetHeaderConfig;
    updateWidgetHistoricalPerformanceFrequency({ reportId, widgetId, historicalPerformanceFrequency: value });
  };

  function updateWidgetHistoricalPerformanceDisplayedMetrics({ selectedMetrics, shouldForceSaveChanges }) {
    const reportId = selectedReport.value;
    const { widgetId } = widgetHeaderConfig;
    updateHistoricalPerformanceDisplayedMetrics({
      reportId, widgetId, selectedMetrics, shouldForceSaveChanges,
    });
  }

  const requestId = getWidgetRequestId({ widget: widgetTypes.historicalPerformance, widgetConfig: getWidgetConfigForServerRequestId({ widgetConfig, widgetHeaderConfig, widget: widgetTypes.historicalPerformance }) });
  const historicalPerformanceData = historicalPerformance?.[requestId]?.result;

  const isLoaded = historicalPerformance?.[requestId]?.status === 'finished';
  const isFailedToLoad = historicalPerformance?.[requestId]?.status === 'failed';

  return (
    <HistoricalPerformance
      isLoaded={isLoaded}
      isFailedToLoad={isFailedToLoad}
      historicalPerformance={historicalPerformanceData}
      historicalPerformanceFrequency={historicalPerformanceFrequency}
      navigateToJourneys={navigateFromHistoricalPerformanceToJourneys}
      updateHistoricalPerformanceFrequency={onChangeFrequency}
      widgetHeaderConfig={widgetHeaderConfig}
      activeIndicator={activeIndicator}
      activePage={activePage}
      isReadOnly={widgetHeaderConfig.isReadOnly}
      widgetHeaderProps={widgetHeaderProps}
      isCompareToPreviousEnabled={widgetHeaderConfig.isCompareToPreviousEnabled}
      selectedMetrics={widgetConfig.displayedMetrics || []}
      setSelectedMetrics={({ selectedMetrics, isOnMount }) => {
        updateWidgetHistoricalPerformanceDisplayedMetrics({ selectedMetrics, shouldForceSaveChanges: isOnMount });
      }}
      emptyStateComponent={emptyStateComponent}
      title={widgetHeaderConfig.title}
    />
  );
}

export default inject(
  ({
    reportsStore: {
      updateHistoricalPerformanceDisplayedMetrics,
      updateWidgetHistoricalPerformanceFrequency,
      selectedReport,
    },
    widgetsAnalysisStore: {
      getWidgetRequestId,
      dataPerWidget: {
        [widgetTypes.historicalPerformance]: historicalPerformance,
      },
    },
  }) => ({
    updateHistoricalPerformanceDisplayedMetrics,
    updateWidgetHistoricalPerformanceFrequency,
    selectedReport,
    getWidgetRequestId,
    historicalPerformance,
  }),
  observer
)(HistoricalPerformanceWrapper);
