export const inspectTableColumnsKeys = {
  displayName: 'displayName',
  opportunity: 'opportunity',
  opportunitySource: 'opportunitySource',
  uniqChannels: 'uniqChannels',
};

export const inspectTableColumnsLabels = {
  [inspectTableColumnsKeys.displayName]: 'Company Name',
  [inspectTableColumnsKeys.opportunity]: 'Opportunity',
  [inspectTableColumnsKeys.opportunitySource]: 'Opportunity Source (Salesforce)',
  [inspectTableColumnsKeys.uniqChannels]: 'Attributed Channels (InfiniGrow)',
};

export const inspectTableColumns = [
  inspectTableColumnsKeys.displayName,
  inspectTableColumnsKeys.opportunity,
  inspectTableColumnsKeys.opportunitySource,
  inspectTableColumnsKeys.uniqChannels,
];
