import { camelCase, isEmpty, orderBy } from 'lodash';

import userStore from 'stores/userStore';

import { getRevenueFunnel, getPipelineFunnel } from 'components/utils/indicators';
import { metricsTypes } from 'components/common/logic/enums';
import { DIRECT_CHANNEL, UNKNOWN_CHANNEL } from 'components/utils/channels';

import {
  avgDealSize,
  pipelineAvgDealSize,
  currentFunnelStage,
  kpiFocusOptionsKeys,
} from 'components/widgets/optimalJourney/enums';
import { firstStageKey } from 'components/widgets/optimalJourney/journeyCanvas/enums';
import { pageViews, webVisits } from 'components/pages/analyze/AttribuitonTable/enums';
import { segmentsBlackList } from 'components/pages/analyze/optimalJourneyTab/enums';
import { getFilteredSegmentAnalysisRecords } from 'components/pages/analyze/SegmentsTab/logic/segments';

export function getSelectedStagesDefault({ stages }) {
  if (stages.length === 0) {
    return [];
  }

  const selectedStages = new Set();
  const firstStage = stages[0];
  const secondStage = stages[1];
  selectedStages.add(firstStage);
  selectedStages.add(secondStage);

  let revenueFunnelStage = getRevenueFunnel();
  if (!revenueFunnelStage) {
    revenueFunnelStage = stages[stages.length - 1].value;
  }
  const revenueStageNumber = revenueFunnelStage.replace('funnel', '');
  const revenueStage = stages[revenueStageNumber - 1];
  selectedStages.add(revenueStage);

  const { isPipelineStageHidden } = userStore.userMonthPlan || {};

  if (isPipelineStageHidden) {
    const revenuePreviousStage = stages[revenueStageNumber - 2];
    const revenuePreviousTwoStages = stages[revenueStageNumber - 3];
    selectedStages.add(revenuePreviousTwoStages);
    selectedStages.add(revenuePreviousStage);
  } else {
    let pipelineFunnelStage = getPipelineFunnel();
    if (!pipelineFunnelStage) {
      pipelineFunnelStage = stages[stages.length - 2].value;
    }

    const pipelineStageNumber = pipelineFunnelStage.replace('funnel', '');
    const pipelineStage = stages[pipelineStageNumber - 1];
    const pipelinePreviousStage = stages[pipelineStageNumber - 2];
    selectedStages.add(pipelinePreviousStage);
    selectedStages.add(pipelineStage);
  }

  const selectedStagesArray = Array.from(selectedStages);
  return orderBy(selectedStagesArray, ['value'], ['asc']);
}

export function getMaxValueOfDataBySegmentKey({
  data,
  metric,
  total = 0,
  segment,
}) {
  const maxSegment = { value: 0, segmentValue: null, growth: 0 };

  const segmentsToFilter = [...segmentsBlackList];
  if (segment === 'channel') {
    segmentsToFilter.push(DIRECT_CHANNEL);
    segmentsToFilter.push(UNKNOWN_CHANNEL);
  }

  if (segment === 'campaign') {
    segmentsToFilter.push('- other');
  }

  const filteredDataByBlackList = data.filter((dataItem) => !segmentsToFilter.some((blackWord) => !dataItem.firstSegment || dataItem.firstSegment.includes(blackWord)));

  for (const dataItem of filteredDataByBlackList) {
    const value = dataItem[metric];
    if (!value) {
      continue;
    }

    const highPercentAnomaly = value > 99;
    if (highPercentAnomaly) {
      continue;
    }

    const touched = dataItem.touched != null ? dataItem.touched : dataItem.attributed;
    const shareOfTotal = (total === 0 ? 0 : touched / total) * 100;
    const shareOfTotalAnomaly = shareOfTotal < 1;
    if (shareOfTotalAnomaly) {
      continue;
    }

    if (value > maxSegment.value) {
      maxSegment.value = value;
      maxSegment.segmentValue = dataItem.firstSegment;
      maxSegment.growth = dataItem.growthPerMetric?.[metric];
      maxSegment.shareOfTotal = shareOfTotal;
      maxSegment.touched = dataItem.touched;
      maxSegment.total = total;
    }
  }

  return maxSegment;
}

export function parseSegmentsAnalysisData({
  segmentData = [], stages, kpiFocus, dataByStages, segment, filters,
}) {
  const updatedDataByStages = { ...dataByStages };
  const lastStage = stages[stages.length - 1];
  let destinationStage = stages[stages.length - 1];

  for (const [index, currentStage] of stages.entries()) {
    let currentStageKey = currentStage.value;
    const nextStage = stages[index + 1];
    if (currentStage === lastStage.value || !nextStage) {
      continue;
    }
    if (kpiFocus === kpiFocusOptionsKeys.conversionRateToNext) {
      destinationStage = nextStage;
    }

    if (currentStageKey === firstStageKey) {
      currentStageKey = segmentData[webVisits] ? webVisits : pageViews;
    }

    const dataForStage = segmentData[currentStageKey] ?? [];
    const filteredDataForStage = getFilteredSegmentAnalysisRecords({
      data: dataForStage,
      filters,
      segments: { firstSegment: segment },
    });

    const conversionRateKey = camelCase(`${currentStageKey} to ${destinationStage.value} conversionRate`);
    const total = segmentData.countPerStage?.[currentStageKey] ?? 0;

    const maxSegment = getMaxValueOfDataBySegmentKey({
      data: filteredDataForStage,
      metric: conversionRateKey,
      total,
      segment,
    });

    updatedDataByStages[currentStage.value] = {
      ...updatedDataByStages[currentStage.value],
      [segment]: maxSegment,
      destinationStage,
      currentStage,
      nextStage,
      lastStage,
    };
  }

  return updatedDataByStages;
}

export function parseAccountPerformancesData({ performancesData = [], stages, dataByStages }) {
  const updatedDataByStages = { ...dataByStages };
  for (const [index, currentStage] of stages.entries()) {
    const currentStageKey = currentStage.value;
    const nextStage = stages[index + 1];
    if (!nextStage) {
      continue;
    }

    const betweenStagesPrefix = camelCase(`${currentStageKey} to ${nextStage.value}`);
    const beforeStagePrefix = camelCase(`before ${nextStage.value}`);
    const stagesPrefix = currentStageKey === firstStageKey ? beforeStagePrefix : betweenStagesPrefix;

    const velocityKey = camelCase(`${betweenStagesPrefix} velocity`);
    const numberOfSessionsKey = camelCase(`${stagesPrefix} numberOfSessions`);
    const numberOfContactsKey = camelCase(`${stagesPrefix} numberOfContacts`);

    const performancesDestinationStageData = performancesData[nextStage.value] ?? {};
    const performanceMetrics = {
      velocity: {
        value: performancesDestinationStageData?.[velocityKey],
        growth: performancesDestinationStageData?.growthPerMetric?.[velocityKey],
      },
      numberOfSessions: {
        value: performancesDestinationStageData?.[numberOfSessionsKey],
        growth: performancesDestinationStageData?.growthPerMetric?.[numberOfSessionsKey],
      },
      numberOfContacts: {
        value: performancesDestinationStageData?.[numberOfContactsKey],
        growth: performancesDestinationStageData?.growthPerMetric?.[numberOfContactsKey],
      },
    };

    updatedDataByStages[currentStage.value] = {
      ...dataByStages[currentStage.value],
      ...performanceMetrics,
    };
  }
  return updatedDataByStages;
}

export function parseOverviewData({ data, lastSelectedStageValue }) {
  const metrics = {
    [currentFunnelStage]: {},
    [avgDealSize]: {},
  };

  if (isEmpty(data)) {
    return metrics;
  }

  const currentFunnelStageData = data[lastSelectedStageValue] || {};
  const growthPerMetric = currentFunnelStageData?.growthPerMetric || {};

  metrics[currentFunnelStage] = { value: currentFunnelStageData[metricsTypes.attributed], growth: growthPerMetric?.[metricsTypes.attributed] };
  const avgDealSizeMetricKey = currentFunnelStageData[pipelineAvgDealSize] != null ? pipelineAvgDealSize : avgDealSize;
  metrics[avgDealSize] = { value: currentFunnelStageData[avgDealSizeMetricKey], growth: growthPerMetric?.[avgDealSizeMetricKey] };

  return metrics;
}
