import React from 'react';
import { orderBy } from 'lodash';

import useStyles from 'hooks/useStyles';

import userStore from 'stores/userStore';
import servicesStore from 'stores/servicesStore';

import Dropdown from 'components/controls/Dropdown';
import MultiCheckSelect from 'components/controls/MultiCheckSelect';
import TimeframeDropdown from 'components/common/TimeframeDropdown';
import Tooltip from 'components/controls/Tooltip';

import { Events } from 'trackers/analytics/enums';
import { getKpiFocusOptions } from 'components/widgets/optimalJourney/enums';
import { maxSelectedStages } from 'components/pages/analyze/optimalJourneyTab/enums';

import style from 'styles/analyze/optimal-journey.css';

const styles = style.locals || {};

export default function OptimalJourneyHeader({
  optimalJourneyStages = [],
  setOptimalJourneyStages,
  optimalJourneyKpi,
  setOptimalJourneyKpi,
  shouldShowTimeframe = true,
  optimalJourneyTimeframe,
  setOptimalJourneyTimeframe,
  hasOptimalJourneyTimeframeWarning,
}) {
  useStyles([style]);

  function onChangeSelectedStages({ stages }) {
    const orderedStages = orderBy(stages, ['value'], ['asc']);
    setOptimalJourneyStages({ stages: orderedStages });

    servicesStore.eventTracker.track({
      eventName: Events.optimalJourney.selectFunnelStages,
      properties: {
        selectedStagesRatio: stages.length / maxSelectedStages,
        maxStages: maxSelectedStages,
      },
    });
  }

  function onChangeKpiFocus({ kpiFocus }) {
    setOptimalJourneyKpi({ kpi: kpiFocus.value });

    servicesStore.eventTracker.track({
      eventName: Events.optimalJourney.changeKPI,
      properties: {
        KPI: kpiFocus.label,
      },
    });
  }

  const lastStageLabel = optimalJourneyStages[optimalJourneyStages.length - 1]?.label || '';

  return (
    <div className={styles.header}>
      {shouldShowTimeframe ? (
        <TimeframeDropdown
          onChange={(newTimeFrame) => setOptimalJourneyTimeframe({ timeFrame: newTimeFrame })}
          timeframe={optimalJourneyTimeframe}
          classNamePopup={styles.timeframeDropdownPopup}
          classNameMenuTooltip={styles.timeframeDropdownMenuTooltip}
          rightIconRerender={hasOptimalJourneyTimeframeWarning ? (
            <Tooltip
              tip="Timeframe was adjusted to Last 12 Months to better match sales cycle length."
              id="timeframeTooltipIcon"
              className={styles.timeframeTooltipIcon}
            >
              <div className={styles.timeframeIcon} />
            </Tooltip>
          ) : null}
        />
      ) : null}
      <MultiCheckSelect
        options={userStore.funnelsOptions}
        isDropDownSelect
        selected={optimalJourneyStages}
        onChange={(stages) => onChangeSelectedStages({ stages })}
        dropdownLabel="Stages"
        controlWidth={250}
        isSearchable={false}
        isClearable={false}
        isSearchIconPlaceholder={false}
        isDropdownIndicator
        isMultiLabelAsText
        maxSelectedItems={maxSelectedStages}
      />
      <Dropdown
        options={getKpiFocusOptions({ lastStageLabel })}
        selectedKey={optimalJourneyKpi}
        dropdownLabel="KPI"
        onChange={(kpiFocus) => onChangeKpiFocus({ kpiFocus })}
      />
    </div>
  );
}
