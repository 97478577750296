export const segmentsAnalysisMockData = {
  channel: {
    countPerStage: {
      webVisits: 500,
      funnel1: 400,
      funnel2: 300,
      funnel3: 200,
      funnel4: 100,
    },
    funnel1: [
      {
        firstSegment: 'advertising_socialAds_linkedinAdvertising',
        attributed: 50,
        touched: 70,
        funnel1ToFunnel2ConversionRate: 17.3,
        funnel1ToFunnel4ConversionRate: 0.1,
        growthPerMetric: {
          funnel1ToFunnel2ConversionRate: -12,
          funnel1ToFunnel4ConversionRate: 0,
        },
      },
      {
        firstSegment: 'Organic Search',
        attributed: 40,
        touched: 58,
        funnel1ToFunnel2ConversionRate: 0.1,
        funnel1ToFunnel4ConversionRate: 7.1,
        growthPerMetric: {
          funnel1ToFunnel2ConversionRate: 0,
          funnel1ToFunnel4ConversionRate: 20,
        },
      },
    ],
    funnel2: [
      {
        firstSegment: 'Events',
        attributed: 10,
        touched: 13,
        funnel2ToFunnel3ConversionRate: 0.1,
        funnel2ToFunnel4ConversionRate: 13,
        growthPerMetric: {
          funnel2ToFunnel3ConversionRate: -50,
          funnel2ToFunnel4ConversionRate: 10,
        },
      },
      {
        firstSegment: 'advertising_socialAds_linkedinAdvertising',
        attributed: 10,
        touched: 20,
        funnel2ToFunnel3ConversionRate: 26,
        funnel2ToFunnel4ConversionRate: 0.1,
        growthPerMetric: {
          funnel2ToFunnel3ConversionRate: 10,
          funnel2ToFunnel4ConversionRate: 20,
        },
      },
    ],
    funnel3: [
      {
        firstSegment: 'Sales Call',
        attributed: 12,
        touched: 15,
        funnel3ToFunnel4ConversionRate: 38,
        growthPerMetric: {
          funnel3ToFunnel4ConversionRate: -24,
        },
      },
    ],
    webVisits: [
      {
        firstSegment: 'advertising_socialAds_linkedinAdvertising',
        attributed: 60,
        touched: 75,
        webVisitsToFunnel1ConversionRate: 0.1,
        webVisitsToFunnel4ConversionRate: 1.7,
        growthPerMetric: {
          webVisitsToFunnel1ConversionRate: 1,
          webVisitsToFunnel4ConversionRate: 30,
        },
      },
      {
        firstSegment: 'Google Ads',
        attributed: 80,
        touched: 90,
        webVisitsToFunnel1ConversionRate: 12.7,
        webVisitsToFunnel4ConversionRate: 0.1,
        growthPerMetric: {
          webVisitsToFunnel1ConversionRate: -15,
          webVisitsToFunnel4ConversionRate: 0,
        },
      },
    ],
  },
  department: {
    countPerStage: {
      webVisits: 500,
      funnel1: 400,
      funnel2: 300,
      funnel3: 200,
      funnel4: 100,
    },
    funnel1: [
      {
        firstSegment: 'marketing',
        attributed: 50,
        touched: 70,
        funnel1ToFunnel2ConversionRate: 14.3,
        funnel1ToFunnel4ConversionRate: 4.3,
        growthPerMetric: {
          funnel1ToFunnel2ConversionRate: -6,
          funnel1ToFunnel4ConversionRate: 12,
        },
      },
    ],
    funnel2: [
      {
        firstSegment: 'marketing',
        attributed: 50,
        touched: 70,
        funnel2ToFunnel3ConversionRate: 18.6,
        funnel2ToFunnel4ConversionRate: 8.2,
        growthPerMetric: {
          funnel2ToFunnel3ConversionRate: -50,
          funnel2ToFunnel4ConversionRate: 10,
        },
      },
    ],
    funnel3: [
      {
        firstSegment: 'sales',
        attributed: 10,
        touched: 15,
        funnel3ToFunnel4ConversionRate: 38,
        growthPerMetric: {
          funnel3ToFunnel4ConversionRate: -20,
        },
      },
    ],
    webVisits: [
      {
        firstSegment: 'marketing',
        attributed: 80,
        touched: 90,
        webVisitsToFunnel1ConversionRate: 9.2,
        webVisitsToFunnel4ConversionRate: 2.1,
        growthPerMetric: {
          webVisitsToFunnel1ConversionRate: 0,
          webVisitsToFunnel4ConversionRate: 30,
        },
      },
    ],
  },
  '585f3b85-cfb7-4969-9b0e-79f4a369e2b6': {
    countPerStage: {
      webVisits: 500,
      funnel1: 400,
      funnel2: 300,
      funnel3: 200,
      funnel4: 100,
    },
    funnel1: [
      {
        firstSegment: 'Financial Analyst',
        attributed: 50,
        touched: 70,
        funnel1ToFunnel2ConversionRate: 21,
        funnel1ToFunnel4ConversionRate: 5.6,
        growthPerMetric: {
          funnel1ToFunnel2ConversionRate: -6,
          funnel1ToFunnel4ConversionRate: 12,
        },
      },
    ],
    funnel2: [
      {
        firstSegment: 'Senior Financial Lead',
        attributed: 50,
        touched: 70,
        funnel2ToFunnel3ConversionRate: 28,
        funnel2ToFunnel4ConversionRate: 0.1,
        growthPerMetric: {
          funnel2ToFunnel3ConversionRate: -50,
          funnel2ToFunnel4ConversionRate: 0,
        },
      },
      {
        firstSegment: 'CFO',
        attributed: 50,
        touched: 70,
        funnel2ToFunnel3ConversionRate: 0.1,
        funnel2ToFunnel4ConversionRate: 11.2,
        growthPerMetric: {
          funnel2ToFunnel3ConversionRate: 0,
          funnel2ToFunnel4ConversionRate: -24,
        },
      },
    ],
    funnel3: [
      {
        firstSegment: 'CFO',
        attributed: 10,
        touched: 15,
        funnel3ToFunnel4ConversionRate: 31,
        growthPerMetric: {
          funnel3ToFunnel4ConversionRate: -18,
        },
      },
    ],
    webVisits: [
      {
        firstSegment: 'Financial Analyst',
        attributed: 80,
        touched: 90,
        webVisitsToFunnel1ConversionRate: 18,
        webVisitsToFunnel4ConversionRate: 1.8,
        growthPerMetric: {
          webVisitsToFunnel1ConversionRate: 3,
          webVisitsToFunnel4ConversionRate: 6,
        },
      },
    ],
  },
};

export const accountPerformanceMockData = {
  funnel1: {
    beforeFunnel1NumberOfSessions: 7,
    webVisitsToFunnel1Velocity: 32,
    growthPerMetric: {
      beforeFunnel1NumberOfSessions: 9,
      webVisitsToFunnel1Velocity: -8,
    },
  },
  funnel2: {
    beforeFunnel2NumberOfSessions: 6,
    funnel1ToFunnel2NumberOfSessions: 2,
    funnel1ToFunnel2Velocity: 1,
    growthPerMetric: {
      beforeFunnel1NumberOfSessions: 10,
      funnel1ToFunnel2NumberOfSessions: 14,
      funnel1ToFunnel2Velocity: -10,
    },
  },
  funnel3: {
    beforeFunnel3NumberOfSessions: 9,
    funnel1ToFunnel3NumberOfSessions: 7,
    funnel2ToFunnel3NumberOfSessions: 5,
    funnel2ToFunnel3Velocity: 93,
    growthPerMetric: {
      beforeFunnel3NumberOfSessions: -3,
      funnel1ToFunnel3NumberOfSessions: 12,
      funnel2ToFunnel3NumberOfSessions: 4,
      funnel2ToFunnel3Velocity: 23,
    },
  },
  funnel4: {
    attributed: 1100,
    avgDealSize: 75000,
    beforeFunnel4NumberOfSessions: 23,
    funnel1ToFunnel4NumberOfSessions: 20,
    funnel2ToFunnel4NumberOfSessions: 16,
    funnel3ToFunnel4NumberOfSessions: 11,
    funnel3ToFunnel4Velocity: 63,
    growthPerMetric: {
      attributed: 13,
      avgDealSize: -12,
      beforeFunnel4NumberOfSessions: 2,
      funnel1ToFunnel4NumberOfSessions: 15,
      funnel2ToFunnel4NumberOfSessions: -2,
      funnel3ToFunnel4NumberOfSessions: -4,
      funnel3ToFunnel4Velocity: -8,
    },
  },
};
