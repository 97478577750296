exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".editable-budget-cell__container-1fu{display:flex;align-items:center;width:100%;height:100%}.editable-budget-cell__toggle-button-ARZ{width:20px;height:20px;margin:0 0 0 6px;background:url(/assets/plan_icons/edit-grey.svg) transparent 50% no-repeat;border:none;opacity:0}.editable-budget-cell__toggle-button__active-WTX{cursor:pointer}.editable-budget-cell__container-1fu:hover .editable-budget-cell__toggle-button-ARZ{opacity:1}.editable-budget-cell__container-1fu:hover .editable-budget-cell__toggle-button-ARZ[disabled]{opacity:0}.editable-budget-cell__input-2Fg{min-width:80px;height:32px;padding:0 10px;background-color:var(--colorsGeneralWhite);border-radius:4px;border:1px solid rgba(178,187,213,.5);font-size:var(--fontsSizesBodyNormal)}.editable-budget-cell__save-button-3cG{margin:0 0 0 4px;background:url(/assets/plan_icons/accept.svg) transparent 50% no-repeat}.editable-budget-cell__cancel-button-2e_,.editable-budget-cell__save-button-3cG{flex-grow:0;flex-shrink:0;width:20px;height:20px;border-radius:50%;border:none;cursor:pointer}.editable-budget-cell__cancel-button-2e_{margin:0 0 0 2px;background:url(/assets/plan_icons/decline.svg) transparent 50% no-repeat}", ""]);

// exports
exports.locals = {
	"container": "editable-budget-cell__container-1fu",
	"container": "editable-budget-cell__container-1fu",
	"toggle-button": "editable-budget-cell__toggle-button-ARZ",
	"toggleButton": "editable-budget-cell__toggle-button-ARZ",
	"toggle-button__active": "editable-budget-cell__toggle-button__active-WTX",
	"toggleButtonActive": "editable-budget-cell__toggle-button__active-WTX",
	"input": "editable-budget-cell__input-2Fg",
	"input": "editable-budget-cell__input-2Fg",
	"save-button": "editable-budget-cell__save-button-3cG",
	"saveButton": "editable-budget-cell__save-button-3cG",
	"cancel-button": "editable-budget-cell__cancel-button-2e_",
	"cancelButton": "editable-budget-cell__cancel-button-2e_"
};