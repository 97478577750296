import interactionsStore from 'stores/interactionsStore';

import { widgetsConfig } from 'components/pages/analyze/enums';
import { TIMEFRAME_VALUES } from 'components/utils/timeframe';
import { Interactions } from 'trackers/interactions/enums';

export const orderConfigFilters = (filtersConfig, sortConfigOrder) => {
  const updateFilterConfig = [];
  for (const filterKind of sortConfigOrder) {
    const filterConfig = filtersConfig.find((config) => config.kind === filterKind);
    if (!filterConfig) {
      continue;
    }
    updateFilterConfig.push(filterConfig);
  }
  return updateFilterConfig;
};

export function getFlagByWidgetName({ widgetName }) {
  const widgetsNamesValues = Object.values(widgetsConfig);
  return widgetsNamesValues.find((item) => item.type === widgetName)?.flag;
}

export function getTimeframeFromQueryParams({ params }) {
  if (params.timeframe_rollingValue) {
    return ({
      timeframeParams: {
        rollingValue: params.timeframe_rollingValue,
        value: TIMEFRAME_VALUES.ROLLING,
      },
      timeframeValue: TIMEFRAME_VALUES.ROLLING,
    });
  }

  if (params.timeframe_start && params.timeframe_end) {
    return ({
      timeframeParams: {
        startDate: params.timeframe_start,
        endDate: params.timeframe_end,
        value: TIMEFRAME_VALUES.CUSTOM,
      },
      timeframeValue: TIMEFRAME_VALUES.CUSTOM,
    });
  }

  const timeframeValue = params.timeframe_value;
  const timeframeToExclude = [TIMEFRAME_VALUES.CUSTOM, TIMEFRAME_VALUES.ROLLING];
  const timeframeValues = Object.values(TIMEFRAME_VALUES).filter((value) => !timeframeToExclude.includes(value));

  if (params.timeframe_value && timeframeValues.includes(timeframeValue)) {
    return ({
      timeframeParams: { value: timeframeValue },
      timeframeValue,
    });
  }

  return {};
}

export function applyFiltersInteraction({ filters }) {
  if (!filters.length) {
    interactionsStore.interactionTracker.trackConfig({
      type: Interactions.global.filtersRemoved.type,
      name: Interactions.global.filtersRemoved.name,
      description: '',
    });
    return;
  }

  const aiFiltersLabel = filters.map((filter) => filter.aiLabel).join(', ');
  interactionsStore.interactionTracker.trackConfig({
    type: Interactions.global.filtersApplied.type,
    name: Interactions.global.filtersApplied.name,
    description: aiFiltersLabel,
  });
}
