exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".add-custom-funnel__popup-container-3aP{top:40px;left:0;width:440px;min-height:0!important;box-sizing:border-box;z-index:100;height:330px;padding:12px!important}.add-custom-funnel__filter-popup-2L6{z-index:5;top:80px!important;width:600px;left:90px;display:flex}.add-custom-funnel__filter-popup-integration-QLe{left:40px;top:0!important}.add-custom-funnel__text-aFt{font-weight:500!important;color:var(--colorsGeneralBlack);font-size:13px}.add-custom-funnel__text-title-3yo{font-size:15px}.add-custom-funnel__filterTagsTitle-3W6{font-size:var(--fontsSizesBodyLarge);width:150px}.add-custom-funnel__filterTagsGroup-1Hn{width:calc(100% - 150px)}.add-custom-funnel__filterTagsRow-FWP{align-items:baseline}.add-custom-funnel__conversion-select-pVk{display:flex;align-items:center;font-size:var(--fontsSizesBodySmall);margin-left:8px;z-index:4;width:130px}.add-custom-funnel__row-center-11z{display:flex;flex-direction:row;align-items:center;margin-bottom:12px;padding:5px;box-sizing:border-box!important;border:1px solid transparent}.add-custom-funnel__error-2B3{border:1px solid #ff6961;border-radius:4px}.add-custom-funnel__preDefinedFiltersRow-2ei{margin-bottom:24px}", ""]);

// exports
exports.locals = {
	"popup-container": "add-custom-funnel__popup-container-3aP",
	"popupContainer": "add-custom-funnel__popup-container-3aP",
	"filter-popup": "add-custom-funnel__filter-popup-2L6",
	"filterPopup": "add-custom-funnel__filter-popup-2L6",
	"filter-popup-integration": "add-custom-funnel__filter-popup-integration-QLe add-custom-funnel__filter-popup-2L6",
	"filterPopupIntegration": "add-custom-funnel__filter-popup-integration-QLe add-custom-funnel__filter-popup-2L6",
	"text": "add-custom-funnel__text-aFt",
	"text": "add-custom-funnel__text-aFt",
	"text-title": "add-custom-funnel__text-title-3yo add-custom-funnel__text-aFt",
	"textTitle": "add-custom-funnel__text-title-3yo add-custom-funnel__text-aFt",
	"filterTagsTitle": "add-custom-funnel__filterTagsTitle-3W6",
	"filterTagsTitle": "add-custom-funnel__filterTagsTitle-3W6",
	"filterTagsGroup": "add-custom-funnel__filterTagsGroup-1Hn",
	"filterTagsGroup": "add-custom-funnel__filterTagsGroup-1Hn",
	"filterTagsRow": "add-custom-funnel__filterTagsRow-FWP",
	"filterTagsRow": "add-custom-funnel__filterTagsRow-FWP",
	"conversion-select": "add-custom-funnel__conversion-select-pVk add-custom-funnel__text-aFt",
	"conversionSelect": "add-custom-funnel__conversion-select-pVk add-custom-funnel__text-aFt",
	"row-center": "add-custom-funnel__row-center-11z",
	"rowCenter": "add-custom-funnel__row-center-11z",
	"error": "add-custom-funnel__error-2B3",
	"error": "add-custom-funnel__error-2B3",
	"preDefinedFiltersRow": "add-custom-funnel__preDefinedFiltersRow-2ei",
	"preDefinedFiltersRow": "add-custom-funnel__preDefinedFiltersRow-2ei"
};