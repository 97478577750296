exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".toggle-with-icons__wrapper-1gb{display:flex;flex-wrap:wrap;gap:8px}.toggle-with-icons__item-_pQ{height:40px;width:40px;border-radius:4px;border:1px solid var(--colorsInputGrayLine);display:flex;align-items:center;justify-content:center;cursor:pointer}.toggle-with-icons__item-_pQ.toggle-with-icons__active-3EH,.toggle-with-icons__item-_pQ:hover{background:var(--colorsGeneralBlue);border:1px solid var(--colorsGeneralBlue);color:var(--colorsGeneralWhite)}.toggle-with-icons__item-_pQ.toggle-with-icons__active-3EH img,.toggle-with-icons__item-_pQ:hover img{filter:brightness(0) invert(1)}.toggle-with-icons__disabled-2nk{cursor:default}", ""]);

// exports
exports.locals = {
	"wrapper": "toggle-with-icons__wrapper-1gb",
	"wrapper": "toggle-with-icons__wrapper-1gb",
	"item": "toggle-with-icons__item-_pQ",
	"item": "toggle-with-icons__item-_pQ",
	"active": "toggle-with-icons__active-3EH",
	"active": "toggle-with-icons__active-3EH",
	"disabled": "toggle-with-icons__disabled-2nk",
	"disabled": "toggle-with-icons__disabled-2nk"
};