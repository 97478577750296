import React from 'react';
import classnames from 'classnames';

import useStyles from 'hooks/useStyles';

import Page from 'components/Page';
import Dropdown from 'components/controls/Dropdown';
import SankeyShape from 'components/pages/settings/workflows/demoInspectPopups/SankeyShape';

import style from 'styles/settings/workflows/compareEnrichedMetrics.css';
import inspectStyle from 'styles/analyze/inspectData/inspect.css';

const styles = style.locals || {};
const inspectStyles = inspectStyle.locals || {};

export default function InspectConversionRatePopup({
  onClose,
  timeFrameLabel,
}) {
  useStyles([style, inspectStyle]);

  const sankeyShapeColor = '#d9dcf0';

  return (
    <Page
      popup
      width="940px"
      otherProps={{
        id: 'inspect-conversion-rate-popup',
      }}
      contentClassName={inspectStyles.content}
    >
      <div className={inspectStyles.inspectPopupWrapper}>
        <div className={inspectStyles.mainTopPopup}>
          <div
            className={inspectStyles.close}
            onClick={() => { onClose(); }}
          />
          <div className={inspectStyles.title}>
            {'MQLs -> Opps CR in Previous quarter'}
          </div>
          <div className={inspectStyles.topMenu}>
            <Dropdown
              options={[{ label: timeFrameLabel, value: timeFrameLabel }]}
              selectedKey={timeFrameLabel}
              dropdownLabel="Date range"
              disabled
            />
          </div>
        </div>
        <div className={styles.mainContentPopup}>
          <div className={styles.sankeyChartsTitles}>
            <div>New MQLS: 100</div>
            <div>New OPPS: 6</div>
          </div>
          <div className={styles.sankeyCharts}>
            <div className={styles.topSankey}>
              <div className={classnames(styles.left, styles.item, styles.hoverGeneral)}>
                Previous Quarter
                <b>100</b>
              </div>
              <div>
                <SankeyShape
                  fillColor={sankeyShapeColor}
                  leftHeight={120}
                  rightHeight={84}
                  width={148}
                />
              </div>
              <div className={classnames(styles.middle, styles.item, styles.hoverIG)}>
                MQL Accounts
                <b>38</b>
              </div>
              <div>
                <SankeyShape
                  fillColor={sankeyShapeColor}
                  leftHeight={84}
                  rightHeight={48}
                  width={148}
                />
              </div>
              <div className={classnames(styles.right, styles.item, styles.hoverIG, styles.hoverGeneral)}>
                <b>5</b>
              </div>
            </div>
            <div className={styles.bottomSankey}>
              <div className={classnames(styles.left, styles.item)}>
                From
                <br />
                {' Previous Timeframes'}
              </div>
              <div>
                <SankeyShape
                  fillColor="#cceae6"
                  leftHeight={180}
                  rightHeight={28}
                  width={456}
                  leftY={44}
                />
              </div>
              <div className={classnames(styles.right, styles.item, styles.hoverGeneral)}>
                <b>1</b>
              </div>
            </div>
          </div>

          <div className={styles.formulasWrapper}>
            <div className={classnames(styles.formulaBox, styles.formulaGeneral)}>
              <b>Traditional Conversion Rate</b>
              <div>=</div>
              <div className={styles.fraction}>
                <div>New Opps</div>
                <div>New MQLs</div>
              </div>
              <div>=</div>
              <div className={styles.fraction}>
                <div>6</div>
                <div>100</div>
              </div>
              <div>=</div>
              <b>6%</b>
            </div>

            <div className={classnames(styles.formulaBox, styles.formulaIG)}>
              <b>InfiniGrow’s Conversion Rate</b>
              <div>=</div>
              <div className={styles.fraction}>
                <div>Opps from New MQL’s Accounts</div>
                <div>New MQL’s Accounts</div>
              </div>
              <div>=</div>
              <div className={styles.fraction}>
                <div>5</div>
                <div>38</div>
              </div>
              <div>=</div>
              <b>13%</b>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}
