import { Fragment } from 'react';
import { isEmpty } from 'lodash';

import { kpiFocusOptionsKeys } from 'components/widgets/optimalJourney/enums';
import { firstJourneySection } from 'components/widgets/optimalJourney/journeyCanvas/enums';
import {
  journeyInsightsConditionMetadata,
  journeyInsightTypes,
  blockTypesBlacklist,
  optimizationMetrics,
  timeStatus,
  ratioThreshold,
} from 'components/widgets/optimalJourney/insights/enums';
import { metricsFormatter, segmentsFormatter } from 'components/widgets/optimalJourney/logic/optimalJourney';
import { getSegmentLabel } from 'components/pages/analyze/SegmentsTab/logic/segments';

export function getConsecutiveAndSequenceFunnels({ optimalJourneyStages, segmentFunnelStages }) {
  const sequenceFunnelStages = [];
  const consecutiveFunnelStages = [];
  const funnelsSet = new Set(segmentFunnelStages);

  let sequence = {};
  const lastSelectedFunnelStageIndex = optimalJourneyStages.length - 1;
  for (let funnelStageIndex = 0; funnelStageIndex < lastSelectedFunnelStageIndex; funnelStageIndex++) {
    const source = optimalJourneyStages[funnelStageIndex];
    const dest = optimalJourneyStages[funnelStageIndex + 1];

    if (funnelsSet.has(source.value)) {
      consecutiveFunnelStages.push({ source, dest });
    }

    if (funnelsSet.has(source.value) && sequence.dest?.value === source.value) {
      sequence.dest = dest;
      sequence.size++;
    } else if (funnelsSet.has(source.value) && funnelsSet.has(dest.value)) {
      sequence.source = source;
      sequence.dest = dest;
      sequence.size = 0;
    }

    if (sequence.size) {
      sequenceFunnelStages.push({ source: sequence.source, dest: sequence.dest });
      sequence = {};
    }
  }

  return { consecutiveFunnelStages, sequenceFunnelStages };
}

export function getInsights({
  data,
  optimalJourneyKpi,
  optimalJourneyStages,
  selectedBlocksItems,
  customFieldsIdToLabelMap,
  customUtmsWhitelist = [],
}) {
  const optimizationMetricsData = {};
  const allSegmentsData = {};

  for (const [funnel, funnelData] of Object.entries(data)) {
    for (const [blockType, blockData] of Object.entries(funnelData)) {
      const shouldSkipFirstStageData = funnel === firstJourneySection.value && optimalJourneyKpi === kpiFocusOptionsKeys.conversionRateToLast;
      const shouldSkipBlock = !selectedBlocksItems.find((blockItem) => blockItem.value === blockType)
        || blockTypesBlacklist.includes(blockType)
        || isEmpty(blockData)
        || shouldSkipFirstStageData;

      if (shouldSkipBlock) {
        continue;
      }

      const isOptimizationMetric = optimizationMetrics.includes(blockType);
      if (isOptimizationMetric) {
        if (!blockData.value) {
          continue;
        }

        if (!optimizationMetricsData[blockType]) {
          optimizationMetricsData[blockType] = {};
        }

        optimizationMetricsData[blockType][funnel] = blockData;

        continue;
      }

      const { segmentValue } = blockData;
      if (!segmentValue) {
        continue;
      }

      if (!allSegmentsData[blockType]) {
        allSegmentsData[blockType] = {};
      }

      if (!allSegmentsData[blockType][segmentValue]) {
        allSegmentsData[blockType][segmentValue] = {
          funnels: [],
        };
      }

      allSegmentsData[blockType][segmentValue].funnels.push(funnel);
    }
  }

  const optimizationMetricsInsights = getOptimizationMetricsInsights({ optimalJourneyStages, optimizationMetricsData });
  const segmentInsights = getSegmentInsights({
    optimalJourneyStages,
    optimalJourneyKpi,
    allSegmentsData,
    customFieldsIdToLabelMap,
    customUtmsWhitelist,
  });

  return [...optimizationMetricsInsights, ...segmentInsights];
}

export function getOptimizationMetricsInsights({ optimalJourneyStages, optimizationMetricsData }) {
  const insights = [];
  const maxPerMetric = {};
  const minPerMetric = {};
  const highestGrowthRatioPerMetric = {};

  for (const [metric, dataPerFunnel] of Object.entries(optimizationMetricsData)) {
    maxPerMetric[metric] = { value: 0 };
    minPerMetric[metric] = { value: Infinity };
    highestGrowthRatioPerMetric[metric] = {};

    for (const [funnel, funnelData] of Object.entries(dataPerFunnel)) {
      const sourceFunnelIndex = optimalJourneyStages.findIndex((funnelStage) => funnelStage.value === funnel);
      const sourceFunnel = optimalJourneyStages[sourceFunnelIndex] || firstJourneySection;
      const destFunnel = optimalJourneyStages[sourceFunnelIndex + 1];

      if (funnelData.value > maxPerMetric[metric].value) {
        maxPerMetric[metric] = {
          value: funnelData.value,
          sourceFunnel,
          destFunnel,
        };
      }

      if (funnelData.value < minPerMetric[metric].value) {
        minPerMetric[metric] = {
          value: funnelData.value,
          sourceFunnel,
          destFunnel,
        };
      }

      if (!highestGrowthRatioPerMetric[metric].growth || Math.abs(funnelData.growth) > Math.abs(highestGrowthRatioPerMetric[metric].growth)) {
        highestGrowthRatioPerMetric[metric] = {
          value: funnelData.value,
          growth: funnelData.growth,
          sourceFunnel,
          destFunnel,
        };
      }
    }
  }

  const insightTypes = journeyInsightTypes.optimizationMetrics;
  for (const metric of Object.keys(optimizationMetricsData)) {
    const metricMetadata = journeyInsightsConditionMetadata[metric];
    const minValueFormatted = metricsFormatter({ metricKey: metric, value: minPerMetric[metric].value });
    const maxValueFormatted = metricsFormatter({ metricKey: metric, value: maxPerMetric[metric].value });

    const highRatioThreshold = metricMetadata[journeyInsightTypes.optimizationMetrics.highRatio];
    if (maxPerMetric[metric].value >= highRatioThreshold * minPerMetric[metric].value) {
      const insight = journeyInsightsTemplates.optimizationMetrics[metric][insightTypes.highRatio]({
        quickFunnels: {
          source: minPerMetric[metric].sourceFunnel,
          dest: minPerMetric[metric].destFunnel,
          value: minValueFormatted,
        },
        slowFunnels: {
          source: maxPerMetric[metric].sourceFunnel,
          dest: maxPerMetric[metric].destFunnel,
          value: maxValueFormatted,
        },
      });

      insights.push({ type: insightTypes.highRatio, insight });
      continue;
    }

    const highGrowthRatioThreshold = metricMetadata[journeyInsightTypes.optimizationMetrics.highGrowthRatio];
    const highestGrowthRatioValue = highestGrowthRatioPerMetric[metric].growth;
    if (Math.abs(highestGrowthRatioValue) >= highGrowthRatioThreshold) {
      const growthValue = metricMetadata.shouldUseAbs ? Math.abs(highestGrowthRatioValue) : highestGrowthRatioValue;
      const isFaster = (metricMetadata.positiveIsFaster && highestGrowthRatioValue > 0) || (!metricMetadata.positiveIsFaster && highestGrowthRatioValue < 0);
      const status = isFaster ? timeStatus.faster : timeStatus.slower;

      const insight = journeyInsightsTemplates.optimizationMetrics[metric][insightTypes.highGrowthRatio]({
        funnels: {
          source: highestGrowthRatioPerMetric[metric].sourceFunnel,
          dest: highestGrowthRatioPerMetric[metric].destFunnel,
          value: metricsFormatter({ metricKey: metric, value: highestGrowthRatioPerMetric[metric].value }),
          growth: metricsFormatter({ growth: growthValue }),
        },
        status,
      });

      insights.push({ type: insightTypes.highGrowthRatio, insight });
      continue;
    }

    if (minPerMetric[metric].value !== maxPerMetric[metric].value) {
      const insight = journeyInsightsTemplates.optimizationMetrics[metric][insightTypes.topAndBottom]({
        quickFunnels: {
          source: minPerMetric[metric].sourceFunnel,
          dest: minPerMetric[metric].destFunnel,
          value: minValueFormatted,
        },
        slowFunnels: {
          source: maxPerMetric[metric].sourceFunnel,
          dest: maxPerMetric[metric].destFunnel,
          value: maxValueFormatted,
        },
      });

      insights.push({ type: insightTypes.topAndBottom, insight });
    }
  }

  return insights;
}

export function getSegmentInsights({
  optimalJourneyStages,
  optimalJourneyKpi,
  allSegmentsData,
  customFieldsIdToLabelMap,
  customUtmsWhitelist = [],
}) {
  const optimalJourneyStagesCount = optimalJourneyStages.length;
  const lastFunnelStage = optimalJourneyStages[optimalJourneyStagesCount - 1];
  const optimalJourneyRelevantStagesCount = optimalJourneyKpi === kpiFocusOptionsKeys.conversionRateToLast ? optimalJourneyStagesCount - 1 : optimalJourneyStagesCount;

  const insights = [];
  const maxRatioSegments = {};
  for (const [segmentType, segmentsData] of Object.entries(allSegmentsData)) {
    maxRatioSegments[segmentType] = { ratio: 0 };

    for (const [segmentValue, segmentData] of Object.entries(segmentsData)) {
      const { consecutiveFunnelStages, sequenceFunnelStages } = getConsecutiveAndSequenceFunnels({
        optimalJourneyStages: [firstJourneySection, ...optimalJourneyStages],
        segmentFunnelStages: segmentData.funnels,
      });

      segmentData.consecutiveFunnelStages = consecutiveFunnelStages;
      segmentData.sequenceFunnelStages = sequenceFunnelStages;

      if (segmentData.funnels.length <= 1) {
        continue;
      }

      segmentData.ratio = segmentData.funnels.length / optimalJourneyRelevantStagesCount;

      if (segmentData.ratio > maxRatioSegments[segmentType].ratio) {
        maxRatioSegments[segmentType].segmentValue = segmentValue;
        maxRatioSegments[segmentType].ratio = segmentData.ratio;
      }
    }
  }

  const insightTypes = journeyInsightTypes.segments;
  for (const [segmentType, segmentsData] of Object.entries(allSegmentsData)) {
    let segmentTypeAlreadyHasInsight = false;
    for (const [segmentValue, segmentData] of Object.entries(segmentsData)) {
      if (segmentTypeAlreadyHasInsight) {
        continue;
      }

      const insightParams = {
        segmentValue: segmentsFormatter.label({ segmentValue }),
        segmentType: getSegmentLabel({
          segment: segmentType,
          customFieldsIdToLabelMap,
          customUtmsWhitelist,
        }),
        lastFunnelStage,
      };

      const ratio = segmentData.funnels.length / optimalJourneyRelevantStagesCount;
      if (ratio >= ratioThreshold.full) {
        const insight = journeyInsightsTemplates[optimalJourneyKpi][insightTypes.all](insightParams);
        insights.push({ type: insightTypes.all, insight });
        segmentTypeAlreadyHasInsight = true;
        continue;
      }

      if (ratio >= ratioThreshold.most) {
        const insight = journeyInsightsTemplates[optimalJourneyKpi][insightTypes.most](insightParams);
        insights.push({ type: insightTypes.most, insight });
        segmentTypeAlreadyHasInsight = true;
        continue;
      }

      if (segmentData.sequenceFunnelStages.length) {
        const insight = journeyInsightsTemplates[optimalJourneyKpi][insightTypes.sequence]({ ...insightParams, sequenceFunnelStages: segmentData.sequenceFunnelStages });
        insights.push({ type: insightTypes.sequence, insight });
        segmentTypeAlreadyHasInsight = true;
        continue;
      }

      if (maxRatioSegments[segmentType].segmentValue === segmentValue) {
        const insight = journeyInsightsTemplates[optimalJourneyKpi][insightTypes.mostFrequent]({ ...insightParams, consecutiveFunnelStages: segmentData.consecutiveFunnelStages });
        insights.push({ type: insightTypes.mostFrequent, insight });
        segmentTypeAlreadyHasInsight = true;
      }
    }
  }

  return insights;
}

function getTextBetweenFunnelsPairs({ metric, insightType, funnelStagePairs }) {
  return (
    <>
      {funnelStagePairs.map((funnelsPair, index) => {
        const funnelsPairKey = `${metric}-${insightType}-${index}-${funnelsPair.source.label}-${funnelsPair.dest.label}`;
        return (
          <Fragment key={funnelsPairKey}>
            <b>{funnelsPair.source.label}</b>
            {' '}
            to
            {' '}
            <b>{funnelsPair.dest.label}</b>
            {index < funnelStagePairs.length - 1 && ' and between '}
          </Fragment>
        );
      })}
    </>
  );
}

export const journeyInsightsTemplates = {
  [kpiFocusOptionsKeys.conversionRateToNext]: {
    [journeyInsightTypes.segments.all]: ({ segmentValue, segmentType }) => (
      <>
        <b>{segmentValue}</b>
        {' '}
        is the top converting
        {' '}
        <b>{segmentType}</b>
        {' '}
        across all of your funnel.
      </>
    ),
    [journeyInsightTypes.segments.most]: ({ segmentValue, segmentType }) => (
      <>
        <b>{segmentValue}</b>
        {' '}
        is the top converting
        {' '}
        <b>{segmentType}</b>
        {' '}
        across most of your funnel.
      </>
    ),
    [journeyInsightTypes.segments.sequence]: ({
      segmentValue, segmentType, sequenceFunnelStages,
    }) => (
      <>
        <b>{segmentValue}</b>
        {' '}
        is the top converting
        {' '}
        <b>{segmentType}</b>
        {' '}
        when touched between
        {' '}
        {getTextBetweenFunnelsPairs({
          metric: kpiFocusOptionsKeys.conversionRateToNext,
          insightType: journeyInsightTypes.segments.sequence,
          funnelStagePairs: sequenceFunnelStages,
        })}
        .
      </>
    ),
    [journeyInsightTypes.segments.mostFrequent]: ({ segmentValue, segmentType, consecutiveFunnelStages }) => (
      <>
        <b>{segmentValue}</b>
        {' '}
        is the top converting
        {' '}
        <b>{segmentType}</b>
        {' '}
        when touched between
        {' '}
        {getTextBetweenFunnelsPairs({
          metric: kpiFocusOptionsKeys.conversionRateToNext,
          insightType: journeyInsightTypes.segments.mostFrequent,
          funnelStagePairs: consecutiveFunnelStages,
        })}
        .
      </>
    ),
  },
  [kpiFocusOptionsKeys.conversionRateToLast]: {
    [journeyInsightTypes.segments.all]: ({ segmentValue, segmentType, lastFunnelStage }) => (
      <>
        <b>{segmentValue}</b>
        {' '}
        is the top converting
        {' '}
        <b>{segmentType}</b>
        {' '}
        to
        {' '}
        <b>{lastFunnelStage.label}</b>
        {' '}
        across all of your funnel.
      </>
    ),
    [journeyInsightTypes.segments.most]: ({ segmentValue, segmentType, lastFunnelStage }) => (
      <>
        <b>{segmentValue}</b>
        {' '}
        is the top converting
        {' '}
        <b>{segmentType}</b>
        {' '}
        to
        {' '}
        <b>{lastFunnelStage.label}</b>
        {' '}
        across most of your funnel.
      </>
    ),
    [journeyInsightTypes.segments.sequence]: ({
      segmentValue, segmentType, sequenceFunnelStages, lastFunnelStage,
    }) => (
      <>
        <b>{segmentValue}</b>
        {' '}
        is the top converting
        {' '}
        <b>{segmentType}</b>
        {' '}
        to
        {' '}
        <b>{lastFunnelStage.label}</b>
        {' '}
        when touched between
        {' '}
        {getTextBetweenFunnelsPairs({
          metric: kpiFocusOptionsKeys.conversionRateToLast,
          insightType: journeyInsightTypes.segments.sequence,
          funnelStagePairs: sequenceFunnelStages,
        })}
        .
      </>
    ),
    [journeyInsightTypes.segments.mostFrequent]: ({ segmentValue, segmentType, consecutiveFunnelStages }) => (
      <>
        <b>{segmentValue}</b>
        {' '}
        is the top converting
        {' '}
        <b>{segmentType}</b>
        {' '}
        when touched between
        {' '}
        {getTextBetweenFunnelsPairs({
          metric: kpiFocusOptionsKeys.conversionRateToLast,
          insightType: journeyInsightTypes.segments.mostFrequent,
          funnelStagePairs: consecutiveFunnelStages,
        })}
        .
      </>
    ),
  },
  [kpiFocusOptionsKeys.shareOfTotal]: {
    [journeyInsightTypes.segments.all]: ({ segmentValue, segmentType, lastFunnelStage }) => (
      <>
        <b>{segmentValue}</b>
        {' '}
        is the most-common touched
        {' '}
        <b>{segmentType}</b>
        {' '}
        across your funnel stages, for your
        {' '}
        <b>{lastFunnelStage.label}</b>
        {' '}
        journeys.
      </>
    ),
    [journeyInsightTypes.segments.most]: ({ segmentValue, segmentType, lastFunnelStage }) => (
      <>
        <b>{segmentValue}</b>
        {' '}
        is the most-common touched
        {' '}
        <b>{segmentType}</b>
        {' '}
        across most your funnel stages, for your
        {' '}
        <b>{lastFunnelStage.label}</b>
        {' '}
        journeys.
      </>
    ),
    [journeyInsightTypes.segments.sequence]: ({ segmentValue, segmentType, sequenceFunnelStages }) => (
      <>
        <b>{segmentValue}</b>
        {' '}
        is the most-common
        {' '}
        <b>{segmentType}</b>
        {' '}
        when touched between
        {' '}
        {getTextBetweenFunnelsPairs({
          metric: kpiFocusOptionsKeys.shareOfTotal,
          insightType: journeyInsightTypes.segments.sequence,
          funnelStagePairs: sequenceFunnelStages,
        })}
        .
      </>
    ),
    [journeyInsightTypes.segments.mostFrequent]: ({ segmentValue, segmentType, consecutiveFunnelStages }) => (
      <>
        <b>{segmentValue}</b>
        {' '}
        is the most-common
        {' '}
        <b>{segmentType}</b>
        {' '}
        when touched between
        {' '}
        {getTextBetweenFunnelsPairs({
          metric: kpiFocusOptionsKeys.shareOfTotal,
          insightType: journeyInsightTypes.segments.mostFrequent,
          funnelStagePairs: consecutiveFunnelStages,
        })}
        .
      </>
    ),
  },
  optimizationMetrics: {
    velocity: {
      [journeyInsightTypes.optimizationMetrics.highRatio]: ({ quickFunnels, slowFunnels }) => (
        <>
          <b>{quickFunnels.source.label}</b>
          {' '}
          convert to
          {' '}
          <b>{quickFunnels.dest.label}</b>
          {' '}
          quickly (
          <b>{quickFunnels.value}</b>
          ), while the journey from
          {' '}
          <b>{slowFunnels.source.label}</b>
          {' '}
          to
          {' '}
          <b>{slowFunnels.dest.label}</b>
          {' '}
          is significantly longer (
          <b>{slowFunnels.value}</b>
          ).
        </>
      ),
      [journeyInsightTypes.optimizationMetrics.highGrowthRatio]: ({ funnels, status }) => (
        <>
          <b>{funnels.source.label}</b>
          {' '}
          convert to
          {' '}
          <b>{funnels.dest.label}</b>
          {' '}
          quickly (
          <b>{funnels.value}</b>
          ), for your optimal journeys, compared to journeys from previous period, which converted
          {' '}
          <b>{funnels.growth}</b>
          %
          {' '}
          <b>{status}</b>
          {' '}
          from
          {' '}
          <b>{funnels.source.label}</b>
          {' '}
          to
          {' '}
          <b>{funnels.dest.label}</b>
          .
        </>
      ),
      [journeyInsightTypes.optimizationMetrics.topAndBottom]: ({ quickFunnels, slowFunnels }) => (
        <>
          <b>{quickFunnels.source.label}</b>
          {' '}
          convert to
          {' '}
          <b>{quickFunnels.dest.label}</b>
          {' '}
          quickly (
          <b>{quickFunnels.value}</b>
          ), while the journey from
          {' '}
          <b>{slowFunnels.source.label}</b>
          {' '}
          to
          {' '}
          <b>{slowFunnels.dest.label}</b>
          {' '}
          is significantly longer (
          <b>{slowFunnels.value}</b>
          ).
        </>
      ),
    },
    numberOfSessions: {
      [journeyInsightTypes.optimizationMetrics.highRatio]: ({ quickFunnels, slowFunnels }) => (
        <>
          It takes
          {' '}
          <b>{slowFunnels.value}</b>
          {' '}
          touchpoints to convert from
          {' '}
          <b>{slowFunnels.source.label}</b>
          {' '}
          to
          {' '}
          <b>{slowFunnels.dest.label}</b>
          , while only
          {' '}
          <b>{quickFunnels.value}</b>
          {' '}
          toucpoints from
          {' '}
          <b>{quickFunnels.source.label}</b>
          {' '}
          to
          {' '}
          <b>{quickFunnels.dest.label}</b>
          .
        </>
      ),
      [journeyInsightTypes.optimizationMetrics.highGrowthRatio]: ({ funnels }) => (
        <>
          It takes
          {' '}
          <b>{funnels.value}</b>
          {' '}
          touchpoints to convert from
          {' '}
          <b>{funnels.source.label}</b>
          {' '}
          to
          {' '}
          <b>{funnels.source.label}</b>
          {' '}
          , which is
          {' '}
          <b>{funnels.growth}</b>
          % compared to previous period.
        </>
      ),
      [journeyInsightTypes.optimizationMetrics.topAndBottom]: ({ quickFunnels, slowFunnels }) => (
        <>
          It takes
          {' '}
          <b>{slowFunnels.value}</b>
          {' '}
          touchpoints to convert from
          {' '}
          <b>{slowFunnels.source.label}</b>
          {' '}
          to
          {' '}
          <b>{slowFunnels.dest.label}</b>
          , while only
          {' '}
          <b>{quickFunnels.value}</b>
          {' '}
          toucpoints from
          {' '}
          <b>{quickFunnels.dest.label}</b>
          {' '}
          to
          {' '}
          <b>{quickFunnels.dest.label}</b>
          .
        </>
      ),
    },
    numberOfContacts: {
      [journeyInsightTypes.optimizationMetrics.highRatio]: ({ quickFunnels, slowFunnels }) => (
        <>
          <b>{slowFunnels.value}</b>
          {' '}
          contacts involved in a conversion from
          {' '}
          <b>{slowFunnels.source.label}</b>
          {' '}
          to
          {' '}
          <b>{slowFunnels.dest.label}</b>
          , while only
          {' '}
          <b>{quickFunnels.value}</b>
          {' '}
          contacts from
          {' '}
          <b>{quickFunnels.source.label}</b>
          {' '}
          to
          {' '}
          <b>{quickFunnels.dest.label}</b>
          .
        </>
      ),
      [journeyInsightTypes.optimizationMetrics.highGrowthRatio]: ({ funnels }) => (
        <>
          It takes
          {' '}
          <b>{funnels.value}</b>
          {' '}
          contacts involved in a conversion from
          {' '}
          <b>{funnels.source.label}</b>
          {' '}
          to
          {' '}
          <b>{funnels.source.label}</b>
          {' '}
          for your optimal journeys, which is
          {' '}
          <b>{funnels.growth}</b>
          % compared to previous period.
        </>
      ),
      [journeyInsightTypes.optimizationMetrics.topAndBottom]: ({ quickFunnels, slowFunnels }) => (
        <>
          It takes
          {' '}
          <b>{slowFunnels.value}</b>
          {' '}
          contacts involved in a conversion from
          {' '}
          <b>{slowFunnels.source.label}</b>
          {' '}
          to
          {' '}
          <b>{slowFunnels.dest.label}</b>
          , while only
          {' '}
          <b>{quickFunnels.value}</b>
          {' '}
          contacts from
          {' '}
          <b>{quickFunnels.dest.label}</b>
          {' '}
          to
          {' '}
          <b>{quickFunnels.dest.label}</b>
          .
        </>
      ),
    },
  },
};
