import moment from 'moment-timezone';

import timeFrameModule from 'modules/timeframe';

import { getTSForTimezone } from 'stores/analyze/timeUtils';
import { nonUpDirectionMetricTypes } from 'components/common/logic/enums';

import { getFiscalQuarterForMonth, getFiscalYearForMonth } from './dates';

export function getMonthTitle({ startDate, timezone }) {
  return moment.parseZone(startDate).tz(timezone).format('MMMM, YYYY');
}

export function getQuarterTitle({ startDate, timezone, fiscalYearFirstMonth = 0 }) {
  const year = moment.parseZone(startDate).tz(timezone).format('YYYY');
  const fiscalMonth = moment.parseZone(startDate).tz(timezone).format('M');

  const fiscalQuarter = getFiscalQuarterForMonth({ month: fiscalMonth, fiscalYearFirstMonth });
  const fiscalYear = getFiscalYearForMonth(fiscalMonth, fiscalYearFirstMonth + 1, year);

  return `Quarter ${fiscalQuarter}, ${fiscalYear}`;
}

function safeDivide({ value, divider }) {
  return divider !== 0 ? value / divider : 1;
}

export function getGoalProgress({ metricType, planned, actual }) {
  const isUp = !nonUpDirectionMetricTypes.includes(metricType);
  return isUp
    ? safeDivide({ value: actual, divider: planned })
    : safeDivide({ value: planned, divider: actual });
}

export function getTimeFrameInAccountTZ({ timeframe }) {
  const startDateTimestamp = moment(timeframe.startDate).toDate().getTime();
  const endDateTimestamp = moment(timeframe.endDate).toDate().getTime();

  const startDateTZ = getTSForTimezone(startDateTimestamp);
  const endDateTZ = getTSForTimezone(endDateTimestamp);

  return {
    ...timeframe,
    startDate: new Date(startDateTZ),
    endDate: new Date(endDateTZ),
  };
}

export const getTimeData = (start, finish) => {
  const today = moment();
  const isCompleted = today.isAfter(finish);
  const isOngoing = today.isBetween(start, finish);
  const leftToFinish = finish.from(isOngoing ? today : start, true);
  return {
    isCompleted, isOngoing, leftToFinish, startDate: start, finishDate: finish,
  };
};

export function isGoalOnTrack({ metricType, project, target }) {
  const isDirectionUp = !nonUpDirectionMetricTypes.includes(metricType);
  return (isDirectionUp && project >= target) || (!isDirectionUp && project <= target);
}

export function getGoalTimeframe({ timeframe = {}, fiscalYearFirstMonth }) {
  const parsedTimeframe = timeFrameModule.getTimeframeParams({ ...timeframe, fiscalYearFirstMonth, useEndOfToday: false });
  return getTimeFrameInAccountTZ({
    timeframe: {
      value: timeframe.value,
      ...parsedTimeframe,
    },
  });
}
