exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".navigationMenuPopup__menuPopup-2D7{box-shadow:0 4px 12px rgba(24,32,51,.07);background:var(--colorsGeneralWhite);border:1px solid var(--colorsInputGrayLine);border-radius:4px;text-align:left}.navigationMenuPopup__headerTitle-3T8{background:#f5f6fb;font-weight:var(--fontsWeightBold);font-size:var(--fontsSizesBodyNormal);line-height:20px;color:#737ea4;padding:8px 12px;border-radius:4px 4px 0 0;font-weight:var(--fontsWeightMedium)}.navigationMenuPopup__menuPopupOptionTitle-3HH{display:flex;gap:6px}.navigationMenuPopup__navigationNewTag-1Yl{background:#ccf4e3;color:#00c875;font-size:var(--fontsSizesBodySmall);padding:0 6px;border-radius:4px;height:22px}.navigationMenuPopup__menuPopupOption-vGr{font-size:var(--fontsSizesBodyNormal);line-height:20px;margin:6px;padding:3px 6px;color:#182033;white-space:normal;font-weight:var(--fontsWeightMedium)}.navigationMenuPopup__menuPopupOption-vGr:hover{background-color:#f5f6fb;border-radius:4px;cursor:pointer}.navigationMenuPopup__menuPopupOptionDisabled-10w,.navigationMenuPopup__menuPopupOptionDisabled-10w:hover{cursor:default;opacity:.6;background:none}.navigationMenuPopup__optionSubtitle-2Q2{font-size:var(--fontsSizesBodySmall);line-height:20px;color:#737ea4;font-weight:var(--fontsWeightNormal)}.navigationMenuPopup__navigationMenuPopupControler-2qq{width:20px;height:20px;border-radius:4px;background:#e6e8ef url(/assets/settingsOfNavigationMenu.svg) 50% no-repeat}.navigationMenuPopup__navigationMenuPopupControler-2qq:hover{cursor:pointer;opacity:.5}", ""]);

// exports
exports.locals = {
	"menuPopup": "navigationMenuPopup__menuPopup-2D7",
	"menuPopup": "navigationMenuPopup__menuPopup-2D7",
	"headerTitle": "navigationMenuPopup__headerTitle-3T8",
	"headerTitle": "navigationMenuPopup__headerTitle-3T8",
	"menuPopupOptionTitle": "navigationMenuPopup__menuPopupOptionTitle-3HH",
	"menuPopupOptionTitle": "navigationMenuPopup__menuPopupOptionTitle-3HH",
	"navigationNewTag": "navigationMenuPopup__navigationNewTag-1Yl",
	"navigationNewTag": "navigationMenuPopup__navigationNewTag-1Yl",
	"menuPopupOption": "navigationMenuPopup__menuPopupOption-vGr",
	"menuPopupOption": "navigationMenuPopup__menuPopupOption-vGr",
	"menuPopupOptionDisabled": "navigationMenuPopup__menuPopupOptionDisabled-10w",
	"menuPopupOptionDisabled": "navigationMenuPopup__menuPopupOptionDisabled-10w",
	"optionSubtitle": "navigationMenuPopup__optionSubtitle-2Q2",
	"optionSubtitle": "navigationMenuPopup__optionSubtitle-2Q2",
	"navigationMenuPopupControler": "navigationMenuPopup__navigationMenuPopupControler-2qq",
	"navigationMenuPopupControler": "navigationMenuPopup__navigationMenuPopupControler-2qq"
};