import classnames from 'classnames';

import useStyles from 'hooks/useStyles';

import VelocityPill from 'components/widgets/optimalJourney/journeyCanvas/VelocityPill';
import TouchpointsPill from 'components/widgets/optimalJourney/journeyCanvas/TouchpointsPill';
import SegmentPill from 'components/widgets/optimalJourney/journeyCanvas/SegmentPill';

import { isSegmentBlock } from 'components/widgets/optimalJourney/logic/optimalJourney';
import { firstStageKey } from 'components/widgets/optimalJourney/journeyCanvas/enums';
import {
  kpiFocusOptionsKeys,
  numberOfTouchpoints,
  stageVelocity,
} from 'components/widgets/optimalJourney/enums';

import style from 'styles/analyze/journey-canvas.css';

const styles = style.locals || {};

export default function CanvasSection({
  data = {},
  label,
  isLoaded,
  lastSectionLabel,
  optimalJourneyKpi,
  blocksItems,
  colorsBySegment,
}) {
  useStyles([style]);

  const currentStage = data.currentStage || {};
  const destinationStage = data.destinationStage || {};
  const nextStage = data.nextStage || {};
  const lastStage = data.lastStage || {};

  const blocksItemsKeys = blocksItems.map((blockItem) => blockItem.value);
  const segmentsPills = blocksItems.filter((blockItem) => isSegmentBlock({ blockItem: blockItem.value }));
  const shouldHideSegmentPills = optimalJourneyKpi === kpiFocusOptionsKeys.conversionRateToLast && currentStage.value === firstStageKey;

  return (
    <div className={classnames(styles.canvasSectionWrapper)} data-testid="canvas-section">
      {lastSectionLabel ? (
        <div className={styles.canvasLastSectionLabel}>
          {lastSectionLabel}
        </div>
      ) : null}
      <div className={styles.canvasSectionLabel}>
        {label}
      </div>
      <div className={styles.canvasSectionContainer}>
        <div className={styles.sectionContentWrapper}>
          <div className={styles.topPillsWrapper}>
            {blocksItemsKeys.includes(stageVelocity) ? (
              <VelocityPill
                isLoaded={isLoaded.accountPerformance}
                data={data.velocity}
                currentStage={currentStage}
                nextStage={nextStage}
                lastStage={lastStage}
              />
            ) : null}

            {blocksItemsKeys.includes(numberOfTouchpoints) ? (
              <TouchpointsPill
                isLoaded={isLoaded.accountPerformance}
                data={data.numberOfSessions}
                currentStage={currentStage}
                nextStage={nextStage}
                lastStage={lastStage}
              />
            ) : null}
          </div>

          {segmentsPills.length && !shouldHideSegmentPills ? (
            <div className={styles.segmentsPillsWrapper}>
              {segmentsPills.map((pilItem, index) => {
                const pilItemKey = pilItem.value;
                const pillKey = `pill-${index}-${pilItemKey}`;
                const pillColor = colorsBySegment[pilItemKey];
                const isPillLoaded = isLoaded[pilItemKey];
                const dataPillItem = data[pilItemKey] || {};

                return (
                  <SegmentPill
                    key={pillKey}
                    isLoaded={isPillLoaded}
                    color={pillColor}
                    data={dataPillItem}
                    segment={pilItem}
                    currentStage={currentStage}
                    destinationStage={destinationStage}
                  />
                );
              })}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
